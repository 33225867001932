import PropTypes from 'prop-types';
import React, { useMemo, Fragment } from 'react';
import { Button } from "reactstrap";
import AWPopup from "@aviwest/ui-kit/dist/js/components/popup";
import { AWControlGridItemSelect } from '@aviwest/ui-kit/dist/js/components/control-grid';
import AWIcon from '@aviwest/ui-kit/dist/js/components/icon';
import { withLocalize } from "react-localize-redux";
import { Translate } from "react-localize-redux";

import { inputIpProfilePropTypes } from "../../../utils/models-prop-types";
import { INPUT_TYPE_DEVICE, INPUT_TYPE_IP, STATUS_OFF } from '../../../constants';
import { inputIcon } from '../../../utils/global-utils';

const propTypes = {
  input: PropTypes.object.isRequired,
  detach: PropTypes.bool,
  ipProfiles: PropTypes.arrayOf(inputIpProfilePropTypes).isRequired,
  maxWidth: PropTypes.number,
  name: PropTypes.string.isRequired,
  onProfileChange: PropTypes.func.isRequired,
  onProfileCreate: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

const defaultProps = {
  maxWidth: null,
  detach: false,
};

const IpProfileSelector = (props) => {

  const handleProfileSelect = (profile, close) => {
    onProfileChange(profile ? profile.id : null);
    close();
  };

  const handleProfileCreate = (close) => {
    onProfileCreate();
    close();
  };

  const {
    input,
    detach,
    ipProfiles,
    name,
    onProfileChange,
    onProfileCreate,
    translate,
    hasAdminLevel,
    hasViewerLevel,
    configTooBig
  } = props;

  const displayedName = useMemo(() => {
    if (!detach && !input.booking && input.deviceName == null) {
      return (
        <Fragment>
          <div className="text-clip" title={translate("genericLabel.AVAILABLE.text")}>
            <Translate id="genericLabel.AVAILABLE.text" />
          </div>
          <AWIcon name="caret_down" />
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          {(input.type === INPUT_TYPE_IP || input.type === INPUT_TYPE_DEVICE) && <AWIcon name={inputIcon(input.type, input.channelType)} />}
          {input.booking && <AWIcon name="booking" />}
          <div className="text-clip" title={input.booking ? input.booking : name}>{input.booking ? input.booking : name}</div>
          {(input.status === STATUS_OFF && !input.booking) && <AWIcon name="caret_down" />}
        </Fragment>
      )
    }
  }, [detach, name, input, translate]);

  return (
    <AWControlGridItemSelect>
      <AWPopup scrollable direction="bottom" portal={true} className="aw-input-selector-popup" renderTrigger={({ ref, onClick, className }) => (
        <Button innerRef={ref} className={className + " aw-profile-selector"} onClick={onClick}
          disabled={input.status !== STATUS_OFF || input.booking !== null || hasViewerLevel}>
          {displayedName}
        </Button>
      )}>
        {({ close }) => (
          <ul className={`${ipProfiles.length > 20 ? 'popup-condensed' : ''}`}>
            {ipProfiles.map((currentProfile) => (
              <li key={currentProfile.id}>
                <button
                  className={`text-clip${currentProfile.name === name ? ' active' : ''}`}
                  onClick={() => handleProfileSelect(currentProfile, close)}
                  title={currentProfile.name}>
                  <AWIcon name={inputIcon(INPUT_TYPE_IP, currentProfile.protocol)} className="mr-1"/>{currentProfile.name}
                </button>
              </li>
            ))}
            <li key={0}>
                <button
                  className="text-clip"
                  disabled={!hasAdminLevel || configTooBig}
                  onClick={() => handleProfileCreate(close)}
                  title={translate("genericLabel.NEW_IP_PROFILE.text")}>
                  <AWIcon name="add" className="mr-1"/>
                </button>
              </li>
          </ul>
        )}
      </AWPopup>
    </AWControlGridItemSelect>
  );
};

IpProfileSelector.propTypes = propTypes;
IpProfileSelector.defaultProps = defaultProps;

export default withLocalize(IpProfileSelector);