import PropTypes from "prop-types";
import React from 'react';
import {Translate, withLocalize} from "react-localize-redux";
import {Button, Form, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {Formik} from "formik";

import {isEmptyString, isLDAPValid} from "../../../../utils/string-utils";
import PasswordRevealInput from "../../../common/password-reveal-input";

const propTypes = {
  config: PropTypes.shape({
    enable: PropTypes.bool,
    url: PropTypes.string,
    username: PropTypes.string,
    password: PropTypes.string,
    users: PropTypes.string,
    groupAdmin: PropTypes.string,
    groupUser: PropTypes.string,
    groupViewer: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired
};

const LdapSettingsForm = (props) => {

  const { config, translate } = props;

  const handleSubmit = (values) => {
    props.onSubmit(values);
  };

  const handleValidation = (values) => {
    const errors = {};

    if (values.enable === true) {
      if(isEmptyString(values.url)){
        errors.url = 'genericLabel.REQUIRED_FIELD.text';
      } else if (!isLDAPValid(values.url)) {
        errors.url = 'genericLabel.INVALID_FORMAT.text';
      }
      if(isEmptyString(values.username)){
        errors.username = 'genericLabel.REQUIRED_FIELD.text';
      }
      if(isEmptyString(values.password)){
        errors.password = 'genericLabel.REQUIRED_FIELD.text';
      }
      if(isEmptyString(values.users)){
        errors.users = 'genericLabel.REQUIRED_FIELD.text';
      }
      if(isEmptyString(values.groupAdmin)){
        errors.groupAdmin = 'genericLabel.REQUIRED_FIELD.text';
      }
      if(isEmptyString(values.groupUser)){
        errors.groupUser = 'genericLabel.REQUIRED_FIELD.text';
      }
      if(isEmptyString(values.groupViewer)){
        errors.groupViewer = 'genericLabel.REQUIRED_FIELD.text';
      }
    }

    return errors;
  };


  return (
    <Formik initialValues={{
              enable: config ? config.enable : false,
              url: config ? config.url : '',
              username: config ? config.username : '',
              password: config ? config.password : '',
              users: config ? config.users : '',
              groupAdmin: config ? config.groupAdmin : '',
              groupUser: config ? config.groupUser : '',
              groupViewer: config ? config.groupViewer : '',
            }}
            validate={ handleValidation }
            validateOnBlur={false}
            validateOnChange={true}
            onSubmit={ handleSubmit }>
      {({
          values,
          errors,
          dirty,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting
          /* and other goodies */
        }) => {
          return (
            <Form onSubmit={ handleSubmit }>
              <FormGroup check>
                <Label check>
                  <Input id="security_ldap_enable"
                         type="checkbox"
                         name="enable"
                         onChange={handleChange}
                         checked={values.enable}/>{' '}
                  <Translate id="genericLabel.LDAP_ENABLE.text"/>
                </Label>
              </FormGroup>
              { values.enable &&
              <>
                <FormGroup>
                  <Label for="url">
                    <Translate id="genericLabel.URL.text"/>
                  </Label>
                  <Input type="text"
                         name="url"
                         id="security_ldap_url"
                         invalid={errors.url !== undefined}
                         placeholder={translate('genericLabel.URL.text')}
                         value={values.url}
                         onChange={handleChange}/>
                  <FormFeedback>
                    <Translate id={errors.url}/>
                  </FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="username">
                    <Translate id="genericLabel.USERNAME.text"/>
                  </Label>
                  <Input type="text"
                        name="username"
                        id="security_ldap_username"
                        invalid={errors.username !== undefined}
                        placeholder={translate('genericLabel.USERNAME.text')}
                        value={values.username}
                        onChange={handleChange}/>
                  <FormFeedback>
                    <Translate id={errors.username}/>
                  </FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="password">
                    <Translate id="genericLabel.PASSWORD.text"/>
                  </Label>
                  <PasswordRevealInput name="password"
                                      id="security_ldap_dpassword"
                                      setFieldValue={setFieldValue}
                                      invalid={errors.password !== undefined}
                                      placeholder={ translate('genericLabel.PASSWORD.text') }
                                      value={values.password}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      error={errors.password}/>
                </FormGroup>
                <div className="header">
                  <div className="title secondary">
                    <Translate id="genericLabel.LDAP_REQUEST.text"/>
                  </div>
                </div>
                <FormGroup>
                  <Label for="users">
                    <Translate id="genericLabel.LDAP_REQUEST_USERS.text"/>
                  </Label>
                  <Input type="text"
                        name="users"
                        id="security_ldap_users"
                        invalid={errors.users !== undefined}
                        placeholder={translate('genericLabel.LDAP_REQUEST_USERS_PLACEHOLDER.text')}
                        value={values.users}
                        onChange={handleChange}/>
                  <FormFeedback>
                    <Translate id={errors.users}/>
                  </FormFeedback>
                  <div className="indicator">
                    <Translate id="genericLabel.LDAP_REQUEST_USERS_HELP.text"/>
                  </div>
                </FormGroup>
                <FormGroup>
                  <Label for="groupAdmin">
                    <Translate id="genericLabel.LDAP_REQUEST_GROUP_ADMIN.text"/>
                  </Label>
                  <Input type="text"
                        name="groupAdmin"
                        id="security_ldap_groupAdmin"
                        invalid={errors.groupAdmin !== undefined}
                        placeholder={translate('genericLabel.LDAP_REQUEST_GROUP_ADMIN_PLACEHOLDER.text')}
                        value={values.groupAdmin}
                        onChange={handleChange}/>
                  <FormFeedback>
                    <Translate id={errors.groupAdmin}/>
                  </FormFeedback>
                  <div className="indicator">
                    <Translate id="genericLabel.LDAP_REQUEST_GROUP_ADMIN_HELP.text"/>
                  </div>
                </FormGroup>
                <FormGroup>
                  <Label for="groupUser">
                    <Translate id="genericLabel.LDAP_REQUEST_GROUP_USER.text"/>
                  </Label>
                  <Input type="text"
                        name="groupUser"
                        id="security_ldap_groupUser"
                        invalid={errors.groupUser !== undefined}
                        placeholder={translate('genericLabel.LDAP_REQUEST_GROUP_USER_PLACEHOLDER.text')}
                        value={values.groupUser}
                        onChange={handleChange}/>
                  <FormFeedback>
                    <Translate id={errors.groupUser}/>
                  </FormFeedback>
                  <div className="indicator">
                    <Translate id="genericLabel.LDAP_REQUEST_GROUP_USER_HELP.text"/>
                  </div>
                </FormGroup>
                <FormGroup>
                  <Label for="groupViewer">
                    <Translate id="genericLabel.LDAP_REQUEST_GROUP_VIEWER.text"/>
                  </Label>
                  <Input type="text"
                        name="groupViewer"
                        id="security_ldap_groupViewer"
                        invalid={errors.groupViewer !== undefined}
                        placeholder={translate('genericLabel.LDAP_REQUEST_GROUP_VIEWER_PLACEHOLDER.text')}
                        value={values.groupViewer}
                        onChange={handleChange}/>
                  <FormFeedback>
                    <Translate id={errors.groupViewer}/>
                  </FormFeedback>
                  <div className="indicator">
                    <Translate id="genericLabel.LDAP_REQUEST_GROUP_VIEWER_HELP.text"/>
                  </div>
                </FormGroup>
              </>
              }
              <FormGroup className="buttons">
                <Button id="security_ldap_saveButton"
                        color="primary"
                        disabled={isSubmitting || !dirty}
                        type="submit">
                  <Translate id="genericLabel.SAVE.text"/>
                </Button>
              </FormGroup>
            </Form>
          )
        }
      }
    </Formik>
  )
};

LdapSettingsForm.propTypes = propTypes;

export default withLocalize(LdapSettingsForm);