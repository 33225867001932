import PropTypes from "prop-types";
import React, {Component} from "react";
import {Translate} from "react-localize-redux";
import Slider from "rc-slider";

import {deviceLiveInfoPropTypes} from "../../../../../utils/models-prop-types";
import {Button, Form, FormGroup} from "reactstrap";
import {formatMilliToSeconds, formatBitrate} from "../../../../../utils/global-utils";
import { VIDEO_BITRATE_MODE_CBR } from "../../../../../constants";

const propTypes = {
  sliderLatencyCapability: PropTypes.bool.isRequired,
  liveInfo: deviceLiveInfoPropTypes.isRequired,
  onLatencyChange: PropTypes.func.isRequired,
  onCappedBitrateChange: PropTypes.func.isRequired,
  hasViewerLevel: PropTypes.bool,
};

const STEP = 100;

class LatencyBitrateControls extends Component {

  constructor(props){
    super(props);
    this.latencyInputRef = React.createRef();
    this.bitrateInputRef = React.createRef();

    this.handleLatencySliderChange = this.handleLatencySliderChange.bind(this);
    this.handleBitrateSliderChange = this.handleBitrateSliderChange.bind(this);
    this.toggleLatencyEdit = this.toggleLatencyEdit.bind(this);
    this.toggleBitrateEdit = this.toggleBitrateEdit.bind(this);

    this.state = {
      delay: props.liveInfo.delay,
      videoCappedBitrate: props.liveInfo.videoCappedBitrate,
      bitrateEdit: false,
      lantencyEdit: false
    };
  }

  componentWillUnmount(){
    this.clearLatencyTimer();
    this.clearBitrateTimer();
  }

  componentDidUpdate(prevProps){
    if (this.props.liveInfo.delay !== prevProps.liveInfo.delay) {
      this.setState({delay: this.props.liveInfo.delay})
    }

    if (this.props.liveInfo.videoCappedBitrate !== prevProps.liveInfo.videoCappedBitrate) {
      this.setState({videoCappedBitrate: this.props.liveInfo.videoCappedBitrate})
    }

    // Set focus on input latency
    if (this.latencyInputRef.current) {
      this.latencyInputRef.current.focus();
    }

    // Set focus on input bitrate
    if (this.bitrateInputRef.current) {
      this.bitrateInputRef.current.focus();
    }
  }

  handleLatencySliderChange(value){
    this.setState({
      delay: value
    });

    this.clearLatencyTimer();
    this.latencyTimer = setTimeout(() => {
      this.props.onLatencyChange(value);
    }, 1000);
  }

  handleBitrateSliderChange(value){
    this.setState({
      videoCappedBitrate: value
    });

    this.clearBitrateTimer();
    this.bitrateTimer = setTimeout(() => {
      this.props.onCappedBitrateChange(value);
    }, 1000);
  }

  toggleBitrateEdit(){
    this.setState({
      bitrateEdit: !this.state.bitrateEdit
    });
  }

  toggleLatencyEdit(){
    this.setState({
      latencyEdit: !this.state.latencyEdit
    });
  }

  clearLatencyTimer(){
    if(this.latencyTimer){
      clearTimeout(this.latencyTimer);
      this.latencyTimer = null;
    }
  }

  clearBitrateTimer(){
    if(this.bitrateTimer){
      clearTimeout(this.bitrateTimer);
      this.bitrateTimer = null;
    }
  }

  render() {
    const { liveMinLatency, liveMaxLatency, videoMinBitrate, videoMaxBitrate, videoBitrateMode } = this.props.liveInfo;
    const { hasViewerLevel} = this.props;
    // We handle dynamic latency only when
    const showLatencyControls = this.props.sliderLatencyCapability && (liveMinLatency !== null && liveMaxLatency !== null);

    // If CBR, we don't handle videoCappedBitrate
    const showBitrateControls = videoBitrateMode !== VIDEO_BITRATE_MODE_CBR;

    if(!showLatencyControls && !showBitrateControls){
      return null;
    }

    const { delay, videoCappedBitrate } = this.state;
    const latencySTEP = liveMaxLatency < 1000 ? 10 : STEP;
    return (
      <div className="settings-controls">
        <div className="controls rounded">
          {showLatencyControls &&
          <div className="latency-controls">
            <Form className="form-inline">
              <label className="text-secondary">
                <Translate id="genericLabel.LATENCY.text"/>
              </label>
              <div className="manual">
                { !this.state.latencyEdit &&
                <Button className="basic"
                        onClick={this.toggleLatencyEdit}
                        disabled={liveMinLatency === liveMaxLatency}>
                  <span className="value">{formatMilliToSeconds(delay)}</span>
                </Button>
                }
                { this.state.latencyEdit &&
                <FormGroup>
                  <input placeholder={delay}
                    type="number"
                    ref={this.latencyInputRef}
                    className="form-control-sm form-control"
                    id="liveLatency"
                    min={liveMinLatency}
                    max={liveMaxLatency}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        this.handleLatencySliderChange(
                          parseInt(e.target.value) >= liveMinLatency && parseInt(e.target.value) <= liveMaxLatency ? parseInt(e.target.value) : delay);
                        this.toggleLatencyEdit()
                      }
                    }}
                    onBlur={(e) => {this.toggleLatencyEdit()}}
                  />
                </FormGroup>
                }
              </div>
            </Form>
            <Slider className="aw-theme"
                    horizontal
                    id="latencyControls_sliderLantecy"
                    min={liveMinLatency}
                    max={liveMaxLatency}
                    step={latencySTEP}
                    onChange={this.handleLatencySliderChange}
                    value={delay}
                    disabled={ hasViewerLevel}/>
            <span className="min-value">
              { formatMilliToSeconds(liveMinLatency) }
            </span>
            <span className="max-value">
              { formatMilliToSeconds(liveMaxLatency) }
            </span>
          </div>
          }
          {showBitrateControls &&
          <div className="bitrate-controls">
            <Form className="form-inline">
              <label className="text-secondary">
                <Translate id="genericLabel.VIDEO_CAPPED_BITRATE.text"/>
              </label>
              <div className="manual">
                { !this.state.bitrateEdit &&
                <Button className="basic"
                        onClick={this.toggleBitrateEdit}
                        disabled={videoMinBitrate === videoMaxBitrate}>
                  <span className="value">{formatBitrate(videoCappedBitrate * 1000)}</span>
                </Button>
                }
                { this.state.bitrateEdit &&
                <FormGroup>
                  <input placeholder={videoCappedBitrate}
                    type="number"
                    ref={this.bitrateInputRef}
                    className="form-control-sm form-control"
                    id="bitrateControls_videoBitrate"
                    min={videoMinBitrate}
                    max={videoMaxBitrate}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        this.handleBitrateSliderChange(
                          parseInt(e.target.value) >= videoMinBitrate && parseInt(e.target.value) <= videoMaxBitrate ? parseInt(e.target.value) : videoCappedBitrate);
                        this.toggleBitrateEdit()
                      }
                    }}
                    onBlur={(e) => {this.toggleBitrateEdit()}}
                  />
                </FormGroup>
                }
              </div>
            </Form>

            <Slider className="aw-theme"
                    horizontal
                    id="bitrateControls_slider"
                    min={videoMinBitrate}
                    max={videoMaxBitrate}
                    step={STEP}
                    onChange={this.handleBitrateSliderChange}
                    value={videoCappedBitrate}
                    disabled={ hasViewerLevel}/>
            <span className="min-value">
              { formatBitrate(videoMinBitrate * 1000) }
            </span>
            <span className="max-value">
              { formatBitrate(videoMaxBitrate * 1000) }
            </span>
          </div>
          }
        </div>
      </div>
    );
  }
}

LatencyBitrateControls.propTypes = propTypes;

export default LatencyBitrateControls;