import PropTypes from 'prop-types';
import React from 'react';

import {Translate} from "react-localize-redux";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";
import { formatMonitorSize } from "../../../../utils/global-utils";
import { AWControlGrid, AWControlGridItem} from '@aviwest/ui-kit';

const propTypes = {
    interfaces: PropTypes.shape({
        rxValue: PropTypes.number.isRequired,
        txValue: PropTypes.number.isRequired,
        capacity: PropTypes.number.isRequired
  })
};

const Network = (props) => {
  const { info } = props;
  if(!info){
    return null;
  }
  return (
    <>
   { info.interfaces.length !== 0 &&
      <div className="header">
                <div className="title">
                  <Translate id="genericLabel.NETWORK.text"/>
                </div>
        </div>}
    <AWControlGrid>
      {info.interfaces.map((inface, index)=> {
        const input =  inface.rxValue <1 ? (inface.rxValue*1000).toFixed(0)+" Kb/s" : ( inface.rxValue >=1000 ? (inface.rxValue/1000).toFixed(1)+" Gb/s" : (inface.rxValue).toFixed(0)+" Mb/s"); 
        const output = inface.txValue <1 ? (inface.txValue*1000).toFixed(0)+" Kb/s" : ( inface.txValue >=1000 ? (inface.txValue/1000).toFixed(1)+" Gb/s" : (inface.txValue).toFixed(0)+" Mb/s"); 
        const capacity = inface.capacity >= 1000 ? (inface.capacity/1000)+" Gb/s" : (inface.capacity).toFixed(1)+" Mb/s";
        const capacityClass = (inface.rxValue+inface.txValue) > inface.capacity*0.9 ? 'aw-alert' : ((inface.rxValue+inface.txValue) > inface.capacity*0.7 ? 'aw-warning' : '');
        return(
          <AWControlGridItem >
                  <div className="network-monitor">
                    <div className="section">
                      <div className="title grey">
                        <AWIcon name="network"/>
                       <Translate id="genericLabel.NETWORK.text"/> {index+1}
                      </div>
                    </div>
                    <div className={`section ${capacityClass} `}>
                      <div className={`value ${capacityClass}`}>
                        {input}
                      </div>
                      <p id="system_memory_value" value={formatMonitorSize(info.memoryTotal)} className="grey"><AWIcon name="arrow_down" /> <Translate id="genericLabel.INPUT_TRAFFIC.text"/></p>
                    </div>
                    <div className={`section ${capacityClass}`}>
                      <div className={`value ${capacityClass}`}>
                        {output}
                      </div>
                      <p id="system_memory_value" value={formatMonitorSize(info.memoryTotal)} className="grey"><AWIcon name="arrow_up" /> <Translate id="genericLabel.OUTPUT_TRAFFIC.text"/></p>
                    </div>
                    <div className="section">
                      <div className="subtitle">
                       <p> <span className="label grey">Port speed : </span>{capacity}</p> 
                      </div>
                      <div className="subtitle">
                       <p> <span className="label grey">Private IP : </span>{inface.ipAddress}</p> </div>
                    </div>
                  </div>
          </AWControlGridItem>
          
        )
      })          
    }
    </AWControlGrid>
    </>
  );
};

Network.propTypes = propTypes;

export default Network;