import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import {Translate, withLocalize} from 'react-localize-redux';
import { connect } from 'react-redux';
import { Button, Form, FormFeedback, FormGroup, Input, Label, InputGroupAddon, Alert } from 'reactstrap';
import {Formik} from "formik";

import { updateInputSettings } from '../../../settings/settings.actions';
import { inputPropTypes, encoderPropTypes, inputIpProfilePropTypes, audioDevicePropTypes} from "../../../../../utils/models-prop-types";
import { isEmptyString } from '../../../../../utils/string-utils';
import {inputDisplayName, inputMatchingIPProfile} from "../../../../../utils/global-utils";
import AWSwitch from "@aviwest/ui-kit/dist/js/components/switch";
import { Link } from 'react-router-dom';
import { inputEditSettings } from '../../dashboard.actions';
import AWHeading from '@aviwest/ui-kit/dist/js/components/heading';
import { INPUT_TYPE_IP, STATUS_ERROR, STATUS_OFF, VIDEO_IFB_LATENCY_MIN, VIDEO_IFB_LATENCY_MAX } from '../../../../../constants';
import { canSetIntercom } from '../../../../../misc/license.selectors';
import HelpLayout from '../../../../common/help-layout';

const profiles = [
  {
    value: 0,
    label: 'Low',
    labelShort: 'Low'
  },
  {
    value: 1,
    label: 'Medium',
    labelShort: 'Medium'
  },
  {
    value: 2,
    label: 'High',
    labelShort: 'High'
  },
];

const propTypes = {
  callUpdateInputSettings: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  inputId: PropTypes.string.isRequired,
  config: PropTypes.shape({
    name: PropTypes.string.isRequired,
    lostStreamTimeout: PropTypes.number.isRequired,
    autoRecord: PropTypes.bool.isRequired,
    autoPlay: PropTypes.bool.isRequired,
    recorderPrefix: PropTypes.string,
    videoIFBSourceIdx: PropTypes.number.isRequired,
    videoIFBEncoderIdx: PropTypes.number.isRequired,
    videoIFBLatency: PropTypes.number.isRequired,
    intercomConfig: PropTypes.shape({
      audioInputMap: PropTypes.string,
      audioOutputMap: PropTypes.string,
      intercomProfile: PropTypes.number
    }),
    intercomAutoStart: PropTypes.bool
  }),
  inputIPProfiles: PropTypes.arrayOf(inputIpProfilePropTypes),
  videoReturnInputs: PropTypes.arrayOf(inputPropTypes),
  videoReturnEncoders: PropTypes.arrayOf(encoderPropTypes),
  audioInputDevice: audioDevicePropTypes,
  audioOutputDevice: audioDevicePropTypes,
  canSetIntercom: PropTypes.bool.isRequired,
  inputType: PropTypes.string,
  channelType: PropTypes.string,
  inputActive: PropTypes.bool.isRequired,
  inputBooking: PropTypes.string,
  ipProfile: inputIpProfilePropTypes,
};

const LOST_STREAM_TIMEOUT_MIN = 10
const LOST_STREAM_TIMEOUT_MAX = 30

  class OptionsForm extends Component {

    constructor(props){
      super(props);
      this.toggleVideoReturnFromEncoder = this.toggleVideoReturnFromEncoder.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);
      this.handleValidation = this.handleValidation.bind(this);
      this.mapValues = this.mapValues.bind(this);

      this.state = {
        videoReturnFromEncoder: this.props.config && this.props.config.videoIFBEncoderIdx > -1
      }
    }

    mapValues(maxEntries, audioMapString) {
      const audioMap = JSON.parse(audioMapString);
      return [...Array(maxEntries)].map((_, i) => {
        return audioMap.indexOf(i) !== -1;
      });
    };

    toggleVideoReturnFromEncoder(setFieldValue){
      setFieldValue('videoIFBSourceIdx', -1)
      setFieldValue('videoIFBEncoderIdx', -1)
      this.setState({
        videoReturnFromEncoder: !this.state.videoReturnFromEncoder
      });
    }

    handleFormSubmit = (values, { resetForm }) => {

      const activeInputChannels = [];
      values.inputDeviceValues.forEach((value, index) => {
        if(value === true){
          activeInputChannels.push(index);
        }
      });

      const activeOutputChannels = [];
      values.outputDeviceValues.forEach((value, index) => {
        if(value === true){
          activeOutputChannels.push(index);
        }
      });

      const data = {
        ...values,
        intercom: {
          intercomProfile: parseInt(values.profile),
          audioInputMap: JSON.stringify(activeInputChannels),
          audioOutputMap: JSON.stringify(activeOutputChannels)
        }
      };

      // Check for video return
      if( !this.state.videoReturnFromEncoder) {
        data['videoIFBEncoderIdx'] = -1;
      }
      else {
        data['videoIFBSourceIdx'] = -1;
      }
      this.props.callUpdateInputSettings(data);
      resetForm(values);
      this.props.closeModal();
    }

    handleValidation = (values) => {
      const errors = {};
      if(isEmptyString(values.name)){
        errors.name = 'genericLabel.REQUIRED_FIELD.text';
      }
      // Recorder prefix
      const recorderPrefixRegex = /^\w*$/
      if (!isEmptyString(values.recorderPrefix)) {
        if (values.recorderPrefix.length < 3) {
          errors.recorderPrefix = 'genericLabel.INVALID_PREFIX_MIN.text';
        } else if (!recorderPrefixRegex.test(values.recorderPrefix)) {
          errors.recorderPrefix = 'genericLabel.INVALID_PREFIX.text';
        }
      }

      if(isEmptyString(values.lostStreamTimeout)){
        errors.lostStreamTimeout = 'genericLabel.REQUIRED_FIELD.text';
      }
      else if (values.lostStreamTimeout < LOST_STREAM_TIMEOUT_MIN || values.lostStreamTimeout > LOST_STREAM_TIMEOUT_MAX) {
        errors.lostStreamTimeout = 'genericLabel.INVALID_FORMAT.text';
      }
      if(isEmptyString(values.videoIFBLatency)){
        errors.videoIFBLatency = 'genericLabel.REQUIRED_FIELD.text';
      }
      else if (values.videoIFBLatency < VIDEO_IFB_LATENCY_MIN || values.videoIFBLatency > VIDEO_IFB_LATENCY_MAX) {
        errors.videoIFBLatency = 'genericLabel.INVALID_FORMAT.text';
      }
      return errors;
    }

  render() {
    const {
      translate,
      closeModal,
      config,
      inputIPProfiles,
      videoReturnInputs,
      videoReturnEncoders,
      audioInputDevice,
      audioOutputDevice,
      canSetIntercom,
      inputType,
      channelType,
      inputActive,
      inputBooking,
      generalIntercomConfig
    } = this.props;
    return (
      <Formik initialValues={{
                name: config ? config.name : '',
                lostStreamTimeout: config ? config.lostStreamTimeout : '',
                autoRecord: config ? config.autoRecord : false,
                autoPlay: config ? config.autoPlay : false,
                recorderPrefix: config && config.recorderPrefix ? config.recorderPrefix : '',
                videoIFBSourceIdx: config ? config.videoIFBSourceIdx : -1,
                videoIFBEncoderIdx: config ? config.videoIFBEncoderIdx : -1,
                videoIFBLatency: config ? config.videoIFBLatency : '',
                inputDeviceValues: (config && config.intercomConfig && audioInputDevice)? this.mapValues(audioInputDevice.max_entries, config.intercomConfig.audioInputMap) : [],
                outputDeviceValues: (config && config.intercomConfig && audioOutputDevice) ? this.mapValues(audioOutputDevice.max_entries, config.intercomConfig.audioOutputMap) : [],
                profile: config.intercomConfig ? config.intercomConfig.intercomProfile : 1
              }}
              validate={ this.handleValidation }
              validateOnBlur={false}
              validateOnChange={true}
              onSubmit={ this.handleFormSubmit }>
        {({
            values,
            errors,
            dirty,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue
            /* and other goodies */
          }) => {
          return (
          <Form className="input-settings-form"
                onSubmit={handleSubmit}>
            <HelpLayout
            filename="c_sh_configuring_input_settings.html"
            form={
              <Fragment>
                { inputBooking &&
                <Alert color="info">
                  { this.props.translate('genericLabel.INPUT_IS_BOOKED.text', { 'param.booking': inputBooking }) }
                </Alert>
                }
                { inputActive &&
                <Alert color="warning">
                  <Translate id="genericLabel.MODIFYING_CONFIGURATION_INGEST_PROFILE_MAY_DISCONNECT_DEVICE.text"/>
                </Alert>
                }
                <AWHeading priority={4}>
                  <Translate id="genericLabel.GENERAL.text"/>
                </AWHeading>

                <FormGroup>
                  <Label for="name">
                    <Translate id="genericLabel.NAME.text"/>
                  </Label>
                  <Input type="text"
                        required
                        name="name"
                        id="input_settings_name"
                        invalid={errors.name !== undefined}
                        placeholder={translate("genericLabel.NAME.text")}
                        value={values.name}
                        onBlur={handleBlur}
                        onChange={handleChange}/>
                  <FormFeedback>
                    <Translate id={errors.name} />
                  </FormFeedback>
                </FormGroup>

                <FormGroup>
                  <Label for="lostStreamTimeout">
                    <Translate id="genericLabel.TIMEOUT.text"/>
                  </Label>
                  <Input type="number"
                        required
                        name="lostStreamTimeout"
                        id="input_settings_lostStreamTimeout"
                        min={LOST_STREAM_TIMEOUT_MIN}
                        max={LOST_STREAM_TIMEOUT_MAX}
                        invalid={errors.lostStreamTimeout !== undefined}
                        placeholder={translate("genericLabel.TIMEOUT.text")}
                        value={values.lostStreamTimeout}
                        onChange={handleChange}/>
                  <FormFeedback>
                    <Translate id={errors.lostStreamTimeout} />
                  </FormFeedback>
                  <div className="indicator">
                    <Translate id="genericLabel.TIMEOUT_LOST_STREAM_MODE.text"/>
                  </div>
                </FormGroup>

                <FormGroup>
                  <Label for="recorderPrefix">
                    <Translate id="genericLabel.PREFIX.text"/>
                  </Label>
                  <Input type="text"
                        name="recorderPrefix"
                        id="input_settings_recorderPrefix"
                        invalid={errors.recorderPrefix !== undefined}
                        placeholder={translate("genericLabel.PREFIX.text")}
                        value={values.recorderPrefix}
                        onBlur={handleBlur}
                        onChange={handleChange}/>
                  <FormFeedback>
                    <Translate id={errors.recorderPrefix} />
                  </FormFeedback>
                  <div className="indicator">
                    <Translate id="genericLabel.PREFIX_HELP.text"/>
                  </div>
                </FormGroup>

                <FormGroup check>
                  <Label check>
                    <Input type="checkbox"
                          name="autoRecord"
                          id="input_settings_autoRecord"
                          onChange={handleChange}
                          checked={values.autoRecord}/>{' '}
                    <Translate id="genericLabel.ENABLE_RECORD_AUTO_START.text"/>
                  </Label>
                </FormGroup>

                <FormGroup check>
                  <Label check>
                    <Input type="checkbox"
                          name="autoPlay"
                          id="input_settings_autoPlay"
                          onChange={handleChange}
                          checked={values.autoPlay}/>{' '}
                    <Translate id="genericLabel.ENABLE_AUTO_PLAY.text"/>
                  </Label>
                </FormGroup>

                {(inputType !== INPUT_TYPE_IP || channelType === 'WEBRTC') && <FormGroup>
                  <label>
                    <Translate id="genericLabel.VIDEO_RETURN.text"/>
                  </label>
                  <div className="input-group">
                    <InputGroupAddon addonType="prepend">
                    <div className="push-pull" id="input_settings_pushPull">
                      <div className="push">
                        <Translate id="genericLabel.INPUT.text"></Translate>
                      </div>
                      <AWSwitch checked={this.state.videoReturnFromEncoder}
                              onChange={() => this.toggleVideoReturnFromEncoder(setFieldValue)}/>
                      <div className="pull">
                        <Translate id="genericLabel.ENCODER.text"></Translate>
                      </div>
                    </div>
                    </InputGroupAddon>
                    { !this.state.videoReturnFromEncoder &&
                    <Input type="select"
                          name="videoIFBSourceIdx"
                          id="input_settings_videoIFBSourceIdx"
                          value={values.videoIFBSourceIdx}
                          onChange={handleChange}>
                      <option value="-1">{translate("genericLabel.NONE.text")}</option>
                      { videoReturnInputs
                          .map(videoReturnInput => <option key={videoReturnInput.index} value={videoReturnInput.index} id={inputDisplayName(videoReturnInput, inputMatchingIPProfile(inputIPProfiles, videoReturnInput))}>{inputDisplayName(videoReturnInput, inputMatchingIPProfile(inputIPProfiles, videoReturnInput))}</option>)
                      }
                    </Input>
                    }
                    { this.state.videoReturnFromEncoder &&
                    <Input type="select"
                          name="videoIFBEncoderIdx"
                          id="input_settings_videoIFBEncoderIdx"
                          value={values.videoIFBEncoderIdx}
                          onChange={handleChange}>
                      <option value="-1">{translate("genericLabel.NONE.text")}</option>
                      { videoReturnEncoders
                          .map(videoReturnEncoder => <option key={videoReturnEncoder.index} value={videoReturnEncoder.index} id={videoReturnEncoder.displayName}>{videoReturnEncoder.displayName}</option>)
                      }
                    </Input>
                    }
                  </div>
                </FormGroup>}

                { (values.videoIFBSourceIdx > -1 || values.videoIFBEncoderIdx > -1) &&
                <FormGroup>
                  <Label for="videoIFBLatency">
                    <Translate id="genericLabel.VIDEO_IFB_LATENCY.text"/>
                  </Label>
                  <Input type="number"
                        disabled={inputType === INPUT_TYPE_IP &&  channelType !== 'WEBRTC'}
                        required
                        min={VIDEO_IFB_LATENCY_MIN}
                        max={VIDEO_IFB_LATENCY_MAX}
                        name="videoIFBLatency"
                        id="input_settings_videoIFBLatency"
                        invalid={errors.videoIFBLatency !== undefined}
                        placeholder={translate("genericLabel.VIDEO_IFB_LATENCY.text")}
                        value={values.videoIFBLatency}
                        onChange={handleChange}/>
                  <FormFeedback>
                    <Translate id={errors.videoIFBLatency} />
                  </FormFeedback>
                  <div className="indicator">
                    <Translate id="genericLabel.LATENCY_MS.text"/>
                  </div>
                </FormGroup>
                }

                { canSetIntercom && (inputType !== INPUT_TYPE_IP || channelType === 'WEBRTC') &&
                  <Fragment><AWHeading priority={4}><Translate id="genericLabel.INTERCOM.text"/></AWHeading>

                  { audioInputDevice !== null && config.intercomAutoStart && (
                    !values.inputDeviceValues.reduce((acc, value) => acc || value, false) ||
                    !values.outputDeviceValues.reduce((acc, value) => acc || value, false)
                    ) &&
                  <Alert color="warning">
                    <Translate id="genericLabel.SET_AT_LEAST_ONE_INPUT_AND_ONE_OUTPUT.text"/>
                    <Link className="alert-link floating-right"
                          to="/settings/intercom"
                          onClick={ closeModal }>
                      <Translate id="genericLabel.SETTINGS.text"/>
                    </Link>
                  </Alert>
                  }

                  { audioInputDevice !== null &&
                  <FormGroup>
                    <Label className="d-block" for="inputDevice">
                      <Translate id="genericLabel.INPUT.text"/>
                    </Label>
                    <FormGroup id="inputDevice" check inline>
                      { [...Array(generalIntercomConfig.nbInputVisible < audioInputDevice.max_entries ? generalIntercomConfig.nbInputVisible : audioInputDevice.max_entries)].map((value, key) => {
                        return <Label check key={key}>
                            <Input type="checkbox"
                                  id={"input_settings_inputDeviceValues_"+key}
                                  name={`inputDeviceValues[${key}]`}
                                  onChange={handleChange}
                                  checked={values.inputDeviceValues[key] === true}/>
                              {`i.${key + 1}`}
                          </Label>;
                      }) }
                    </FormGroup>
                  </FormGroup>
                  }
                  { audioInputDevice === null &&
                  <Alert color="warning">
                    <Translate id="genericLabel.INTERCOM_INPUT_DEVICE_NOT_CONFIGURED.text"/>
                    <Link className="alert-link floating-right"
                          to="/settings/intercom"
                          onClick={ closeModal }>
                      <Translate id="genericLabel.SETTINGS.text"/>
                    </Link>
                  </Alert>
                  }
                  { audioOutputDevice !== null &&
                  <FormGroup>
                    <Label className="d-block" for="outputDevice">
                      <Translate id="genericLabel.OUTPUT.text"/>
                    </Label>
                    <FormGroup id="outputDevice" check inline>
                      { [...Array(generalIntercomConfig.nbOutputVisible < audioOutputDevice.max_entries ? generalIntercomConfig.nbOutputVisible : audioOutputDevice.max_entries)].map((value, key) => {
                        return <Label check key={key} id={"outputDevice_"+(key + 1)} >
                        <Input 
                              type="checkbox"
                              id={"input_settings_outputDeviceValues_"+key}
                              name={`outputDeviceValues[${key}]`}
                              onChange={handleChange}
                              checked={values.outputDeviceValues[key] === true}/>
                          {`o.${key + 1}`}
                        </Label>;
                      }) }
                    </FormGroup>
                  </FormGroup>
                  }
                  { audioOutputDevice === null &&
                  <Alert color="warning">
                    <Translate id="genericLabel.INTERCOM_OUTPUT_DEVICE_NOT_CONFIGURED.text"/>
                    <Link className="alert-link floating-right"
                          to="/settings/intercom"
                          onClick={ closeModal }>
                      <Translate id="genericLabel.SETTINGS.text"/>
                    </Link>
                  </Alert>
                  }
                  <FormGroup>
                    <Label for="intercomProfile">
                      <Translate id="genericLabel.INTERCOM_PROFILE.text"/>
                    </Label>
                    <div >
                      <Input id="input_settings_intercomProfile"
                            type="select"
                            name="profile"
                            value={ values.profile }
                            onChange={handleChange}>
                        { profiles.map((currentProfile) => {
                          return <option key={currentProfile.value} value={currentProfile.value} id={currentProfile.label}>{currentProfile.label}</option>
                        })}
                      </Input>
                    </div>
                  </FormGroup></Fragment>
                }

              </Fragment>}
              buttons={
                <FormGroup className="buttons">
                  <Button id="input_settings_saveButton"
                          color="primary"
                          disabled={ !dirty }
                          type="submit">
                    <Translate id="genericLabel.SAVE.text" />
                  </Button>
                </FormGroup>
            }/>
          </Form>
        )}}
      </Formik>
    );
  }
};

OptionsForm.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  const { config, streamhub } = state;
  const input = state.streamhub.inputs.find(input => input.id === ownProps.inputId);
  return {
    inputIPProfiles: streamhub.inputIPProfiles,
    videoReturnInputs: streamhub.inputs,
    videoReturnEncoders: streamhub.encoders.filter(encoder => encoder.videoReturnMode === true),
    audioInputDevice: streamhub.inputAudioDeviceSelected,
    audioOutputDevice: streamhub.outputAudioDeviceSelected,
    generalIntercomConfig: config.intercom ,
    config: {
      name: config.channelProfile[ownProps.inputId].name,
      lostStreamTimeout: config.channelProfile[ownProps.inputId].lostStreamTimeout,
      autoRecord: config.channelProfile[ownProps.inputId].autoRecord,
      autoPlay: config.channelProfile[ownProps.inputId].autoPlay,
      recorderPrefix: config.channelProfile[ownProps.inputId].recorderPrefix,
      videoIFBSourceIdx: config.channelProfile[ownProps.inputId].videoIFBSourceIdx,
      videoIFBEncoderIdx: config.channelProfile[ownProps.inputId].videoIFBEncoderIdx,
      videoIFBLatency: config.channelProfile[ownProps.inputId].videoIFBLatency,
      intercomConfig: config.intercomChannelProfile[ownProps.inputId],
      intercomAutoStart: config.intercom.autoStart,
    },
    canSetIntercom: canSetIntercom(state),
    inputType: input ? input.type : null,
    channelType: input ? input.channelType : null,
    inputActive: input ? (input.status !== STATUS_OFF && input.status !== STATUS_ERROR) : false,
    inputBooking: input ? input.booking : '',
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    closeModal: () => dispatch(inputEditSettings(null)),
    callUpdateInputSettings: ({ name, lostStreamTimeout, autoRecord, autoPlay,
      recorderPrefix, videoIFBSourceIdx, videoIFBEncoderIdx, videoIFBLatency,
      intercom }) => dispatch(
        updateInputSettings(ownProps.inputId, name, autoRecord, autoPlay,
          recorderPrefix, lostStreamTimeout, parseInt(videoIFBSourceIdx),
          parseInt(videoIFBEncoderIdx), videoIFBLatency, intercom)
      )
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(OptionsForm));