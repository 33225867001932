import PropTypes from "prop-types";
import {
  DEVICE_TYPE_AIR,
  DEVICE_TYPE_HE4000,
  DEVICE_TYPE_RACK,
  DEVICE_TYPE_MOJOPRO_APP,
  DEVICE_TYPE_MOJOPRO_LAPTOP,
  DEVICE_TYPE_PRO,
  DEVICE_TYPE_STUDIO,
  FFMPEG,
  FILE_TRANSFER_TYPE_FTP, FILE_TRANSFER_TYPE_sFTP,
  FORWARD_STATUS_DONE,
  FORWARD_STATUS_FORWARDING,
  HLS,
  INPUT_TYPE_DEFAULT,
  INPUT_TYPE_DEVICE,
  INPUT_TYPE_IP,
  INTERCOM_STATUS_ERROR,
  INTERCOM_STATUS_NOT_SUPPORTED,
  INTERCOM_STATUS_OFF,
  INTERCOM_STATUS_ON,
  INTERCOM_STATUS_STARTING,
  LIVE_STATUS_CONNECTING,
  LIVE_STATUS_ERROR,
  LIVE_STATUS_OFF,
  LIVE_STATUS_ON,
  NETWORK_INTERFACE_TYPE_CELLULAR,
  NETWORK_INTERFACE_TYPE_ETHERNET,
  NETWORK_INTERFACE_TYPE_WIFI,
  NETWORK_INTERFACE_TYPE_GADGET,
  NETWORK_INTERFACE_TYPE_WIFI_AP,
  OUTPUT_TYPE_HARDWARE,
  OUTPUT_TYPE_NDI,
  OUTPUT_TYPE_IP,
  PLAYBACK_STATUS_OFF,
  PLAYBACK_STATUS_PAUSED,
  PLAYBACK_STATUS_PLAYING,
  PLAYBACK_STATUS_ERROR,
  RECORD_STATUS_OFF,
  RECORD_STATUS_ON,
  RECORD_STATUS_STARTING,
  RECORD_STATUS_CODEC_NOT_SUPPORTED,
  RECORD_STATUS_ERROR,
  REF_INPUT_STATUS_DISABLED,
  REF_INPUT_STATUS_OFF,
  RTMP,
  RTSP,
  SDI,
  SRT,
  SST,
  NDI,
  STATUS_ERROR,
  STATUS_LIVE,
  STATUS_OFF,
  STATUS_ON,
  THEME_DARK,
  THEME_LIGHT,
  TS, VIDEO_BITRATE_MODE_CBR, VIDEO_BITRATE_MODE_VBR,
  VIDEO_CARD_TYPE_DECKLINK,
  VIDEO_CARD_TYPE_DELTACAST,
  VIDEO_CARD_TYPE_RIVERMAX, 
  WEBRTC,
  STATUS_WARNING
} from "../constants";

export const themePropTypes = PropTypes.oneOf([THEME_LIGHT, THEME_DARK]);

const protocols = [HLS, RTMP, RTSP, TS, SST, SRT, FFMPEG, WEBRTC, SDI, NDI];

export const deviceLiveProfilePropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
});

export const identityPropTypes = PropTypes.shape({
  token: PropTypes.string.isRequired,
  username: PropTypes.string,
  role: PropTypes.string
});

export const lostStreamModePropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired
});

export const encoderPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  inputId: PropTypes.string,
  name: PropTypes.string.isRequired,
  profileId: PropTypes.string,
  status: PropTypes.oneOf([STATUS_LIVE, STATUS_OFF, STATUS_ON, STATUS_ERROR, STATUS_WARNING]).isRequired,
  inputInfo: PropTypes.string,
  outputInfo: PropTypes.string,
  message: PropTypes.string
});

export const deviceLiveInfoPropTypes = PropTypes.shape({
  audioChannelMode: PropTypes.string,
  delay: PropTypes.number,
  liveMaxLatency: PropTypes.number,
  liveMinLatency: PropTypes.number,
  liveProfileID: PropTypes.string,
  resolution: PropTypes.string,
  videoBitrateMode: PropTypes.oneOf([VIDEO_BITRATE_MODE_CBR, VIDEO_BITRATE_MODE_VBR]),
  videoBitrate: PropTypes.number,
  videoCappedBitrate: PropTypes.number,
  videoFrequency: PropTypes.string,
  videoInput: PropTypes.string,
  videoMaxBitrate: PropTypes.number,
  videoMinBitrate: PropTypes.number
});

export const intercomInfoPropTypes = PropTypes.shape({
  input_device_status: PropTypes.number,
  mute_input_status: PropTypes.bool,
  mute_output_status: PropTypes.bool,
  output_device_status: PropTypes.number,
  pattern: PropTypes.number,
  quality_profile: PropTypes.number,
  status: PropTypes.number,
  input: PropTypes.number,
  output: PropTypes.number
});

export const inputIpProfilePropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  protocol: PropTypes.oneOf(protocols).isRequired,
  inputId: PropTypes.string,
  enable: PropTypes.bool.isRequired,
  uri: PropTypes.string,
  localUris: PropTypes.arrayOf(PropTypes.string),
  publicUri: PropTypes.string,
  hostnameUri: PropTypes.string,
  port: PropTypes.number,
  enableEncoder: PropTypes.bool,
  encoderVideoType: PropTypes.string,
  encoderVideoBitrate: PropTypes.number,
  encoderAudioType: PropTypes.string,
  encoderAudioBitrate: PropTypes.number,
  sdiIdx: PropTypes.number,
  sdiNbChannels: PropTypes.number
});

export const encoderProfilePropTypes = PropTypes.shape({
  name: PropTypes.string,
  encoderVideoType: PropTypes.string,
  outputStandard: PropTypes.string,
  quality: PropTypes.string,
  videoBitrate: PropTypes.number,
  encoderAudioType: PropTypes.string,
  audioBitrate: PropTypes.number,
  noPlayingPatternMode: PropTypes.number,
  lostPatternMode: PropTypes.number,
  gopSize: PropTypes.number,
  bFrame: PropTypes.bool
});

export const outputIpProfilePropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  protocol: PropTypes.oneOf(protocols).isRequired,
  enable: PropTypes.bool,
  available: PropTypes.bool,
  inputId: PropTypes.string,
  encoderId: PropTypes.string,
  uri: PropTypes.string,
  localUris: PropTypes.arrayOf(PropTypes.string),
  publicUri: PropTypes.string,
  hostnameUri: PropTypes.string,
  uris: PropTypes.arrayOf(PropTypes.string)
});

export const inputPropTypes = PropTypes.shape({
  deviceType: PropTypes.oneOf([DEVICE_TYPE_AIR, DEVICE_TYPE_HE4000, DEVICE_TYPE_RACK, DEVICE_TYPE_MOJOPRO_APP, DEVICE_TYPE_MOJOPRO_LAPTOP, DEVICE_TYPE_PRO, DEVICE_TYPE_STUDIO]),
  deviceChannelType: PropTypes.string,
  deviceProduct: PropTypes.string,
  deviceName: PropTypes.string,
  deviceMessage: PropTypes.string,
  deviceErrorStatus: PropTypes.number,
  deviceErrorStatusParam: PropTypes.shape({
    'param.arg1': PropTypes.string.isRequired,
    'param.arg2': PropTypes.string.isRequired
  }),
  deviceLiveProfiles: PropTypes.arrayOf(deviceLiveProfilePropTypes),
  deviceLiveProfileID: PropTypes.string,
  deviceLiveStatus: PropTypes.oneOf([LIVE_STATUS_OFF, LIVE_STATUS_CONNECTING, LIVE_STATUS_ON, LIVE_STATUS_ERROR]),
  deviceLiveInfo: deviceLiveInfoPropTypes,
  deviceMetaData: PropTypes.string,
  deviceRecordStatus: PropTypes.oneOf([RECORD_STATUS_OFF, RECORD_STATUS_ON]),
  deviceForwardStatus: PropTypes.oneOf([FORWARD_STATUS_FORWARDING, FORWARD_STATUS_DONE]),
  deviceIntercomStatus: PropTypes.oneOf([INTERCOM_STATUS_OFF, INTERCOM_STATUS_ON]),
  deviceNetworkInterfaces: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.oneOf([NETWORK_INTERFACE_TYPE_ETHERNET,NETWORK_INTERFACE_TYPE_WIFI, NETWORK_INTERFACE_TYPE_CELLULAR, NETWORK_INTERFACE_TYPE_GADGET, NETWORK_INTERFACE_TYPE_WIFI_AP]).isRequired,
    name: PropTypes.string.isRequired,
    itf_name: PropTypes.string,
    state: PropTypes.string.isRequired
  })),
  deviceTunnelIPAddress: PropTypes.string,
  deviceHardwareID: PropTypes.string,
  deviceVersion: PropTypes.string,
  id: PropTypes.string.isRequired,
  inputInfo: PropTypes.string,
  outputInfo: PropTypes.string,
  intercomStatus: PropTypes.oneOf([INTERCOM_STATUS_ON, INTERCOM_STATUS_OFF, INTERCOM_STATUS_NOT_SUPPORTED, INTERCOM_STATUS_ERROR, INTERCOM_STATUS_STARTING]).isRequired,
  ipProfileId: PropTypes.string,
  name: PropTypes.string.isRequired,
  port: PropTypes.number.isRequired,
  playbackFilename: PropTypes.string,
  playbackStatus: PropTypes.oneOf([PLAYBACK_STATUS_OFF, PLAYBACK_STATUS_PLAYING, PLAYBACK_STATUS_PAUSED, PLAYBACK_STATUS_ERROR]).isRequired,
  recordStatus: PropTypes.oneOf([RECORD_STATUS_ON, RECORD_STATUS_STARTING, RECORD_STATUS_OFF, RECORD_STATUS_CODEC_NOT_SUPPORTED, RECORD_STATUS_ERROR]),
  status: PropTypes.oneOf([STATUS_LIVE, STATUS_OFF, STATUS_ON, STATUS_ERROR, STATUS_WARNING]).isRequired,
  type: PropTypes.oneOf([INPUT_TYPE_DEFAULT, INPUT_TYPE_DEVICE, INPUT_TYPE_IP]).isRequired
});

export const multiViewPropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  status: PropTypes.oneOf([STATUS_OFF, STATUS_ON, STATUS_LIVE, STATUS_ERROR, STATUS_WARNING]),
  audioChannelIndex: PropTypes.number,
  outputStandard: PropTypes.string.isRequired,
  forceAspectRatio: PropTypes.bool.isRequired,
  lostStreamMode: PropTypes.number.isRequired,
  lostStreamTimeout: PropTypes.number.isRequired,
  showOverlayInfo: PropTypes.bool,
  nbRow: PropTypes.number,
  nbCol: PropTypes.number,
  videoChannelIndexes: PropTypes.string
});

export const outputPropTypes = PropTypes.shape({
  connections: PropTypes.number, // RTSP only: Number of stream consumers
  encoderId: PropTypes.string,
  has4K: PropTypes.bool, //Hardware only
  id: PropTypes.string.isRequired,
  index: PropTypes.number,
  inputId: PropTypes.string,
  ipProfile: outputIpProfilePropTypes,
  lockstate: PropTypes.bool,
  name: PropTypes.string,
  refInputStatus: PropTypes.oneOf([REF_INPUT_STATUS_DISABLED, REF_INPUT_STATUS_OFF, RECORD_STATUS_ON]), //Hardware only
  status: PropTypes.oneOf([STATUS_LIVE, STATUS_OFF, STATUS_ON, STATUS_ERROR, STATUS_WARNING]).isRequired,
  type: PropTypes.oneOf([OUTPUT_TYPE_HARDWARE, OUTPUT_TYPE_NDI, OUTPUT_TYPE_IP]).isRequired,
  videoCardId: PropTypes.string, //Hardware only
  videoStandard: PropTypes.string,
  inputInfo: PropTypes.string,
  outputInfo: PropTypes.string,
  message: PropTypes.string
});

export const audioDevicePropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  max_entries: PropTypes.number,
  nb_volumes: PropTypes.number
});

export const filePropTypes = PropTypes.shape({
  filetype: PropTypes.oneOf(['video', 'file', 'image']),
  type: PropTypes.oneOf(['directory', 'file']),
  filename: PropTypes.string.isRequired,
  path: PropTypes.string,
  player: PropTypes.bool,
  token: PropTypes.string,
  stats: PropTypes.object
});

export const licensePropTypes = PropTypes.shape({
  hardwareIdentifier: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  input: PropTypes.number.isRequired,
  DMNGApp: PropTypes.number.isRequired,
  MOJOPRO: PropTypes.number.isRequired,
  physicalOutput: PropTypes.number.isRequired,
  record: PropTypes.bool.isRequired,
  intercom: PropTypes.bool.isRequired,
  FTPPush: PropTypes.number.isRequired,
  AES: PropTypes.bool.isRequired,
  streamingView: PropTypes.bool.isRequired,
  FourK: PropTypes.bool.isRequired,
  playback: PropTypes.bool.isRequired,
  IPInput: PropTypes.bool.isRequired,
  multiView: PropTypes.bool.isRequired,
  IPOutput: PropTypes.number.isRequired,
  encoder: PropTypes.number.isRequired,
  forwardInput: PropTypes.number.isRequired,
  HotSpot: PropTypes.number.isRequired,
  videoReturn: PropTypes.number.isRequired,
});

export const featureNumberPropTypes = PropTypes.arrayOf(PropTypes.shape({
  count: PropTypes.number.isRequired,
  expirationDate: PropTypes.string
}));

export const featureBoolPropTypes = PropTypes.shape({
  enabled: PropTypes.bool.isRequired,
  expirationDate: PropTypes.string
});

export const licenseFeaturesPropTypes = PropTypes.shape({
  hardwareIdentifier: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  input: featureNumberPropTypes.isRequired,
  DMNGApp: featureNumberPropTypes.isRequired,
  MOJOPRO: featureNumberPropTypes.isRequired,
  physicalOutput: featureNumberPropTypes.isRequired,
  record: featureBoolPropTypes.isRequired,
  intercom: featureBoolPropTypes.isRequired,
  FTPPush: featureNumberPropTypes.isRequired,
  AES: featureBoolPropTypes.isRequired,
  streamingView: featureBoolPropTypes.isRequired,
  FourK: featureBoolPropTypes.isRequired,
  playback: featureBoolPropTypes.isRequired,
  IPInput: featureBoolPropTypes.isRequired,
  multiView: featureBoolPropTypes.isRequired,
  IPOutput: featureNumberPropTypes.isRequired,
  encoder: featureNumberPropTypes.isRequired,
  forwardInput: featureNumberPropTypes.isRequired,
  HotSpot: featureNumberPropTypes.isRequired,
  videoReturn: featureNumberPropTypes.isRequired,
});

export const systemPropTypes = PropTypes.shape({
  cpuModelName: PropTypes.string.isRequired,
  enabledIntelGPU: PropTypes.bool.isRequired,
  hardwareIdentifier: PropTypes.string.isRequired,
  nbGpiInputs: PropTypes.number.isRequired,
  isDocker: PropTypes.bool.isRequired
});

export const fileTransferPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf([FILE_TRANSFER_TYPE_FTP, FILE_TRANSFER_TYPE_sFTP]),
  uri: PropTypes.string.isRequired
});

export const videoCardCapabilitiesPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  modelName: PropTypes.string.isRequired,
  sdiCardType: PropTypes.oneOf([VIDEO_CARD_TYPE_DECKLINK, VIDEO_CARD_TYPE_DELTACAST,VIDEO_CARD_TYPE_RIVERMAX]).isRequired,
  sdiIndex: PropTypes.array,
  sdiOrder: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired
  }))
});