import PropTypes from "prop-types";
import React, {Fragment} from "react";
import {Translate} from "react-localize-redux";
import {Button} from "reactstrap";
import AWModal from "@aviwest/ui-kit/dist/js/components/modal";

const propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  state: PropTypes.oneOf([0, 1, 2])
};

const UpdateVideoCardFirmwareModal = (props) => {
  const { onCancel, onConfirm, state } = props;
  return (
  <AWModal id="UpdateVideoCard_modal"
           confirm={true}
           icon="update"
           open={true}
           title={<Translate id="genericLabel.UPDATE_VIDEO_BOARD_FIRMWARE.text"/>}>
      <div className="confirm-text">
        { state === 0 && <Translate id="genericLabel.VIDEO_BOARD_FIRMWARE_UP_TO_DATE.text"/> }
        { state === 1 && <Translate id="genericLabel.VIDEO_BOARD_NOT_DETECTED.text"/> }
        { state === 2 && <Translate id="genericLabel.CONFIRM_UPDATE_VIDEO_BOARD_RESTART.text"/> }
      </div>
      <div className="buttons">
        { state === 2 &&
        <Fragment>
          <Button id="UpdateVideoCard_modal_cancelButton"
                  className="btn-cancel"
                  size="lg"
                  onClick={onCancel}>
            <Translate id="genericLabel.NO.text"/>
          </Button>
          <Button id="UpdateVideoCard_modal_confirmButton"
                  color="primary"
                  size="lg"
                  onClick={onConfirm}>
            <Translate id="genericLabel.YES.text"/>
          </Button>
        </Fragment>
        }
        { state !== 2 &&
        <Button id="UpdateVideoCard_modal_okButton"
                color="primary"
                size="lg"
                onClick={onCancel}>
          <Translate id="genericLabel.OK.text"/>
        </Button>
        }
      </div>
    </AWModal>
  );
};

UpdateVideoCardFirmwareModal.propTypes = propTypes;

export default UpdateVideoCardFirmwareModal;