import React from 'react';
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

import {
  outputIpProfilePropTypes,
  outputPropTypes,
  videoCardCapabilitiesPropTypes
} from "../../../../../utils/models-prop-types";
import {OUTPUT_TYPE_IP, OUTPUT_TYPE_NDI, SDI, NDI} from "../../../../../constants";

const propTypes = {
  output: outputPropTypes.isRequired,
  ipProfile: outputIpProfilePropTypes,
  videoCard: videoCardCapabilitiesPropTypes
};

const Destination = (props) => {
  const { output, ipProfile, videoCard } = props;
  const { type } = output;
  const hardwareName = videoCard ? videoCard.modelName : null;
  if(type === OUTPUT_TYPE_IP && ipProfile){
    return (
      <div className="destination">
        <div className="type protocol">{ ipProfile.protocol }</div>
        <AWIcon name="tv_screen"/>
      </div>
    );
  }
  else if(type === OUTPUT_TYPE_NDI ){
    return (
      <div className="destination">
        <div className="type protocol">{NDI}</div>
        <AWIcon name="network_interface_ethernet"/>
      </div>
    );
  }
  else {
    return (
      <div className="destination">
        { hardwareName != null && <div className="type text-clip" title={hardwareName}>{ hardwareName }</div> }
        { hardwareName == null && <div className="type protocol">{SDI}</div> }
        <AWIcon name="sdi"/>
      </div>
    );
  }
};

Destination.propTypes = propTypes;

export default Destination;