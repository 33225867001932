import PropTypes from "prop-types";
import React from "react";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";
import AWBadgeLabel from "@aviwest/ui-kit/dist/js/components/badge-label";
import { HLS, OUTPUT_TYPE_HARDWARE, OUTPUT_TYPE_NDI, RTMP, RTSP, SRT, SST, TS } from "../../../../../constants";
import { outputIpProfilePropTypes, outputPropTypes } from "../../../../../utils/models-prop-types";
import ConnectionsIndicator from "./connections-indicator";
import NdiBadge from "./ndi-badge";
import RefBadge from "./ref-badge";
import { formatOutputId } from "../../../../../utils/global-utils";


const propTypes = {
  output: outputPropTypes.isRequired,
  finalName: PropTypes.string.isRequired,
  ipProfile: outputIpProfilePropTypes,
  videoCardType: PropTypes.string.isRequired
};

const OutputBadge = (props) => {
  const { output, finalName, ipProfile, videoCardType} = props;

  if (ipProfile) {
    switch (ipProfile.protocol) {
      case RTSP:
        return <ConnectionsIndicator icon="rtsp_cast" connections={output.connections} outputId={output.id} finalName={finalName} serverMode={output.serverMode}/>
      case RTMP:
        return <AWBadgeLabel left={formatOutputId(output.id, output.type)} right={<AWIcon name="rtmp_cast" />}>{finalName}</AWBadgeLabel>
      case HLS:
        return <AWBadgeLabel left={formatOutputId(output.id, output.type)} right={<AWIcon name="hls_cast" />}>{finalName}</AWBadgeLabel>
      case TS:
        return <AWBadgeLabel left={formatOutputId(output.id, output.type)} right={<AWIcon name="ts_cast" />}>{finalName}</AWBadgeLabel>
      case SRT:
        if (ipProfile.serverMode) {
          return <ConnectionsIndicator icon="srt_cast" connections={output.connections} outputId={output.id} finalName={finalName} serverMode={output.serverMode}/>
        } else {
          return <AWBadgeLabel left={formatOutputId(output.id, output.type)} right={<AWIcon name="srt_cast" />}>{finalName}</AWBadgeLabel>
        }
      case SST:
        return <AWBadgeLabel left={formatOutputId(output.id, output.type)} right={<AWIcon name="sst_cast" />}>{finalName}</AWBadgeLabel>
      default:
        return null;
    }
  } else {
    switch (output.type) {
      case OUTPUT_TYPE_NDI:
        return  <NdiBadge nbConnect={output.nbConnect} tallyState={output.tallyState} outputId={output.id} finalName={finalName}/>
      case OUTPUT_TYPE_HARDWARE:
        return <RefBadge refInputStatus={output.refInputStatus} outputId={output.id} finalName={finalName} videoCardType={videoCardType}/>;
      default:
        return null;
    }
  }
};

OutputBadge.propTypes = propTypes;

export default OutputBadge;