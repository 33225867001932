import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getAvailableIPInputProtocols } from "../../../../misc/capabilities.selectors";
import { Button } from 'reactstrap';
import AWIcon from '@aviwest/ui-kit/dist/js/components/icon';
import { inputIcon } from '../../../../utils/global-utils';
import { INPUT_TYPE_IP, WEBRTC } from '../../../../constants';
import { Translate } from 'react-localize-redux';

const propTypes = {
  onProfileCreation: PropTypes.func.isRequired,
  protocols: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const NewInputIPProfile = (props) => {
  const { protocols, onProfileCreation } = props;

  return (<div id="ipInput_newProfile" className="new-profile">
    {protocols.map(protocol => (
      <Button 
        id={"ipInput_"+protocol+"_button"}
        className="square protocol" 
        key={protocol}
        onClick={() => onProfileCreation(protocol)}>
        <AWIcon name={inputIcon(INPUT_TYPE_IP, protocol)} />
        {protocol === WEBRTC && <Translate id="genericLabel.LIVE_GUEST_IP_PROFILE_LABEL.text" />}
      </Button>
    ))}
  </div>);
}


const mapStateToProps = (state, ownProps) => {
  
  return {
    protocols: getAvailableIPInputProtocols(state)
  }
};

NewInputIPProfile.propTypes = propTypes;

export default connect(mapStateToProps, {})(NewInputIPProfile);