import {createSelector} from "reselect";

const getEncoderProfile = (state) => state.config.encoderProfile;
const getNDIOutput = (state) => state.config.NDIOutput;
const getBasebandPlayerOutput = (state) => state.config.basebandPlayer;

export const getEncoderProfileNames = createSelector(
  [ getEncoderProfile ],
  (encoderProfiles) => {
    if(!encoderProfiles){
      return [];
    }
    return Object.values(encoderProfiles).map(profile => profile.name);
  }
);

export const getNDIOutputNames = createSelector(
  [ getNDIOutput ],
  (NDIOutputs) => {
    if(!NDIOutputs){
      return [];
    }
    return Object.values(NDIOutputs).map(NDIOutput => NDIOutput.name);
  }
);

export const getBasebandPlayerOutputNames = createSelector(
  [ getBasebandPlayerOutput ],
  (basebandPlayerOutputs) => {
    if(!basebandPlayerOutputs){
      return [];
    }
    return Object.values(basebandPlayerOutputs).map(basebandPlayerOutput => basebandPlayerOutput.name);
  }
);