import {
  DASHBOARD_ENCODER_SETTINGS,
  DASHBOARD_ENCODERS_DISPLAY,
  DASHBOARD_ENCODERS_LAYOUT_CHANGE,
  DASHBOARD_HIGHLIGHT_ENCODER,
  DASHBOARD_FILES_DISPLAY,
  DASHBOARD_FILES_LAYOUT_CHANGE,
  DASHBOARD_INPUT_REMOTE_CONTROL,
  DASHBOARD_INPUT_SETTINGS,
  DASHBOARD_INPUT_ENTER_DETAILS,
  DASHBOARD_INPUT_CLOSE_DETAILS,
  DASHBOARD_INPUTS_FILTER_CHANGE,
  DASHBOARD_INPUTS_LAYOUT_CHANGE,
  DASHBOARD_HIGHLIGHT_INPUT,
  DASHBOARD_LAYOUT_FULLSIZE,
  DASHBOARD_LAYOUT_GRID,
  DASHBOARD_LAYOUT_INLINE,
  DASHBOARD_MOBILE_SHOW_OUTPUTS,
  DASHBOARD_MULTIVIEW_SETTINGS,
  DASHBOARD_OUTPUT_HARDWARE_SETTINGS,
  DASHBOARD_OUTPUT_NDI_SETTINGS,
  DASHBOARD_OUTPUT_IP_PREVIEW,
  DASHBOARD_OUTPUT_IP_SETTINGS,
  DASHBOARD_OUTPUTS_FILTER_CHANGE,
  DASHBOARD_OUTPUTS_LAYOUT_CHANGE,
  DASHBOARD_SPLITTER_POSITION_CHANGE,
  FILE_LAYOUT_ICON,
  // FILE_LAYOUT_INLINE,
  LS_DASHBOARD_INPUTS_FILTERS,
  LS_DASHBOARD_INPUTS_LAYOUT,
  LS_DASHBOARD_OUTPUTS_FILTERS,
  LS_DASHBOARD_OUTPUTS_LAYOUT,
  LS_DASHBOARD_VERTICAL_SIZES,
  LS_DASHBOARD_HORIZONTAL_SIZES,
  RECEIVED_CONFIG,
  RESPONSIVE_CHANGE,
  DASHBOARD_IP_INPUTS_PROFILES_SETTINGS,
  DASHBOARD_IP_OUTPUTS_PROFILES_SETTINGS,
  DASHBOARD_ENCODERS_PROFILES_SETTINGS,
  LICENSE_STATUS_VALID,
  RECEIVED_LICENSE,
  DASHBOARD_MULTIVIEW_MODAL
} from '../../../constants';

// Splitter position
let initialVerticalSectionsSizes = [50, 50];
let initialHorizontalSectionsSizes = [100, 0];
if(localStorage.getItem(LS_DASHBOARD_VERTICAL_SIZES)){
  initialVerticalSectionsSizes = JSON.parse(localStorage.getItem(LS_DASHBOARD_VERTICAL_SIZES));
}

// Inputs filters
let initialInputsFilters = {
  devices: true,
  ipInputs: true,
  multiView: true,
  statusOff: true,
  statusOn: true,
  statusLive: true
};
if(localStorage.getItem(LS_DASHBOARD_INPUTS_FILTERS)){
  initialInputsFilters = JSON.parse(localStorage.getItem(LS_DASHBOARD_INPUTS_FILTERS));
}

// Inputs layout
let initialInputsLayout = DASHBOARD_LAYOUT_GRID;
if(localStorage.getItem(LS_DASHBOARD_INPUTS_LAYOUT)){
  initialInputsLayout = localStorage.getItem(LS_DASHBOARD_INPUTS_LAYOUT);
}

// Outputs filters
let initialOutputsFilters = {
  hardware: true,
  ndiOutputs: true,
  ipOutputs: true,
  statusOff: true,
  statusOn: true,
  statusLive: true
};
if(localStorage.getItem(LS_DASHBOARD_OUTPUTS_FILTERS)){
  initialOutputsFilters = JSON.parse(localStorage.getItem(LS_DASHBOARD_OUTPUTS_FILTERS));
}

// Inputs layout
let initialOutputsLayout = DASHBOARD_LAYOUT_GRID;
if(localStorage.getItem(LS_DASHBOARD_OUTPUTS_LAYOUT)){
  initialOutputsLayout = localStorage.getItem(LS_DASHBOARD_OUTPUTS_LAYOUT);
}

// Encoders layout
let initialEncodersLayout = DASHBOARD_LAYOUT_GRID;

// Files layout
let initialFilesLayout = FILE_LAYOUT_ICON;

const minWidthForInputsSection = (inputsLayout, encodersLayout = null, filesLayout = null) => {

  // Inputs
  let inputsMinWidth = 0;
  switch(inputsLayout){
    case DASHBOARD_LAYOUT_GRID:
      inputsMinWidth = 220;
      break;
    case DASHBOARD_LAYOUT_INLINE:
      inputsMinWidth = 540;
      break;
    case DASHBOARD_LAYOUT_FULLSIZE:
      inputsMinWidth = 640;
      break;
    default:
      break;
  }

  // Encoders
  let encodersMinWidth = 0;
  if(encodersLayout){
    switch(encodersLayout){
      case DASHBOARD_LAYOUT_GRID:
        encodersMinWidth = 220;
        break;
      case DASHBOARD_LAYOUT_INLINE:
        encodersMinWidth = 540;
        break;
      default:
        break;
    }
  }

  let filesMinWidth = 0;
  if(filesLayout){
    filesMinWidth = 450;
    // switch(filesLayout){
    //   case FILE_LAYOUT_ICON:
    //     filesMinWidth = 220;
    //     break;
    //   case FILE_LAYOUT_INLINE:
    //     filesMinWidth = 540;
    //     break;
    //   default:
    //     break;
    // }
  }
  return Math.max(inputsMinWidth, encodersMinWidth, filesMinWidth);
};

const minWidthForOutputsSection = (outputLayout) => {
  switch(outputLayout){
    case DASHBOARD_LAYOUT_GRID:
      return 220;
    case DASHBOARD_LAYOUT_INLINE:
      return 220;
    default:
      return 0;
  }
};

const minHeightForInputsLayout = (layout) => {
  return 250;
};

const widthPercentage = (state, pixels) => {
  if(state.screenWidth === 0) return 0;
  return (pixels / state.screenWidth) * 100;
};

const initialState = {
  ready: false,

  isMobile: false,
  mobileOutputsVisible: false,
  encodersVisible: false,
  filesVisible: false,
  screenWidth: 0,
  screenHeight: 0,

  horizontalSectionsSizes: initialHorizontalSectionsSizes,
  verticalSectionsSizes: initialVerticalSectionsSizes,
  inputsMinWidth: minWidthForInputsSection(initialInputsLayout, initialEncodersLayout, initialFilesLayout),
  inputsMinHeight: minHeightForInputsLayout(initialInputsLayout),
  outputsMinWidth: minWidthForOutputsSection(initialOutputsLayout),
  encodersFilesMinHeight: 50,

  encodersLayout: initialEncodersLayout,
  highlightedEncoder: -1,

  filesLayout: initialFilesLayout,

  inputsFilters: initialInputsFilters,
  inputsLayout: initialInputsLayout,
  highlightedInput: -1,

  outputsFilters: initialOutputsFilters,
  outputsLayout: initialOutputsLayout,

  editingInput: null,
  controllingInput: null,
  editingEncoder: null,
  editingHardwareOutput: null,
  editingNDIOutput: null,
  editingIPOutput: null,
  previewingIPOutput: null,
  editingMultiView: false,
  multiViewModal: false,
  editingIpInputsProfilesSettings: {open: false, profileToEdit: null},
  editingIpOutputsProfilesSettings: {open: false, canSelect: false},
  editingEncodersProfilesSettings: {open: false, canSelect: false}

};

const dashboardReducer = (state = initialState, action) => {
  if(action.type === DASHBOARD_SPLITTER_POSITION_CHANGE){
    if(action.vertical === false){
      return {
        ...state,
        horizontalSectionsSizes: action.sizes
      };
    }
    else {
      return {
        ...state,
        verticalSectionsSizes: action.sizes
      };
    }
  }

  if(action.type === DASHBOARD_INPUTS_FILTER_CHANGE){
    return {
      ...state,
      inputsFilters: { ...action.filters }
    };
  }

  if(action.type === DASHBOARD_INPUTS_LAYOUT_CHANGE){
    if(action.mobile){
      return {
        ...state,
        inputsLayout: action.layout
      };
    }
    else {
      const inputsMinWidth = minWidthForInputsSection(action.layout, state.encodersVisible ? state.encodersLayout : null, state.filesVisible ? state.filesLayout : null);
      let minWidthPercentage = widthPercentage(state, inputsMinWidth);
      let inputsPercentage = state.verticalSectionsSizes[0];
      if(inputsPercentage < minWidthPercentage){
        inputsPercentage = minWidthPercentage;
      }
      return {
        ...state,
        inputsLayout: action.layout,
        inputsMinWidth: inputsMinWidth,
        verticalSectionsSizes: [inputsPercentage, 100 - inputsPercentage]
      };
    }
  }

  if(action.type === DASHBOARD_FILES_LAYOUT_CHANGE){
    if(action.mobile){
      return {
        ...state,
        filesLayout: action.layout
      };
    }
    else {
      const inputsMinWidth = minWidthForInputsSection(state.inputsLayout, state.encodersVisible ? state.encodersLayout : null, state.filesVisible ? action.layout : null);
      let minWidthPercentage = widthPercentage(state, inputsMinWidth);
      let inputsPercentage = state.verticalSectionsSizes[0];
      if(inputsPercentage < minWidthPercentage){
        inputsPercentage = minWidthPercentage;
      }
      return {
        ...state,
        filesLayout: action.layout,
        inputsMinWidth: inputsMinWidth,
        verticalSectionsSizes: [inputsPercentage, 100 - inputsPercentage]
      };
    }
  }

  if(action.type === DASHBOARD_ENCODERS_LAYOUT_CHANGE){
    if(action.mobile){
      return {
        ...state,
        encodersLayout: action.layout
      };
    }
    else {
      const inputsMinWidth = minWidthForInputsSection(state.inputsLayout, state.encodersVisible ? action.layout : null, state.filesVisible ? state.filesLayout : null);
      let minWidthPercentage = widthPercentage(state, inputsMinWidth);
      let inputsPercentage = state.verticalSectionsSizes[0];
      if(inputsPercentage < minWidthPercentage){
        inputsPercentage = minWidthPercentage;
      }
      return {
        ...state,
        encodersLayout: action.layout,
        inputsMinWidth: inputsMinWidth,
        verticalSectionsSizes: [inputsPercentage, 100 - inputsPercentage]
      };
    }
  }

  if(action.type === DASHBOARD_OUTPUTS_FILTER_CHANGE){
    return { ...state,
      outputsFilters: { ...action.filters }
    };
  }

  if(action.type === DASHBOARD_OUTPUTS_LAYOUT_CHANGE){
    if(action.mobile){
      return {
        ...state,
        outputsLayout: action.layout
      };
    }
    else {
      const outputsMinWidth = minWidthForOutputsSection(action.layout);
      let minWidthPercentage = widthPercentage(state, outputsMinWidth);
      let outputsPercentage = state.verticalSectionsSizes[1];
      if(outputsPercentage < minWidthPercentage){
        outputsPercentage = minWidthPercentage;
      }
      return {
        ...state,
        outputsLayout: action.layout,
        outputsMinWidth: outputsMinWidth,
        verticalSplitterPosition: [100 - outputsPercentage, outputsPercentage]
      };
    }
  }

  if(action.type === RECEIVED_CONFIG){
    return { ...state, ready: true };
  }

  if(action.type === DASHBOARD_INPUT_SETTINGS){
    return { ...state, editingInput: action.inputId };
  }

  if(action.type === DASHBOARD_INPUT_REMOTE_CONTROL){
    return { ...state, controllingInput: action.inputId };
  }

  if(action.type === DASHBOARD_OUTPUT_HARDWARE_SETTINGS){
    return { ...state, editingHardwareOutput: action.outputId };
  }

  if(action.type === DASHBOARD_OUTPUT_NDI_SETTINGS){
    return { ...state, editingNDIOutput: action.outputId };
  }

  if(action.type === DASHBOARD_OUTPUT_IP_SETTINGS){
    return { ...state, editingIPOutput: action.outputId };
  }

  if(action.type === DASHBOARD_MULTIVIEW_SETTINGS){
    return { ...state, editingMultiView: action.open };
  }

  if(action.type === DASHBOARD_MULTIVIEW_MODAL){
    return { ...state, multiViewModal: action.open };
  }

  if(action.type === DASHBOARD_OUTPUT_IP_PREVIEW){
    return { ...state, previewingIPOutput: action.output };
  }

  if(action.type === DASHBOARD_INPUT_ENTER_DETAILS){
    if(!state.mobileOutputsVisible){
      const inputsMinWidth = minWidthForInputsSection(DASHBOARD_LAYOUT_FULLSIZE);
      let minWidthPercentage = widthPercentage(state, inputsMinWidth);
      let inputsPercentage = state.verticalSectionsSizes[0];
      // If input position is less than min width, we adjust it
      if(inputsPercentage < minWidthPercentage){
        inputsPercentage = minWidthPercentage;
      }
      return {
        ...state,
        verticalSectionsSizes: [inputsPercentage, 100 - inputsPercentage],
        inputsMinWidth: inputsMinWidth
      };
    }
  }

  if(action.type === DASHBOARD_INPUT_CLOSE_DETAILS){
    if(!state.mobileOutputsVisible){
      const inputsMinWidth = minWidthForInputsSection(state.inputsLayout, state.encodersVisible ? state.encodersLayout : null, state.filesVisible ? state.filesLayout : null);
      let minWidthPercentage = widthPercentage(state, inputsMinWidth);
      let inputsPercentage = state.verticalSectionsSizes[0];
      // If input position is less than min width, we adjust it
      if(inputsPercentage < minWidthPercentage){
        inputsPercentage = minWidthPercentage;
      }
      return {
        ...state,
        verticalSectionsSizes: [inputsPercentage, 100 - inputsPercentage],
        inputsMinWidth: inputsMinWidth
      };
    }
  }

  if(action.type === RESPONSIVE_CHANGE){
    if(action.mobile){
      return {
        ...state,
        isMobile: action.mobile,
        verticalSectionsSizes: state.mobileOutputsVisible ? [0, 100] : [100, 0],
        screenWidth: action.width,
        screenHeight: action.height,
        inputsMinWidth: 0,
        outputsMinWidth: 0
      }
    }
    else {
      const inputsMinWidth = minWidthForInputsSection(state.inputsLayout, state.encodersVisible ? state.encodersLayout : null, state.filesVisible ? state.filesLayout : null);
      let minWidthPercentage = widthPercentage(state, inputsMinWidth);
      const outputsMinWidth = minWidthForOutputsSection(state.outputsLayout);
      let inputsPercentage = state.verticalSectionsSizes[0];

      if(inputsPercentage < minWidthPercentage){
        inputsPercentage = minWidthPercentage;
      }
      return {
        ...state,
        isMobile: action.mobile,
        verticalSectionsSizes: [inputsPercentage, 100 - inputsPercentage],
        screenWidth: action.width,
        screenHeight: action.height,
        mobileOutputsVisible: false,
        inputsMinWidth: inputsMinWidth,
        outputsMinWidth: outputsMinWidth
      }
    }
  }

  if(action.type === DASHBOARD_MOBILE_SHOW_OUTPUTS){
    return {
      ...state,
      mobileOutputsVisible: action.display,
      verticalSectionsSizes: action.display ? [0, 100] : [100, 0]
    };
  }

  if(action.type === DASHBOARD_ENCODERS_DISPLAY){
    const inputsMinWidth = minWidthForInputsSection(state.inputsLayout, action.display ? state.encodersLayout : null, null);
    let encodersFilesMinHeight = action.display ? 50 : 0;
    return {
      ...state,
      encodersVisible: action.display,
      encodersFilesMinHeight: encodersFilesMinHeight,
      filesVisible: false,
      inputsMinWidth
    };
  }

  if(action.type === DASHBOARD_FILES_DISPLAY){
    const inputsMinWidth = minWidthForInputsSection(state.inputsLayout, null, action.display ? state.filesLayout : null);
    let encodersFilesMinHeight = action.display ? 50 : 0;
    return {
      ...state,
      encodersVisible: false,
      encodersFilesMinHeight: encodersFilesMinHeight,
      filesVisible: action.display,
      inputsMinWidth
    };
  }

  if(action.type === DASHBOARD_ENCODER_SETTINGS){
    return {
      ...state,
      editingEncoder: action.encoderId
    };
  }

  if (action.type === DASHBOARD_IP_INPUTS_PROFILES_SETTINGS){
    return {
      ...state,
      editingIpInputsProfilesSettings: {open: action.open, profileToEdit: action.profileToEdit, backToDashboard: action.backToDashboard, channelProfileId: action.channelProfileId}
    }
  }

  if (action.type === DASHBOARD_IP_OUTPUTS_PROFILES_SETTINGS){
    return {
      ...state,
      editingIpOutputsProfilesSettings: {open: action.open, canSelect: action.canSelect}
    }
  }

  if (action.type === DASHBOARD_ENCODERS_PROFILES_SETTINGS){
    return {
      ...state,
      editingEncodersProfilesSettings: {open: action.open, canSelect: action.canSelect}
    }
  }

  if(action.type === RECEIVED_LICENSE){
    // Resizing if encoders are available
    if (action.status === LICENSE_STATUS_VALID && action.license.encoder > 0) {
      if(localStorage.getItem(LS_DASHBOARD_HORIZONTAL_SIZES)){
        initialHorizontalSectionsSizes = JSON.parse(localStorage.getItem(LS_DASHBOARD_HORIZONTAL_SIZES));
      } else {
        initialHorizontalSectionsSizes = [65, 35]
      }
    }
    else {
      initialHorizontalSectionsSizes = [100, 0];
    }
    return {
      ...state,
      horizontalSectionsSizes: initialHorizontalSectionsSizes,
      encodersVisible: action.status === LICENSE_STATUS_VALID && action.license.encoder > 0,
      filesVisible: action.status === LICENSE_STATUS_VALID && action.license.encoder <= 0 && action.license.playback > 0,
    };
  }

  if (action.type === DASHBOARD_HIGHLIGHT_INPUT){
    return {
      ...state,
      highlightedInput: action.inputId,
    };
  }

  if (action.type === DASHBOARD_HIGHLIGHT_ENCODER){
    return {
      ...state,
      highlightedEncoder: action.encoderId,
    };
  }

  return state;
};

export default dashboardReducer;