import React, {Component} from 'react';
import Thumbnail from '../../../../common/thumbnail/index';
import {multiViewPropTypes} from "../../../../../utils/models-prop-types";
import { AWControlGridItemThumbnail, AWControlGridItemContentTitle } from '@aviwest/ui-kit/dist/js/components/control-grid';
import AWBadgeLabel from "@aviwest/ui-kit/dist/js/components/badge-label";

const propTypes = {
  multiView: multiViewPropTypes.isRequired
};

class Preview extends Component {

  render(){
    const { multiView } = this.props;
    return (
      <div>
        <AWControlGridItemThumbnail className='draggable' overlay={<div><p>{multiView.outputStandard}</p></div>}>
          <Thumbnail multiView={true} timestamp={new Date().getTime()}>
            <AWControlGridItemContentTitle>
              <AWBadgeLabel>{multiView.name}</AWBadgeLabel>
            </AWControlGridItemContentTitle>
          </Thumbnail>
        </AWControlGridItemThumbnail>
      </div>
    );
  }
}

Preview.propTypes = propTypes;

export default Preview;