import {
  SETTINGS_ENCODER_PROFILE_CREATE,
  SETTINGS_ENCODER_PROFILE_DELETE,
  SETTINGS_ENCODER_PROFILE_UPDATE,
} from "../../../../constants";

export const createEncoderProfile = (profile) => {
  return {
    type: SETTINGS_ENCODER_PROFILE_CREATE,
    profile
  }
};

export const updateEncoderProfile = (profileId, profile) => {
  return {
    type: SETTINGS_ENCODER_PROFILE_UPDATE,
    profileId,
    profile
  }
};

export const deleteEncoderProfile = (profileId) => {
  return {
    type: SETTINGS_ENCODER_PROFILE_DELETE,
    profileId
  }
};