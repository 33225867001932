import PropTypes from "prop-types";
import React from "react";

const propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning', 'info']),
  style: PropTypes.object
};

const defaultProps = {
  children: null,
  color: 'secondary'
};

const CircularBadge = (props) => {
  const { children, color, style } = props;
  return (
    <div style={style} className={`aw-circular-badge ${color}`}>
      { children != null &&
      <div className="text-container">
        <div className="text">{children}</div>
      </div>
      }
    </div>
  );
};


CircularBadge.propTypes = propTypes;
CircularBadge.defaultProps = defaultProps;

export default CircularBadge;