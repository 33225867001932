
class Logger {
  // Log levels as per https://tools.ietf.org/html/rfc5424
  static get ERROR() { return 3 }
  static get WARN() { return 4 }
  static get INFO() { return 6 }
  static get DEBUG() { return 7 }

  constructor(options) {
    options = options || {};
    this.level = options.level || Logger.INFO;
    this.enabled = options.enabled || true;
  }

  log(level) {
    if (level <= this.level && this.enabled) {
      let args = [...arguments];
      args.shift();

      let isTablable = args.length === 1 && ((typeof args[0] === 'object') || (args[0] instanceof Array));
      switch (level) {
        case Logger.ERROR:
          console.error(...args);
          break;
        case Logger.WARN:
          console.warn(...args);
          break;
        case Logger.DEBUG:
          isTablable ? console.table(args[0]) : console.debug(...args);
          break;
        case Logger.INFO:
        default:
          isTablable ? console.table(args[0]) : console.info(...args);
      }
    }
  }

  error() {
    this.log(Logger.ERROR, ...arguments);
  }

  warn() {
    this.log(Logger.WARN, ...arguments);
  }

  info() {
    this.log(Logger.INFO, ...arguments);
  }

  debug() {
    this.log(Logger.DEBUG, ...arguments);
  }

  disable() {
    this.enabled = false;
    this.allowConsole(0);
  }

  setLevel(level) {
    this.level = level || Logger.INFO;
    console.groupCollapsed('MAN DEBUG');
    console.info("Debug enabled, current level", this.level);
    console.info("To change debug level, set 'debug' value in localstorage");
    console.table({ DEBUG: 7, INFO: 6, WARN: 4, ERROR: 3 });
    console.groupEnd();

    this.allowConsole(this.level);
  }

  allowConsole(level) {
    if (level <= this.level) {
      if (level <= Logger.INFO) {
        console.debug = () => { };
      }
      if (level <= Logger.WARN) {
        console.info = () => { };
        console.log = () => { };
      }
      if (level <= Logger.ERROR) {
        console.warn = () => { };
      }

      if (level < Logger.ERROR) {
        console.error = () => { };
      }
    }
  }
}

export default new Logger();
