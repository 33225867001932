import PropTypes from "prop-types";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Translate, withLocalize} from "react-localize-redux";
import {Badge, Col, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row, Table, Form} from "reactstrap";
import { getAvailableDecoderCapabilities } from "../../../../misc/capabilities.selectors";

const propTypes = {
  capabilities: PropTypes.arrayOf(PropTypes.shape({
    std: PropTypes.string.isRequired,
    worstCase: PropTypes.string.isRequired,
    a: PropTypes.number.isRequired,
    b: PropTypes.number.isRequired,
    bitrateMax: PropTypes.number.isRequired
  })),
  disabled: PropTypes.bool
};

class EncodingCapabilities extends Component {

  constructor(props){
    super(props);

    this.handleBitrateChange = this.handleBitrateChange.bind(this);
    this.computeDecoderBitrate = this.computeDecoderBitrate.bind(this);

    this.state = {
      bitrate: 20
    }
  }

  handleBitrateChange(e){
    this.setState({
      bitrate: e.target.value
    });
  }

  computeDecoderBitrate(decoderCapability){
    const bitrate = this.state.bitrate;

    if(isNaN(parseInt(bitrate)) || bitrate > decoderCapability.bitrateMax){
      return (
        <Badge color="secondary" pill>NA</Badge>
      );
    }
    else {
      return (
        <Badge color="info" pill>{ Math.floor(decoderCapability.a * bitrate + decoderCapability.b) }</Badge>
      )
    }
  }

  render() {
    const { bitrate } = this.state;
    const { capabilities, disabled } = this.props;
    if(!capabilities || capabilities.length === 0){
      return null;
    }
    return (
      <Form className="encoding-capabilities">
        <div className="title">
          <Translate id="genericLabel.DECODING_CAPABILITIES.text"/>
        </div>
        <div className="subtitle">
          <Translate id="genericLabel.DECODING_CAPABILITIES_HELP.text"/>
        </div>
        <Row>
          <Col lg={3}
               md={12}>
            <FormGroup>
              <Label for="bitrate">
                <Translate id="genericLabel.VIDEO_BITRATE_PER_INPUT.text"/>
              </Label>
              <InputGroup>
                <Input type="number"
                       id="decoding_bitrate"
                       name="bitrate"
                       disabled={disabled}
                       onChange={this.handleBitrateChange}
                       placeholder={this.props.translate('genericLabel.VIDEO_BITRATE_PER_INPUT.text')}
                       value={bitrate}/>
                <InputGroupAddon addonType="append">
                  <InputGroupText>Mb/s</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col lg={9}
               md={12}>
            <Table borderless
                   className="aw-table"
                   size="sm">
              <thead>
              <tr>
                <td></td>
                { capabilities.map(capability => (
                  <td key={capability.std}><Translate id={`decoderCapability.${capability.std}.text`}/></td>))}
              </tr>
              </thead>
              <tbody>
              <tr>
                <th><Translate id="genericLabel.MAXIMUM_DECODED_STREAM_COUNT.text"/></th>
                { capabilities.map(capability => (
                  <td id={"decoding_maxDecodingStreamCount_"+capability.std} key={capability.std}>{this.computeDecoderBitrate(capability)}</td>))}
              </tr>
              <tr>
                <th className="discreet"><Translate id="genericLabel.WITHOUT_TRANSCODING.text"/></th>
                { capabilities.map(capability => (
                  <td id={"decoding_withoutTranscoding_"+capability.std} key={capability.std} className="discreet">{capability.worstCase}</td>))}
              </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Form>
    );
  }
}

EncodingCapabilities.propTypes = propTypes;

const mapStateToProps = (state) => {
  return {
    capabilities: getAvailableDecoderCapabilities(state)
  };
};

export default connect(mapStateToProps)(withLocalize(EncodingCapabilities));