import {
  SETTINGS_OUTPUT_IP_PROFILE_CREATE,
  SETTINGS_OUTPUT_IP_PROFILE_DELETE,
  SETTINGS_OUTPUT_IP_PROFILE_UPDATE
} from "../../../../constants";

export const createOutputIPProfile = (profile) => {
  return {
    type: SETTINGS_OUTPUT_IP_PROFILE_CREATE,
    profile
  }
};

export const updateOutputIPProfile = (profileId, profile) => {
  return {
    type: SETTINGS_OUTPUT_IP_PROFILE_UPDATE,
    profileId,
    profile
  }
};

export const deleteOutputIPProfile = (profileId) => {
  return {
    type: SETTINGS_OUTPUT_IP_PROFILE_DELETE,
    profileId
  }
};
