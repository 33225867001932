import PropTypes from "prop-types";
import React, { Component } from 'react';
import {connect} from "react-redux";
import {Translate} from "react-localize-redux";

import Form from "./form";
import {
  updateGeneralSettings,
} from "./general.settings.actions";
import ConfigSection from "./config-section";

const propTypes = {
  config: PropTypes.object,
  languages: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    code_long: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })),
  callUpdateGeneralSettings: PropTypes.func.isRequired
};

class GeneralSettings extends Component {

  render() {
    const { config, languages} = this.props;
    return (
        <div className="settings-section">
          <div className="header">
            <div className="title">
              <Translate id="genericLabel.GENERAL.text"/>
            </div>
          </div>
          <div className="content pretty-scroll">
            { config &&
            <Form key={config._timestamp}
                  config={config}
                  languages={languages}
                  onSubmit={this.props.callUpdateGeneralSettings}/>
            }
            <ConfigSection/>
          </div>
        </div>
    );
  }
}

GeneralSettings.propTypes = propTypes;

const mapStateToProps = (state) => {
  return {
    config: Object.keys(state.config).length > 0 ? state.config : null, // Remember, config is an empty object at first
    languages: state.streamhub.availableLanguages,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    callUpdateGeneralSettings: (data) => dispatch(updateGeneralSettings(data))
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(GeneralSettings);