import React from 'react';
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

import { inputPropTypes } from "../../../../../utils/models-prop-types";
import {
  INPUT_TYPE_DEVICE,
  STATUS_OFF,
  BATTERY_LEVEL_EMPTY,
  BATTERY_LEVEL_20,
  BATTERY_LEVEL_40,
  BATTERY_LEVEL_60,
  BATTERY_LEVEL_80,
  BATTERY_LEVEL_FULL,
  BATTERY_LEVEL_IN_CHARGE,
  BATTERY_LEVEL_ADAPTER,
  BATTERY_LEVEL_WARNING,
  BATTERY_LEVEL_CRITICAL,
  BATTERY_LEVEL_IN_ERROR
} from "../../../../../constants";

const propTypes = {
  input: inputPropTypes.isRequired
};

const BatteryLevel = (props) => {
  let { batteryLevel, status, type } = props.input;
  let icon = null;

  if(type === INPUT_TYPE_DEVICE && status !== STATUS_OFF){
    switch(batteryLevel){
      case BATTERY_LEVEL_EMPTY:
        icon = <AWIcon name="battery_level_0"/>
        break
      case BATTERY_LEVEL_20:
        icon = <AWIcon name="battery_level_20"/>
        break
      case BATTERY_LEVEL_40:
        icon = <AWIcon name="battery_level_40"/>
        break
      case BATTERY_LEVEL_60:
        icon = <AWIcon name="battery_level_60"/>
        break
      case BATTERY_LEVEL_80:
        icon = <AWIcon name="battery_level_80"/>
        break
      case BATTERY_LEVEL_FULL:
        icon = <AWIcon name="battery_level_100"/>
        break
      case BATTERY_LEVEL_IN_CHARGE:
        icon = <AWIcon name="battery_level_charge"/>
        break
      case BATTERY_LEVEL_ADAPTER:
        icon = <AWIcon name="battery_level_adapter"/>
        break
      case BATTERY_LEVEL_WARNING:
        icon = <AWIcon name="battery_level_warning"/>
        break
      case BATTERY_LEVEL_CRITICAL:
        icon = <AWIcon name="battery_level_warning"/>
        break
      case BATTERY_LEVEL_IN_ERROR:
        icon = <AWIcon name="battery_level_warning"/>
        break
      default:
        break;
    }
  }

  if(!icon){
    return null;
  }
  return (
      <div className="battery-level">
        { icon }
      </div>
  );
};

BatteryLevel.propTypes = propTypes;

export default BatteryLevel;