import {
  SETTINGS_GENERAL_IMPORT_CONFIG_MODAL,
  SETTINGS_GENERAL_RESET_CONFIG_MODAL
} from "../../../../constants";

const initialState = {
  importConfigModalOpened: false,
  resetConfigModalOpened: false
};

const generalSettingsReducer = (state = initialState, action) => {

  if(action.type === SETTINGS_GENERAL_IMPORT_CONFIG_MODAL){
    return {
      ...state,
      importConfigModalOpened: action.open
    };
  }

  if(action.type === SETTINGS_GENERAL_RESET_CONFIG_MODAL){
    return {
      ...state,
      resetConfigModalOpened: action.open
    };
  }

  return state;
};

export default generalSettingsReducer;