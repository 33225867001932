import PropTypes from "prop-types";
import React from "react";
import {Translate} from "react-localize-redux";
import {connect} from "react-redux";
import {Button, FormGroup, Label} from "reactstrap";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";
import AWPopup, {AWPopupCol, AWPopupRow} from "@aviwest/ui-kit/dist/js/components/popup";

import {
  exportConfig,
  exportStreamhubProfile,
  importConfig,
  importConfigModal,
  resetConfig,
  resetConfigModal
} from "./general.settings.actions";
import ImportConfigModal from "./import-config-modal";
import ResetConfigModal from "./reset-config-modal";

const propTypes = {
  callExportConfig: PropTypes.func.isRequired,
  callImportConfigModal: PropTypes.func.isRequired,
  callImportConfig: PropTypes.func.isRequired,
  callResetConfigModal: PropTypes.func.isRequired,
  callResetConfig: PropTypes.func.isRequired,
  callExportStreamhubProfile: PropTypes.func.isRequired,
  importConfigModalOpened: PropTypes.bool.isRequired,
  resetConfigModalOpened: PropTypes.bool.isRequired,
  publicIp: PropTypes.string
};

const ConfigSection = (props) => {
  const {
    importConfigModalOpened,
    resetConfigModalOpened,
    publicIp
  } = props;
  const elementRef = React.createRef();
  return (
    <section className="form-section">
      <div className="header">
        <div className="title secondary">
          <Translate id="genericLabel.CONFIG_IMPORT_EXPORT.text"/>
          <div className="subtitle">
            <Translate id="genericLabel.CONFIG_IMPORT_EXPORT_DESCRIPTION.text"/>
          </div>
        </div>
      </div>

      <div className="config-actions">
        <Button id="general_config_exportButton"
                className="square"
                onClick={() => props.callExportConfig()}>
          <AWIcon name="file_export"/>
          <span className="text"><Translate id="genericLabel.EXPORT_CONFIG.text"/></span>
        </Button>
        <Button id="general_config_importButton"
                className="square"
                onClick={() => props.callImportConfigModal(true)}>
          <AWIcon name="file_import"/>
          <span className="text"><Translate id="genericLabel.IMPORT_CONFIG.text"/></span>
        </Button>
        <Button id="general_config_restoreFactorySettingsButton"
                className="square"
                onClick={() => props.callResetConfigModal(true)}>
          <AWIcon name="restore"/>
          <span className="text long"><Translate id="genericLabel.RESTORE_FACTORY_SETTINGS.text"/></span>
        </Button>
        <AWPopup direction="bottom-start"
                shadow={true}
                renderTrigger={({ ref, onClick, className }) => (
                <Button id="general_config_connectionProfileButton"
                        innerRef={ref}
                        className="square"
                        onClick={ onClick }>
                  <AWIcon name="connect"/>
                  <span className="text"><Translate id="genericLabel.EXPORT_PROFILE.text"/></span>
                </Button>
                )}>
          {({ close }) => (
          <AWPopupRow>
            <AWPopupCol>
              <form>
                <FormGroup style={{ minWidth: '16rem' }}>
                  <Label for="host">
                    <Translate id="genericLabel.IP_ADDRESS_OR_HOSTNAME.text"/>
                  </Label>
                  <input
                    id="general_config_connectionProfile_input"
                    ref={elementRef}
                    type="text"
                    placeholder={publicIp}
                    autoFocus
                    className="form-control-sm form-control"
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        props.callExportStreamhubProfile(elementRef.current.value); close()
                      }
                    }}
                    />
                </FormGroup>
                <FormGroup className="buttons">
                  <Button id="general_config_connectionProfile_downloadButton"
                          color="primary"
                          outline
                          size="sm"
                          onClick={() => {props.callExportStreamhubProfile(elementRef.current.value); close()}}>
                    <Translate id="genericLabel.DOWNLOAD.text"/>
                  </Button>
                </FormGroup>
              </form>
            </AWPopupCol>
          </AWPopupRow>
          )}
        </AWPopup>
      </div>
      { importConfigModalOpened &&
      <ImportConfigModal onCancel={() => props.callImportConfigModal(false)}
                         onUpdate={props.callImportConfig}/>
      }
      { resetConfigModalOpened &&
      <ResetConfigModal onCancel={() => props.callResetConfigModal(false)}
                        onConfirm={props.callResetConfig}/>
      }
    </section>
  )
};

ConfigSection.propTypes = propTypes;

const mapStateToProps = (state) => {
  const { importConfigModalOpened, resetConfigModalOpened } = state.settings.general;
  const publicIp = state.streamhub.publicIp;
  return {
    importConfigModalOpened,
    resetConfigModalOpened,
    publicIp
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    callExportConfig: () => dispatch(exportConfig()),
    callImportConfigModal: (open) => dispatch(importConfigModal(open)),
    callImportConfig: (file) => dispatch(importConfig(file)),
    callResetConfigModal: (open) => dispatch(resetConfigModal(open)),
    callResetConfig: () => dispatch(resetConfig()),
    callExportStreamhubProfile: (ipAddress) => dispatch(exportStreamhubProfile(ipAddress))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigSection);