import PropTypes from "prop-types";
import React, { Component } from 'react';
import { Tooltip } from "reactstrap";
import { withLocalize } from "react-localize-redux";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";
import AWBadgeLabel from "@aviwest/ui-kit/dist/js/components/badge-label";
import { formatOutputId } from "../../../../../utils/global-utils";
import { OUTPUT_TYPE_NDI } from '../../../../../constants';

const propTypes = {
  nbConnect: PropTypes.number,
  tallyState: PropTypes.number,
  outputId: PropTypes.string.isRequired,
  finalName: PropTypes.string.isRequired,
};

class NdiBadge extends Component {
  constructor(props) {
    super(props);
    this.toggleMessage = this.toggleMessage.bind(this);

    this.state = {
      tooltipMessage: false
    }
  }

  toggleMessage() {
    this.setState({
      tooltipMessage: !this.state.tooltipMessage
    });
  }

  render() {
    const { nbConnect, tallyState, outputId, translate, finalName } = this.props;
    if (nbConnect < 0) {
      return (<AWBadgeLabel>{finalName}</AWBadgeLabel>);
    }
    // Provide users explanation about number of connections and tally state.
    let message;
    if (nbConnect === 0) {
      message = translate('genericLabel.NDI_SOURCE_HAS_NO_RECEIVER.text');
    } else if (tallyState === 0) {
      message = translate('genericLabel.NDI_SOURCE_CONNECTED_TO.text', { 'param.nbConnect': nbConnect });
    } else if (tallyState === 1) {
      message = translate('genericLabel.NDI_SOURCE_ON_PREVIEW_CONNECTED_TO.text', { 'param.nbConnect': nbConnect });
    } else if (tallyState === 2) {
      message = translate('genericLabel.NDI_SOURCE_ON_PROGRAM_CONNECTED_TO.text', { 'param.nbConnect': nbConnect });
    }

    return (
      <AWBadgeLabel left={formatOutputId(outputId, OUTPUT_TYPE_NDI)} right={
        <span className="ndi-badge" id={`ndi-badge-${outputId}`}><AWIcon name="ndi_cast" /> {nbConnect}
          <Tooltip position="top-end" isOpen={this.state.tooltipMessage} target={`ndi-badge-${outputId}`} toggle={this.toggleMessage}>
            {message}
          </Tooltip>
        </span>
      }>
        {finalName}
      </AWBadgeLabel>
    )
  }
};

NdiBadge.propTypes = propTypes;

export default withLocalize(NdiBadge);