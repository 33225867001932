import {
  SETTINGS_GET_NDI_STREAMS,
  SETTINGS_INPUT_IP_PROFILE_CREATE,
  SETTINGS_INPUT_IP_PROFILE_DELETE,
  SETTINGS_INPUT_IP_PROFILE_UPDATE,
  SETTINGS_INPUT_SAVE,
  SETTINGS_INPUT_TMP_PROFILE_CREATE,
  SETTINGS_INPUT_TMP_PROFILE_RESULT,
  SETTINGS_MULTIVIEW_SAVE,
  SETTINGS_OUTPUT_HARDWARE_SAVE,
  SETTINGS_OUTPUT_NDI_SAVE,
  SETTINGS_PREP_NDI_FINDER,
  SETTINGS_CUSTOM_PATTERN_UPDATE
} from "../../../constants";

export const updateMultiViewSettings = (data) => {
  return {
    type: SETTINGS_MULTIVIEW_SAVE,
    ...data
  }
};

export const updateCustomPattern = (file, prefix, id) => {
  return {
    type: SETTINGS_CUSTOM_PATTERN_UPDATE,
    prefix,
    file,
    id
  }
};

export const updateInputSettings = (inputId, name, autoRecord, autoPlay, recorderPrefix, lostStreamTimeout, videoIFBSourceIdx, videoIFBEncoderIdx, videoIFBLatency, intercom) => {
  return {
    type: SETTINGS_INPUT_SAVE,
    inputId,
    name,
    autoRecord,
    autoPlay,
    recorderPrefix,
    lostStreamTimeout,
    videoIFBSourceIdx,
    videoIFBEncoderIdx,
    videoIFBLatency,
    intercom
  }
};

export const createInputIPProfile = (profile) => {
  return {
    type: SETTINGS_INPUT_IP_PROFILE_CREATE,
    profile
  }
};

export const updateInputIPProfile = (profileId, profile) => {
  return {
    type: SETTINGS_INPUT_IP_PROFILE_UPDATE,
    profileId,
    profile
  }
};

export const deleteInputIPProfile = (profileId) => {
  return {
    type: SETTINGS_INPUT_IP_PROFILE_DELETE,
    profileId
  }
};

export const createInputTmpProfile = (profile) => {
  return {
    type: SETTINGS_INPUT_TMP_PROFILE_CREATE,
    profile
  }
}

export const inputTmpProfileResult = (profileName, errorCode) => {
  return {
    type: SETTINGS_INPUT_TMP_PROFILE_RESULT,
    name: profileName,
    errorCode
  }
}

export const updateOutputSettings = (outputId, data) => {
  return {
    type: SETTINGS_OUTPUT_HARDWARE_SAVE,
    outputId,
    ...data
  }
};

export const updateNDIOutputSettings = (outputId, data) => {
  return {
    type: SETTINGS_OUTPUT_NDI_SAVE,
    outputId,
    ...data
  }
};

export const getNDIStreams = () => {
  return {
    type: SETTINGS_GET_NDI_STREAMS
  }
};

export const prepNDIFinder = (groups, extraIPs) => {
  return {
    type: SETTINGS_PREP_NDI_FINDER,
    groups: groups,
    extraIPs: extraIPs
  }
};