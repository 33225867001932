import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import {Button} from "reactstrap";
import {Translate} from "react-localize-redux";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

import {inputIpProfilePropTypes, inputPropTypes} from "../../../../utils/models-prop-types";
import IPProfileMetadata from '../../../common/ip-profile-metadata';
import Empty from "../../../common/empty";

import {isEmptyString} from "../../../../utils/string-utils";
import StatusBadge from '../../../common/status-badge';
import { INPUT_TYPE_IP, STATUS_OFF } from '../../../../constants';
import { formatInputId, inputIcon } from '../../../../utils/global-utils';

const matchingSearch = (profile, search) => {
  if(search.length === 0){
    return true
  }
  const lowerCaseSearch = search.toLowerCase();
  return profile.name.toLowerCase().indexOf(lowerCaseSearch) !== -1 ||
      profile.protocol.toLowerCase().indexOf(lowerCaseSearch) !== -1 ||
      (profile.uri && profile.uri.toLowerCase().indexOf(lowerCaseSearch) !== -1) ||
      (profile.port && profile.port.toString().indexOf(lowerCaseSearch) !== -1) ||
      (profile.uris && profile.uris.toString().indexOf(lowerCaseSearch) !== -1) ||
      (profile.localUris && profile.localUris.toString().indexOf(lowerCaseSearch) !== -1) ||
      (profile.publicUri && profile.publicUri.toLowerCase().indexOf(lowerCaseSearch) !== -1) ||
      (profile.hostnameUri && profile.hostnameUri.toLowerCase().indexOf(lowerCaseSearch) !== -1)
};

const propTypes = {
  search: PropTypes.string.isRequired,
  input: inputPropTypes,
  ipProfiles: PropTypes.arrayOf(inputIpProfilePropTypes),
  onProfileEdit: PropTypes.func,
  inputs: PropTypes.arrayOf(inputPropTypes),
};

const defaultProps = {
  input: null,
  onProfileEdit: null,
};

class InputIPProfileList extends Component {

  render() {
    const { ipProfiles, onProfileEdit, search, inputs } = this.props;
    const hasActions = onProfileEdit !== null;
    const matchingProfiles = ipProfiles.filter(profile => matchingSearch(profile, search));
    if(matchingProfiles.length === 0){
      if(!isEmptyString(search)){
        return (
          <Empty icon="search"
                 text={<Translate id="genericLabel.INPUTS_IP_PROFILES_SEARCH_MATCH_EMPTY.text"/>}
                 subText={`"${search}"`}/>
        );
      }
      else {
        return (
          <Empty icon="tv_screen"
                 text={<Translate id="genericLabel.INPUTS_IP_PROFILES_EMPTY.text"/>}/>
        );
      }
    }
    return (
        <div className="profiles-settings">
          { matchingProfiles
              .map(profile => {
                const matchingInput = inputs.find(input => profile.inputId === input.id);
                return <div className="profile" id={"inputsProfile_list_"+profile.id} key={ profile.name }>
                  <div className="linked-input" id={"inputsProfile_list_"+profile.name+"_linkedInput"}>{matchingInput && formatInputId(matchingInput.id)}</div>
                  <div className="name text-clip" id={"inputsProfile_list_"+profile.id+"_name"}>
                    <span title={profile.name} className="text-clip">
                      { profile.name }
                    </span>
                  </div>
                  <div className="protocol" id={"inputsProfile_list_"+profile.name+"_protocol"}>
                    <AWIcon name={inputIcon(INPUT_TYPE_IP, profile.protocol)} className="mr-1" /> { <Translate id={`channelType.${profile.protocol}.text`} /> }
                  </div>
                  <div className="status" id={"inputsProfile_list_"+profile.name+"_status"}><StatusBadge status={matchingInput ? matchingInput.status : STATUS_OFF} /></div>
                  <div className="metadata">
                    <IPProfileMetadata ipProfile={profile}/>
                  </div>
                  <div className="actions">
                    { hasActions &&
                    <Fragment>
                      { profile.enable === false && onProfileEdit !== null &&
                      <Button id={"ipInput_list_"+profile.name+"_editButton"}
                              className="basic icon"
                              onClick={() => onProfileEdit(profile)}>
                        <AWIcon name="edit"/>
                      </Button>
                      }
                    </Fragment>
                    }
                  </div>
                </div>;
              }) }
        </div>
    );
  }
}

InputIPProfileList.propTypes = propTypes;
InputIPProfileList.defaultProps = defaultProps;

export default InputIPProfileList;