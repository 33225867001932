import {TOOLS_LOGS_EXPORT, TOOLS_LOGS_FILTERS_CHANGE, TOOLS_LOGS_PAGE_CHANGE} from "../../../../constants";

export const changeFilters = (filters) => {
  return {
    type: TOOLS_LOGS_FILTERS_CHANGE,
    filters
  }
};

export const downloadLogs = () => {
  return {
    type: TOOLS_LOGS_EXPORT
  }
};

export const changePage = (page, perPage) => {
  return {
    type: TOOLS_LOGS_PAGE_CHANGE,
    page,
    perPage
  }
};