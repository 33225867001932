import {Formik} from "formik";
import PropTypes from "prop-types";
import React, {useState} from "react";
import { connect } from 'react-redux';
import {Button, Form, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {Translate, withLocalize} from "react-localize-redux";

import {
  outputSrtPullLocalURIs,
  outputSrtPullPublicURI,
  outputSrtPushURI
} from "../../../../misc/streamhub.adapters";
import {isEmptyString, isFullURLValid, isGroupValid, isSRTValid} from "../../../../utils/string-utils";
import {SRT} from "../../../../constants";
import AWSwitch from "@aviwest/ui-kit/dist/js/components/switch";
import PasswordRevealInput from "../../../common/password-reveal-input";
import { getAvailableSrtKeyLengths } from "../../../../misc/capabilities.selectors";
import HelpLayout from "../../../common/help-layout";

const propTypes = {
  additionalActions: PropTypes.node,
  config: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    serverMode: PropTypes.bool.isRequired,
    uri: PropTypes.string,
    port: PropTypes.number,
    latency: PropTypes.number,
    password: PropTypes.string,
    keyLength: PropTypes.string,
    cbrMode: PropTypes.bool,
  }),
  forbiddenNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  forbiddenUris: PropTypes.arrayOf(PropTypes.object).isRequired,
  forbiddenUDPPorts: PropTypes.arrayOf(PropTypes.number).isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  localIps: PropTypes.arrayOf(PropTypes.string),
  publicIp: PropTypes.string,
  publicHostname: PropTypes.string,
  srtKeyLengths: PropTypes.arrayOf(PropTypes.string).isRequired,
  alert: PropTypes.node,
  protocol: PropTypes.node
};

const BITRATE_MIN = 500
const BITRATE_MAX = 100000
const LATENCY_MIN = 20
const LATENCY_MAX = 5000
const PORT_MIN = 21;
const PORT_MAX = 65535;

const SRTForm = (props) => {
  const { config, forbiddenNames, forbiddenUris, forbiddenUDPPorts, localIps, publicIp, publicHostname, translate, srtKeyLengths, alert, protocol } = props;
  const [passwordProtected, setPasswordProtected] = useState(config && config.password ? true : false)

  const uriList = (values) => {
    let uris = null;
    if(values.serverMode){
      uris = outputSrtPullLocalURIs(localIps, values);
      if (publicIp) {
        uris.push(outputSrtPullPublicURI(publicIp, values));
      }
      if (publicHostname) {
        uris.push(outputSrtPullPublicURI(publicHostname, values));
      }
    }
    else {
      uris = [outputSrtPushURI(values)];
    }
    return uris;
  };

  const handleFormSubmit = (values) => {

    props.onSubmit(values);
  };

  const handleValidation = (values) => {
    const errors = {};

    // Name
    if(isEmptyString(values.name)){
      errors.name = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if(values.name.length >= 32){
      errors.name = 'genericLabel.TOO_LONG.text';
    }
    else if(forbiddenNames.indexOf(values.name) !== -1){
      errors.name = 'genericLabel.DUPLICATED_VALUES.text';
    }
    else if(!isGroupValid(values.name)){
      errors.name = 'genericLabel.INVALID_FORMAT.text'
    }

    if(!values.serverMode) {
      if (!(isFullURLValid(values.uri) || isSRTValid(values.uri))){
        errors.uri = 'genericLabel.INVALID_FORMAT.text';
      }
      if (isEmptyString(values.uri)) {
        errors.uri = 'genericLabel.REQUIRED_FIELD.text';
      }
      }
    else {
      if (isEmptyString(values.port)) {
        errors.port = 'genericLabel.REQUIRED_FIELD.text';
      } else if (values.port < PORT_MIN || values.port > PORT_MAX) {
        errors.port = 'genericLabel.INVALID_FORMAT.text';
      } else if(forbiddenUDPPorts.indexOf(parseInt(values.port)) !== -1){
        errors.port = 'genericLabel.PORT_ALREADY_USED.text';
      }
    }

    if (isEmptyString(values.latency)) {
      errors.latency = 'genericLabel.REQUIRED_FIELD.text';
    } else if (values.latency < LATENCY_MIN || values.latency > LATENCY_MAX) {
      errors.latency = 'genericLabel.INVALID_FORMAT.text';
    }

    if (values.keyLength !== 'Disabled' && isEmptyString(values.password )) {
      errors.password = 'genericLabel.REQUIRED_FIELD.text';
    }

    // Password with length >= 80 characters are not encrypted 
    if(values.keyLength !== 'Disabled' && (!config || (config && config.password !== values.password)) && values.password.length >= 80) {
      errors.password = 'genericLabel.TOO_LONG.text';
    }

    // Password length > 10 characters (Bugs #16892)
    if(values.keyLength !== 'Disabled' && (!config || (config && config.password !== values.password)) && values.password.length < 10) {
      errors.password = 'genericLabel.TOO_SHORT.text';
    }

    const uris = uriList(values);
    if(uris !== null && uris.find(uri => forbiddenUris.filter( element => element.uri === uri).length !== 0) !== undefined){
      errors.duplicateUriFrom = forbiddenUris.find(element => uris.filter(uri => uri === element.uri).length !== 0).name
      errors.uri = 'genericLabel.DUPLICATED_VALUES_FROM.text';
      errors.port = 'genericLabel.DUPLICATED_VALUES_FROM.text';
    }

    if (values.cbrMode) {
      // Bitrate
      if(isEmptyString(values.bitrate)){
        errors.bitrate = 'genericLabel.REQUIRED_FIELD.text';
      } else if (values.bitrate < BITRATE_MIN || values.bitrate > BITRATE_MAX) {
        errors.bitrate = 'genericLabel.INVALID_FORMAT.text';
      }
    }

    return errors;
  };

  return (
    <Formik initialValues={{
              id: config ? config.id : undefined,
              mode: SRT,
              name: config ? config.name : '',
              serverMode: config ? config.serverMode : false,
              uri: config && config.uri ? config.uri : '',
              port: config && config.port ? config.port : '',
              latency: config && config.latency ? config.latency : 125,
              password: config && config.password ? config.password : '',
              keyLength: config && config.keyLength ? config.keyLength : 'Disabled',
              cbrMode: config ? config.cbrMode : false,
              bitrate: config && config.bitrate ? config.bitrate : 12000,
              delay: config && config.delay ? config.delay : 2000
            }}
            validate={ handleValidation }
            validateOnBlur={false}
            validateOnChange={true}
            onSubmit={ handleFormSubmit }>
      {({
          values,
          errors,
          dirty,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue
          /* and other goodies */
        }) => (
        <Form onSubmit={ handleSubmit }>

          <HelpLayout
          filename={`c_sh_srt_output_profile.html`}
          form={<fieldset disabled={config && config.enable === true}>
            {alert}
            {protocol}

            <FormGroup>
              <Label for="name">
                <Translate id="genericLabel.NAME.text"/>
              </Label>
              <Input type="text"
                    name="name"
                    id="outputsProfile_srt_name"
                    invalid={errors.name !== undefined}
                    placeholder={ translate('genericLabel.NAME.text') }
                    value={values.name}
                    onBlur={handleBlur}
                    onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.name} />
              </FormFeedback>
            </FormGroup>

            <FormGroup>
              <Label for="serverMode">
                <Translate id="genericLabel.SERVER_MODE.text"/>
              </Label>
              <div className="push-pull" id="outputsProfile_srt_serverMode">
                <div className="push">
                  <Translate id="genericLabel.CALLER.text"></Translate>
                </div>
                <AWSwitch checked={values.serverMode}
                          onChange={(checked) => setFieldValue('serverMode', !values.serverMode)}/>
                <div className="pull">
                  <Translate id="genericLabel.LISTENER.text"></Translate>
                </div>
              </div>
            </FormGroup>

            { values.serverMode === true &&
            <FormGroup>
              <Label for="port">
                <Translate id="genericLabel.UDP_PORT.text"/>
              </Label>
              <Input type="number"
                    name="port"
                    id="outputsProfile_srt_listener_port"
                    min={PORT_MIN}
                    max={PORT_MAX}
                    invalid={errors.port !== undefined}
                    placeholder={ translate('genericLabel.UDP_PORT.text') }
                    value={values.port}
                    onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.port} /> { errors.duplicateUriFrom }
              </FormFeedback>
            </FormGroup>
            }
            { values.serverMode === false &&
            <FormGroup>
              <Label for="uri">
                <Translate id="genericLabel.STREAM_URL.text"/>
              </Label>
              <Input type="text"
                    name="uri"
                    id="outputsProfile_srt_caller_uri"
                    invalid={errors.uri !== undefined}
                    placeholder={ translate('genericLabel.STREAM_URL.text') }
                    value={values.uri}
                    onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.uri} /> { errors.duplicateUriFrom }
              </FormFeedback>
            </FormGroup>
            }

            <FormGroup>
              <Label for="latency">
                <Translate id="genericLabel.LATENCY.text"/>
              </Label>
              <Input type="number"
                    name="latency"
                    id="outputsProfile_srt_latency"
                    min={LATENCY_MIN}
                    max={LATENCY_MAX}
                    invalid={errors.latency !== undefined}
                    placeholder={ translate('genericLabel.LATENCY.text') }
                    value={values.latency}
                    onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.latency} />
              </FormFeedback>
            </FormGroup>

            <FormGroup check>
              <Label check>
                <Input type="checkbox"
                      name="passwordProtected"
                      id="outputsProfile_srt_passwordProtected"
                      onChange={() => {
                        setPasswordProtected(!passwordProtected);
                        setFieldValue('keyLength', !passwordProtected ? srtKeyLengths[0] : 'Disabled');
                        setFieldValue('password', '');
                      }}
                      checked={passwordProtected}/>{' '}
                <Translate id="genericLabel.PASSWORD_PROTECTED.text"/>
              </Label>
            </FormGroup>
            { passwordProtected &&
            <>
              <FormGroup>
                <Label for="keyLength">
                  <Translate id="genericLabel.KEY_LENGTH.text"/>
                </Label>
                <Input type="select"
                      name="keyLength"
                      id="outputsProfile_srt_keyLength"
                      value={values.keyLength}
                      onChange={handleChange}>
                  { srtKeyLengths.map(keyLength => (
                    <option key={keyLength} value={keyLength}>{ keyLength }</option>
                  ))}
                </Input>
              </FormGroup>
              { values.keyLength !== 'Disabled' &&
              <FormGroup>
                <Label for="password">
                  <Translate id="genericLabel.PASSWORD.text"/>
                </Label>
                <PasswordRevealInput name="password"
                                    id="outputsProfile_srt_password"
                                    setFieldValue={setFieldValue}
                                    invalid={errors.password !== undefined}
                                    placeholder={ translate('genericLabel.PASSWORD.text') }
                                    value={values.password}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    error={errors.password}/>
              </FormGroup>
              }
            </>
            }
            <FormGroup>
              <Label for="cbrMode">
                <Translate id="genericLabel.VIDEO_BITRATE_MODE.text"/>
              </Label>
              <div className="push-pull" id="outputsProfile_srt_cbrMode">
                <div className="push">VBR</div>
                <AWSwitch checked={values.cbrMode}
                          onChange={(checked) => setFieldValue('cbrMode', !values.cbrMode)}/>
                <div className="pull">CBR</div>
              </div>
            </FormGroup>

            { values.cbrMode === true &&
            <FormGroup>
              <Label for="bitrate">
                <Translate id="genericLabel.BITRATE.text"/>
              </Label>
              <Input type="number"
                    name="bitrate"
                    id="outputsProfile_srt_cbr_bitrate"
                    min={BITRATE_MIN}
                    max={BITRATE_MAX}
                    invalid={errors.bitrate !== undefined}
                    placeholder={ translate('genericLabel.BITRATE.text') }
                    value={values.bitrate}
                    onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.bitrate} />
              </FormFeedback>
              <div className="indicator">
                <Translate id="genericLabel.BITRATE_KBPS.text" />
              </div>
            </FormGroup>
            }
            <div className="uri-preview">
              { uriList(values).map((uri, index) => (<div key={`${uri}-${index}`} className="uri" id={"outputsProfile_srt_uri_"+index}>{uri}</div>)) }
            </div>
            </fieldset>}

            buttons={<FormGroup className="buttons">
              { props.additionalActions }
              <Button id="outputsProfile_srt_saveButton"
                      color="primary"
                      disabled={!dirty}
                      type="submit">
                <Translate id="genericLabel.SAVE.text"/>
              </Button>
            </FormGroup>
          } />
        </Form>
      )}
    </Formik>
  );
};

SRTForm.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  return {
    srtKeyLengths: getAvailableSrtKeyLengths(state)
  }
};

export default connect(mapStateToProps)(withLocalize(SRTForm));
