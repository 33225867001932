
export const toggleInputConfig = (ipProfileId, enable) => {
  return {"name": `inputProtocol.${ipProfileId}.enable`, "value": enable, "type":"boolean"};
};

export const toggleHardwareOutputConfig = (outputId, itemConfig, enable) => {
  return {"name": `basebandPlayer.${outputId}.${itemConfig}`, "value": enable, "type":"boolean"};
};

export const toggleNDIOutputConfig = (outputId, itemConfig, enable) => {
  return {"name": `NDIOutput.${outputId}.${itemConfig}`, "value": enable, "type":"boolean"};
};

export const toggleIPOutputConfig = (ipProfileId, itemConfig, enable) => {
  return {"name": `streamingOutput.${ipProfileId}.${itemConfig}`, "value": enable, "type":"boolean"};
};

export const textItemConfig = (path, value, encrypt = undefined) => {
  return {"name": path, "value": value, "type": "text", "encrypt": encrypt};
};

export const numberItemConfig = (path, value) => {
  return {"name": path, "value": value, "type": "number"};
};

export const booleanItemConfig = (path, value) => {
  return {"name": path, "value": value, "type": "boolean"};
};

export const nextIndexForProfileConfig = (profileConfig) => {
  if(!profileConfig) {
    profileConfig = {};
  }
  const lastIndexString = Object.keys(profileConfig)
    .reduce((prev, current) => parseInt(prev) > parseInt(current) ? prev : current, '0');
  const lastIndex = parseInt(lastIndexString);
  if(isNaN(lastIndex)){
    throw new Error(`Cannot compute next index for profile  ${profileConfig}`);
  }
  return lastIndex + 1;
};

export const maxStreamingOutputOrder = (streamingOutput) => {
  if(!streamingOutput){
    streamingOutput = {};
  }
  const orders = Object.values(streamingOutput).map(streamingOutput => streamingOutput.outputOrder);
  if(orders.length === 0){
    return 0;
  }
  if(Math.max(...orders)<0){
    return 0;
  }
  return Math.max(...orders);
};