import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Translate} from "react-localize-redux";
import DayJs from 'dayjs';
import { Button } from "reactstrap";

import {
  SERVER_LOG_LEVEL_ERROR,
  SERVER_LOG_LEVEL_INFO,
  SERVER_LOG_LEVEL_WARNING
} from "../../../../constants";
import {changeFilters, downloadLogs, changePage} from "./logs.tools.actions";
import LogFilters, {filtersPropTypes, hasAtLeastOneFilter} from "./filters";
import Empty from "../../../common/empty";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";
import AWPageExplorer from "@aviwest/ui-kit/dist/js/components/page-explorer"

const propTypes = {
  callChangeFilters: PropTypes.func.isRequired,
  callDownloadLogs: PropTypes.func.isRequired,
  callChangePage: PropTypes.func.isRequired,
  filters: filtersPropTypes.isRequired,
  logs: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.oneOf([SERVER_LOG_LEVEL_INFO, SERVER_LOG_LEVEL_WARNING, SERVER_LOG_LEVEL_ERROR]).isRequired,
    label: PropTypes.string.isRequired,
    timestamp: PropTypes.instanceOf(Date).isRequired
  })),
  pagesTotal: PropTypes.number,
  currentPage: PropTypes.number,
  perPage: PropTypes.number,
};

class LogsTools extends Component {

  componentDidMount(){
    this.props.callChangePage(1, this.props.perPage);
  }

  render() {
    const { callChangeFilters, callDownloadLogs, callChangePage, filters, logs, pagesTotal, currentPage, perPage } = this.props;
    const atLeastOneFilter = hasAtLeastOneFilter(filters);
    return (
        <div className="tools-section">
          <div className="header">
            <div className="title">
              <Translate id="genericLabel.LOGS.text"/>
            </div>
            <div className="actions">
              <LogFilters onChange={ callChangeFilters }
                          filters={ filters }/>
              <Button className="basic"
                      onClick={ callDownloadLogs }>
                <AWIcon name="download"/>
              </Button>
            </div>
          </div>
          <div className="content pretty-scroll">
            { logs && logs.length > 0 &&
            <ul className="aw-logs">
              { logs.map((log, index) => {
                return <li key={index}>
                  <span className="timestamp">{DayJs(log.timestamp).format('YYYY-MM-DD HH:mm:ss')}</span>
                  <span className={`label ${log.type}`}>{log.label}</span>
                </li>;
              })}
            </ul>
            }
            { logs && logs.length === 0 && atLeastOneFilter &&
              <Empty icon="filter"
                     text={<Translate id="genericLabel.LOGS_FILTER_MATCH_EMPTY.text"/>}/>
            }
          </div>
          { pagesTotal > 0 &&
          <AWPageExplorer currentPage={currentPage} pagesTotal={pagesTotal} onChangePage={callChangePage} itemsPerPage={perPage} itemsPerPageValues={[10, 25, 50, 100]}/>
          }
        </div>
    );
  }
}

LogsTools.propTypes = propTypes;

const mapStateToProps = (state) => {
  const perPage = state.tools.logs.perPage
  return {
    logs: state.tools.logs.list,
    filters: state.tools.logs.filters,
    pagesTotal: state.tools.logs.pagesTotal,
    currentPage: state.tools.logs.currentPage,
    perPage
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    callChangeFilters: (filters) => dispatch(changeFilters(filters)),
    callDownloadLogs: () => dispatch(downloadLogs()),
    callChangePage: (page, perPage) => dispatch(changePage(page, perPage))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LogsTools);