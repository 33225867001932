import {
  RECEIVED_CAPABILITIES
} from "../constants";

const initialState = {
};

const capabilitiesReducer = (state = initialState, action) => {

  if(action.type === RECEIVED_CAPABILITIES){
    return {
      ...state,
      ...action.capabilities,
      _timestamp: new Date().getTime()
    };
  }

  return state;
};

export default capabilitiesReducer;