import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import AWHelpLayout from "@aviwest/ui-kit/dist/js/layouts/help-layout";

const propTypes = {
  form: PropTypes.node.isRequired,
  buttons: PropTypes.node,
  filename: PropTypes.string,
};

function HelpLayout({ form, buttons, filename }) {
  const [html, setHtml] = useState('');
  const [helpNotFound, setHelpNotFound] = useState(true);

  useEffect(() => {
    setHtml('')
    setHelpNotFound(true)
    if (filename !== null) {
      Axios.get(`${process.env.PUBLIC_URL}/help/${filename}`, {
        "Content-Type": "application/xhtml; charset=utf-8"
      })
        .then((response) => {
          if (response.data) {
            setHelpNotFound(false)
            setHtml(response.data)
          }
        })
        .catch((error) => {
          console.error('Axios', error);
        });
    }
  }, [filename]);

  const content = useMemo(() => {
    return {
      __html: html
    }
  }, [html]);

  return (
    <AWHelpLayout form={form} 
      buttons={buttons} 
      help={helpNotFound ? null :
        <div dangerouslySetInnerHTML={content} />} 
    />
  )
}

HelpLayout.propTypes = propTypes;

export default HelpLayout;

