import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import AWModal from "@aviwest/ui-kit/dist/js/components/modal";
import {withLocalize} from "react-localize-redux";
import {openHelpModal} from "../../../misc/help.actions";
import {Alert} from "reactstrap";
import AWIcon from '@aviwest/ui-kit/dist/js/components/icon';
import Axios from 'axios'

const propTypes = {
  callCloseModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  filename: PropTypes.string,
  opened: PropTypes.bool.isRequired,
};

const HelpModal = (props) => {
  const {callCloseModal, translate, filename, opened} = props;
  const [html, setHtml] = useState('');
  const [helpNotFound, setHelpNotFound] = useState(true);

  const content = () => {
    return {
      __html: html
    }
  }

  useEffect(() => {
    setHtml('')
    setHelpNotFound(true)
    if (filename !== null) {
      Axios.get(`${process.env.PUBLIC_URL}/help/${filename}`, {
        "Content-Type": "application/xhtml; charset=utf-8"
      })
      .then((response) => {
        if (response.data) {
          setHelpNotFound(false)
          setHtml(response.data)
        }
      })
      .catch((error) => {
        console.error('Axios', error);
      });
    }
  }, [filename]);

  return (
    <AWModal id="help_modal" 
      icon="faq"
      open={opened}
      onClose={ callCloseModal }
      title={ translate("genericLabel.HELP.text") }>
      { opened && !helpNotFound &&
        <div className='help-layout' dangerouslySetInnerHTML={content()} />
      }
      { opened && helpNotFound &&
        <Alert color="info" className="mt-4">
          <AWIcon name="info_circle" className="mr-2"/>
          {translate("genericLabel.HELP_NOT_FOUND.text")}
        </Alert>
      }
    </AWModal>
  )
}

HelpModal.propTypes = propTypes;

const mapStateToProps = (state) => {
  return {
    opened: state.help.opened,
    filename: state.help.filename
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    callCloseModal: () => dispatch(openHelpModal(null, false))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(HelpModal));
