import PropTypes from 'prop-types';
import React, {Component} from 'react';
import { connect } from 'react-redux';
import {withLocalize} from "react-localize-redux";
import {Button, Form, Input} from "reactstrap";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

import {
  uploadDirectoryContent
} from '../../files/files.actions';

import {filePropTypes} from "../../../../utils/models-prop-types";
import FileLayoutSelector from "../../../common/file-explorer/layout-selector";
import FileExplorer from "../../../common/file-explorer";
import Breadcrumbs from "../../../common/file-explorer/breadcrumbs";
import {changeDashboardFilesLayout, displayEncoders, inputPlaybackStart} from "../dashboard.actions";
import { canUseEncoders } from '../../../../misc/license.selectors';
import { openHelpModal } from '../../../../misc/help.actions';

const propTypes = {
  currentFiles : PropTypes.arrayOf(filePropTypes),
  currentPath : PropTypes.string,
  currentBreadcrumbs : PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    path: PropTypes.string
  })),
  encodersVisible: PropTypes.bool,
  filesVisible: PropTypes.bool,
  callChangeFilesLayout: PropTypes.func.isRequired,
  callPlaybackStart: PropTypes.func.isRequired,
  callUploadDirectoryContent: PropTypes.func.isRequired,
  callDisplayEncoders: PropTypes.func.isRequired,
  openHelpModal: PropTypes.func.isRequired,
  canUseEncoders: PropTypes.bool.isRequired
};

class FileSection extends Component {

  constructor(props) {
    super(props);
    this.state = {
      filterText: '',
      sort: {
        type: 'numeric',
        reverse: true
      }
    };

    this.handleFilterTextChange = this.handleFilterTextChange.bind(this);
  }

  componentDidMount(){
    this.props.callUploadDirectoryContent(this.props.currentPath);
  }

  componentDidUpdate(prevProps, prevState){
    if (prevState.filterText !== this.state.filterText) {
      // Reset selected mode

      // Reset filter
      if (prevProps.currentFiles !== this.props.currentFiles) {
        this.setState({
          filterText: ''
        });
      }
    }

  }

  handleFilterTextChange(e) {
    this.setState({
      filterText: e.target.value
    });
  }

  render() {
    const { currentBreadcrumbs, currentFiles, currentPath, encodersVisible, filesVisible,
      layout, loading, mobileOutputsVisible, translate, canUseEncoders, callDisplayEncoders,
      openHelpModal } = this.props;
    const display = filesVisible && !mobileOutputsVisible;
    const { filterText } = this.state;
    return (
      <div id="fileExplorer" className={ `layout-section file-section ${display ? '' : 'hidden'}` }>
        <div className="section-header">
          <div className="title">
            <Breadcrumbs breadcrumbs={currentBreadcrumbs}
                         onSelect={this.props.callUploadDirectoryContent}/>
            { false && // TODO: waiting for help file
            <Button id="fileExplorer_infoButton"
                    className="basic icon"
                    size="sm"
                    onClick={() => openHelpModal("file_section.html")}>
              <AWIcon name="info_circle"/>
            </Button>
            }
          </div>
          <div className="display-options">
            <Form id="fileExplorer_search" 
                  className="form-inline"><Input className="search mx-3"
                  onChange={this.handleFilterTextChange}
                  placeholder={translate('genericLabel.SEARCH.text')}
                  bsSize="sm"
                  value={this.state.filterText}/></Form>

            <Button id="fileExplorer_folderOpen"
                    className="basic icon"
                    title={translate('genericLabel.FILE_EXPLORER.text')}
                    size="sm"
                    color='primary'
                    active>
              <AWIcon name="folder_open"/>
            </Button>

            { canUseEncoders &&
              <Button id="fileExplorer_encoderButton"
                      className="basic icon" title={translate('genericLabel.ENCODERS.text')}
                      size="sm"
                      onClick={() => callDisplayEncoders(!encodersVisible)}>
                <AWIcon name="encoder_2"/>
              </Button>
            }

            <FileLayoutSelector onChange={this.props.callChangeFilesLayout}
                                selected={layout}/>
          </div>
        </div>
        <div className="section-content pretty-scroll">
          { display &&
          <FileExplorer enableDND={true}
                        files={currentFiles}
                        layout={layout}
                        loading={loading}
                        onDisplayDirectoryContent={this.props.callUploadDirectoryContent}
                        onPlayback={this.props.callPlaybackStart}
                        path={currentPath}
                        search={filterText}
                        sort={this.state.sort}/>
          }
        </div>
      </div>
    );
  }
}

FileSection.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  const { mobileOutputsVisible, filesVisible, filesLayout, encodersVisible } = state.dashboard;
  const { breadcrumbs, files, loading, path } = state.files;
  return {
    currentFiles: files,
    currentPath: path,
    currentBreadcrumbs: breadcrumbs,
    filesVisible,
    encodersVisible,
    layout: filesLayout,
    loading,
    mobileOutputsVisible,
    canUseEncoders: canUseEncoders(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    callChangeFilesLayout: (layout) => dispatch(changeDashboardFilesLayout(layout)),
    callUploadDirectoryContent: (path) => dispatch(uploadDirectoryContent(path)),
    callPlaybackStart: (input, element) => dispatch(inputPlaybackStart(input, element)),
    callDisplayEncoders: (display) => dispatch(displayEncoders(display)),
    openHelpModal: (filename) => dispatch(openHelpModal(filename, true)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(FileSection));