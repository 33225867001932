import PropTypes from 'prop-types';
import React, {Fragment, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import AWRxStoreFactory from '@aviwest/ui-kit/dist/js/rx/rx-store-factory';
import {SETTINGS_MULTIVIEW_CUSTOM_PATTERN_PREFIX, SETTINGS_ENCODER_CUSTOM_PATTERN_PREFIX, SETTINGS_OUTPUT_CUSTOM_PATTERN_PREFIX, SETTINGS_NDI_OUTPUT_CUSTOM_PATTERN_PREFIX} from '../../../constants'

const propTypes = {
  inputId: PropTypes.string,
  outputId: PropTypes.string,
  encoderId: PropTypes.string,
  uniqueId: PropTypes.string,
  multiView: PropTypes.bool,
  defaultPatternUrl: PropTypes.string,
  url: PropTypes.string,
  timestamp: PropTypes.number
};

const defaultProps = {
  multiView: false
};

const Thumbnail = (props) => {
  const { inputId, outputId, encoderId, multiView, ndiOutputId, defaultPatternUrl, children, orientation } = props;
  const [outputUrl, setOutputUrl] = useState();
  const [ndiOutputUrl, setNdiOutputUrl] = useState();
  const [encoderUrl, setEncoderUrl] = useState();
  const [inputUrl, setInputUrl] = useState();
  const [multiViewUrl, setMultiViewUrl] = useState();

  useEffect(() => {
    const subscriber = AWRxStoreFactory.getBasicStore('inputThumbs').getObservable(inputId).subscribe(setInputUrl);
    return () => {
      subscriber.unsubscribe();
    };
  }, [inputId]);

  useEffect(() => {
    const subscriber = AWRxStoreFactory.getBasicStore('outputThumbs').getObservable(outputId).subscribe(setOutputUrl);
    return () => {
      subscriber.unsubscribe();
    };
  }, [outputId]);

  useEffect(() => {
    const subscriber = AWRxStoreFactory.getBasicStore('ndiOutputThumbs').getObservable(ndiOutputId).subscribe(setNdiOutputUrl);
    return () => {
      subscriber.unsubscribe();
    };
  }, [ndiOutputId]);

  useEffect(() => {
    const subscriber = AWRxStoreFactory.getBasicStore('multiViewThumb').getObservable('multiview').subscribe(setMultiViewUrl);
    return () => {
      subscriber.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const subscriber = AWRxStoreFactory.getBasicStore('encoderThumbs').getObservable(encoderId).subscribe(setEncoderUrl);
    return () => {
      subscriber.unsubscribe();
    };
  }, [encoderId]);


  let url;
    // For outputs, we try to get the physical preview, otherwise, we get the input one
    if(outputId){
      url = (outputUrl);
    }
    else if(ndiOutputId){
      url = (ndiOutputUrl);
    }
    else if(encoderId){
      url = (encoderUrl);
    }
    else if(inputId){
      url = (inputUrl);
    }
    else if(multiView){
      url = (multiViewUrl);
    }

  if(!defaultPatternUrl && !url){
    return children ?? null;
  }
  return (
    <Fragment>
      <img className={ orientation === "portrait" ? "portrait" : ''} alt="" src={url || defaultPatternUrl}/>
      {children}
    </Fragment>
  )
}

Thumbnail.propTypes = propTypes;
Thumbnail.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => {
  const {
    encoderId,
    uniqueId,
    outputId,
    ndiOutputId,
    multiView,
    timestamp
  } = ownProps;

  let defaultPatternUrl = null;
  let orientation = "";
  if(encoderId){
    orientation = state.config.encoderProfile[state.config.enc[encoderId].encoderProfileId].orientation;
  }
  if(encoderId && uniqueId){
    defaultPatternUrl = `/thumbnails/${SETTINGS_ENCODER_CUSTOM_PATTERN_PREFIX}/${uniqueId}/${timestamp}`;
  }
  if(outputId){
    defaultPatternUrl = `/thumbnails/${SETTINGS_OUTPUT_CUSTOM_PATTERN_PREFIX}/${outputId}/${timestamp}`;
  }
  else if(ndiOutputId){
    defaultPatternUrl = `/thumbnails/${SETTINGS_NDI_OUTPUT_CUSTOM_PATTERN_PREFIX}/${ndiOutputId}/${timestamp}`;
  }
  else if(multiView){
    defaultPatternUrl = `/thumbnails/${SETTINGS_MULTIVIEW_CUSTOM_PATTERN_PREFIX}/0/${timestamp}`;
  }
  return {
    defaultPatternUrl,
    orientation
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Thumbnail);