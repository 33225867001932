import {SETTINGS_MEDIA_FILE_CLEANER_UPDATE, SETTINGS_MEDIA_RECEIVED_FILES_UPDATE, SETTINGS_MEDIA_RECORDED_FILES_UPDATE, SETTINGS_MEDIA_RENAME_FILES_UPDATE} from "../../../../constants";

export const updateMediaReceivedFilesSettings = (config) => {
  return {
    type: SETTINGS_MEDIA_RECEIVED_FILES_UPDATE,
    config
  }
};

export const updateMediaRecordedFilesSettings = (config) => {
  return {
    type: SETTINGS_MEDIA_RECORDED_FILES_UPDATE,
    config
  }
};

export const updateMediaFileCleanerSettings = (config) => {
  return {
    type: SETTINGS_MEDIA_FILE_CLEANER_UPDATE,
    config
  }
};

export const updateMediaRenameFilesSettings = (config) => {
  return {
    type: SETTINGS_MEDIA_RENAME_FILES_UPDATE,
    config
  }
};