import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {XAxis, YAxis, LineSeries, XYPlot, HorizontalGridLines, VerticalGridLines, DiscreteColorLegend} from 'react-vis';
import AutoSizer from "react-virtualized-auto-sizer";
import { connect } from 'react-redux';
import DayJS from 'dayjs';
import {withLocalize} from 'react-localize-redux';

import '../../../../../../node_modules/react-vis/dist/style.css';
import {GRAPH_COLORS, GRAPH_GRID_COLOR, GRAPH_LEGEND_COLOR, GRAPH_MIN_WIDTH} from "../../../../../constants";

const propTypes = {
  encoderId: PropTypes.string.isRequired,
  stats: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      timestamp: PropTypes.number.isRequired,
      totalBitrate: PropTypes.number.isRequired,
      videoBitrate: PropTypes.number.isRequired,
      audioBitrate: PropTypes.number.isRequired,
      maxTotalBitrate: PropTypes.number,
      maxVideoBitrate: PropTypes.number,
      maxAudioBitrate: PropTypes.number
    })).isRequired
  }),
  translate: PropTypes.func.isRequired
};

const margins= {
  left: 50,
  right: 50,
  bottom: 18
};

const tickTotalX = 4;
const tickTotalY = 4;
const tickWidth = 0;

class Graph extends Component {

  constructor(props){
    super(props);

    this.toggleLegend = this.toggleLegend.bind(this);

    this.state = {
      legend: false
    };
  }

  toggleLegend() {
    this.setState({
      legend: !this.state.legend
    });
  }

  formatBitrate(value, index, scale, tickTotal){
    return `${scale.tickFormat(tickTotal, '~s')(value)}b`;
  }

  render(){
    if(!this.props.stats){
      return null;
    }
    const maxBitrate = this.props.stats.maxTotalBitrate + (this.props.stats.maxTotalBitrate * 0.1); // We had 20% of margin for max value
    return (
      <AutoSizer disableHeight>
        {({ width }) => {
          return width > GRAPH_MIN_WIDTH ?
            <div className="graph">
              <div className="base">
                <XYPlot
                  getX={d => d.timestamp }
                  getY={d => d.videoBitrate }
                  xType="time"
                  yDomain={[0, maxBitrate]}
                  height={135}
                  width={width}
                  margin={margins}>
                  <HorizontalGridLines style={{ stroke: GRAPH_GRID_COLOR }} tickTotal={tickTotalY}/>
                  <VerticalGridLines style={{ stroke: GRAPH_GRID_COLOR }} tickTotal={tickTotalX}/>
                  <XAxis style={{ line: {stroke: GRAPH_GRID_COLOR}, text: {stroke: GRAPH_LEGEND_COLOR}}} tickTotal={tickTotalX} tickFormat={v => `${DayJS(v).format('HH:mm')}`} tickSizeOuter={0} tickSizeInner={tickWidth}/>
                  <YAxis title={this.props.translate("genericLabel.BITRATE.text")} style={{ line: {stroke: GRAPH_GRID_COLOR}, text: {stroke: GRAPH_LEGEND_COLOR}}} tickTotal={tickTotalY} tickFormat={ this.formatBitrate } tickSizeOuter={0} tickSizeInner={tickWidth}/>
                  <LineSeries getNull={(d) => d.hidden !== true} data={this.props.stats.data} color={GRAPH_COLORS[1]}/>
                </XYPlot>
              </div>
              <div className="overlay" onMouseOver={ this.toggleLegend } onMouseOut={ this.toggleLegend }>
                <XYPlot
                  getX={d => d.timestamp }
                  getY={d => d.audioBitrate }
                  xType="time"
                  yDomain={[0, maxBitrate]}
                  height={135}
                  width={width}
                  margin={margins}>
                  <LineSeries getNull={(d) => d.hidden !== true} data={this.props.stats.data} color={GRAPH_COLORS[2]}/>
                </XYPlot>
              </div>
              { this.state.legend &&
                <div className="overlay">
                  <DiscreteColorLegend
                    width={width}
                    orientation="horizontal"
                    items={
                      [
                        {title: this.props.translate("genericLabel.VIDEO_BITRATE.text"), color: GRAPH_COLORS[1], strokeWidth:10},
                        {title: this.props.translate("genericLabel.AUDIO_BITRATE.text"), color: GRAPH_COLORS[2], strokeWidth:10},
                      ]
                    }
                  />
                </div>
              }
            </div> : null;
        }}
      </AutoSizer>
    );
  }
}

Graph.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  return {
    stats: state.datastore.encoderStreamStats[ownProps.encoderId]
  };
};

export default connect(mapStateToProps)(withLocalize(Graph));