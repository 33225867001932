import PropTypes from 'prop-types';
import React, {Fragment, Component} from 'react';
import {Translate, withLocalize} from "react-localize-redux";
import {Button, Form, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {Formik} from "formik";
import {isEmptyString, isEncoderNameValid, generateUniqueId} from "../../../../utils/string-utils";
import {encoderProfilePropTypes, lostStreamModePropTypes} from "../../../../utils/models-prop-types";
import {connect} from "react-redux";
import {getEncoderProfileNames} from "../../../../misc/config.selectors";
import Accordion from "../../../common/accordion";
import {getAvailableEncoderLostStreamModes, getEncoderCapabilities, getEncoderPresets} from "../../../../misc/capabilities.selectors";
import HelpLayout from '../../../common/help-layout';
import AWConfirm from '@aviwest/ui-kit/dist/js/components/confirm';
import CustomPatternForm from '../custom-pattern/custom-pattern-form';
import {SETTINGS_ENCODER_CUSTOM_PATTERN_PREFIX} from '../../../../constants'
const propTypes = {
  editingProfileId: PropTypes.string,
  encoderProfile: encoderProfilePropTypes,
  forbiddenNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  encoderLostStreamModes: PropTypes.arrayOf(lostStreamModePropTypes),
  encoderCapabilities: PropTypes.shape({
    videoTypes: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
    })).isRequired,
    audioTypes: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
    })).isRequired,
    outputStandards: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
    })).isRequired,
    gopSizes: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
    })).isRequired
  }),
  alert: PropTypes.node,
  disabled: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func
};

const VIDEO_BITRATE_MIN = 500
const VIDEO_BITRATE_MAX = 20000
const LATENCY_MIN = 20
const LATENCY_MAX = 5000

const CUSTOM_PATTERN_VALUE = 3;
const EncoderTypeNone = 'Passthrough';

class EncoderProfileForm extends Component {

  constructor(props){
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleCustomPattern = this.handleCustomPattern.bind(this);


    this.state = {
      customPatternFormVisible: false,
      timestamp: new Date().getTime()
    }
  }

  componentDidMount(){
    if(this.props.encoderProfile
        && this.props.encoderProfile.videoReturnMode === false
        && this.props.encoderProfile.encoderVideoType !== EncoderTypeNone
        && this.props.encoderProfile.encoderAudioType !== EncoderTypeNone
        && (this.props.encoderProfile.noPlayingPatternMode === CUSTOM_PATTERN_VALUE || this.props.encoderProfile.lostPatternMode === CUSTOM_PATTERN_VALUE)){
      this.handleCustomPattern(true);
    }
    else {
      this.handleCustomPattern(false);
    }
  }

  handleCustomPattern(display){
    this.setState({
      customPatternFormVisible: display
    });
  }

  handleFormSubmit = (values, { resetForm }) => {
    const data = {
      ...values,
      noPlayingPatternMode: parseInt(values.noPlayingPatternMode),
      lostPatternMode: parseInt(values.lostPatternMode),
      gopSize: parseInt(values.gopSize),
    };

    // Do not allow to enable B frames in 'low' coding complexity
    if( values.quality === 'low') {
      data['bFrame'] = false;
    }

    // Reset some properties in 'Passthrough' mode
    if( values.encoderVideoType === EncoderTypeNone) {
      data['bFrame'] = false;
    }

    //Preset Index for custom is 5 because beOnAir, but need to be sent here as 4
    data.encoderPresetIndex = values.encoderPresetIndex
  
    this.props.onSubmit(data);
  };

  handleValidation = (values) => {
    const errors = {};
    const { forbiddenNames, encoderCapabilities } = this.props;

    // Name
    if(isEmptyString(values.name)){
      errors.name = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if(values.name.length >= 32){
      errors.name = 'genericLabel.TOO_LONG.text';
    }
    else if(!isEncoderNameValid(values.name)){
      errors.name = 'genericLabel.INVALID_FORMAT.text';
    }
    else if(forbiddenNames.indexOf(values.name) !== -1){
      errors.name = 'genericLabel.DUPLICATED_VALUES.text';
    }

    if(isEmptyString(values.encoderPresetIndex) || values.encoderPresetIndex===undefined){
      errors.encoderPresetIndex = 'genericLabel.REQUIRED_FIELD.text';
    }
    else{
      if (values.encoderVideoType !== EncoderTypeNone) {
        if(isEmptyString(values.videoBitrate)){
          errors.videoBitrate = 'genericLabel.REQUIRED_FIELD.text';
        } else if(values.videoBitrate < VIDEO_BITRATE_MIN || values.videoBitrate > (this.props.encoderPresets[values.encoderPresetIndex].videoBitrate.maxValue > 0 ? this.props.encoderPresets[values.encoderPresetIndex].videoBitrate.maxValue : VIDEO_BITRATE_MAX)){
          errors.videoBitrate = 'genericLabel.INVALID_FORMAT.text';
        }
      }

      if(isEmptyString(values.latency)){
        errors.latency = 'genericLabel.REQUIRED_FIELD.text';
      } else if (values.latency < LATENCY_MIN || values.latency > LATENCY_MAX) {
        errors.latency = 'genericLabel.INVALID_FORMAT.text';
      }

      // Audio
      if(values.encoderAudioType !== EncoderTypeNone){
        let capability = encoderCapabilities.audioTypes.find(audioType => audioType.name === values.encoderAudioType);
        if (capability && (capability.bitrateMin > values.audioBitrate || capability.bitrateMax < values.audioBitrate )) {
          errors.audioBitrate = 'genericLabel.INVALID_VALUE.text';
        }
      }

      // We can't have 'None' option for Audio AND Video (otherwise the encoder is pointless)
      if(values.encoderAudioType === EncoderTypeNone && values.encoderVideoType === EncoderTypeNone){
        errors.encoderVideoType = 'genericLabel.INVALID_VALUE.text';
        errors.encoderAudioType = 'genericLabel.INVALID_VALUE.text';
      }

    }
    return errors;
  };

  filterEncoderType = (typeNameList) =>{
    typeNameList.splice(typeNameList.indexOf('name'), 1);
    return typeNameList
  }

  render() {
    const props = this.props;
    const {
      editingProfileId,
      encoderCapabilities,
      encoderProfile,
      encoderLostStreamModes,
      translate,
      alert,
      disabled,
      encoderPresets,
      encoderOutputStandard,
      encoderOrientation
    } = props;

    return (
      <Formik initialValues={{
                id: editingProfileId ? editingProfileId : undefined,
                // Force 'Custom' preset (4) if encoderPresetIndex is not in config (Task #15907)
                encoderPresetIndex: encoderProfile && !encoderProfile.encoderPresetIndex ? 4 : undefined,
                videoBitrate: 0,
                latency: 0,
                audioBitrate: 0,
                name: '',
                uniqueId: encoderProfile && encoderProfile.uniqueId ? encoderProfile.uniqueId : generateUniqueId(),
                noPlayingPatternMode: encoderLostStreamModes[0].value,
                lostPatternMode: encoderLostStreamModes[0].value,
                orientation: "landscape",
                chromaSampling: encoderCapabilities.chromaSampling ? "4:2:0 8 bits" : undefined,
                ...encoderProfile, // That way, we override all properties if profile exists
              }}
              validate={ this.handleValidation }
              validateOnBlur={false}
              validateOnChange={true}
              onSubmit={ this.handleFormSubmit }>
        {({
            values,
            errors,
            dirty,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue
            /* and other goodies */
          }) => {

          const handlePresetChange = (e) => {
            handleChange(e);
            const preset = encoderPresets[e.target.value];
            if (preset) {
              setFieldValue('audioBitrate', preset.audioBitrate.defaultValue);
              setFieldValue('bFrame', preset.bFrame.defaultValue);
              setFieldValue('encoderAudioType', preset.encoderAudioCodec.defaultValue);
              setFieldValue('encoderAudioChannelMode', preset.encoderAudioMode.defaultValue);
              setFieldValue('encoderVideoType', preset.encoderVideoCodec.defaultValue);
              setFieldValue('gopSize', preset.gopSize.defaultValue);
              setFieldValue('latency', preset.latency.defaultValue);
              setFieldValue('outputStandard', preset.outputStandard.defaultValue);
              setFieldValue('quality', preset.quality.defaultValue);
              setFieldValue('videoBitrate', preset.videoBitrate.defaultValue);
              setFieldValue('videoReturnMode', preset.videoReturnMode.defaultValue === 0 ? false : true);
              setFieldValue('orientation', preset.orientation.defaultValue);
              if(!!encoderCapabilities.chromaSampling){setFieldValue('chromaSampling', preset.chromaSampling.defaultValue);}

              // Reset custom pattern
              setFieldValue('noPlayingPatternMode', encoderLostStreamModes[0].value);
              setFieldValue('lostPatternMode', encoderLostStreamModes[0].value);
              this.handleCustomPattern(false);
            }
          };

          const handleVideoTypeChange = (e) => {
            handleChange(e);
            const preset = encoderPresets[values.encoderPresetIndex];
            if (preset) {
              setFieldValue('quality', preset.quality.defaultValue);
            }
          };
          
          return (
          <Form onSubmit={ handleSubmit }>
            <HelpLayout
            filename={`c_sh_configuring_encoder_settings.html`}
            form={<Fragment>
            {alert}
            <FormGroup>
              <Label for="name">
                <Translate id="genericLabel.NAME.text"/>
              </Label>
              <Input type="text"
                     name="name"
                     id="encoderProfile_name"
                     disabled={disabled}
                     invalid={errors.name !== undefined}
                     placeholder={ translate('genericLabel.NAME.text') }
                     value={values.name}
                     onBlur={handleBlur}
                     onChange={handleChange}/>
              <Input type="hidden"
                     name="uniqueId"
                     id="encoderProfile_uniqueId"
                     readOnly={true}
                     value={values.uniqueId}/>
              <FormFeedback>
                <Translate id={errors.name} />
              </FormFeedback>
            </FormGroup>

            <FormGroup>
              <Label for="encoderPreset">
                <Translate id="genericLabel.ENCODER_PRESET.text"/>
              </Label>
              <Input type="select"
                      name="encoderPresetIndex"
                      id="encoderProfile_encoderPresetIndex"
                      disabled={disabled}
                      invalid={errors.encoderPresetIndex !== undefined}
                      value={values.encoderPresetIndex}
                      onChange={handlePresetChange}>
                <option value="">-</option>
                { encoderPresets && encoderPresets.map((preset, index) => (
                  <option key={index} value={index} id={preset.name}>{ preset.name }</option>
                ))}
              </Input>
              <FormFeedback>
                <Translate id={errors.encoderPresetIndex} />
              </FormFeedback>
            </FormGroup>

            { values.encoderPresetIndex &&
            <section className="form-section">
              <div className="header">
                <div className="title secondary">
                  <Translate id="genericLabel.VIDEO.text"/>
                </div>
              </div>

              <FormGroup>
                <Label for="encoderVideoType">
                  <Translate id="genericLabel.VIDEO_ENCODER_TYPE.text"/>
                </Label>
                <Input type="select"
                        name="encoderVideoType"
                        id="encoderProfile_encoderVideoType"
                        invalid={errors.encoderVideoType !== undefined}
                        value={values.encoderVideoType}
                        disabled={disabled || (encoderPresets[values.encoderPresetIndex] && !encoderPresets[values.encoderPresetIndex].encoderVideoCodec.isEditable)}
                        onChange={handleVideoTypeChange}>
                  { encoderOutputStandard && this.filterEncoderType(Object.keys(encoderOutputStandard[values.encoderPresetIndex])).map(videoType => (
                    <option key={videoType} value={videoType}>{ videoType }</option>
                  ))}
                </Input>
                <FormFeedback>
                  <Translate id={errors.encoderVideoType} />
                </FormFeedback>
              </FormGroup>
                { encoderCapabilities.chromaSampling &&
                  <FormGroup>
                    <Label for="chromaSampling">
                      <Translate id="genericLabel.CHROMA_SAMPLING.text"/>
                    </Label>
                    <Input type="select"
                            name="chromaSampling"
                            id="encoderProfile_outputStandard"
                            invalid={errors.chromaSampling !== undefined}
                            value={values.chromaSampling}
                            disabled={disabled  || (encoderPresets[values.encoderPresetIndex] && !encoderPresets[values.encoderPresetIndex].chromaSampling.isEditable )}
                            onChange={handleChange}>
                      { encoderCapabilities.chromaSampling.map((chromaSampling) => (
                        <option key={chromaSampling.name} value={chromaSampling.name}>{ chromaSampling.name }</option>
                      ))}
                    </Input>
                    <FormFeedback>
                      <Translate id={errors.chromaSampling} />
                    </FormFeedback>
                  </FormGroup>
                  }

              { values.encoderVideoType !== EncoderTypeNone &&
              <Fragment>
                <FormGroup>
                  <Label for="videoBitrate">
                    <Translate id="genericLabel.VIDEO_BITRATE_CBR.text"/>
                  </Label>
                  <Input type="number"
                          name="videoBitrate"
                          id="encoderProfile_videoBitrate"
                          invalid={errors.videoBitrate !== undefined}
                          min={VIDEO_BITRATE_MIN}
                          max={(encoderPresets[values.encoderPresetIndex].videoBitrate.maxValue > 0) ? encoderPresets[values.encoderPresetIndex].videoBitrate.maxValue : VIDEO_BITRATE_MAX}
                          placeholder={translate('genericLabel.BITRATE.text')}
                          value={values.videoBitrate}
                          disabled={disabled || (encoderPresets[values.encoderPresetIndex] && !encoderPresets[values.encoderPresetIndex].videoBitrate.isEditable)}
                          onBlur={handleBlur}
                          onChange={handleChange}/>
                  <FormFeedback>
                    <Translate id={errors.videoBitrate}/>
                  </FormFeedback>
                  <div className="indicator">
                    <Translate id="genericLabel.BITRATE_KBPS.text"/>
                  </div>
                </FormGroup>
              {encoderOutputStandard[values.encoderPresetIndex][values.encoderVideoType].length !==0 &&
                <FormGroup>
                  <Label for="outputStandard">
                    <Translate id="genericLabel.OUTPUT_STANDARD.text"/>
                  </Label>
                  <Input type="select"
                          name="outputStandard"
                          id="encoderProfile_outputStandard"
                          invalid={errors.outputStandard !== undefined}
                          value={values.outputStandard}
                          disabled={disabled || (encoderPresets[values.encoderPresetIndex] && !encoderPresets[values.encoderPresetIndex].outputStandard.isEditable)}
                          onChange={handleChange}>
                    { encoderOutputStandard[values.encoderPresetIndex][values.encoderVideoType].map((standard, key) => (
                      <option key={standard.name} value={standard.name}>{ standard.name }</option>
                    ))}
                  </Input>
                  <FormFeedback>
                    <Translate id={errors.outputStandard} />
                  </FormFeedback>
                </FormGroup>}
                
                <FormGroup>
                  <Label for="orientation">
                    <Translate id="genericLabel.ORIENTATION.text"/>
                  </Label>
                  <Input type="select"
                          name="orientation"
                          id="encoderProfile_outputStandard"
                          invalid={errors.orientation !== undefined}
                          value={values.orientation}
                          disabled={disabled || (encoderPresets[values.encoderPresetIndex] && !encoderPresets[values.encoderPresetIndex].orientation.isEditable)}
                          onChange={handleChange}>
                    { encoderOrientation.map((encoderOrientation) => (
                      <option key={encoderOrientation.name} value={encoderOrientation.name}>{ encoderOrientation.name }</option>
                    ))}
                  </Input>
                  <FormFeedback>
                    <Translate id={errors.orientation} />
                  </FormFeedback>
                </FormGroup>

                <Accordion className="form-advanced-section"
                            renderTitle={() => (<Translate id="genericLabel.ADVANCED.text"/>)}>

                  { encoderCapabilities && encoderCapabilities.videoTypes.filter(type => type.name === values.encoderVideoType)[0].quality.length > 1 &&
                  <FormGroup>
                    <Label for="quality">
                      <Translate id="genericLabel.ENCODER_COMPLEXITY.text"/>
                    </Label>
                    <Input type="select"
                            name="quality"
                            id="encoderProfile_quality"
                            invalid={errors.quality !== undefined}
                            value={values.quality}
                            disabled={disabled || (encoderPresets[values.encoderPresetIndex] && !encoderPresets[values.encoderPresetIndex].quality.isEditable)}
                            onChange={handleChange}>
                      { encoderCapabilities && encoderCapabilities.videoTypes.filter(type => type.name === values.encoderVideoType)[0].quality.map(value => (
                        <option key={value} value={value}>{ value }</option>
                      ))}
                    </Input>
                    <FormFeedback>
                      <Translate id={errors.quality} />
                    </FormFeedback>
                  </FormGroup>
                  }

                  <FormGroup>
                    <Label for="gopSize">
                      <Translate id="genericLabel.GOP_SIZE.text"/>
                    </Label>
                    <Input type="select"
                            name="gopSize"
                            id="encoderProfile_gopSize"
                            invalid={errors.gopSize !== undefined}
                            value={values.gopSize}
                            disabled={disabled || (encoderPresets[values.encoderPresetIndex] && !encoderPresets[values.encoderPresetIndex].gopSize.isEditable)}
                            onChange={handleChange}>
                      { encoderCapabilities && encoderCapabilities.gopSizes.map(quality => (
                        <option key={quality.value} value={quality.value} id={ quality.name }>{ quality.name }</option>
                      ))}
                    </Input>
                    <FormFeedback>
                      <Translate id={errors.gopSize} />
                    </FormFeedback>
                  </FormGroup>

                  <FormGroup>
                    <Label for="latency">
                      <Translate id="genericLabel.LATENCY.text"/>
                    </Label>
                    <Input type="number"
                          name="latency"
                          id="encoderProfile_latency"
                          invalid={errors.latency !== undefined}
                          min={LATENCY_MIN}
                          max={LATENCY_MAX}
                          placeholder={translate('genericLabel.LATENCY.text')}
                          value={values.latency}
                          disabled={disabled || (encoderPresets[values.encoderPresetIndex] && !encoderPresets[values.encoderPresetIndex].latency.isEditable)}
                          onBlur={handleBlur}
                          onChange={handleChange}/>
                    <FormFeedback>
                      <Translate id={errors.latency}/>
                    </FormFeedback>
                  </FormGroup>

                  { values.quality !== 'low' &&
                  <FormGroup check>
                    <Label check>
                      <Input type="checkbox"
                              name="bFrame"
                              id="encoderProfile_bFrame"
                              disabled={disabled || (encoderPresets[values.encoderPresetIndex] && !encoderPresets[values.encoderPresetIndex].bFrame.isEditable)}
                              onChange={handleChange}
                              checked={values.bFrame}/>{' '}
                      <Translate id="genericLabel.B_FRAME.text"/>
                    </Label>
                  </FormGroup>
                    }

                </Accordion>
              </Fragment>
              }
            </section>
            }

            { values.encoderPresetIndex &&
            <section className="form-section">
              <div className="header">
                <div className="title secondary">
                  <Translate id="genericLabel.AUDIO.text"/>
                </div>
              </div>

              <FormGroup>
                <Label for="encoderAudioType">
                  <Translate id="genericLabel.AUDIO_ENCODER_TYPE.text"/>
                </Label>
                <Input type="select"
                        name="encoderAudioType"
                        id="encoderProfile_encoderAudioType"
                        invalid={errors.encoderAudioType !== undefined}
                        value={values.encoderAudioType}
                        disabled={disabled || (encoderPresets[values.encoderPresetIndex] && !encoderPresets[values.encoderPresetIndex].encoderAudioCodec.isEditable)}
                        onChange={handleChange}>
                  { encoderCapabilities && encoderCapabilities.audioTypes.map(audioType => (
                    <option key={audioType.name} value={audioType.name}>{ audioType.name }</option>
                  ))}
                </Input>
                <FormFeedback>
                  <Translate id={errors.encoderAudioType} />
                </FormFeedback>
              </FormGroup>


              { values.encoderAudioType !== EncoderTypeNone &&
              <FormGroup>
                <Label for="encoderAudioChannelMode">
                  <Translate id="genericLabel.AUDIO_CHANNEL_MODE.text"/>
                </Label>
                <Input type="select"
                        name="encoderAudioChannelMode"
                        id="encoderProfile_encoderAudioChannelMode"
                        invalid={errors.encoderAudioChannelMode !== undefined}
                        value={values.encoderAudioChannelMode}
                        disabled={disabled || (encoderPresets[values.encoderPresetIndex] && !encoderPresets[values.encoderPresetIndex].encoderAudioMode.isEditable)}
                        onChange={handleChange}>
                  { values.videoReturnMode === false && encoderCapabilities && encoderCapabilities.audioChannelModes.map(audioChannelMode => (
                    <option key={audioChannelMode.name} value={audioChannelMode.name}>{ audioChannelMode.name }</option>
                  ))}
                  { values.videoReturnMode === true && encoderCapabilities && encoderCapabilities.audioChannelModesVR.map(audioChannelMode => (
                    <option key={audioChannelMode.name} value={audioChannelMode.name}>{ audioChannelMode.name }</option>
                  ))}
                </Input>
                <FormFeedback>
                  <Translate id={errors.encoderAudioChannelMode} />
                </FormFeedback>
              </FormGroup>
              }

              { values.encoderAudioType !== EncoderTypeNone &&
              <FormGroup>
                <Label for="audioBitrate">
                  <Translate id="genericLabel.AUDIO_BITRATE.text"/>
                </Label>
                <Input type="number"
                        name="audioBitrate"
                        id="encoderProfile_audioBitrate"
                        invalid={errors.audioBitrate !== undefined}
                        placeholder={translate('genericLabel.BITRATE.text')}
                        value={values.audioBitrate}
                        disabled={disabled || (encoderPresets[values.encoderPresetIndex] && !encoderPresets[values.encoderPresetIndex].audioBitrate.isEditable)}
                        onBlur={handleBlur}
                        onChange={handleChange}/>
                <FormFeedback>
                  <Translate id={errors.audioBitrate}/>
                </FormFeedback>
                <div className="indicator">
                  { values.encoderAudioType === 'Mpeg1L2' &&
                  <Translate id="genericLabel.BITRATE_KBPS_PER_TRACK.text"/>
                  }
                  { values.encoderAudioType !== 'Mpeg1L2' &&
                  <Translate id="genericLabel.BITRATE_KBPS_PER_CHANNEL.text"/>
                  }
                </div>
              </FormGroup>
              }

            </section>
            }

            { values.encoderPresetIndex &&
              values.videoReturnMode === false &&
              values.encoderVideoType !== EncoderTypeNone &&
              values.encoderAudioType !== EncoderTypeNone &&
            <section className="form-section">
              <div className="header">
                <div className="title secondary">
                  <Translate id="genericLabel.MISCELLANEOUS.text"/>
                </div>
              </div>

              <FormGroup>
                <Label for="noPlayingPatternMode">
                  <Translate id="genericLabel.DEFAULT_VIDEO_PATTERN.text"/>
                </Label>
                <Input type="select"
                       name="noPlayingPatternMode"
                       id="encoderProfile_noPlayingPatternMode"
                       disabled={disabled}
                       invalid={errors.noPlayingPatternMode !== undefined}
                       value={values.noPlayingPatternMode}
                       onChange={(e) => {
                             handleChange(e);
                             if (parseInt(e.target.value) !== CUSTOM_PATTERN_VALUE && parseInt(values.lostPatternMode) !== CUSTOM_PATTERN_VALUE) {
                              this.handleCustomPattern(false);
                             }
                             else {
                              this.handleCustomPattern(true);
                             }
                           }}
                       >
                  { encoderLostStreamModes && encoderLostStreamModes.map(mode => (
                    <option key={mode.value} value={mode.value} id={ translate(`lostStreamMode.${mode.name}.text`) }>{ translate(`lostStreamMode.${mode.name}.text`) }</option>
                  ))}
                </Input>
                <FormFeedback>
                  <Translate id={errors.noPlayingPatternMode} />
                </FormFeedback>
              </FormGroup>

              <FormGroup>
                <Label for="lostPatternMode">
                  <Translate id="genericLabel.POLICY_VIDEO_PATTERN.text"/>
                </Label>
                <Input type="select"
                       name="lostPatternMode"
                       id="encoderProfile_lostPatternMode"
                       disabled={disabled}
                       invalid={errors.lostPatternMode !== undefined}
                       value={values.lostPatternMode}
                       onChange={(e) => {
                             handleChange(e);
                             if (parseInt(e.target.value) !== CUSTOM_PATTERN_VALUE && parseInt(values.noPlayingPatternMode) !== CUSTOM_PATTERN_VALUE) {
                               this.handleCustomPattern(false);
                             }
                             else {
                               this.handleCustomPattern(true);
                             }
                           }}
                       >
                  { encoderLostStreamModes && encoderLostStreamModes.map(mode => (
                    <option key={mode.value} value={mode.value} id={ translate(`lostStreamMode.${mode.name}.text`) } >{ translate(`lostStreamMode.${mode.name}.text`) }</option>
                  ))}
                </Input>
                <FormFeedback>
                  <Translate id={errors.lostPatternMode} />
                </FormFeedback>
              </FormGroup>

            </section>
            }

            { this.state.customPatternFormVisible &&
              <CustomPatternForm prefix={SETTINGS_ENCODER_CUSTOM_PATTERN_PREFIX} id={values.uniqueId} timestamp={this.state.timestamp}/>
            }
            </Fragment>}

            buttons={<FormGroup className="buttons">
              { props.onDelete &&
              <AWConfirm
                onConfirm={props.onDelete}
                confirmationText={ props.translate("genericLabel.SECOND_CLICK_CONFIRM.text") }
                render={(ref, onClick) => (
                  <Button id="encoderProfile_deleteButton"
                    innerRef={ref}
                    onClick={onClick}
                    className="mr-auto"
                    outline
                    color="primary">
                    <Translate id="genericLabel.DELETE.text"/>
                  </Button>
                )} />
              }
              { props.onCancel &&
              <Button id="encoderProfile_cancelButton"
                onClick={props.onCancel}
                outline
                color="primary">
                <Translate id="genericLabel.CANCEL.text"/>
              </Button>
              }
              <Button id="encoderProfile_saveButton"
                      color="primary"
                      disabled={disabled || !dirty}
                      type="submit">
                <Translate id="genericLabel.SAVE.text"/>
              </Button>
            </FormGroup>} />
          </Form>
        )}}
      </Formik>
    );
  }
}


EncoderProfileForm.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  const encoderProfile = state.config.encoderProfile ? state.config.encoderProfile[ownProps.editingProfileId] : undefined;
  const existingProfileNames = getEncoderProfileNames(state);
  return {
    encoderProfile,
    encoderLostStreamModes: getAvailableEncoderLostStreamModes(state),
    encoderCapabilities: getEncoderCapabilities(state),
    forbiddenNames: existingProfileNames.filter(name => !encoderProfile || name !== encoderProfile.name),
    encoderPresets: getEncoderPresets(state),
    encoderOutputStandard: state.capabilities.encoderOutputStandard,
    encoderOrientation: state.capabilities.encoderOrientation
  }
};

export default connect(mapStateToProps)(withLocalize(EncoderProfileForm));