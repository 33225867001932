import PropTypes from "prop-types";
import React, {Component} from 'react';
import ReactPlayer from "react-player";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

import Logger from "../../../utils/logger";
import {filePropTypes, outputIpProfilePropTypes} from "../../../utils/models-prop-types";
import {HLS, RTMP} from "../../../constants";
import {fileVideoPreviewUrl} from "../../../utils/global-utils";

const propTypes = {
  controls: PropTypes.bool,
  file: filePropTypes,
  outputIPProfile: outputIpProfilePropTypes,
  streamhubLocalIps: PropTypes.arrayOf(PropTypes.string)
};

const defaultProps = {
  controls: false
};

class Player extends Component {

  constructor(props){
    super(props);

    this.handleError = this.handleError.bind(this);
    this.handleBuffer = this.handleBuffer.bind(this);
    this.handleDuration = this.handleDuration.bind(this);
    this.handleReady = this.handleReady.bind(this);

    this.state = {
      error: null
    }
  }

  handleError(e, data, instance, global){
    Logger.error("[Player] Loading error: ", e, data, instance, global);
    this.setState({
      error: e.target && e.target.error ? e.target.error.message : null
    });
  }

  handleBuffer(){
    Logger.info("[Player] Buffering...");
  }

  handleDuration(duration){
    Logger.info("[Player] Duration: ", duration);
  }

  handleReady(){
    Logger.info("[Player] Player is ready !");
    this.setState({
      error: null
    });
  }

  render(){
    const { file, outputIPProfile, controls, streamhubLocalIps } = this.props;
    const { error } = this.state;
    let url = null;
    if(file){
      url = fileVideoPreviewUrl(file);
    }
    else if(outputIPProfile){
      switch (outputIPProfile.protocol) {
        case HLS:
        case RTMP:
          url = (outputIPProfile.localUris && outputIPProfile.localUris.length > 0 && streamhubLocalIps && streamhubLocalIps.length > 0  ? 
            outputIPProfile.localUris[0].replace(streamhubLocalIps[0], window.location.hostname) : 
            null);
          break;
        default:
          break;
      }
    }
    return (
        <div className="player-wrapper">
          <ReactPlayer className={ `react-player${ error ? ' error' : '' }` }
                       controls={controls}
                       playing
                       url={url}
                       width="100%"
                       height="100%"
                       onReady={ this.handleReady }
                       onBuffer={ this.handleBuffer }
                       onDuration={ this.handleDuration }
                       onError={ this.handleError }/>
          { error &&
          <div className="error-wrapper">
            <div className="error">
              <AWIcon name="error"/>
              <div className="text">
                { error }
              </div>
            </div>
          </div>
          }
        </div>
    );
  }
}

Player.propTypes = propTypes;
Player.defaultProps = defaultProps;

export default Player;