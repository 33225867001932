import {
  SETTINGS_NETWORK_UPDATE,
  SETTINGS_NDI_UPDATE,
  SETTINGS_NETWORK_ADVANCED_UPDATE
} from "../../../../constants";

export const updateNetworkSettings = (data) => {
  return {
    type: SETTINGS_NETWORK_UPDATE,
    data
  }
};

export const updateNdiSettings = (data) => {
  return {
    type: SETTINGS_NDI_UPDATE,
    data
  }
}

export const updateNetworkAdvancedSettings = (data) => {
  return {
    type: SETTINGS_NETWORK_ADVANCED_UPDATE,
    data
  }
};
