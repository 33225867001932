import {
  IDENTITY_CHECKED,
  LOGGED_IN,
  LOGGED_OUT,
  LOGIN,
  LOGIN_ERROR,
  LS_KEY_IDENTITY,
} from '../../../constants';

export const identityChecked = () => {
  return {
    type: IDENTITY_CHECKED
  }
};

export const callLogIn = () => {
  return {
    type: LOGIN
  }
};

export const logInSuccess = (username, token) => {
  cacheIdentity(username, token);
  return {
    type: LOGGED_IN,
    username,
    token
  }
};

const logInError = (error) => {
  return {
    type: LOGIN_ERROR,
    error
  }
};

const getCachedIdentity = () => {
  return JSON.parse(window.sessionStorage.getItem(LS_KEY_IDENTITY));
};

const clearIdentity = () => {
  window.sessionStorage.removeItem(LS_KEY_IDENTITY);
};

const cacheIdentity = (username, token) => {
  const identityToCache = { username, token};
  window.sessionStorage.setItem(LS_KEY_IDENTITY, JSON.stringify(identityToCache));
};

export const cacheIdentityFromSso = (username, token) => {
  const identityToCache = { username, token};
  window.sessionStorage.setItem(LS_KEY_IDENTITY, JSON.stringify(identityToCache));
};

export const loadIdentity = () => (dispatch) => {
  const identity = getCachedIdentity();
  if(identity){
    dispatch(logInSuccess(identity.username, identity.token));
  }
  dispatch(identityChecked());
};

export const logIn = ({ login, password }) => (dispatch, gs, api) => {

  clearIdentity();
  dispatch(callLogIn());
/*
  return api.logInOld(login, password)
      .then((data) => {
        if(!data.token){
          return api.logIn(login, password);
        }
        else {
          return Promise.resolve(data);
        }
      })
      .then((data) => dispatch(logInSuccess(login, data.token)))
      .catch((err) => dispatch(logInError(err)));
    */
  return api.logIn(login, password)

      .then((data) => dispatch(logInSuccess(login, data.token)))
      .catch((err) => dispatch(logInError(err)));
};

export const logOut = () => {
  clearIdentity();
  return {
    type: LOGGED_OUT
  }
};