import PropTypes from "prop-types";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Translate, withLocalize} from "react-localize-redux";
import {Button, Input} from "reactstrap";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

import FileTransferList from "./list";
import FileTransferForm from "./form";
import {fileTransferPropTypes} from "../../../../utils/models-prop-types";
import {
  createFileTransfer,
  deleteFileTransfer,
  updateFileTransfer,
  testFileTransferReset
} from "./file-transfer.settings.actions";

const propTypes = {
  callCreateFileTransfer: PropTypes.func.isRequired,
  callUpdateFileTransfer: PropTypes.func.isRequired,
  callDeleteFileTransfer: PropTypes.func.isRequired,
  callTestFileTransferReset: PropTypes.func.isRequired,
  fileTransfers: PropTypes.arrayOf(fileTransferPropTypes).isRequired
};

class FileTransferSettings extends Component {

  constructor(props){
    super(props);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleEditFileTransfer = this.handleEditFileTransfer.bind(this);
    this.handleNewFileTransfer = this.handleNewFileTransfer.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      search: '',
      editingFileTransferId: null
    };
  }

  handleSearch(evt){
    const search = evt.target.value;
    this.setState((state) => {
      return {
        ...state,
        search
      }
    });
  }

  handleNewFileTransfer() {
    this.setState((state) => {
      return {
        ...state,
        editingFileTransferId: '-1'
      }
    });
  }

  handleEditFileTransfer(fileTransfer) {
    this.setState((state) => {
      return {
        ...state,
        editingFileTransferId: fileTransfer.id
      }
    });
  }

  handleCancel(){
    this.setState((state) => {
      return {
        ...state,
        editingFileTransferId: null
      }
    });
    this.props.callTestFileTransferReset();
  }

  handleSubmit(fileTransfer){
    if(fileTransfer.id){
      this.props.callUpdateFileTransfer(fileTransfer);
    }
    else {
      this.props.callCreateFileTransfer(fileTransfer);
    }
    this.handleCancel();
  }

  render() {
    const { editingFileTransferId, search } = this.state;
    const { fileTransfers, translate, callDeleteFileTransfer, configTooBig} = this.props;
    return (
        <div className="settings-section file-transfer">
          <div className="header">
            <div className="title">
              <Translate id="genericLabel.FILE_TRANSFER.text"/>
            </div>
            { editingFileTransferId === null &&
            <div className="actions">
              <Input id="settings_FileTransfer_search"
                     className="search"
                     onChange={this.handleSearch}
                     placeholder={translate('genericLabel.SEARCH.text')}
                     bsSize="sm"
                     value={search}/>
              <Button id="settings_FileTransfer_addProfile"
                      className="basic"
                      onClick={ this.handleNewFileTransfer }
                      disabled={configTooBig}>
                <AWIcon name="add"/>
              </Button>
            </div>
            }
          </div>
          <div className="content pretty-scroll">
            { editingFileTransferId === null &&
            <FileTransferList fileTransfers={fileTransfers}
                              search={search}
                              onDelete={callDeleteFileTransfer}
                              onEdit={this.handleEditFileTransfer}/>
            }
            { editingFileTransferId !== null &&
            <FileTransferForm editingFileTransferId={editingFileTransferId}
                              onCancel={this.handleCancel}
                              onSubmit={this.handleSubmit}/>
            }
          </div>
        </div>
    );
  }
}

FileTransferSettings.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  return {
    fileTransfers: state.streamhub.fileTransfers,
    configTooBig: state.streamhub.configTooBig
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    callCreateFileTransfer: (fileTransfer) => dispatch(createFileTransfer(fileTransfer)),
    callUpdateFileTransfer: (fileTransfer) => dispatch(updateFileTransfer(fileTransfer)),
    callDeleteFileTransfer: (fileTransfer) => dispatch(deleteFileTransfer(fileTransfer.id)),
    callTestFileTransferReset: () => dispatch(testFileTransferReset())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(FileTransferSettings));