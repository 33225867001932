import PropTypes from "prop-types";
import React, {Fragment, useState } from 'react';
import {Translate} from "react-localize-redux";
import {Label, Input, FormGroup, Button, Alert} from "reactstrap";
import {Form, Formik} from "formik";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";


import {videoCardCapabilitiesPropTypes} from "../../../../utils/models-prop-types";
import {VIDEO_CARD_TYPE_DELTACAST} from "../../../../constants";
import RestartStreamhubModal from "../../tools/system/restart-streamhub-modal";

const propTypes = {
  config: PropTypes.shape({
    '4KMode': PropTypes.bool,
    forceFullFrameRate: false,
    nbSDIInput: PropTypes.number,
    sdiOrder: PropTypes.number,
    videoFrameRate: PropTypes.string,
  }).isRequired,
  videoFrameRates: PropTypes.arrayOf(PropTypes.string),
  videoCard: videoCardCapabilitiesPropTypes.isRequired,
  onSubmit: PropTypes.func.isRequired,
  uploading: PropTypes.bool,
  result: PropTypes.shape({
    res: PropTypes.number.isRequired,
    message: PropTypes.string,
  }),
  callUploadLicense: PropTypes.func.isRequired, // temp
  licenseStatus: PropTypes.bool.isRequired,
};

const VideoCardSettingsForm = (props) => {
  const [confirmModalOpened, setConfirmModalOpened] = useState(false);
  const { config, videoFrameRates, videoCard} = props;

  const handleSubmit = (values, { resetForm }) => {

    props.onSubmit(videoCard.id, values);
    resetForm(values);
    setConfirmModalOpened(false) //temp
  };


  let sdiOrderArray = null;
  if(videoCard.sdiOrder != null){
    sdiOrderArray = videoCard.sdiOrder.map(order => {
      return order.name.split(',').map(value => parseInt(value));
    });
  }

  return (
    <Formik initialValues={{
              '4KMode': config['4KMode'],
              sdiOrder: config.sdiOrder,
              videoFrameRate: config.videoFrameRate,
              forceFullFrameRate: config.forceFullFrameRate,
            }}
            validateOnBlur={false}
            validateOnChange={true}
            onSubmit={ handleSubmit }>
      {({
          values,
          errors,
          dirty,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue
          /* and other goodies */
        }) => (
        <Form onSubmit={handleSubmit}>
          { dirty &&
          <Alert color="warning">
            <Translate id="genericLabel.RESTART_STREAMHUB_TO_TAKE_CHANGES_INTO_ACCOUNT.text"/>
          </Alert>
          }
          { videoCard.has4K &&
          <FormGroup check>
            <Label check>
              <Input id={"videoCard_"+videoCard.modelName+"_enable4K"}
                     type="checkbox"
                     name="4KMode"
                     onChange={handleChange}
                     checked={values['4KMode']}/>{' '}
              <Translate id="genericLabel.4K_MODE.text"/>
            </Label>
          </FormGroup>
          }
          { sdiOrderArray &&
          <FormGroup>
            <Label for="sdiOrder">
              <Translate id="genericLabel.SDI_ORDER.text"/>
            </Label>
            <div>
              { sdiOrderArray.map((order, index) => (
                <div key={index}
                     className="sdi-order">
                  <Button id={"videoCard_"+videoCard.modelName+"_sdiOrder"}
                          className="basic"
                          onClick={() => setFieldValue('sdiOrder', index)}>
                    <div className={`sdi-icon ref ${index === values.sdiOrder ? 'active' : ''}`}>
                      <AWIcon name="sdi"/>
                      <div className="sdi-name"><Translate id="genericLabel.SDI_REF.text"/></div>
                    </div>
                    { order.map(orderIndex => (
                      <div key={orderIndex}
                           className={`sdi-icon ${index === values.sdiOrder ? 'active' : ''}`}>
                        <AWIcon name="sdi"/>
                        <div className="sdi-name">{ orderIndex }</div>
                      </div>
                    ))}
                  </Button>
                </div>
              ))}
            </div>
          </FormGroup>
          }
          { videoCard.sdiCardType === VIDEO_CARD_TYPE_DELTACAST &&
          <Fragment>
            <FormGroup>
              <Label for="videoFrameRate">
                <Translate id="genericLabel.VIDEO_FRAME_RATE.text"/>
              </Label>
              <Input type="select"
                     name="videoFrameRate"
                     id={"videoCard_"+videoCard.modelName+"_videoFrameRate"}
                     value={values.videoFrameRate}
                     onBlur={handleBlur}
                     onChange={handleChange}>
                { videoFrameRates.map(frameRate => (
                  <option key={frameRate} value={frameRate}>{frameRate}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input id={"videoCard_"+videoCard.modelName+"_forceFullFrameRate"}
                       type="checkbox"
                       name="forceFullFrameRate"
                       onChange={handleChange}
                       checked={values.forceFullFrameRate}/>{' '}
                <Translate id="genericLabel.FORCE_FULL_FRAME_RATE.text"/>
              </Label>
            </FormGroup>
          </Fragment>
          }
         <FormGroup className="buttons">
            <Button id={"videoCard_"+videoCard.modelName+"_saveButton"}
                    color="primary"
                    disabled={isSubmitting || !dirty}
                    onClick={() => setConfirmModalOpened(true)}>
              <Translate id="genericLabel.SAVE.text"/>
            </Button>
          </FormGroup>
          { confirmModalOpened &&
          <RestartStreamhubModal onCancel={() => setConfirmModalOpened(false)}
                                  onConfirm={() => handleSubmit()}/>
          }
        </Form>
      )}
    </Formik>
  );
};

VideoCardSettingsForm.propTypes = propTypes;



export default VideoCardSettingsForm;
