import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';
import {withLocalize} from "react-localize-redux";
import AWConfirm from "@aviwest/ui-kit/dist/js/components/confirm";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

import {
  STATUS_ERROR,
  STATUS_LIVE,
  STATUS_OFF,
  STATUS_ON,
  STATUS_WARNING
} from '../../../constants';

const propTypes = {
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  eject: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  status: PropTypes.oneOf([STATUS_OFF, STATUS_ON, STATUS_LIVE, STATUS_ERROR, STATUS_WARNING]).isRequired,
  id: PropTypes.string
};

const defaultProps = {
  disabled: false,
  active: false,
  eject: false
};

const StatusButton = (props) => {
  const { disabled, eject, onChange, status, active, id} = props;

  if(eject){
    return (
    <AWConfirm onConfirm={() => onChange(status === STATUS_OFF ? STATUS_ON : STATUS_OFF)}
               confirmationText={ props.translate("genericLabel.SECOND_CLICK_CONFIRM.text") }
               render={(ref, onClick) => (
            <Button id={id+"_EjectButton"}
                    className="basic icon"
                    active={active}
                    innerRef={ref}
                    disabled={disabled}
                    onClick={ onClick }>
              <AWIcon name="eject"/>
            </Button>
        )}/>
    )
  }
  else {
    return (
        <Button id={id+"_PowerUpButton"}
                className="basic icon"
                active={active}
                disabled={disabled}
                onClick={ () => onChange(status === STATUS_OFF ? STATUS_ON : STATUS_OFF) }>
          <AWIcon name="power_up"/>
        </Button>
    )
  }
};

StatusButton.propTypes = propTypes;

StatusButton.defaultProps = defaultProps;

export default withLocalize(StatusButton);