import PropTypes from "prop-types";
import React, {Component} from 'react';
import {Translate} from "react-localize-redux";
import {connect} from "react-redux";
import AWModal from "@aviwest/ui-kit/dist/js/components/modal";

import {multiViewEditSettings} from "../../dashboard.actions";
import GeneralForm from "./general-form";
import {updateMultiViewSettings} from "../../../settings/settings.actions";

const propTypes = {
  callCloseModal: PropTypes.func.isRequired,
  callUpdateMultiViewSettings: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  outputName: PropTypes.string
};

class MultiViewSettingsModal extends Component {

  constructor(props){
    super(props);

    this.handleCustomPattern = this.handleCustomPattern.bind(this);
    this.handleGeneralFormSubmit = this.handleGeneralFormSubmit.bind(this);

    this.state = {
      customPatternFormVisible: false
    }
  }

  handleCustomPattern(display){
    this.setState({
      customPatternFormVisible: display
    });
  }

  handleGeneralFormSubmit(data){
    this.props.callUpdateMultiViewSettings(data);
    this.props.callCloseModal();
  }

  render(){
    return (
    <AWModal id="multiviewSetting_modal"
             onClose={() => this.props.callCloseModal()}
             open={this.props.opened}
             title={ <span><Translate id="genericLabel.SETTINGS.text"/> <span className="aw-primary">{ this.props.outputName }</span></span> }>
          { this.props.opened &&
          <div className="settings">
            <GeneralForm onDisplayCustomPattern={ this.handleCustomPattern }
                         onSubmit={ this.handleGeneralFormSubmit }/>
          </div>
          }
        </AWModal>
    );
  }

}

MultiViewSettingsModal.propTypes = propTypes;

const mapStateToProps = (state) => {
  return {
    opened: state.dashboard.editingMultiView,
    outputName: state.streamhub.multiView ? state.streamhub.multiView.name : null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    callCloseModal: () => dispatch(multiViewEditSettings(false)),
    callUpdateMultiViewSettings: (data) => dispatch(updateMultiViewSettings(data))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiViewSettingsModal);