import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import {connect} from "react-redux";
import {Translate, withLocalize} from "react-localize-redux";

import AWModal from "@aviwest/ui-kit/dist/js/components/modal";
import { Formik } from 'formik';
import { Button, Form, FormGroup } from 'reactstrap';
import Dropzone from 'react-dropzone';
import { ClipLoader } from 'react-spinners';
import { SPINNER_UPLOAD_COLOR } from '../../../../constants';
import { hasFileExtension } from '../../../../utils/global-utils';
import { uploadLicenseSMPTE2110 } from '../../../../misc/license.actions';

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  result: PropTypes.shape({
    res: PropTypes.number.isRequired,
    message: PropTypes.string,
  }),
  callUploadLicense: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

const LicenseModal = (props) => {
  const {  onCancel, loading, result } = props;

  const handleFormSubmit = (values) => {
    props.onSubmit(values.file);
  };

  const handleValidation = (values) => {
    const errors = {};
    if(!values.file){
      errors.file = props.translate('genericLabel.REQUIRED_FIELD.text');
    }
    else if(!hasFileExtension(values.file.name, 'lic')){
      errors.file = props.translate('genericLabel.INVALID_EXTENSION_CHOICE.text', { 'param.firstChoice': '.awl', 'param.secondChoice': '.json' });
    }
    return errors;
  };
  return (
  <AWModal id="SMPTE2110License_modal"
           icon="license"
           open={true}
           onClose={ onCancel}
           title="SMPTE 2110 LICENSE">
        <Fragment>

        <Formik initialValues={{
              file: null
            }}
            validate={ handleValidation }
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={ handleFormSubmit }>
      {({
          values,
          errors,
          dirty,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          /* and other goodies */
        }) => (
        <Form onSubmit={handleSubmit}>
          { result &&
          <div className={`license-message ${result.res === 0 ? 'success' : 'error' }`}>
            { result.message }
          </div>
          }
          { !loading &&
          <FormGroup id="SMPTE2110License_modal_dropzone">
            <Dropzone multiple={false}
                      accept={['.lic']}
                      onDrop={files => setFieldValue('file', files[0])}>
              {({getRootProps, getInputProps, isDragActive, acceptedFiles}) => (
                <div
                  className={`dropzone ${isDragActive ? 'target' : (errors.file != null ? 'error' : '')}`} {...getRootProps()}>
                    <span className="placeholder">
                      {acceptedFiles.length === 0 && <Translate id="genericLabel.LICENSE_DROPZONE_PLACEHOLDER.text"/>}
                      {acceptedFiles.length > 0 &&
                      <div className="filename">
                        {acceptedFiles.map(acceptedFile => acceptedFile.name)}
                      </div>
                      }
                      {errors.file != null && errors.file}
                    </span>
                  <input {...getInputProps()} />
                </div>
              )}
            </Dropzone>
          </FormGroup>
          }
          { loading &&
            <Fragment>
              <ClipLoader loading={true} color={SPINNER_UPLOAD_COLOR} size={35}/>
              <div className="filename">
                { values.file && values.file.name }
              </div>
            </Fragment>
          }
          <FormGroup className="buttons">
            <Button id="SMPTE2110License_modal_updateButton"
                    color="primary"
                    disabled={!dirty || loading || errors.file != null }
                    size="lg"
                    type="submit">
              <Translate id="genericLabel.UPDATE.text"/>
            </Button>
          </FormGroup>
        </Form>
      )}
    </Formik>
        </Fragment>
    </AWModal>
  );
};

LicenseModal.propTypes = propTypes;

const mapStateToProps = (state) => {
  return {
    
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    callUploadLicense: (file) => dispatch(uploadLicenseSMPTE2110(file))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(LicenseModal));