import {Redirect, Route, withRouter} from "react-router";
import React from "react";
import {connect} from "react-redux";

const PublicRoute = ({ authenticated, name, component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    (authenticated === false || name === 'ssoInput')
      ? <Component {...props}/>
      : <Redirect to="/dashboard"/>
  )}/>
);

const mapStateToProps = (state) => {
  const { login } = state;
  return {
    authenticated: login.authenticated
  };
};

export default withRouter(connect(mapStateToProps)(PublicRoute))