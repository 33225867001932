import PropTypes from "prop-types";
import React from "react";
import {Breadcrumb, BreadcrumbItem} from "reactstrap";
import {withLocalize} from "react-localize-redux";

const propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired
  })).isRequired,
  onSelect: PropTypes.func.isRequired
};

const Breadcrumbs = (props) => {
  const { breadcrumbs, translate } = props;
  if(!breadcrumbs){
    return null;
  }
  return (
    <Breadcrumb className="file-path">
      { breadcrumbs.map((element, index, elements) => {
        if (index < elements.length - 1 ) {
          return (
            <BreadcrumbItem key={element.path}
                            tag="a"
                            active
                            onClick={() => props.onSelect(element.path)}>
              { index === 0 ? translate('genericLabel.FILE_EXPLORER.text') : element.label }
            </BreadcrumbItem>
          );
        }
        else {
          return (
            <BreadcrumbItem key={element.path} active={true}>
              { index === 0 ? translate('genericLabel.FILE_EXPLORER.text') : element.label }
            </BreadcrumbItem>
          );
        }
      })}
    </Breadcrumb>
  );
};

Breadcrumbs.propTypes = propTypes;

export default withLocalize(Breadcrumbs);
