import {
  LICENSE_MODAL,
  LICENSE_STATUS_INVALID,
  LICENSE_UPLOAD,
  LICENSE_UPLOAD_REPONSE,
  RECEIVED_LICENSE,
  LICENSE_MS_TO_EXPIRATION,
  LICENSE_UPLOAD_SMPTE2110,
  LICENSE_UPLOAD_REPONSE_SMPTE2110
} from '../constants';

// const fakeLicense = {
//   hardwareIdentifier: "0A:D1:26:62:E8:14",
//   version: "0",
//   input: 16,
//   DMNGApp: 8,
//   MOJOPRO: 8,
//   physicalOutput: 0,
//   record: true,
//   intercom: false,
//   FTPPush: 8,
//   AES: false,
//   streamingView: false,
//   FourK: false,
//   playback: false,
//   IPInput: true,
//   multiView: false,
//   IPOutput: 8,
//   encoder: 4,
//   forwardInput: 0,
//   HotSpot: 0
// };

const initialState = {
  details: null,
  features: null,
  status: LICENSE_STATUS_INVALID,
  result: null,
  opened: false,
  uploading: false,
  orionConnectionStatus: false,
  hotdeployInProgress: false,
  expirationDateWarning: false,
  message: null,
};

const licenseReducer = (state = initialState, action) => {

  if(action.type === RECEIVED_LICENSE){
    console.log('RECEIVED_LICENSE', action.features)
    // Check for expiration dates
    let expirationDateWarning = false
    Object.keys(action.features).forEach((key) => {
      // Expiration for license
      if (key === 'expirationDate'
        && action.features[key] !== ''
        && (Date.parse(action.features[key]) - Date.now()) < LICENSE_MS_TO_EXPIRATION) {
        console.log('Expiration date raised (global)')
        expirationDateWarning = true
      }
      // Expiration for license features in array
      else if(Array.isArray(action.features[key])){
        action.features[key].forEach(feature => {
          if (feature.count > 0
            && feature.expirationDate
            && feature.expirationDate !== ''
            && (Date.parse(feature.expirationDate) - Date.now()) < LICENSE_MS_TO_EXPIRATION) {
              console.log('Expiration date raised (array)')
              expirationDateWarning = true
          }
        })
      }
      // Expiration for license features
      else if (action.features[key].enabled
        && action.features[key].expirationDate
        && action.features[key].expirationDate !== ''
        && (Date.parse(action.features[key].expirationDate) - Date.now()) < LICENSE_MS_TO_EXPIRATION ) {
        console.log('Expiration date raised')
        expirationDateWarning = true
      }
    });

    return {
      ...state,
      details: action.license,
      features: action.features,
      status: action.status,
      opened: action.status === LICENSE_STATUS_INVALID,
      orionConnectionStatus: action.orionConnectionStatus,
      hotdeployInProgress: action.hotdeployInProgress,
      expirationDateWarning, 
      message: action.message
    };
  }

  if(action.type === LICENSE_MODAL){
      return {
        ...state,
        opened: action.open,
        uploading: false,
        result: null
      };
  }

  if(action.type === LICENSE_UPLOAD){
    return {
      ...state,
      uploading: true
    };
  }

  if(action.type === LICENSE_UPLOAD_REPONSE){
    return {
      ...state,
      uploading: false,
      result: action.result
    };
  }

  if(action.type === LICENSE_UPLOAD_SMPTE2110){
    //console.log("license.reducer")
    return {
      ...state,
      uploading: true
    };
  }

  if(action.type === LICENSE_UPLOAD_REPONSE_SMPTE2110){
    //console.log("license.reducer  reponce")
    window.location.reload();
    return {
      ...state,
      uploading: false,
      result: action.result
    };
  }

  return state;
};

export default licenseReducer;
