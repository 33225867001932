import {
  RECEIVED_CONFIG
} from "../constants";

const initialState = {
};

const configReducer = (state = initialState, action) => {
  switch(action.type){
    case RECEIVED_CONFIG:
      return {
        ...state,
        ...action.config,
        _timestamp: new Date().getTime()
      };

    default:
      return state;
  }
};

export default configReducer;