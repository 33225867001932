import PropTypes from 'prop-types';
import React from 'react';
import {connect} from "react-redux";
import {withLocalize} from "react-localize-redux";
import AWModal from "@aviwest/ui-kit/dist/js/components/modal";

import {openLanguageModal} from "../../../misc/language.actions";
import {LANGUAGES} from "../../../constants";

const propTypes = {
  callCloseModal: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired
};

const LanguageModal = (props) => {
  const { callCloseModal, opened, translate, setActiveLanguage } = props;

  const selectLanguage = (code) => {
    setActiveLanguage(code);
    callCloseModal();
  };

  return (
  <AWModal id="language_modal"
           confirm={true}
           icon="language"
           open={opened}
           onClose={ callCloseModal }
           title={ translate("genericLabel.LANGUAGE.text") }>
      { opened &&
        <ul className="languages">
          { LANGUAGES.map(language => (
          <li key={language.code}
              className="language"
              onClick={() => selectLanguage(language.code)}>
            {language.name}
          </li>
          ))}
        </ul>
      }
    </AWModal>
  );
};

LanguageModal.propTypes = propTypes;

const mapStateToProps = (state) => {
  return {
    opened: state.streamhub.languageModalOpened
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    callCloseModal: () => dispatch(openLanguageModal(false))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(LanguageModal));