import {RECEIVED_FTP_TEST, SETTINGS_FILE_TRANSFER_TEST, SETTINGS_FILE_TRANSFER_TEST_RESET} from "../../../../constants";

const initialState = {
  testResult: null
};

const fileTransferSettingsReducer = (state = initialState, action) => {

  if(action.type === SETTINGS_FILE_TRANSFER_TEST || action.type === SETTINGS_FILE_TRANSFER_TEST_RESET){
    return {
      ...state,
      testResult: null
    };
  }

  if(action.type === RECEIVED_FTP_TEST){
    return {
      ...state,
      testResult: action.res > -1 ? String(action.res) : 'default'
    };
  }

  return state;
};

export default fileTransferSettingsReducer;