import {FieldArray, Formik, getIn} from "formik";
import PropTypes from "prop-types";
import React, {Fragment} from "react";
import {Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row} from "reactstrap";
import {Translate, withLocalize} from "react-localize-redux";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

import {outputTsURIs} from "../../../../misc/streamhub.adapters";
import {isEmptyString, isGroupValid} from "../../../../utils/string-utils";
import {TS} from "../../../../constants";
import HelpLayout from "../../../common/help-layout";

const propTypes = {
  additionalActions: PropTypes.node,
  config: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    bitrate: PropTypes.number.isRequired,
    delay: PropTypes.number.isRequired,
    pmtPID: PropTypes.number.isRequired,
    pcrPID: PropTypes.number.isRequired,
    videoPID: PropTypes.number.isRequired,
    audioPID: PropTypes.number.isRequired,
    protocol: PropTypes.oneOf(['UDP', 'RTP']),
    enableMulticast: PropTypes.bool,
    ttl: PropTypes.number,
    destinations: PropTypes.string.isRequired
  }),
  forbiddenNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  forbiddenUris: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSubmit: PropTypes.func.isRequired,
  localIps: PropTypes.arrayOf(PropTypes.string),
  alert: PropTypes.node,
  protocol: PropTypes.node
};

const PORT_MIN = 1024;
const PORT_MAX = 65535;

const TSForm = (props) => {
  const { config, forbiddenNames, forbiddenUris, translate, alert, protocol } = props;

  const uriList = (values) => {

    return outputTsURIs(values);
  };

  const defaultTTL = (multicast) => {
    if(multicast == null) {
      multicast = false;
    }
    return multicast ? 1 : 64;
  };

  const handleFormSubmit = (values) => {
    const { destinations, ...otherProps } = values;
    otherProps.destinations = destinations.map(destination => `${destination.host}:${destination.port}`).join(',');
    props.onSubmit(otherProps);
  };

  const handleValidation = (values) => {
    const errors = {};

    // Name
    if(isEmptyString(values.name)){
      errors.name = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if(values.name.length >= 32){
      errors.name = 'genericLabel.TOO_LONG.text';
    }
    else if(forbiddenNames.indexOf(values.name) !== -1){
      errors.name = 'genericLabel.DUPLICATED_VALUES.text';
    }
    else if(!isGroupValid(values.name)){
      errors.name = 'genericLabel.INVALID_FORMAT.text'
    }

    // Bitrate
    if(isEmptyString(values.bitrate)){
      errors.bitrate = 'genericLabel.REQUIRED_FIELD.text';
    }
    if(values.bitrate<0){
      errors.bitrate = 'genericLabel.INVALID_VALUE.text';
    }

    // PMT PID
    if(isEmptyString(values.pmtPID)){
      errors.pmtPID = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if (values.pmtPID < 1 || values.pmtPID > 8190){
      errors.pmtPID = 'genericLabel.INVALID_VALUE.text';
    }

    // PCR PID
    if(isEmptyString(values.pcrPID)){
      errors.pcrPID = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if (values.pcrPID < 1 || values.pcrPID > 8190){
      errors.pcrPID = 'genericLabel.INVALID_VALUE.text';
    }

    // Video PID
    if(isEmptyString(values.videoPID)){
      errors.videoPID = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if (values.videoPID < 1 || values.videoPID > 8190){
      errors.videoPID = 'genericLabel.INVALID_VALUE.text';
    }

    // Audio PID
    if(isEmptyString(values.audioPID)){
      errors.audioPID = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if (values.audioPID < 1 || values.audioPID > 8190){
      errors.audioPID = 'genericLabel.INVALID_VALUE.text';
    }

    //TTL
    if(isEmptyString(values.ttl)){
      errors.ttl = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if(values.ttl < 1 || values.ttl > 255){
      errors.ttl = 'genericLabel.TTL_INTERVAL.text';
    }

    // Destinations
    values.destinations.forEach((destination, index) => {
      if(isEmptyString(destination.host)){
        if(!errors.destinations){
          errors.destinations = [];
        }
        if(!errors.destinations[index]){
          errors.destinations[index] = {};
        }
        errors.destinations[index].host = 'genericLabel.REQUIRED_FIELD.text';
      }

      // Multicast address
      let multicastRegex = /^2(?:2[4-9]|3\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d?|0)){3}$/;
      let ipRegex = /\b(?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\b/;
      let validMulticastAddress = true;

      // Invalid multicast IP
      if (destination.host && values.multicast && !multicastRegex.test(destination.host)) {
        validMulticastAddress = false;
      }
      // Invalid unicast IP (should not be a mutlicast IP)
      else if (destination.host && !values.multicast && (!ipRegex.test(destination.host) || multicastRegex.test(destination.host))) {
        validMulticastAddress = false;
      }

      if (!validMulticastAddress) {
        if(!errors.destinations){
          errors.destinations = [];
        }
        if(!errors.destinations[index]){
          errors.destinations[index] = {};
        }
        errors.destinations[index].host = 'genericLabel.INVALID_IP_ADDRESS.text';
      }

      if(isEmptyString(destination.port)){
        if(!errors.destinations){
          errors.destinations = [];
        }
        if(!errors.destinations[index]){
          errors.destinations[index] = {};
        }
        errors.destinations[index].port = 'genericLabel.REQUIRED_FIELD.text';
      } else if (destination.port < PORT_MIN || destination.port > PORT_MAX) {
        if(!errors.destinations){
          errors.destinations = [];
        }
        if(!errors.destinations[index]){
          errors.destinations[index] = {};
        }
        errors.destinations[index].port = 'genericLabel.INVALID_FORMAT.text';
      }
    });

    const uris = uriList(values);
    if(uris !== null && uris.find(uri => forbiddenUris.filter( element => element.uri === uri).length !== 0) !== undefined){
      errors.duplicateUriFrom = forbiddenUris.find(element => uris.filter(uri => uri === element.uri).length !== 0).name
      errors.destinationsGlobal = 'genericLabel.DUPLICATED_VALUES_FROM.text';
    }

    return errors;
  };

  const handleMulticastChange = (e, destinations, setFieldValue) => {
    const multicastValue = e.target.checked;
    setFieldValue('multicast', multicastValue);
    setFieldValue('ttl', multicastValue ? 1 : 64);
    if(multicastValue){
      setFieldValue('ttl', 1);
      setFieldValue('destinations', destinations.filter((dest, index) => index === 0));
    }
    else {
      setFieldValue('ttl', 64);
    }
  };

  return (
    <Formik initialValues={{
              id: config ? config.id : undefined,
              mode: TS,
              name: config ? config.name : '',
              bitrate: config ? config.bitrate : '',
              delay: config ? config.delay : 2000,
              pmtPID: config ? config.pmtPID : 101,
              pcrPID: config ? config.pcrPID : 102,
              videoPID: config ? config.videoPID : 103,
              audioPID: config ? config.audioPID : 104,
              protocol: config ? config.protocol : 'UDP',
              multicast: config && config.multicast != null ? config.multicast : false,
              ttl: config && config.ttl != null ? config.ttl : defaultTTL(config ? config.multicast : null),
              destinations: config && config.destinations ? config.destinations.split(',').map(destination => {
                  return { host: destination.split(':')[0], port: destination.split(':')[1]
                  }}) :
                [{
                  host: '',
                  port: ''
                }]
            }}
            validate={ handleValidation }
            validateOnBlur={false}
            validateOnChange={true}
            onSubmit={ handleFormSubmit }>
      {({
          values,
          errors,
          dirty,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue
          /* and other goodies */
        }) => (
        <Form onSubmit={ handleSubmit }>

          <HelpLayout
          filename={`c_sh_ts_output_profile.html`}
          form={<fieldset disabled={config && config.enable === true}>
            {alert}
            {protocol}

            <FormGroup>
              <Label for="name">
                <Translate id="genericLabel.NAME.text"/>
              </Label>
              <Input type="text"
                    name="name"
                    id="outputsProfile_ts_name"
                    invalid={errors.name !== undefined}
                    placeholder={ translate('genericLabel.NAME.text') }
                    value={values.name}
                    onBlur={handleBlur}
                    onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.name} />
              </FormFeedback>
            </FormGroup>

            <FormGroup>
              <Label for="bitrate">
                <Translate id="genericLabel.TS_BITRATE.text"/>
              </Label>
              <Input type="number"
                      name="bitrate"
                      id="outputsProfile_ts_bitrate"
                      invalid={errors.bitrate !== undefined}
                      placeholder={ translate('genericLabel.TS_BITRATE.text') }
                      value={values.bitrate}
                      min={0}
                      onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.bitrate} />
              </FormFeedback>
              <div className="indicator">
                <Translate id="genericLabel.BITRATE_KBPS.text" />
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="pid">
                <Translate id="genericLabel.PID.text"/>
              </Label>
              <Row id="pid" form>
              <Col md={3}>
                  <Input type="number"
                         min="1"
                         max="8190"
                        name="pmtPID"
                        id="outputsProfile_ts_pmtPID"
                        invalid={errors.pmtPID !== undefined}
                        placeholder={ translate('genericLabel.PMT.text') }
                        value={values.pmtPID}
                        onChange={handleChange}/>
                  <FormFeedback>
                    <Translate id={errors.pmtPID} />
                  </FormFeedback>
                </Col>
                <Col md={3}>
                  <Input type="number"
                         min="1"
                         max="8190"
                        name="pcrPID"
                        id="outputsProfile_ts_pcrPID"
                        invalid={errors.pcrPID !== undefined}
                        placeholder={ translate('genericLabel.PCR.text') }
                        value={values.pcrPID}
                        onChange={handleChange}/>
                  <FormFeedback>
                    <Translate id={errors.pcrPID} />
                  </FormFeedback>
                </Col>
                <Col md={3}>
                  <Input type="number"
                         min="1"
                         max="8190"
                        name="videoPID"
                        id="outputsProfile_ts_videoPID"
                        invalid={errors.videoPID !== undefined}
                        placeholder={ translate('genericLabel.VIDEO.text') }
                        value={values.videoPID}
                        onChange={handleChange}/>
                  <FormFeedback>
                    <Translate id={errors.videoPID} />
                  </FormFeedback>
                </Col>
                <Col md={3}>
                  <Input type="number"
                         min="1"
                         max="8190"
                        name="audioPID"
                        id="outputsProfile_ts_audioPID"
                        invalid={errors.audioPID !== undefined}
                        placeholder={ translate('genericLabel.AUDIO.text') }
                        value={values.audioPID}
                        onChange={handleChange}/>
                  <FormFeedback>
                    <Translate id={errors.audioPID} />
                  </FormFeedback>
                </Col>
              </Row>
              <div className="indicator">
                <Translate id="genericLabel.PID_HELP.text" />
              </div>
            </FormGroup>
            <FormGroup>
              <FormGroup check inline>
                <Label check>
                  <Input type="radio"
                        name="protocol"
                        id="outputsProfile_ts_UDPProtocol"
                        checked={values.protocol === 'UDP'}
                        onChange={handleChange}
                        value="UDP"/>{' '}
                  UDP
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Label check>
                  <Input type="radio"
                        name="protocol"
                        id="outputsProfile_ts_RTPProtocol"
                        checked={values.protocol === 'RTP'}
                        onChange={handleChange}
                        value="RTP" />{' '}
                  RTP
                </Label>
              </FormGroup>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input type="checkbox"
                      name="multicast"
                      id="outputsProfile_ts_enableMulticast"
                      checked={values.multicast}
                      onChange={ (e) => handleMulticastChange(e, values.destinations, setFieldValue)}/>{' '}
                <Translate id="genericLabel.MULTICAST.text"/>
              </Label>
            </FormGroup>
            <FormGroup>
              <Label>
                <Translate id="genericLabel.TTL.text">
                  TTL
                </Translate>
              </Label>
              <Input type="number"
                    name="ttl"
                    id="outputsProfile_ts_ttl"
                    invalid={errors.ttl !== undefined}
                    placeholder={ translate('genericLabel.TTL.text') }
                    value={values.ttl}
                    onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.ttl} />
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label>
                <Translate id="genericLabel.DESTINATION.text" />
              </Label>
              <FieldArray name="destinations"
                          validateOnChange={false}>
                {({ push, remove }) => {
                  return (
                    <Fragment>
                      { values.destinations.map((destination, index) => (
                        <Row key={index}
                            className="destination-row"
                            form>
                          <Col xs={6}>
                            <FormGroup>
                              <Input type="text"
                                    invalid={ getIn(errors, `destinations[${index}].host`) != null }
                                    name={`destinations[${index}].host`}
                                    id={"outputsProfile_ts_destination_"+index}
                                    onChange={handleChange}
                                    placeholder={ translate('genericLabel.HOST.text') }
                                    value={values.destinations[index].host}/>
                              <FormFeedback>
                                { getIn(errors, `destinations[${index}].host`) != null && <Translate id={errors.destinations[index].host}/> }
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col xs={4}>
                            <FormGroup>
                              <Input type="number"
                                    invalid={ getIn(errors, `destinations[${index}].port`) != null }
                                    name={`destinations[${index}].port`}
                                    id={"outputsProfile_ts_port_"+index}
                                    min={PORT_MIN}
                                    max={PORT_MAX}
                                    onChange={handleChange}
                                    placeholder={ translate('genericLabel.PORT.text') }
                                    value={values.destinations[index].port}/>
                              <FormFeedback>
                                { getIn(errors, `destinations[${index}].port`) != null && <Translate id={errors.destinations[index].port}/> }
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col xs={2}>
                            { values.destinations.length > 1 &&
                            <Button id={"outputsProfile_ts_"+index+"_deleteButton"}
                                    className="basic"
                                    onClick={() => remove(index)}>
                              <AWIcon name="delete"/>
                            </Button>
                            }
                          </Col>
                        </Row>
                      ))}
                      <div>
                        <Button id="outputsProfile_ts_addDestinationButton"
                                disabled={values.destinations.length >= 2 || values.multicast}
                                onClick={() => push({
                                  host: '',
                                  port: ''
                                })}>
                          <Translate id="genericLabel.ADD_DESTINATION.text"/>
                        </Button>
                      </div>
                      { errors.destinationsGlobal &&
                        <div className="form-error">
                          <Translate id={errors.destinationsGlobal} /> { errors.duplicateUriFrom }
                        </div>
                      }
                    </Fragment>
                  )}}
              </FieldArray>
            </FormGroup>

            <div className="uri-preview">
              { uriList(values).map((uri, index) => (<div key={`${uri}-${index}`} className="uri" id={"outputsProfile_ts_uri_"+index}>{uri}</div>)) }
            </div>
            </fieldset>}

            buttons={<FormGroup className="buttons">
              { props.additionalActions }
              <Button id="outputsProfile_ts_saveButton"
                      color="primary"
                      disabled={!dirty}
                      type="submit">
                <Translate id="genericLabel.SAVE.text"/>
              </Button>
            </FormGroup>
          } />

        </Form>
      )}
    </Formik>
  );
};

TSForm.propTypes = propTypes;

export default withLocalize(TSForm);
