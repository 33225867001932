import {Formik} from "formik";
import PropTypes from "prop-types";
import React, { Component } from 'react';
import {Translate, withLocalize} from "react-localize-redux";
import { connect } from 'react-redux';
import {Button, Form, FormFeedback, FormGroup, Input, Label, Alert, Col, Row} from "reactstrap";
import RestartStreamhubModal from "../../tools/system/restart-streamhub-modal";
import { isEmptyString} from "../../../../utils/string-utils";
import {videoCardCapabilitiesPropTypes} from "../../../../utils/models-prop-types";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";
import Accordion from "../../../common/accordion";

import {VIDEO_CARD_TYPE_RIVERMAX } from "../../../../constants";
import { licenseModal } from "../../tools/system/system.tools.actions";
import LicenseModal from "./license-form";
import { uploadLicenseSMPTE2110 } from "../../../../misc/license.actions";

const propTypes = {
    config: PropTypes.shape({
        '4KMode': PropTypes.bool,
        forceFullFrameRate: false,
        nbSDIInput: PropTypes.number,
        sdiOrder: PropTypes.number,
        videoFrameRate: PropTypes.string,
        enableDHCP1: PropTypes.bool,
        enableDHCP2: PropTypes.bool,
      }).isRequired,
      videoFrameRates: PropTypes.arrayOf(PropTypes.string),
      videoCard: videoCardCapabilitiesPropTypes.isRequired,
      onSubmit: PropTypes.func.isRequired,
      smpteIps: PropTypes.arrayOf(PropTypes.string),
      callLicenseModal: PropTypes.func.isRequired,
      licenseModalOpened: PropTypes.bool.isRequired,
      uploading: PropTypes.bool,
      result: PropTypes.shape({
        res: PropTypes.number.isRequired,
        message: PropTypes.string,
      }),
      callUploadLicense: PropTypes.func.isRequired, // temp
      licenseStatus: PropTypes.bool.isRequired,
};

const PORT_MIN = 0;
const PORT_MAX = 65536;

class VideoCardSMPTESettingsForm extends Component {

  constructor(props){
    super(props);

    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleValidate = this.handleValidate.bind(this);
    this.handleNmosControlIface2enable = this.handleNmosControlIface2enable.bind(this);
    this.state = {
      confirmModalOpened: false,
      nmosControlIface2enable : isEmptyString(this.props.config.nmosControlIface2) ? false : true
    };
  }

  handleConfirm(value) {
    this.setState({
      confirmModalOpened: value
    });
  }

  handleNmosControlIface2enable() {
    this.setState({
      nmosControlIface2enable: !this.state.nmosControlIface2enable
    });
  }

  handleSubmit(values, { resetForm }){
    if(values.enableDHCP1){
        values.ipaddr1=this.props.smpteIps[0];
        values.mask1='';
      }
      if(values.enableDHCP2){
        values.ipaddr2=this.props.smpteIps[1];
        values.mask2='';
      }
      if( !this.state.nmosControlIface2enable){
        values.nmosControlIface2="";
      }
       values.ptpLogAnnounceInterval = parseInt(values.ptpLogAnnounceInterval, 10);
       values.ptpLogSyncInterval = parseInt(values.ptpLogSyncInterval, 10);
      this.props.onSubmit(this.props.videoCard.id, values);
      resetForm();
      this.handleConfirm(false) //temp
  }

  handleValidate(values){
    const errors = {};
    const ipRegex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))$/;
    //mask Regex
    var maskRegex = /^(((255\.){3}(255|254|252|248|240|224|192|128|0+))|((255\.){2}(255|254|252|248|240|224|192|128|0+)\.0)|((255\.)(255|254|252|248|240|224|192|128|0+)(\.0+){2})|((255|254|252|248|240|224|192|128|0+)(\.0+){3}))$/;
      if(!values.enableDHCP1){
        // ipaddr1
        if(isEmptyString(values.ipaddr1)) {
          errors.ipaddr1 = 'genericLabel.REQUIRED_FIELD.text';
        } else if (!ipRegex.test(values.ipaddr1)) {
          errors.ipaddr1 = 'genericLabel.INVALID_FORMAT.text';
        }
        // mask1
        if(isEmptyString(values.mask1)) {
          errors.mask1 = 'genericLabel.REQUIRED_FIELD.text';
        }
        else if (!maskRegex.test(values.mask1)) {
          errors.mask1 = 'genericLabel.INVALID_FORMAT.text';
        }
        if(isEmptyString(values.defaultGateway)) {
            errors.defaultGateway = 'genericLabel.REQUIRED_FIELD.text';
        }
        else if (!ipRegex.test(values.defaultGateway)) {
          errors.defaultGateway = 'genericLabel.INVALID_FORMAT.text';
        }
      }
      if(!values.enableDHCP2){
        // ipaddr2
        if(isEmptyString(values.ipaddr2)) {
          errors.ipaddr2 = 'genericLabel.REQUIRED_FIELD.text';
        } else if (!ipRegex.test(values.ipaddr2)) {
          errors.ipaddr2 = 'genericLabel.INVALID_FORMAT.text';
        }
        // mask2
        if(isEmptyString(values.mask2)) {
          errors.mask2 = 'genericLabel.REQUIRED_FIELD.text';
        }
        else if (!maskRegex.test(values.mask2)) {
          errors.mask2 = 'genericLabel.INVALID_FORMAT.text';
        }
        if(isEmptyString(values.defaultGateway2)) {
            errors.defaultGateway2 = 'genericLabel.REQUIRED_FIELD.text';
        }
        else if (!ipRegex.test(values.defaultGateway2)) {
          errors.defaultGateway2 = 'genericLabel.INVALID_FORMAT.text';
        }
      }
      if(values.ptpDomainNumber<0 ||  values.ptpDomainNumber>127){
        errors.ptpDomainNumber = 'genericLabel.INVALID_FORMAT.text';
      }
      if(values.ptpAnnounceReceiptTimeout<2 ||  values.ptpAnnounceReceiptTimeout>10){
        errors.ptpAnnounceReceiptTimeout = 'genericLabel.INVALID_FORMAT.text';
      }

      if(values.nmosEnable){
        if(isEmptyString(values.nmosControlIface1)){
          errors.nmosControlIface1 = 'genericLabel.REQUIRED_FIELD.text';
        }else if(!ipRegex.test(values.nmosControlIface1)){
          errors.nmosControlIface1 = 'genericLabel.INVALID_FORMAT.text';
        }
        if(this.state.nmosControlIface2enable){
          if(isEmptyString(values.nmosControlIface2)){
            errors.nmosControlIface2 = 'genericLabel.REQUIRED_FIELD.text';
          }else if(!ipRegex.test(values.nmosControlIface2)){
            errors.nmosControlIface2 = 'genericLabel.INVALID_FORMAT.text';
          }
        }

        if(isEmptyString(values.nmosPort)){
          errors.nmosPort = 'genericLabel.REQUIRED_FIELD.text';
        }
        else if(values.nmosPort < PORT_MIN || values.nmosPort > PORT_MAX){
          errors.nmosPort = 'genericLabel.INVALID_FORMAT.text';
        }

        if(values.nmosStaticReg){
          if(isEmptyString(values.nmosRegistryIP)){
            errors.nmosRegistryIP = 'genericLabel.REQUIRED_FIELD.text';
          }else if(!ipRegex.test(values.nmosRegistryIP)){
            errors.nmosRegistryIP = 'genericLabel.INVALID_FORMAT.text';
          }

          if(isEmptyString(values.nmosRegistryPort)){
            errors.nmosRegistryPort = 'genericLabel.REQUIRED_FIELD.text';
          }
          else if(values.nmosRegistryPort < PORT_MIN || values.nmosRegistryPort > PORT_MAX){
            errors.nmosRegistryPort = 'genericLabel.INVALID_FORMAT.text';
          }
          if(isEmptyString(values.nmosRegistryVersion)){
            errors.nmosRegistryVersion = 'genericLabel.REQUIRED_FIELD.text';
          }

          if(values.nmosStaticRegIS09){
            if(isEmptyString(values.nmosRegistryIPIS09)){
              errors.nmosRegistryIPIS09 = 'genericLabel.REQUIRED_FIELD.text';
            }else if(!ipRegex.test(values.nmosRegistryIPIS09)){
              errors.nmosRegistryIPIS09 = 'genericLabel.INVALID_FORMAT.text';
            }
  
            if(isEmptyString(values.nmosRegistryPortIS09)){
              errors.nmosRegistryPortIS09 = 'genericLabel.REQUIRED_FIELD.text';
            }
            else if(values.nmosRegistryPortIS09 < PORT_MIN || values.nmosRegistryPortIS09 > PORT_MAX){
              errors.nmosRegistryPortIS09 = 'genericLabel.INVALID_FORMAT.text';
            }
            if(isEmptyString(values.nmosRegistryVersionIS09)){
              errors.nmosRegistryVersionIS09 = 'genericLabel.REQUIRED_FIELD.text';
            }
  
          }
        }

      
      }
    return errors;
  }

  render() {
    const { config, videoCard, smpteIps,licenseModalOpened, uploading, result, callUploadLicense, licenseStatus, publicIp, localIps} = this.props;
    const hasChanged = (!this.state.nmosControlIface2enable && !isEmptyString(config.nmosControlIface2)) || (this.state.nmosControlIface2enable && isEmptyString(config.nmosControlIface2))
    
    const handleNmosStaticReg09Change = (nmosStaticRegIS09, setFieldValue) =>{
      setFieldValue('nmosStaticRegIS09', nmosStaticRegIS09);
      if (!nmosStaticRegIS09){
        setFieldValue('nmosRegistryIPIS09', config.nmosRegistryIPIS09!==undefined ? config.nmosRegistryIPIS09 : '')
        setFieldValue('nmosRegistryPortIS09', config.nmosRegistryPortIS09!==undefined ? config.nmosRegistryPortIS09 : 1)
        setFieldValue('nmosRegistryVersionIS09', config.nmosRegistryVersionIS09!==undefined ? config.nmosRegistryVersionIS09 : 'v1.0')
      }
    }
    const handleNmosStaticRegChange = (nmosStaticReg, setFieldValue) =>{
      setFieldValue('nmosStaticReg', nmosStaticReg);
      if (!nmosStaticReg){
        setFieldValue('nmosStaticRegIS09', nmosStaticReg);
        setFieldValue('nmosRegistryIP', config.nmosRegistryIP!==undefined ? config.nmosRegistryIP : '')
        setFieldValue('nmosRegistryPort', config.nmosRegistryPort!==undefined ? config.nmosRegistryPort : 1)
        setFieldValue('nmosRegistryVersion', config.nmosRegistryVersion!==undefined ? config.nmosRegistryVersion : 'v1.0')
        handleNmosStaticReg09Change(nmosStaticReg,setFieldValue)
      }
    }
    const handleNmosChange = (nmosEnable, setFieldValue) =>{
      setFieldValue('nmosEnable', nmosEnable);
      if (!nmosEnable){
        setFieldValue('nmosStaticRegIS09', nmosEnable);
        handleNmosStaticRegChange(nmosEnable,setFieldValue)
      }
    }
    return (<div>
        {videoCard.sdiCardType === VIDEO_CARD_TYPE_RIVERMAX && !licenseStatus &&
              <Alert color="danger">
                Invalid or missing SMPTE2110 License, Please add one to continue
              </Alert>
              }
        {videoCard.sdiCardType === VIDEO_CARD_TYPE_RIVERMAX &&
        <Button id={"videoCard_"+videoCard.modelName+"_uploadLicense"}
                type="submit"//disabled={hasUserLevel || hasViewerLevel}
                color={!licenseStatus ? "primary" : "secondary"}
                onClick={() => this.props.callLicenseModal(true)}>
                    <AWIcon name="license"/>
                    <span className="text">Upload SMPTE 2110 License</span>
                  </Button>}
                  { licenseModalOpened &&
                  <LicenseModal onCancel={() => this.props.callLicenseModal(false)}
                                loading={uploading}
                                result={result}
                                onSubmit={callUploadLicense}/>
                  }
      <Formik initialValues={{
                '4KMode': config['4KMode'],
                sdiOrder: config.sdiOrder,
                videoFrameRate: config.videoFrameRate,
                forceFullFrameRate: config.forceFullFrameRate,
                enableDHCP1: config.enableDHCP1 ? config.enableDHCP1 : false,
                enableDHCP2: config.enableDHCP2 ? config.enableDHCP2 : false,
                ipaddr1: config.enableDHCP1 ? smpteIps[0] : (config.ipaddr1 ? config.ipaddr1 : '' ),
                ipaddr2: config.enableDHCP2 ? smpteIps[1] : (config.ipaddr2 ? config.ipaddr2 : '' ),
                mask1: config.mask1 ? config.mask1 : '',
                mask2: config.mask2 ? config.mask2 : '',
                ptpEnable: config.ptpEnable!==undefined ? config.ptpEnable : true,
                ptpDomainNumber: config.ptpDomainNumber!==undefined ? config.ptpDomainNumber : 127,
                ptpLogAnnounceInterval: config.ptpLogAnnounceInterval!==undefined ? config.ptpLogAnnounceInterval : 0,
                ptpAnnounceReceiptTimeout: config.ptpAnnounceReceiptTimeout!==undefined ? config.ptpAnnounceReceiptTimeout : 3,
                ptpLogSyncInterval: config.ptpLogSyncInterval!==undefined ? config.ptpLogSyncInterval : -3,
                ptpDelay_mechanism: config.ptpDelay_mechanism!==undefined ? config.ptpDelay_mechanism : "E2E",
                nmosEnable: config.nmosEnable!==undefined ? config.nmosEnable : false,
                nmosControlIface1: config.nmosControlIface1!==undefined ? config.nmosControlIface1 : null,
                nmosControlIface2: config.nmosControlIface2!==undefined ? config.nmosControlIface2 : null,
                nmosPort: config.nmosPort!==undefined ? config.nmosPort : 8001,
                nmosNamingPatternNode: config.nmosNamingPatternNode!==undefined ? config.nmosNamingPatternNode : null,
                nmosNamingPatternDevice: config.nmosNamingPatternDevice!==undefined ? config.nmosNamingPatternDevice : null,
                nmosNamingPatternSender: config.nmosNamingPatternSender!==undefined ? config.nmosNamingPatternSender : null,
                nmosStaticReg: config.nmosStaticReg!==undefined ? config.nmosStaticReg : false,
                nmosRegistryIP: config.nmosRegistryIP!==undefined ? config.nmosRegistryIP : '',
                nmosRegistryPort: config.nmosRegistryPort!==undefined ? config.nmosRegistryPort : 1,
                nmosRegistryVersion: config.nmosRegistryVersion!==undefined ? config.nmosRegistryVersion : 'v1.3',
                nmosStaticRegIS09: config.nmosStaticRegIS09!==undefined ? config.nmosStaticRegIS09 : false,
                nmosRegistryIPIS09: config.nmosRegistryIPIS09!==undefined ? config.nmosRegistryIPIS09 : '',
                nmosRegistryPortIS09: config.nmosRegistryPortIS09!==undefined ? config.nmosRegistryPortIS09 : 1,
                nmosRegistryVersionIS09: config.nmosRegistryVersionIS09!==undefined ? config.nmosRegistryVersionIS09 : 'v1.0',
                defaultGateway: config.defaultGateway ? config.defaultGateway : '',
                defaultGateway2: config.defaultGateway2 ? config.defaultGateway2 : '',
              }}
              validate={ this.handleValidate }
              validateOnBlur={false}
              validateOnChange={true}
              onSubmit={ this.handleSubmit }>
        {({
            values,
            errors,
            dirty,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => {
            if(this.state.nmosControlIface2enable && isEmptyString(values.nmosControlIface2)){
              errors.nmosControlIface2 = 'genericLabel.REQUIRED_FIELD.text';
            }
          return (
            <Form onSubmit={handleSubmit}>
            { dirty &&
            <Alert color="warning">
              <Translate id="genericLabel.RESTART_STREAMHUB_TO_TAKE_CHANGES_INTO_ACCOUNT.text"/>
            </Alert>
            }
            { videoCard.has4K &&
            <FormGroup check>
              <Label check>
                <Input id={"videoCard_"+videoCard.modelName+"_enable4K"}
                       type="checkbox"
                       name="4KMode"
                       onChange={handleChange}
                       checked={values['4KMode']}/>{' '}
                <Translate id="genericLabel.4K_MODE.text"/>
              </Label>
            </FormGroup>
            }
              <div className="header">
                    <div className="title secondary">
                      Interface 1
                    </div>
                  </div>
            <FormGroup check>
            <Label check>
              <Input id={"videoCard_"+videoCard.modelName+"_enableDHCP1"}
                     type="checkbox"
                     name="enableDHCP1"
                     onChange={handleChange}
                     disabled={!licenseStatus}
                     checked={values.enableDHCP1}/>{' '}
                     Enable DHCP
              {/*<Translate id="genericLabel.DISPLAY_OUTPUT_NAME.text"/> fix me  */}
            </Label>
          </FormGroup>
                  <FormGroup>
                  <Label for="ipaddr1">
                    Ip address
                  </Label>
                  <Input type="string"
                         name="ipaddr1"
                         id={"videoCard_"+videoCard.modelName+"_ipAddr1"}
                         disabled={values.enableDHCP1 || !licenseStatus}
                         invalid={ errors.ipaddr1 !== undefined }
                         value={values.ipaddr1}
                         onBlur={handleBlur}
                         onChange={handleChange}/>
                  <FormFeedback>
                    <Translate id={errors.ipaddr1}/>
                  </FormFeedback>
                </FormGroup>
                {values.enableDHCP1===false &&
             <> <FormGroup>
                <Label for="mask1">
                  Mask
                </Label>
                <Input type="string"
                       name="mask1"
                       id={"videoCard_"+videoCard.modelName+"_mask1"}
                       invalid={ errors.mask1 !== undefined }
                       value={values.mask1}
                       onBlur={handleBlur}
                       onChange={handleChange}
                       disabled={!licenseStatus}/>
                <FormFeedback>
                  <Translate id={errors.mask1}/>
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="defaultGateway">
                  Default Gateway
                </Label>
                <Input type="string"
                       name="defaultGateway"
                       id={"videoCard_"+videoCard.modelName+"_defaultGateway"}
                       disabled={values.enableDHCP1 || !licenseStatus}
                       invalid={ errors.defaultGateway !== undefined }
                       value={values.defaultGateway}
                       onBlur={handleBlur}
                       onChange={handleChange}/>
                <FormFeedback>
                  <Translate id={errors.defaultGateway}/>
                </FormFeedback>
              </FormGroup></>}
              <div className="header">
                  <div className="title secondary">
                    Interface 2
                  </div>
                </div>
          <FormGroup check>
          <Label check>
            <Input id={"videoCard_"+videoCard.modelName+"_enableDHCP2"}
                   type="checkbox"
                   name="enableDHCP2"
                   onChange={handleChange}
                   checked={values.enableDHCP2}
                   disabled={!licenseStatus}/>{' '}
                   Enable DHCP
            {/*<Translate id="genericLabel.DISPLAY_OUTPUT_NAME.text"/> fix me  */}
          </Label>
        </FormGroup>
                <FormGroup>
                <Label for="ipaddr2">
                  Ip address
                </Label>
                <Input type="string"
                       name="ipaddr2"
                       id={"videoCard_"+videoCard.modelName+"_ipAddr2"}
                       disabled={values.enableDHCP2 || !licenseStatus}
                       invalid={ errors.ipaddr2 !== undefined }
                       value={values.ipaddr2}
                       onBlur={handleBlur}
                       onChange={handleChange}/>
                <FormFeedback>
                  <Translate id={errors.ipaddr2}/>
                </FormFeedback>
              </FormGroup>
          
        {values.enableDHCP2===false && 
             <> <FormGroup>
                <Label for="mask2">
                  Mask
                </Label>
                <Input type="string"
                       name="mask2"
                       id={"videoCard_"+videoCard.modelName+"_mask2"}
                       invalid={ errors.mask2 !== undefined }
                       value={values.mask2}
                       onBlur={handleBlur}
                       onChange={handleChange}
                       disabled={!licenseStatus}/>
                <FormFeedback>
                  <Translate id={errors.mask1}/>
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="defaultGateway2">
                  Default Gateway
                </Label>
                <Input type="string"
                       name="defaultGateway2"
                       id={"videoCard_"+videoCard.modelName+"_defaultGateway2"}
                       disabled={values.enableDHCP2 || !licenseStatus}
                       invalid={ errors.defaultGateway2 !== undefined }
                       value={values.defaultGateway2}
                       onBlur={handleBlur}
                       onChange={handleChange}/>
                <FormFeedback>
                  <Translate id={errors.defaultGateway2}/>
                </FormFeedback>
              </FormGroup></>}
              <div className="header">
                  <div className="title secondary">
                    PTP
                  </div>
                </div>
                 { ((config.ptpEnable!==undefined && config.ptpEnable !==values.ptpEnable) || (config.ptpEnable===undefined && !values.ptpEnable )) &&
                    <Alert color="warning">
                      Reboot necessary for the change to take effect
                    </Alert>
                  }
                <FormGroup check>
                  <Label check>
                    <Input id={"videoCard_"+videoCard.modelName+"_ptpEnable"}
                          type="checkbox"
                          name="ptpEnable"
                          onChange={handleChange}
                          checked={values.ptpEnable}
                          disabled={!licenseStatus}/>{' '}
                          Enable PTP
                    </Label>
                </FormGroup>
                <FormGroup>
                  <Label for="ptpDomainNumber">
                     Domain Number
                  </Label>
                  <Input type="number"
                        name="ptpDomainNumber"
                        id={"videoCard_"+videoCard.modelName+"_ptpDomainNumber"}
                        min={0}
                        max={127}
                        default={0}
                        value={values.ptpDomainNumber}
                        disabled={!values.ptpEnable}
                        invalid={ errors.ptpDomainNumber !== undefined }
                        onChange={handleChange}/>
                <FormFeedback>
                  <Translate id={errors.ptpDomainNumber}/>
                </FormFeedback>
                </FormGroup>
                <Accordion className="form-advanced-section"
                            renderTitle={() => (<Translate id="genericLabel.ADVANCED.text"/>)}>
                  <FormGroup>
                    <Label for="ptpLogAnnounceInterval">
                      Announce Interval
                    </Label>
                    <Input type="select"
                          name="ptpLogAnnounceInterval"
                          id={"videoCard_"+videoCard.modelName+"_ptpLogAnnounceInterval"}
                          value={values.ptpLogAnnounceInterval}
                          disabled={!values.ptpEnable}
                          onChange={handleChange}>
                      <option value={-3} id="8Hz">0.125s 8Hz</option>
                      <option value={-2} id="4Hz">0.25s 4Hz</option>
                      <option value={-1} id="2Hz">0.5s 2Hz</option>
                      <option value={0} id="1Hz">1s 1Hz</option>
                      <option value={1} id="0.5Hz">2s 0.5Hz</option>
                    </Input>
                </FormGroup>
                  <FormGroup>
                    <Label for="ptpAnnounceReceiptTimeout">
                      Announce receipt timeout
                    </Label>
                    <Input type="number"
                          name="ptpAnnounceReceiptTimeout"
                          id={"videoCard_"+videoCard.modelName+"_ptpAnnounceReceiptTimeout"}
                          min={2}
                          max={10}
                          value={values.ptpAnnounceReceiptTimeout}
                          disabled={!values.ptpEnable}
                          invalid={ errors.ptpAnnounceReceiptTimeout !== undefined }
                          onChange={handleChange}/>
                <FormFeedback>
                  <Translate id={errors.ptpAnnounceReceiptTimeout}/>
                </FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="ptpLogSyncInterval">
                    Log sync interval
                    </Label>
                    <Input type="select"
                          name="ptpLogSyncInterval"
                          id={"videoCard_"+videoCard.modelName+"_ptpLogSyncInterval"}
                          value={values.ptpLogSyncInterval}
                          disabled={!values.ptpEnable}
                          onChange={handleChange}>
                      <option value={0} id="1Hz">1s 1Hz</option>
                      <option value={-1} id="2Hz">0.5s 2Hz</option>
                      <option value={-2} id="4Hz">0.25s 4Hz</option>
                      <option value={-3} id="8Hz">0.125s 8Hz</option>
                      <option value={-4} id="16Hz">0.0625s 16Hz</option>
                      <option value={-5} id="32Hz">0.03125s 32Hz</option>
                      <option value={-6} id="64Hz">0.015625s 64Hz</option>
                      <option value={-7} id="128Hz">0.0078125s 128Hz</option>
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for="ptpDelay_mechanism">
                    delay mechanism
                    </Label>
                    <Input type="select"
                          name="ptpDelay_mechanism"
                          id={"videoCard_"+videoCard.modelName+"_ptpDelay_mechanism"}
                          value={values.ptpDelay_mechanism}
                          disabled={!values.ptpEnable}
                          onChange={handleChange}>
                      <option value={"E2E"}>E2E</option>
                      <option value={"P2P"}>P2P</option>
                    </Input>
                </FormGroup>
              </Accordion>
              <div className="header">
                <div className="title secondary">
                  NMOS
                </div>
              </div>
              { ((config.nmosEnable!==undefined && config.nmosEnable !==values.nmosEnable) || (config.nmosEnable===undefined)) &&
                <Alert color="warning">
                  Reboot necessary for the change to take effect
                </Alert>
              }
              <FormGroup check>
                <Label check>
                  <Input id={"videoCard_"+videoCard.modelName+"_nmosEnable"}
                        type="checkbox"
                        name="nmosEnable"
                        onChange={(e)=>handleNmosChange(e.target.checked,setFieldValue)}
                        checked={values.nmosEnable}
                        disabled={!licenseStatus}/>
                        Enable NMOS
                  </Label>
              </FormGroup>
              { values.nmosEnable && 
                <>
                  <FormGroup>
                    <Label>
                            Control Interface
                    </Label>
                    <Row className="mr-0">
                      <Col xs={5}>
                        <Label>
                          Interface 1
                        </Label>
                        <Input id={"videoCard_"+videoCard.modelName+"_nmosControlIface1"}
                              type="select"
                              name="nmosControlIface1"
                              invalid={errors.nmosControlIface1 !== undefined }
                              onChange={handleChange}
                              value={values.nmosControlIface1 ? values.nmosControlIface1 : ""}
                              disabled={!licenseStatus}>
                          <option key={""} value={''} id=''></option>
                          <option key={"PublicIP"} value={publicIp} id='publicIp'>{publicIp}</option>
                          {localIps.map(ip=> <option key={ip} value={ip} id={ip}>{ip}</option>)}
                          { values.ipaddr1 && <option key={"SMPTEiface1"} value={values.ipaddr1} id='SMPTEiface1'>{values.ipaddr1}</option>}
                          { values.ipaddr2 && <option key={"SMPTEiface2"} value={values.ipaddr2} id='SMPTEiface2'>{values.ipaddr2}</option>}
                        </Input>
                        <FormFeedback>
                          <Translate id={errors.nmosControlIface1}/>
                        </FormFeedback>
                      </Col>
                      { this.state.nmosControlIface2enable &&
                        <Col xs={5}>
                          <Label>
                            Interface 2
                          </Label>
                          <Input id={"videoCard_"+videoCard.modelName+"_nmosControlIface2"}
                                type="select"
                                name="nmosControlIface2"
                                invalid={errors.nmosControlIface2 !== undefined }
                                onChange={handleChange}
                                value={values.nmosControlIface2 ? values.nmosControlIface2 : ""}
                                disabled={!licenseStatus}>
                            <option key={""} value={''} id=''></option>
                            <option key={"PublicIP"} value={publicIp} id='publicIp'>{publicIp}</option>
                            {localIps.map(ip=> <option key={ip} value={ip} id={ip}>{ip}</option>)}
                            { values.ipaddr1 && <option key={"SMPTEiface1"} value={values.ipaddr1} id='SMPTEiface1'>{values.ipaddr1}</option>}
                            { values.ipaddr2 && <option key={"SMPTEiface2"} value={values.ipaddr2} id='SMPTEiface2'>{values.ipaddr2}</option>}
                          </Input>
                          <FormFeedback>
                            <Translate id={errors.nmosControlIface2}/>
                          </FormFeedback>
                        </Col>
                        }
                        <Col xs={2}>
                          <Button className="mt-4" onClick={this.handleNmosControlIface2enable}><AWIcon name={this.state.nmosControlIface2enable ? "remove" : "add"}/></Button>
                        </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup>
                    <Label>Node Port</Label>
                      <Input id={"videoCard_"+videoCard.modelName+"_nmosPort"}
                            type="Number"
                            name="nmosPort"
                            min={PORT_MIN}
                            max={PORT_MAX}
                            invalid={errors.nmosPort !== undefined }
                            onChange={handleChange}
                            value={values.nmosPort}
                            disabled={!licenseStatus}/>
                      <FormFeedback>
                        <Translate id={errors.nmosPort}/>
                      </FormFeedback>
                  </FormGroup>
                  <Label>
                    Custom Resources Naming
                  </Label>
                  <FormGroup>
                    <Label for="nmosNamingPatternNode">
                      Node naming pattern
                    </Label>
                    <Input type="string"
                          name="nmosNamingPatternNode"
                          id="network_product_nmosNamingPatternNode"
                          invalid={errors.nmosNamingPatternNode !== undefined}
                          placeholder={ "$(hostname)" }
                          value={values.nmosNamingPatternNode}
                          onBlur={handleBlur}
                          onChange={handleChange}/>
                    <FormFeedback>
                      <Translate id={errors.nmosNamingPatternNode} />
                    </FormFeedback>
                    <div className="indicator">
                      Can contain $(hostname)
                    </div>
                  </FormGroup>
                  
                  <FormGroup>
                    <Label for="nmosNamingPatternDevice">
                      Device naming pattern
                    </Label>
                    <Input type="string"
                          name="nmosNamingPatternDevice"
                          id="network_product_nmosNamingPatternDevice"
                          invalid={errors.nmosNamingPatternDevice !== undefined}
                          placeholder={ "$(hostname) Output $(index)" }
                          value={values.nmosNamingPatternDevice}
                          onBlur={handleBlur}
                          onChange={handleChange}/>
                    <FormFeedback>
                      <Translate id={errors.nmosNamingPatternDevice} />
                    </FormFeedback>
                    <div className="indicator">
                      Can contain $(hostname), $(node), $(index)
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <Label for="nmosNamingPatternSender">
                      Sender naming pattern
                    </Label>
                    <Input type="string"
                          name="nmosNamingPatternSender"
                          id="network_product_nmosNamingPatternSender"
                          invalid={errors.nmosNamingPatternSender !== undefined}
                          placeholder={ "$(hostname) Out $(index) $(full_essence) sender" }
                          value={values.nmosNamingPatternSender}
                          onBlur={handleBlur}
                          onChange={handleChange}/>
                    <FormFeedback>
                      <Translate id={errors.nmosNamingPatternSender} />
                    </FormFeedback>
                    <div className="indicator">
                      Can contain $(hostname), $(node), $(index), $(device), $(essence) et $(full_essence)
                    </div>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input id={"videoCard_"+videoCard.modelName+"_nmosStaticReg"}
                            type="checkbox"
                            name="nmosStaticReg"
                            onChange={(e)=>handleNmosStaticRegChange(e.target.checked,setFieldValue)}
                            checked={values.nmosStaticReg}
                            disabled={!licenseStatus}/>
                            Use static registry
                    </Label>
                  </FormGroup>
                  {values.nmosStaticReg && 
                    <div className="ml-2">
                      <Label>IS04</Label>
                      <FormGroup>
                        <Label>Registry IP</Label>
                        <Input id={"videoCard_"+videoCard.modelName+"_nmosRegistryIP"}
                              type="string"
                              name="nmosRegistryIP"
                              invalid={errors.nmosRegistryIP !== undefined }
                              onChange={handleChange}
                              value={values.nmosRegistryIP}
                              disabled={!licenseStatus}/>
                        <FormFeedback>
                          <Translate id={errors.nmosRegistryIP}/>
                        </FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label>Port</Label>
                        <Input id={"videoCard_"+videoCard.modelName+"_nmosRegistryPort"}
                              type="Number"
                              name="nmosRegistryPort"
                              invalid={errors.nmosRegistryPort !== undefined }
                              onChange={handleChange}
                              value={values.nmosRegistryPort}
                              disabled={!licenseStatus}/>
                        <FormFeedback>
                          <Translate id={errors.nmosRegistryPort}/>
                        </FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label>Version</Label>
                        <Input id={"videoCard_"+videoCard.modelName+"_nmosRegistryVersion"}
                              type="select"
                              name="nmosRegistryVersion"
                              invalid={errors.nmosRegistryVersion !== undefined }
                              onChange={handleChange}
                              value={values.nmosRegistryVersion}
                              disabled={!licenseStatus}>
                          <option key={"v1.3"} value={"v1.3"} id='v1.3'>v1.3</option>
                          <option key={"v1.2"} value={"v1.2"} id='v1.2'>v1.2</option>
                        </Input>
                        <FormFeedback>
                          <Translate id={errors.nmosRegistryVersion}/>
                        </FormFeedback>
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input id={"videoCard_"+videoCard.modelName+"_nmosStaticRegIS09"}
                                type="checkbox"
                                name="nmosStaticRegIS09"
                                onChange={(e)=>handleNmosStaticReg09Change(e.target.checked,setFieldValue)}
                                checked={values.nmosStaticRegIS09}
                                disabled={!licenseStatus}/>
                                Enable IS09
                        </Label>
                      </FormGroup>

                      {values.nmosStaticRegIS09 && 
                        <div className="ml-2">
                          <Label>IS09</Label>
                          <FormGroup>
                            <Label>Registry IP</Label>
                            <Input id={"videoCard_"+videoCard.modelName+"_nmosRegistryIPIS09"}
                                  type="string"
                                  name="nmosRegistryIPIS09"
                                  invalid={errors.nmosRegistryIPIS09 !== undefined }
                                  onChange={handleChange}
                                  value={values.nmosRegistryIPIS09}
                                  disabled={!licenseStatus}/>
                            <FormFeedback>
                              <Translate id={errors.nmosRegistryIPIS09}/>
                            </FormFeedback>
                          </FormGroup>
                          <FormGroup>
                            <Label>Port</Label>
                            <Input id={"videoCard_"+videoCard.modelName+"_nmosRegistryPortIS09"}
                                  type="Number"
                                  name="nmosRegistryPortIS09"
                                  invalid={errors.nmosRegistryPortIS09 !== undefined }
                                  onChange={handleChange}
                                  value={values.nmosRegistryPortIS09}
                                  disabled={!licenseStatus}/>
                            <FormFeedback>
                              <Translate id={errors.nmosRegistryPortIS09}/>
                            </FormFeedback>
                          </FormGroup>
                          <FormGroup>
                            <Label>Version</Label>
                            <Input id={"videoCard_"+videoCard.modelName+"_nmosRegistryVersionIS09"}
                                  type="select"
                                  name="nmosRegistryVersionIS09"
                                  invalid={errors.nmosRegistryVersionIS09 !== undefined }
                                  onChange={handleChange}
                                  value={values.nmosRegistryVersionIS09}
                                  disabled={!licenseStatus}>
                              <option key={"v1.0"} value={"v1.0"} id='v1.0'>v1.0</option>
                            </Input>
                            <FormFeedback>
                              <Translate id={errors.nmosRegistryVersionIS09}/>
                            </FormFeedback>
                          </FormGroup>
                        </div>
                      }
                    </div>
                  }
                </>
              }
         <FormGroup className="buttons">
            <Button id={"videoCard_"+videoCard.modelName+"_saveButton"}
                    color="primary"
                    disabled={isSubmitting || (!dirty  && !hasChanged) || Object.keys(errors).length!==0}
                    onClick={() => this.handleConfirm(true)}>
              <Translate id="genericLabel.SAVE.text"/>
            </Button>
          </FormGroup>
          { this.state.confirmModalOpened &&
          <RestartStreamhubModal onCancel={() => this.handleConfirm(false)}
                                  onConfirm={() => handleSubmit()}/>
          }
          </Form>
        )}}
      </Formik>
      </div>
    );
  }
}

VideoCardSMPTESettingsForm.propTypes = propTypes;


const mapStateToProps = (state) => {
  const { publicIp, localIps} = state.streamhub;
    const {
      licenseModalOpened
    } = state.tools.system;
    return {
      licenseModalOpened,
      uploading: state.license.uploading,
      result: state.license.result,
      publicIp,
      localIps
    };
  };


const mapDispatchToProps = (dispatch) => {
    return {
      callLicenseModal: (open) => dispatch(licenseModal(open)),
      callUploadLicense: (file) => dispatch(uploadLicenseSMPTE2110(file)) // temp
    };
  };
export default withLocalize(connect(mapStateToProps,mapDispatchToProps)(VideoCardSMPTESettingsForm));