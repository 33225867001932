import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {inputIpProfilePropTypes, inputPropTypes, multiViewPropTypes} from "../../../../../utils/models-prop-types";
import {Button, Input, Label} from "reactstrap";
import {Translate} from "react-localize-redux";
import GridItem from "./grid-item";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";
import {STATUS_OFF} from '../../../../../constants';

const propTypes = {
  multiView: multiViewPropTypes.isRequired,
  inputs: PropTypes.arrayOf(inputPropTypes).isRequired,
  inputIPProfiles: PropTypes.arrayOf(inputIpProfilePropTypes).isRequired,
  onAudioSwitch: PropTypes.func.isRequired,
  onGridChange: PropTypes.func.isRequired,
  onShowOverlay: PropTypes.func.isRequired
};

const GRID_MIN = 1;
const GRID_MAX = 4;

class MultiViewGrid extends Component {

  constructor(props){
    super(props);

    this.handleAddRow = this.handleAddRow.bind(this);
    this.handleRemoveRow = this.handleRemoveRow.bind(this);
    this.handleAddColumn = this.handleAddColumn.bind(this);
    this.handleRemoveColumn = this.handleRemoveColumn.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleLightModeChange = this.handleLightModeChange.bind(this);

    this.state = {
      light: props.multiView.showOverlayInfo ? false : true,
      nbRow: props.multiView.nbRow,
      nbCol: props.multiView.nbCol,
      rows: props.multiView.videoChannelIndexes ? this.divide(JSON.parse(props.multiView.videoChannelIndexes), props.multiView.nbCol) : this.firstTime()
    }
  }

  firstTime(nbRow, nbCol){
    const col = Array(nbCol).fill(1); // We fill the new array with 1, as the identifier of the first 'input' is the default value
    return Array(nbRow).fill(col);
  }

  divide(array, nbCol){
    return Array(Math.ceil(array.length / nbCol)).fill().map((_, index) => index * nbCol).map(begin => array.slice(begin, begin + nbCol));
  }

  handleAddRow(){
    if(this.state.nbRow < GRID_MAX){
      const rows = this.state.rows;
      const newRow = Array(this.state.nbCol).fill(0); // We fill the new array with 1, as the identifier of the first 'input' is the default value
      rows.push(newRow);
      this.setState((state) => {
        return {
          ...state,
          nbRow: state.nbRow + 1,
          rows
        }
      }, () => {
        this.notifyGridChange();
      });
    }
  }

  handleRemoveRow(){
    if(this.state.nbRow > GRID_MIN){
      const rows = this.state.rows;
      rows.splice(rows.length - 1, 1);
      this.setState((state) => {
        return {
          ...state,
          nbRow: state.nbRow - 1,
          rows
        }
      }, () => {
        this.notifyGridChange();
      });
    }
  }

  handleAddColumn(){
    if(this.state.nbCol < GRID_MAX){
      const rows = this.state.rows;
      rows.forEach(row => {
        row.push(0); // We fill the new array with 1, as the identifier of the first 'input' is the default value
      });
      this.setState((state) => {
        return {
          ...state,
          nbCol: state.nbCol + 1,
          rows
        }
      }, () => {
        this.notifyGridChange();
      });
    }
  }

  handleRemoveColumn(){
    if(this.state.nbCol > GRID_MIN){
      const rows = this.state.rows;
      rows.forEach(row => {
        row.splice(row.length - 1, 1); // We fill the new array with 1, as the identifier of the first 'input' is the default value
      });
      this.setState((state) => {
        return {
          ...state,
          nbCol: state.nbCol - 1,
          rows
        }
      }, () => {
        this.notifyGridChange();
      });
    }
  }

  handleLightModeChange(){
    this.setState((state) => {
      return {
        ...state,
        light: !state.light
      }
    }, () => {
      this.props.onShowOverlay(!this.state.light);
    });
  }

  handleInputChange(row, col, inputId){
    const rows = this.state.rows;
    rows[row][col] = parseInt(inputId);
    this.setState({
      rows
    }, () => {
      this.notifyGridChange();
    });
  }

  notifyGridChange(){
    const indexes = this.state.rows.flat();
    if(indexes.length !== this.state.nbCol * this.state.nbRow){
      throw new Error(`Grid indexes count (${this.state.rows.length}) not matching nb col (${this.state.nbCol}) and nb row (${this.state.nbRow})`);
    }
    const multiView = {
      nbCol: this.state.nbCol,
      nbRow: this.state.nbRow,
      videoChannelIndexes: JSON.stringify(indexes)
    };
    this.props.onGridChange(multiView);
  }

  render(){
    const {
      inputs,
      inputIPProfiles,
      onAudioSwitch,
      multiView,
      multiViewStatus
    } = this.props;

    const { status } = multiViewStatus;
    const audioChannelIndex = multiView.audioChannelIndex;
    const { nbRow, nbCol, rows, light } = this.state;
    return (
        <div className="multiview-grid">
          <div className="controls">
            <div className="rows">
              <span className="label">
                <Translate id="genericLabel.MULTI_VIEW_ROWS_SHORT.text"/>
              </span>
              <Button id="multiView_grid_row_minusButton"
                      className="basic"
                      disabled={ nbRow === GRID_MIN }
                      onClick={ this.handleRemoveRow }>
                <AWIcon name="remove_square"/>
              </Button>
              <div className="value">
              { nbRow }
              </div>
              <Button id="multiView_grid_row_plusButton"
                      className="basic"
                      disabled={ nbRow === GRID_MAX }
                      onClick={ this.handleAddRow }>
                <AWIcon name="add_square"/>
              </Button>
            </div>
            <div className="columns">
              <span className="label">
                <Translate id="genericLabel.MULTI_VIEW_COLUMNS_SHORT.text"/>
              </span>
              <Button id="multiView_grid_col_minusButton"
                      className="basic"
                      disabled={ nbCol === GRID_MIN }
                      onClick={ this.handleRemoveColumn }>
                <AWIcon name="remove_square"/>
              </Button>
              <div className="value">
              { nbCol }
              </div>
              <Button id="multiView_grid_col_plusButton"
                      className="basic"
                      disabled={ nbCol === GRID_MAX }
                      onClick={ this.handleAddColumn }>
                <AWIcon name="add_square"/>
              </Button>
            </div>
            <div className="presentation">
              <Label check>
                <Input type="checkbox"
                       id="multiview_lightmode_change"
                       checked={light}
                       onChange={ this.handleLightModeChange }/>
                <Translate id="genericLabel.BASIC.text"/>
              </Label>
            </div>
          </div>

          { status !== STATUS_OFF &&
          <div className="grid">
          <table>
            <tbody>
            { rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  { row.map((col, colIndex) => {
                    let input = inputs.find(input => input.id === `${col}`);
                    let inputStatus = multiViewStatus.inputStatus ? multiViewStatus.inputStatus.find(inputStatus => inputStatus.index === col) : null
                    const audible = audioChannelIndex === col;
                    // Reset input (Bugs #16037 and Bugs #16208)
                    if (!input) {
                      input = {id: '0'}
                    }
                    return (
                        <td key={`${rowIndex}-${colIndex}`}>
                          <GridItem audible={audible}
                                    input={input}
                                    inputIPProfiles={inputIPProfiles}
                                    inputs={inputs} light={light}
                                    inputStatus={inputStatus}
                                    onAudioClick={() => audible === false ? onAudioSwitch(input.id) : onAudioSwitch('0')}
                                    onInputChange={ (inputId) => this.handleInputChange(rowIndex, colIndex, inputId) }/>
                        </td>
                    )
                  })}
                </tr>
                )) }
            </tbody>
          </table>
          </div>
          }
        </div>
    );
  }
}

MultiViewGrid.propTypes = propTypes;

export default MultiViewGrid;