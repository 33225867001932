import {LOGGED_IN} from '../constants';

// import Logger from '../utils/logger';
import {responsiveChange} from "./responsive.actions";

const context = {};

export default (store) => {

  const reactOnWindowSizeChanges = () => {
    if(window){
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      if(context.timeout){
        clearTimeout(context.timeout);
        context.timeout = null;
      }
      context.timeout = setTimeout(() => {
        store.dispatch(responsiveChange(windowWidth, windowHeight));
      }, 300);
    }
  };

  return (next) => (action) => {
    next(action);

    const observeWindowSizeChanges = () => {
      if(window){
        window.removeEventListener('resize', reactOnWindowSizeChanges);
        window.addEventListener('resize', reactOnWindowSizeChanges);
        reactOnWindowSizeChanges();
      }
    };

    if(action.type === LOGGED_IN){
      observeWindowSizeChanges();
    }
  }
}