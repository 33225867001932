import PropTypes from 'prop-types';
import React, { useState } from 'react'
import { connect } from "react-redux";
import AWModal from '@aviwest/ui-kit/dist/js/components/modal'
import { Translate, withLocalize } from "react-localize-redux";
import { ipOutputsProfilesSettings, outputAttachIPProfile } from '../../dashboard.actions';
import { inputIpProfilePropTypes } from '../../../../../utils/models-prop-types';
import OutputIPProfileList from '../../../settings/outputs/list';
import OutputIPProfileForm from '../../../settings/outputs/form';
import NewInputIPProfile from '../../../settings/outputs/new-profile';
import { Breadcrumb, BreadcrumbItem, Button, Form, Input } from "reactstrap";
import AWIcon from '@aviwest/ui-kit/dist/js/components/icon';
import { createOutputIPProfile, deleteOutputIPProfile, updateOutputIPProfile } from '../../../settings/outputs/outputs.settings.actions';

const propTypes = {
  callCreateProfile: PropTypes.func.isRequired,
  callDeleteProfile: PropTypes.func.isRequired,
  callUpdateProfile: PropTypes.func.isRequired,
  callOutputAttachIPProfile: PropTypes.func.isRequired,
  ipProfiles: PropTypes.arrayOf(inputIpProfilePropTypes),
  callCloseModal: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired
};

const IpOutputProfilesSettingsModal = (props) => {
  const [search, setSearch] = useState("")
  const [editingProfileId, setEditingProfileId] = useState(null);
  const [newProfileProtocol, setNewProfileProtocol] = useState(null)

  const { callCreateProfile, callDeleteProfile, callUpdateProfile, callOutputAttachIPProfile,
    callCloseModal, ipProfiles, opened, canSelect, ipOutputs, translate, configTooBig } = props;

  const closeModal = () => {
    setEditingProfileId(null);
    callCloseModal();
  }

  const handleSearch = (evt) => {
    setSearch(evt.target.value);
  }

  const handleNewProfile = () => {
    setEditingProfileId("-1");
  }

  const handleEditProfile = (profile) => {
    setEditingProfileId(profile.id);
  }

  const handleCreateProfile = (protocol) => {
    setNewProfileProtocol(protocol);
    setEditingProfileId("0");
  }

  const handleCancel = () => {
    setEditingProfileId(null);
  }

  const handleProfileSubmit = (data) => {
    if (data.id !== undefined) {
      const { id, ...otherProps } = data;
      callUpdateProfile(id, otherProps);
    }
    else {
      callCreateProfile(data);
    }
    handleCancel();
  };

  const handleProfileDelete = () => {
    callDeleteProfile(editingProfileId);
    setEditingProfileId(null);
  }

  const handleProfileAttach = (profile) => {
    callOutputAttachIPProfile(profile.id);
    closeModal();
  }

  return (
    <AWModal id="ipOutputProfilesSetting_modal"
      onClose={closeModal}
      open={opened}
      title={
        <div className="ip-output-profiles-settings-modal">
          <Breadcrumb>
            <BreadcrumbItem onClick={handleCancel} tag={editingProfileId !== null ? "a" : "li"} active>
              <Translate id="genericLabel.CONTRIBUTION_PROFILES.text" />
            </BreadcrumbItem>
            {editingProfileId !== null &&
              <BreadcrumbItem active><Translate id="genericLabel.SETTINGS.text" /></BreadcrumbItem>}
          </Breadcrumb>
          {editingProfileId === null &&
            <div className="actions">
              <Form className="form-inline">
                <Input className="search"
                  onChange={handleSearch}
                  placeholder={translate('genericLabel.SEARCH.text')}
                  bsSize="sm"
                  value={search} />
              </Form>
              <Button id="encodersProfile_addButton" size="sm" onClick={handleNewProfile} disabled={configTooBig}>
                <AWIcon name="add" />
              </Button>
            </div>
          }
        </div>
      }>
      <div className="content">
        {editingProfileId === null &&
          <OutputIPProfileList ipProfiles={ipProfiles}
            onProfileEdit={handleEditProfile}
            onProfileAttach={handleProfileAttach}
            search={search}
            canSelect={canSelect}
            ipOutputs={ipOutputs} />
        }
        {(editingProfileId !== null && editingProfileId === "-1") &&
          <NewInputIPProfile onProfileCreation={handleCreateProfile} />
        }
        {(editingProfileId !== null && editingProfileId !== "-1") &&
          <OutputIPProfileForm editingProfileId={editingProfileId}
            protocolSet={newProfileProtocol}
            onCancel={handleCancel}
            onSubmit={handleProfileSubmit}
            onDelete={editingProfileId > 0 ? handleProfileDelete : null} />
        }
      </div>
    </AWModal>
  )
}

IpOutputProfilesSettingsModal.propTypes = propTypes;

const mapStateToProps = (state) => {
  return {
    ipProfiles: state.streamhub.outputIPProfiles,
    opened: state.dashboard.editingIpOutputsProfilesSettings.open,
    canSelect: state.dashboard.editingIpOutputsProfilesSettings.canSelect,
    ipOutputs: state.streamhub.ipOutputs,
    configTooBig: state.streamhub.configTooBig
  }
}

const mapDispatchToProps = dispatch => {
  return {
    callCreateProfile: (data) => dispatch(createOutputIPProfile(data)),
    callDeleteProfile: (id) => dispatch(deleteOutputIPProfile(id)),
    callUpdateProfile: (id, data) => dispatch(updateOutputIPProfile(id, data)),
    callOutputAttachIPProfile: (profileId) => dispatch(outputAttachIPProfile(profileId)),
    callCloseModal: () => dispatch(ipOutputsProfilesSettings(false, false))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(IpOutputProfilesSettingsModal));
