import PropTypes from 'prop-types';
import React, { Component } from 'react';

const propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  fileDuration: PropTypes.number.isRequired
};

class PlayerSeeker extends Component {

  constructor(props){
    super(props);

    this.handleChange = this.handleChange.bind(this);

    this.state = {
      value: props.value ? props.value : 0
    }
  }

  componentWillReceiveProps(props){
    this.setState({
      value : props.value
    });
  }

  handleChange(e){
    const value = e.target.value;
    if(value){
      this.setState((state) => {
        return {
          ...state,
          value: parseInt(value)
        }
      }, () => {
        this.props.onChange(this.state.value);
      });
    }
  }

  render(){
    const { fileDuration } = this.props;
    const { value } = this.state;
    return (
        <input className="player-seeker"
               type="range"
               min={0}
               max={fileDuration}
               onChange={ this.handleChange }
               value={value}/>
    )
  }
}

PlayerSeeker.propTypes = propTypes;

export default PlayerSeeker;