import PropTypes from 'prop-types';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { Link } from 'react-router-dom';
import {
  Nav,
  NavItem,
  NavLink,
  Button
} from 'reactstrap';
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

import {apiDocLink} from "../../../utils/global-utils";

const propTypes = {
  apiKey: PropTypes.string,
  helpLink: PropTypes.string,
  location: PropTypes.object,
  onCloseMenu: PropTypes.func,
  onLogoutRequested: PropTypes.func,
  onLicenseDetailsRequested: PropTypes.func
};

const SideBar = (props) => {
  const hasPath = (location, path) => {
    return location.pathname.includes(path);
  };
  const {  apiKey, helpLink, location, onCloseMenu, onLogoutRequested, onLicenseDetailsRequested,ptp } = props;
  return (
      <div id="aw-sidebar">
        <Nav vertical>
          <NavItem active={ hasPath(location, 'dashboard') }>
            <NavLink tag={Link} to="/dashboard" onClick={ onCloseMenu }>
              <AWIcon name="layout_grid"/>
              <span className="title"><Translate id="genericLabel.DASHBOARD.text"/></span>
            </NavLink>
          </NavItem>
          <NavItem active={ hasPath(location, 'files') }>
            <NavLink tag={Link} to="/files" onClick={ onCloseMenu }>
              <AWIcon name="folder"/>
              <span className="title "><Translate id="genericLabel.FILE_EXPLORER.text"/></span>
            </NavLink>
          </NavItem>
          <NavItem active={ hasPath(location, 'settings') }>
            <NavLink tag={Link} to="/settings" onClick={ onCloseMenu }>
              <AWIcon name="settings"/>
              <span className="title"><Translate id="genericLabel.SETTINGS.text"/></span>
            </NavLink>
            <Nav className="sub"
                 vertical>
              <NavItem active={ hasPath(location, 'settings/general') }>
                <NavLink tag={Link} to="/settings/general" onClick={ onCloseMenu }>
                  <span className="title"><Translate id="genericLabel.GENERAL.text"/></span>
                </NavLink>
              </NavItem>
              <NavItem active={ hasPath(location, 'settings/security') }>
                <NavLink tag={Link} to="/settings/security" onClick={ onCloseMenu }>
                  <span className="title"><Translate id="genericLabel.SECURITY.text"/></span>
                </NavLink>
              </NavItem>
              <NavItem active={ hasPath(location, 'settings/network') }>
                <NavLink tag={Link} to="/settings/network" onClick={ onCloseMenu }>
                  <span className="title"><Translate id="genericLabel.NETWORK.text"/></span>
                </NavLink>
              </NavItem>
              <NavItem active={ hasPath(location, 'settings/media') }>
                <NavLink tag={Link} to="/settings/media" onClick={ onCloseMenu }>
                  <span className="title"><Translate id="genericLabel.MEDIA.text"/></span>
                </NavLink>
              </NavItem>
              <NavItem active={ hasPath(location, 'settings/video-cards') }>
                <NavLink tag={Link} to="/settings/video-cards" onClick={ onCloseMenu }>
                  <span className="title"><Translate id="genericLabel.VIDEO_CARDS.text"/></span>
                </NavLink>
              </NavItem>
              <NavItem active={ hasPath(location, 'settings/file-transfer') }>
                <NavLink tag={Link} to="/settings/file-transfer" onClick={ onCloseMenu }>
                  <span className="title"><Translate id="genericLabel.FILE_TRANSFER.text"/></span>
                </NavLink>
              </NavItem>
              <NavItem active={ hasPath(location, 'settings/intercom') }>
                <NavLink tag={Link} to="/settings/intercom" onClick={ onCloseMenu }>
                  <span className="title"><Translate id="genericLabel.INTERCOM.text"/></span>
                </NavLink>
              </NavItem>
            </Nav>
          </NavItem>
          <NavItem active={ hasPath(location, 'tools') }>
            <NavLink tag={Link} to="/tools" onClick={ onCloseMenu }>
              <AWIcon name="tools"/>
              <span className="title"><Translate id="genericLabel.TOOLS.text"/></span>
            </NavLink>
            <Nav className="sub"
                 vertical>
              <NavItem active={ hasPath(location, 'tools/logs') }>
                <NavLink tag={Link} to="/tools/logs" onClick={ onCloseMenu }>
                  <span className="title"><Translate id="genericLabel.LOGS.text"/></span>
                </NavLink>
              </NavItem>
              <NavItem active={ hasPath(location, 'tools/system') }>
                <NavLink tag={Link} to="/tools/system" onClick={ onCloseMenu }>
                  <span className="title"><Translate id="genericLabel.SYSTEM.text"/></span>
                </NavLink>
              </NavItem>
              {ptp &&
                <NavItem active={ hasPath(location, 'tools/status') }>
                  <NavLink tag={Link} to="/tools/status" onClick={ onCloseMenu }>
                    <span className="title"><Translate id="genericLabel.STATUS.text"/></span>
                  </NavLink>
                </NavItem>
              }
              {/*<NavItem active={ hasPath(location, 'tools/intercom') }>*/}
                {/*<NavLink tag={Link} to="/tools/intercom" onClick={ onCloseMenu }>*/}
                  {/*<span className="title"><Translate id="genericLabel.INTERCOM.text"/></span>*/}
                {/*</NavLink>*/}
              {/*</NavItem>*/}
              {/*<NavItem active={ hasPath(location, 'tools/advanced') }>*/}
                {/*<NavLink tag={Link} to="/tools/advanced" onClick={ onCloseMenu }>*/}
                  {/*<span className="title"><Translate id="genericLabel.ADVANCED.text"/></span>*/}
                {/*</NavLink>*/}
              {/*</NavItem>*/}
            </Nav>
          </NavItem>
        </Nav>
        <Nav fill
             vertical>
          <NavItem>
            <Button className="basic"
                    onClick={ () => { onLicenseDetailsRequested(); onCloseMenu();}}>
              <Translate id="genericLabel.LICENSE.text"/>
            </Button>
          </NavItem>
          { helpLink &&
          <NavItem>
            <NavLink className="secondary"
                     tag="a"
                     href={helpLink}
                     target="_blank">
              <Translate id="genericLabel.HELP.text"/>
            </NavLink>
          </NavItem>
          }
          { apiKey &&
          <NavItem>
            <NavLink className="secondary"
                     tag="a"
                     href={apiDocLink(apiKey)}
                     target="_blank">
              <Translate id="genericLabel.REST_API_DOC.text"/>
            </NavLink>
          </NavItem>
          }
          <NavItem>
            <Button className="basic"
                    onClick={ () => { onLogoutRequested(); onCloseMenu(); }}>
              <Translate id="genericLabel.LOG_OUT.text"/>
            </Button>
          </NavItem>
        </Nav>
      </div>
  );
};

SideBar.propTypes = propTypes;

export default SideBar;