import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {withLocalize} from 'react-localize-redux';
import {XAxis, YAxis, LineSeries, VerticalBarSeries, XYPlot, HorizontalGridLines, VerticalGridLines, DiscreteColorLegend} from 'react-vis';
import AutoSizer from "react-virtualized-auto-sizer";
import { connect } from 'react-redux';
import DayJS from 'dayjs';

import '../../../../../../node_modules/react-vis/dist/style.css';
import {outputPropTypes} from "../../../../../utils/models-prop-types";
import {GRAPH_COLORS, GRAPH_GRID_COLOR, GRAPH_LEGEND_COLOR, GRAPH_MIN_WIDTH} from "../../../../../constants";

const propTypes = {
  output: outputPropTypes.isRequired,
  stats: PropTypes.shape({
    maxAudioLoss: PropTypes.number.isRequired,
    maxTotalBitrate: PropTypes.number,
    maxVideoLoss: PropTypes.number.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
      timestamp: PropTypes.number.isRequired,
      totalBitrate: PropTypes.number.isRequired,
      videoLostPackets: PropTypes.number.isRequired,
      audioLostPackets: PropTypes.number.isRequired,
    })).isRequired
  }),
  translate: PropTypes.func.isRequired
};

const color1 = '#ec502e';
const color2 = '#8cc7e0';
// const color3 = '#2d83a6';

const margins= {
  left: 50,
  right: 50,
  bottom: 18
};

const height = 135;
const tickTotalX = 4;
const tickTotalY = 4;
const tickWidth = 0;

class Graph extends Component {

  constructor(props){
    super(props);

    this.toggleLegend = this.toggleLegend.bind(this);

    this.state = {
      legend: false
    };
  }

  toggleLegend() {
    this.setState({
      legend: !this.state.legend
    });
  }

  formatBitrate(value, index, scale, tickTotal){
    return `${scale.tickFormat(tickTotal, '~s')(value)}b`;
  }

  render(){
    if(!this.props.stats || this.props.stats.data.length <= 1){ // We check that we indeed have stats to display (at least 2)
      return null;
    }
    return (
      <AutoSizer disableHeight>
        {({ width }) => {
          return width > GRAPH_MIN_WIDTH ?
            <div className="graph">
              <div className="base">
                <XYPlot
                  getX={d => d.timestamp }
                  getY={d => d.totalBitrate }
                  xType="time"
                  yDomain={[0, this.props.stats.maxTotalBitrate + (parseInt(this.props.stats.maxTotalBitrate * 0.2))]}
                  height={height}
                  margin={margins}
                  width={width}>
                  <HorizontalGridLines style={{ stroke: GRAPH_GRID_COLOR }} tickTotal={tickTotalY}/>
                  <VerticalGridLines style={{ stroke: GRAPH_GRID_COLOR }} tickTotal={tickTotalX}/>
                  <LineSeries data={this.props.stats.data} color={color1}/>
                  <XAxis style={{ line: {stroke: GRAPH_GRID_COLOR}, text: {stroke: GRAPH_LEGEND_COLOR}}} tickTotal={tickTotalX} tickFormat={v => `${DayJS(v).format('HH:mm')}`} tickSizeOuter={0} tickSizeInner={tickWidth}/>
                  <YAxis title={this.props.translate("genericLabel.BITRATE.text")} style={{ line: {stroke: GRAPH_GRID_COLOR}, text: {stroke: GRAPH_LEGEND_COLOR}}} tickTotal={tickTotalY} tickFormat={ this.formatBitrate } tickSizeOuter={0} tickSizeInner={tickWidth}/>
                </XYPlot>
              </div>
              <div className="overlay" onMouseOver={ this.toggleLegend } onMouseOut={ this.toggleLegend }>
                <XYPlot
                  getX={d => d.timestamp }
                  getY={d => d.videoLostPackets }
                  yDomain={[0, Math.max(this.props.stats.maxVideoLoss, this.props.stats.maxAudioLoss)]}
                  height={height}
                  margin={margins}
                  width={width}>
                  <VerticalBarSeries data={this.props.stats.data} color={color2}/>
                  <YAxis title={this.props.translate("genericLabel.LOST_PACKETS.text")} style={{ line: {stroke: GRAPH_GRID_COLOR}, text: {stroke: GRAPH_LEGEND_COLOR}}} orientation="right" tickSizeOuter={0} tickSizeInner={tickWidth}/>
                  {/*<VerticalBarSeries data={this.props.stats.audio} color={color3}/>*/}
                </XYPlot>
              </div>
              { this.state.legend &&
                  <div className="overlay">
                    <DiscreteColorLegend
                      width={width}
                      orientation="horizontal"
                      items={
                        [
                          {title: this.props.translate("streamStats.STREAM_BITRATE.text"), color: GRAPH_COLORS[0], strokeWidth:10},
                          {title: this.props.translate("streamStats.VIDEO_LOST.text"), color: GRAPH_COLORS[1], strokeWidth:10},
                        ]
                      }
                    />
                  </div>
                }
            </div> : null
        }}
      </AutoSizer>
    );
  }
}

Graph.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  return {
    stats: state.datastore.ipOutputStreamStats[ownProps.output.id]
  };
};

export default connect(mapStateToProps)(withLocalize(Graph));