import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Translate} from "react-localize-redux";
import {Button, Table} from "reactstrap";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

import {formatBitrate} from "../../../../../utils/global-utils";

const propTypes = {
  inputId: PropTypes.string.isRequired,
  onResetDroppedPackets: PropTypes.func.isRequired,
  stats: PropTypes.shape({
    maxAudioLoss: PropTypes.number.isRequired,
    maxTotalBitrate: PropTypes.number,
    maxVideoLoss: PropTypes.number.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
      timestamp: PropTypes.number.isRequired,
      totalBitrate: PropTypes.number.isRequired,
      videoBitrate: PropTypes.number.isRequired,
      videoLostPackets: PropTypes.number.isRequired,
      totalVideoLostPackets: PropTypes.number.isRequired,
      audioBitrate: PropTypes.number.isRequired,
      audioLostPackets: PropTypes.number.isRequired,
      totalAudioLostPackets: PropTypes.number.isRequired
    })).isRequired
  })
};

class StreamInfoDevice extends Component {
  render(){
    const { stats, onResetDroppedPackets } = this.props;
    if(!stats){
      return null;
    }
    const lastStat = stats.data.length > 0 ? stats.data[stats.data.length - 1] : null;
    return (
        <div className="stream-info-device rounded">
        <Table borderless
              className="rounded"
              size="sm">
          <thead>
          <tr>
            <th><Button className="basic text-secondary"
                      color="primary"
                      onClick={ onResetDroppedPackets }>
                <AWIcon name="refresh"/>
              </Button></th>
            <th>
              <Translate id="genericLabel.DROPPED_PACKETS.text"/>
            </th>
            <th><Translate id="genericLabel.BITRATE.text"/></th>
            { stats.mpegtsUpMode === false &&
            <>
            <th></th>
            <th>
              <Translate id="genericLabel.DROPPED_PACKETS.text"/>
            </th>
            <th><Translate id="genericLabel.BITRATE.text"/></th>
            </>}
          </tr>
          </thead>
          <tbody>
          <tr>
            <th><Translate id={`genericLabel.${stats.mpegtsUpMode ? 'STREAM' : 'VIDEO'}.text`}/></th>
            <td>{ lastStat.totalVideoLostPackets }</td>
            <td className="bitrate">{ formatBitrate(lastStat.videoBitrate) }</td>
            { stats.mpegtsUpMode === false &&
            <>
              <th><Translate id="genericLabel.AUDIO.text"/></th>
              <td>{ lastStat.totalAudioLostPackets }</td>
              <td className="bitrate">{ formatBitrate(lastStat.audioBitrate) }</td>
            </>
          }
          </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}

StreamInfoDevice.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {

  return {
    stats: state.datastore.inputStreamStats[ownProps.inputId]
  };
};

export default connect(mapStateToProps)(StreamInfoDevice);