import PropTypes from 'prop-types';
import React from 'react';
import {Button, Table} from 'reactstrap';
import {Translate} from "react-localize-redux";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

import AWPopup, {AWPopupCol, AWPopupRow} from "@aviwest/ui-kit/dist/js/components/popup";

const propTypes = {
  sessionsCount: PropTypes.number,
  version: PropTypes.string,
  hardwareIdentifier: PropTypes.string,
  publicIp: PropTypes.string,
  publicHostname: PropTypes.string,
  localIps: PropTypes.arrayOf(PropTypes.string),
};

const Info = React.memo((props) => {
  const {
    hardwareIdentifier,
    sessionsCount,
    version,
    publicIp,
    publicHostname,
    localIps,
    isoVersion
  } = props;

  return (
  <div className="info">
    <AWPopup direction="top-start"
             shadow={true}
             renderTrigger={({ ref, onClick, className }) => (
             <Button id="footer_infoButton"
                     innerRef={ref}
                     className={`${className} basic`}
                     onClick={ onClick }>
               <AWIcon name="info_circle"/>
             </Button>
             )}>
      {() => (
      <AWPopupRow>
        <AWPopupCol>
          <Table size="sm" borderless={true}>
            <tbody>
            <tr>
              <td><Translate id="genericLabel.STREAMHUB_USERS.text"/></td>
              <td className="value" id="footer_infoButton_sessionsCount">{sessionsCount}</td>
            </tr>
            <tr>
              <td><Translate id="genericLabel.FIRMWARE_VERSION.text"/></td>
              <td className="value" id="footer_infoButton_firmwareVersion">{version}</td>
            </tr>
            <tr>
              <td>ISO Version{/*< Translate id="genericLabel.FIRMWARE_VERSION.text"/> */}</td>
              <td className="value" id="footer_infoButton_isoVersion">{isoVersion}</td>
            </tr>
            <tr>
              <td><Translate id="genericLabel.HARDWARE_ID.text"/></td>
              <td className="value" id="footer_infoButton_hardwareID">{hardwareIdentifier}</td>
            </tr>
            <tr style={{display: publicHostname ? 'table-row' : 'none'}}>
              <td><Translate id="genericLabel.HOSTNAME.text"/></td>
              <td className="value" id="footer_infoButton_publicHostName">{publicHostname}</td>
            </tr>
            <tr style={{display: publicIp ? 'table-row' : 'none'}}>
              <td><Translate id="genericLabel.PUBLIC_IP.text"/></td>
              <td className="value" id="footer_infoButton_publicIp">{publicIp}</td>
            </tr>
            <tr>
              <td><Translate id="genericLabel.LOCAL_IP.text"/></td>
              <td className="value" id="footer_infoButton_localIp">{localIps != null && localIps.join(', ')}</td>
            </tr>
            </tbody>
          </Table>
        </AWPopupCol>
      </AWPopupRow>
      )}
    </AWPopup>
  </div>
  )
})

Info.propTypes = propTypes;
export default Info;