import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect, Switch } from 'react-router';
import { withLocalize } from 'react-localize-redux';
import { renderToStaticMarkup } from "react-dom/server";

import frTranslations from './i18n/awi18n-fr.json';
import enTranslations from './i18n/awi18n-en.json';
import ptTranslations from './i18n/awi18n-pt.json';
import spTranslation from './i18n/awi18n-sp.json';
import chTranslation from './i18n/awi18n-ch.json';
import DashboardPage from './components/pages/dashboard'
import FilesPage from './components/pages/files';
import LoginPage from './components/pages/login';
import SettingsPage from './components/pages/settings';
import ToolsPage from './components/pages/tools';
import {loadIdentity} from "./components/pages/login/login.actions";
import {themePropTypes} from "./utils/models-prop-types";
import PublicRoute from "./components/hoc/public-route";
import ProtectedRoute from "./components/hoc/protected-route";
import {LANGUAGES, USER_ROLE_ADMIN} from "./constants";
import LoginSso from "./components/pages/login/loginSso"

// Import common translations
try {
  const commonFrTranslations = require('./i18n/common/awi18n-common-fr.json');
  Object.keys(commonFrTranslations).forEach(key => {
    if (!frTranslations[key]) {
      frTranslations[key] = commonFrTranslations[key]
    }
  });
  const commonEnTranslations = require('./i18n/common/awi18n-common-en.json');
  Object.keys(commonEnTranslations).forEach(key => {
    if (!enTranslations[key]) {
      enTranslations[key] = commonEnTranslations[key]
    }
  });
  const commonPtTranslations = require('./i18n/common/awi18n-common-pt.json');
  Object.keys(commonPtTranslations).forEach(key => {
    if (!ptTranslations[key]) {
      ptTranslations[key] = commonPtTranslations[key]
    }
  });
  const commonSpTranslations = require('./i18n/common/awi18n-common-es.json');
  Object.keys(commonSpTranslations).forEach(key => {
    if (!spTranslation[key]) {
      spTranslation[key] = commonSpTranslations[key]
    }
  });
  const commonChTranslations = require('./i18n/common/awi18n-common-ch.json');
  Object.keys(commonChTranslations).forEach(key => {
    if (!chTranslation[key]) {
      chTranslation[key] = commonChTranslations[key]
    }
  });
} catch(error) {
  /* Error handling */
  console.error('Import common translations', error)
};




const propTypes = {
  theme: themePropTypes.isRequired,
  addTranslationForLanguage: PropTypes.func.isRequired,
  authenticated: PropTypes.bool.isRequired,
  callLoadIdentity: PropTypes.func.isRequired,
  identityChecked: PropTypes.bool.isRequired,
  initialize: PropTypes.func.isRequired,
  hasAdminLevel: PropTypes.bool
};

class App extends Component {

  constructor(props) {
    super(props);

    const defaultLanguage = localStorage.getItem('languageCode') /*|| this.parseNavigatorLanguage() */ || LANGUAGES[0].code;

    if(!this.props.authenticated && !this.props.identityChecked){
      this.props.callLoadIdentity();
    }

    document.body.classList.add(props.theme);

    props.initialize({
      languages: LANGUAGES,
      options: {
        defaultLanguage,
        renderToStaticMarkup,
        onMissingTranslation: ({ translationId }) => translationId.replace(/\w+\.(.+)\.\w+/, '$1')
      }
    });

    this.addTranslationsForActiveLanguage();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.activeLanguage !== this.props.activeLanguage) {
      this.addTranslationsForActiveLanguage();
      localStorage.setItem('languageCode', this.props.activeLanguage.code);
    }
  }

  addTranslationsForActiveLanguage() {
    if (!this.props.activeLanguage) {
      return;
    }

    switch (this.props.activeLanguage.code) {
      case 'fr':
        this.props.addTranslationForLanguage(frTranslations, this.props.activeLanguage.code);
        break;
      case 'sp':
        this.props.addTranslationForLanguage(spTranslation, this.props.activeLanguage.code);
        break;
      case 'pt':
        this.props.addTranslationForLanguage(ptTranslations, this.props.activeLanguage.code);
        break;
      case 'ch':
        this.props.addTranslationForLanguage(chTranslation, this.props.activeLanguage.code);
        break;
      default:
        this.props.addTranslationForLanguage(enTranslations, this.props.activeLanguage.code);
    }
  }

  render() {
    const { identityChecked, hasAdminLevel } = this.props;
    if(!identityChecked){
      return (
      <div className="loading">LOADING...</div>
      );
    }
    return (
        <Switch>
          <PublicRoute exact
                       name="login"
                       path="/login"
                       component={LoginPage}/>
          <PublicRoute exact
                       name="sso"
                       path="/sso/:ssoKey"
                       component={LoginSso}/>
          <PublicRoute exact
                       name="ssoInput"
                       path="/sso/:ssoKey/dashboard/:inputId"
                       component={LoginSso}/>
          <ProtectedRoute exact
                          name="dashboard"
                          path="/dashboard/:inputId?"
                          component={DashboardPage}/>
          <ProtectedRoute exact
                          name="files"
                          path="/files"
                          component={FilesPage}/>
          { hasAdminLevel &&
          <ProtectedRoute exact
                          name="settings"
                          path="/settings*"
                          component={SettingsPage}/>
          }
          <ProtectedRoute exact
                          name="tools"
                          path="/tools*"
                          component={ToolsPage}/>
          <Redirect from="/" to="/login" />
        </Switch>
    );
  }
}

App.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  const { identity, login } = state;
  return {
    hasAdminLevel: state.identity.role === USER_ROLE_ADMIN,
    authenticated: login.authenticated,
    identityChecked: identity.checked
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    callLoadIdentity: () => dispatch(loadIdentity())
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLocalize(App)));
