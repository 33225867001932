import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {XAxis, YAxis, LineSeries, XYPlot, VerticalBarSeries, HorizontalGridLines, VerticalGridLines} from 'react-vis';
import {Button} from "reactstrap";
import { connect } from 'react-redux';
import DayJS from 'dayjs';
import AutoSizer from "react-virtualized-auto-sizer";
import '../../../../../../node_modules/react-vis/dist/style.css';
import {GRAPH_COLORS, GRAPH_GRID_COLOR_DARK, GRAPH_LEGEND_COLOR, GRAPH_MIN_WIDTH, NDI, PLAYBACK_STATUS_PAUSED, STATUS_LIVE, STATUS_WARNING} from "../../../../../constants";
import {formatRTT, formatLost} from "../../../../../utils/global-utils";
import {Translate} from "react-localize-redux";
import {inputPropTypes} from "../../../../../utils/models-prop-types";
import AWIcon from '@aviwest/ui-kit/dist/js/components/icon';
import StreamInfoDevice from './stream-info-device';
import Graph from './graph';

const propTypes = {
  input: inputPropTypes.isRequired,
  stats: PropTypes.shape({
    currentNetworkInterfaces: PropTypes.arrayOf(PropTypes.string),
    maxAudioLoss: PropTypes.number.isRequired,
    maxTotalBitrate: PropTypes.number,
    maxTotalRxBitrate: PropTypes.number,
    maxTotalTxBitrate: PropTypes.number,
    maxVideoLoss: PropTypes.number.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
      timestamp: PropTypes.number.isRequired,
      totalBitrate: PropTypes.number.isRequired,
      totalRxBitrate: PropTypes.number,
      totalTxBitrate: PropTypes.number,
      videoBitrate: PropTypes.number.isRequired,
      videoLostPackets: PropTypes.number.isRequired,
      audioBitrate: PropTypes.number.isRequired,
      audioLostPackets: PropTypes.number.isRequired,
    })).isRequired
  }),
};

const margins= {
  left: 50,
  right: 40
};

const tickTotalX = 4;
const tickTotalY = 4;
const tickWidth = 0;

class NetworkGraph extends Component {

  constructor(props){
    super(props);
    this.toggleAllInterface = this.toggleAllInterface.bind(this);
    this.toggleInterface = this.toggleInterface.bind(this);
    this.toggleGraph = this.toggleGraph.bind(this);
    this.getBackgroundColor = this.getBackgroundColor.bind(this);
    this.toggleAll = this.toggleAll.bind(this);

    this.state = {
      allInterface: true,
      graphRtt: true,
      graphRx: true,
      graphTx: false,
      streamStats :true,
      all: true,
      graphRTT: true,
      rttLostPackets: true,
    };
  }

  getBackgroundColor(index) {
    return index < GRAPH_COLORS.length ? GRAPH_COLORS[index + 1]: GRAPH_COLORS[0];
  }

  toggleAllInterface() {
    this.setState({
      allInterface: !this.state.allInterface
    });
  }

  toggleAll() {
    if(this.state.all){
      this.props.stats.currentNetworkInterfaces.forEach(element => {
        this.setState({
          [element]: true,
        });
      });
      this.setState({
        allInterface: false,
        all: false
      });
    }else{
      this.props.stats.currentNetworkInterfaces.forEach(element => {
        this.setState({
          [element]: false,
        });
      });
      this.setState({
        allInterface: true,
        all: true
      });
    }
  }

  toggleInterface(element) {
    this.setState({
      [element]: !this.state[element]
    });
  }

  toggleGraph(graph) {
    this.setState({
      [graph]: !this.state[graph]
    });
  }

  tickFormatBitrate(value, index, scale, tickTotal){
    return `${scale.tickFormat(tickTotal, '~s')(value)}b`;
  }

  getNumberGraph(){
    var i=0;
    i= this.state.graphRtt ? i+1 :i;
    i= this.state.graphRx ? i+1 :i;
    i= this.state.graphTx ? i+1 :i;
    if(this.props.input.channelType !== NDI && (this.props.input.status === STATUS_LIVE || this.props.input.status === STATUS_WARNING || this.props.input.playbackStatus === PLAYBACK_STATUS_PAUSED)){
      i= this.state.streamStats ? i+1 :i;
    }
    return i;
  }

  handleDelete(element){
      this.setState({
        [element]: undefined
      });
  }

  render(){
    if(!this.props.stats){
      return null;
    }
    if(this.props.input.channelType === "SRT" && this.state.graphTx !==undefined){
      this.handleDelete('graphTx')
    }
    const streamInfo = this.props.input.channelType !== NDI && (this.props.input.status === STATUS_LIVE || this.props.input.status === STATUS_WARNING || this.props.input.playbackStatus === PLAYBACK_STATUS_PAUSED);
    return (
        <>
          <div className="controls background">
            { streamInfo &&
              <StreamInfoDevice inputId={this.props.input.id}
                onResetDroppedPackets={() => this.props.onResetDroppedPackets(this.props.input)}/>
                        }
            <div className="flex-right">
            { streamInfo &&
              <Button
                className="basic"
                active={this.state.streamStats}
                color="primary"
                onClick={() => this.toggleGraph('streamStats')}>
                  <AWIcon name="chart_line" style={{marginRight: '0.2rem'}}/>
                  Live
              </Button>}
              <Button
                className="basic"
                active={this.state.graphRtt}
                color="primary"
                onClick={() => this.toggleGraph('graphRtt')}>
                  <AWIcon name="chart_line" style={{marginRight: '0.2rem'}}/>
                  RTT
              </Button>
              <Button
                className="basic"
                active={this.state.graphRx}
                color="primary"
                onClick={() => this.toggleGraph('graphRx')}>
                  <AWIcon name="chart_line" style={{marginRight: '0.2rem'}}/>
                  Rx
              </Button>
              {  this.state.graphTx !== undefined &&
                <Button
                  className="basic"
                  active={this.state.graphTx}
                  color="primary"
                  onClick={() => this.toggleGraph('graphTx')}>
                    <AWIcon name="chart_line" style={{marginRight: '0.2rem'}}/>
                    Tx
                </Button>
              }
            </div>
          </div>
          { streamInfo &&
                <>
                  { this.state.streamStats &&
                    <div className="subtitle">
                      Stream Stats
                    </div>
                  }
                  { this.state.streamStats &&
                      <div className={`${streamInfo ? `graph-container-${this.getNumberGraph()}-live`:`graph-container-${this.getNumberGraph()}`} mb-2`}>
                        <Graph inputId={this.props.input.id} stats={this.props.stats} layout={this.layout}/>
                        
                      </div>
                  }
                </>}
            
          { this.state.graphRtt &&
          <div className='controls'>
            <div className="subtitle">
              <Translate id="genericLabel.RTT.text"/>
            </div>
            <div className="flex-right">
              <Button
                    className="basic"
                    active={this.state.graphRTT}
                    color="primary"
                    onClick={() => this.toggleGraph('graphRTT')}>
                      <Translate id="genericLabel.RTT.text"/>
              </Button>
              <Button
                    className="basic"
                    active={this.state.rttLostPackets}
                    color="primary"
                    onClick={() => this.toggleGraph('rttLostPackets')}>
                      <Translate id="genericLabel.DROPPED_PACKETS.text"/>
              </Button>
            </div>
          </div>
          }
          { this.state.graphRtt &&
          <div className={streamInfo ? `graph-container-${this.getNumberGraph()}-live`:`graph-container-${this.getNumberGraph()}`}>
          <AutoSizer>
            {({ height, width }) => {
              return width > GRAPH_MIN_WIDTH ?
                  <div className="graph">
                    <div className="base">
                      <XYPlot
                          getX={d => d.timestamp }
                          getY={d => d.totalRtt }
                          xType="time"
                          yDomain={[0, this.props.stats.maxTotalRtt + (parseInt(this.props.stats.maxTotalRtt * 0.2))]}
                          height={height}
                          width={width}
                          margin={margins}>
                        <HorizontalGridLines style={{ stroke: GRAPH_GRID_COLOR_DARK }} tickTotal={tickTotalY}/>
                        <VerticalGridLines style={{ stroke: GRAPH_GRID_COLOR_DARK }} tickTotal={tickTotalX}/>
                        <XAxis style={{ line: {stroke: GRAPH_GRID_COLOR_DARK}, text: {stroke: GRAPH_LEGEND_COLOR}}} tickTotal={tickTotalX} tickFormat={v => `${DayJS(v).format('HH:mm')}`} tickSizeOuter={0} tickSizeInner={tickWidth}/>
                        <YAxis style={{ line: {stroke: GRAPH_GRID_COLOR_DARK}, text: {stroke: GRAPH_LEGEND_COLOR}}} tickTotal={tickTotalY} tickFormat={ formatRTT } tickSizeOuter={0} tickSizeInner={tickWidth}/>
                        <LineSeries getNull={(d) => (false)} data={this.props.stats.data} color={GRAPH_COLORS[0]}/>
                      </XYPlot>
                    </div>
                    { this.props.stats.currentNetworkInterfaces.map((networkInterface, index) => {
                      if (!this.state[networkInterface]) {
                        return (
                        <div key={ networkInterface }>
                        { this.state.graphRTT &&
                          <div className="overlay">
                            <XYPlot
                                getX={d => d.timestamp }
                                getY={d => d[networkInterface] ? d[networkInterface].rtt : 0 }
                                xType="time"
                                yDomain={[0, this.props.stats.maxTotalRtt + (parseInt(this.props.stats.maxTotalRtt * 0.2))]}
                                height={height}
                                width={width}
                                margin={margins}>
                              <LineSeries getNull={(d) => d.hidden !== true} data={this.props.stats.data} color={this.getBackgroundColor(index)}/>
                            </XYPlot>
                          </div>
                        }
                        { this.state.rttLostPackets &&
                          <div className="overlay">
                            <XYPlot
                                getX={d => d.timestamp }
                                getY={d => (d[networkInterface] && d[networkInterface].lostPackets >= 0) ? d[networkInterface].lostPackets : 0 }
                                yDomain={[0, this.props.stats.maxTotalLostPackets + (parseInt(this.props.stats.maxTotalLostPackets * 0.2))]}
                                height={height}
                                width={width}
                                margin={margins}>
                              <YAxis style={{ line: {stroke: GRAPH_GRID_COLOR_DARK}, text: {stroke: GRAPH_LEGEND_COLOR}}} orientation="right" tickFormat={ formatLost } tickPadding={2} tickSizeOuter={0} tickSizeInner={tickWidth} tickTotal={tickTotalY}/>
                              <VerticalBarSeries data={this.props.stats.data} color={this.getBackgroundColor(index)}/>
                            </XYPlot>
                          </div>
                        }
                        </div>
                    )}
                    else return null})}
                  </div> : null;
            }}
          </AutoSizer>
          </div>
          }
          { this.state.graphRx &&
          <div className="subtitle">
            <Translate id="genericLabel.RX_BITRATE.text"/>
          </div>
          }
          { this.state.graphRx &&
          <div className={streamInfo ? `graph-container-${this.getNumberGraph()}-live`:`graph-container-${this.getNumberGraph()}`}>
          <AutoSizer>
            {({ height, width }) => {
              // Y Domain depending on visible interface
              let maxRxBitrate = 0;
              for (const networkInterface in  this.props.stats.maxRxBitrate) {
                if (!this.state[networkInterface] && this.props.stats.maxRxBitrate[networkInterface] > maxRxBitrate) {
                  maxRxBitrate = this.props.stats.maxRxBitrate[networkInterface]
                }
              }
              if (this.state.allInterface || maxRxBitrate === 0) {
                maxRxBitrate = this.props.stats.maxTotalRxBitrate
              }
              return width > GRAPH_MIN_WIDTH ?
                  <div className="graph">
                    <div className="base">
                      <XYPlot
                          getX={d => d.timestamp }
                          getY={d => d.totalRxBitrate }
                          xType="time"
                          yDomain={[0, maxRxBitrate + (parseInt(maxRxBitrate * 0.2))]}
                          height={height}
                          width={width}
                          margin={margins}>
                        <HorizontalGridLines style={{ stroke: GRAPH_GRID_COLOR_DARK }} tickTotal={tickTotalY}/>
                        <VerticalGridLines style={{ stroke: GRAPH_GRID_COLOR_DARK }} tickTotal={tickTotalX}/>
                        <XAxis style={{ line: {stroke: GRAPH_GRID_COLOR_DARK}, text: {stroke: GRAPH_LEGEND_COLOR}}} tickTotal={tickTotalX} tickFormat={v => `${DayJS(v).format('HH:mm')}`} tickSizeOuter={0} tickSizeInner={tickWidth}/>
                        <YAxis style={{ line: {stroke: GRAPH_GRID_COLOR_DARK}, text: {stroke: GRAPH_LEGEND_COLOR}}} tickTotal={tickTotalY} tickFormat={ this.tickFormatBitrate } tickSizeOuter={0} tickSizeInner={tickWidth}/>
                        <LineSeries getNull={(d) => (d.hidden !== true && this.state.allInterface)} data={this.props.stats.data} color={GRAPH_COLORS[0]}/>
                      </XYPlot>
                    </div>
                    { this.props.stats.currentNetworkInterfaces.map((networkInterface, index) => {
                      if (!this.state[networkInterface]) {
                        return (
                        <div key={ networkInterface }
                            className="overlay">
                          <XYPlot
                              getX={d => d.timestamp }
                              getY={d => d[networkInterface] ? d[networkInterface].rxBitrate : 0 }
                              xType="time"
                              yDomain={
                                [0, this.state.allInterface || maxRxBitrate === 0
                                  ? this.props.stats.maxTotalRxBitrate + (parseInt(this.props.stats.maxTotalRxBitrate * 0.2))
                                  : maxRxBitrate + (parseInt(maxRxBitrate * 0.2))
                                ]
                              }
                              height={height}
                              width={width}
                              margin={margins}>
                            <LineSeries getNull={(d) => d.hidden !== true} data={this.props.stats.data} color={this.getBackgroundColor(index)}/>
                          </XYPlot>
                        </div>
                    )}
                    else return null})}
                  </div> : null;
            }}
          </AutoSizer>
          </div>
          }
          { this.state.graphTx &&
          <div className="subtitle">
            <Translate id="genericLabel.TX_BITRATE.text"/>
          </div>
          }
          { this.state.graphTx &&
          <div className={streamInfo ? `graph-container-${this.getNumberGraph()}-live`:`graph-container-${this.getNumberGraph()}`}>
          <AutoSizer>
            {({ height, width }) => {
              // Y Domain depending on visible interface
              let maxTxBitrate = 0;
              for (const networkInterface in  this.props.stats.maxTxBitrate) {
                if (!this.state[networkInterface] && this.props.stats.maxTxBitrate[networkInterface] > maxTxBitrate) {
                  maxTxBitrate = this.props.stats.maxTxBitrate[networkInterface]
                }
              }
              if (this.state.allInterface || maxTxBitrate === 0) {
                maxTxBitrate = this.props.stats.maxTotalTxBitrate
              }
              return width > GRAPH_MIN_WIDTH ?
                  <div className="graph">
                    <div className="base">
                      <XYPlot
                          getX={d => d.timestamp }
                          getY={d => d.totalTxBitrate }
                          xType="time"
                          yDomain={[0, maxTxBitrate + (parseInt(maxTxBitrate * 0.2))]}
                          height={height}
                          width={width}
                          margin={margins}>
                        <HorizontalGridLines style={{ stroke: GRAPH_GRID_COLOR_DARK }} tickTotal={tickTotalY}/>
                        <VerticalGridLines style={{ stroke: GRAPH_GRID_COLOR_DARK }} tickTotal={tickTotalX}/>
                        <XAxis style={{ line: {stroke: GRAPH_GRID_COLOR_DARK}, text: {stroke: GRAPH_LEGEND_COLOR}}} tickTotal={tickTotalX} tickFormat={v => `${DayJS(v).format('HH:mm')}`} tickSizeOuter={0} tickSizeInner={tickWidth}/>
                        <YAxis style={{ line: {stroke: GRAPH_GRID_COLOR_DARK}, text: {stroke: GRAPH_LEGEND_COLOR}}} tickTotal={tickTotalY} tickFormat={ this.tickFormatBitrate } tickSizeOuter={0} tickSizeInner={tickWidth}/>
                        <LineSeries getNull={(d) => (d.hidden !== true && this.state.allInterface)} data={this.props.stats.data} color={GRAPH_COLORS[0]}/>
                      </XYPlot>
                    </div>
                    { this.props.stats.currentNetworkInterfaces.map((networkInterface, index) => {
                      if (!this.state[networkInterface]) {
                        return (
                        <div key={ networkInterface }
                            className="overlay">
                          <XYPlot
                              getX={d => d.timestamp }
                              getY={d => d[networkInterface] ? d[networkInterface].txBitrate : 0 }
                              xType="time"
                              yDomain={
                                [0, this.state.allInterface || maxTxBitrate === 0
                                  ? this.props.stats.maxTotalTxBitrate + (parseInt(this.props.stats.maxTotalTxBitrate * 0.2))
                                  : maxTxBitrate + (parseInt(maxTxBitrate * 0.2))
                                ]
                              }
                              height={height}
                              width={width}
                              margin={margins}>
                            <LineSeries getNull={(d) => d.hidden !== true} data={this.props.stats.data} color={this.getBackgroundColor(index)}/>
                          </XYPlot>
                        </div>
                    )}
                    else return null })}
                  </div> : null;
            }}
          </AutoSizer>
          </div>
          }
          <div className="controls">
            <div className="flex-right flex-wrap">
              { (this.state.graphRtt || this.state.graphRx || this.state.graphTx ) &&
              <>
                { this.props.stats.currentNetworkInterfaces.map((networkInterface, index) => (
                  <Button
                    key={ networkInterface }
                    className="basic"
                    active={!this.state[networkInterface]}
                    style={this.state[networkInterface] ? {} : {backgroundColor: this.getBackgroundColor(index, this.state[networkInterface])}}
                    onClick={() => this.toggleInterface(networkInterface)}>
                      {networkInterface}
                  </Button>
                ))}
                <Button
                  className="basic"
                  active={this.state.allInterface}
                  style={!this.state.allInterface ? {} : {backgroundColor: GRAPH_COLORS[0]}}
                  onClick={() => this.toggleAllInterface()}>
                    <Translate id="streamStats.TOTAL.text"/>
               </Button>
                <Button
                  className="basic"
                  active={this.state.all}
                  color="primary"
                  onClick={() => this.toggleAll()}>
                    <Translate id="genericLabel.FILTERS_ALL.text"/>
               </Button>
              </>
              }
            </div>
          </div>
        </>
    );
  }
}

NetworkGraph.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  return {
    stats: state.datastore.inputStreamStats[ownProps.input.id]
  };
};

export default connect(mapStateToProps)(NetworkGraph);