import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {formatElapsedTime} from "../../../../../utils/global-utils";

const propTypes = {
  inputId: PropTypes.string.isRequired,
  liveDurationInMinutes: PropTypes.number
};

class LiveTimer extends Component {
  render(){
    if(!this.props.liveDurationInMinutes){
      return null;
    }
    return (
      <div className="live-timer text-clip">{ formatElapsedTime(this.props.liveDurationInMinutes) }</div>
    )
  }
}

LiveTimer.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  const stats = state.datastore.inputStreamStats[ownProps.inputId];
  return {
    liveDurationInMinutes: stats ? stats.liveDurationInMinutes : null
  }
};

export default connect(mapStateToProps)(LiveTimer);