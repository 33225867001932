import PropTypes from "prop-types";
import React from "react";
import Slider from "rc-slider";

const propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired
};

const defaultProps = {
  disabled: false
};

const VolumeSlider = (props) => {
  const { disabled, value, onChange, name } = props;
  return (
    <div className="volume-slider">
    <span className="value">{value}</span>
      <Slider className="aw-theme"
              disabled={disabled}
              vertical
              min={0}
              max={100}
              step={1}
              onChange={onChange}
              value={value}/>
      <span className="name">{name}</span>
    </div>
  )
};

VolumeSlider.propTypes = propTypes;
VolumeSlider.defaultProps = defaultProps;

export default VolumeSlider;
