import PropTypes from 'prop-types';
import React, { useState } from 'react'
import {connect} from "react-redux";
import AWModal from '@aviwest/ui-kit/dist/js/components/modal'
import {Translate, withLocalize} from "react-localize-redux";
import { ipInputsProfilesSettings } from '../../dashboard.actions';
import { nextIndexForProfileConfig } from '../../../../../utils/config-utils';
import {WEBRTC} from '../../../../../constants';
import { inputIpProfilePropTypes } from '../../../../../utils/models-prop-types';
import InputIPProfileList from '../../../settings/inputs/list';
import InputIPProfileForm from '../../../settings/inputs/form';
import NewInputIPProfile from '../../../settings/inputs/new-profile';
import {
  createInputIPProfile,
  createInputTmpProfile,
  deleteInputIPProfile,
  updateInputIPProfile,
} from '../../../settings/settings.actions';
import {Breadcrumb, BreadcrumbItem, Button, Form, Input} from "reactstrap";
import AWIcon from '@aviwest/ui-kit/dist/js/components/icon';

const propTypes = {
  callCreateProfile: PropTypes.func.isRequired,
  callDeleteProfile: PropTypes.func.isRequired,
  callUpdateProfile: PropTypes.func.isRequired,
  callEditProfile: PropTypes.func.isRequired,
  ipProfiles: PropTypes.arrayOf(inputIpProfilePropTypes),
  callCloseModal: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  profileToEdit: PropTypes.string,
  translate: PropTypes.func.isRequired
};

const IpInputProfilesSettingsModal = (props) => {
  const [search, setSearch] = useState("")
  const [lgStep, setLgStep] = useState(1);
  const [newProfileProtocol, setNewProfileProtocol] = useState(null)

  const {callCreateProfile, callDeleteProfile, callUpdateProfile, callEditProfile, callCreateTmpProfile, inputProtocols,
    callCloseModal, ipProfiles, opened, profileToEdit, backToDashboard, channelProfileId, inputs, translate, configTooBig} = props;

  const closeModal = () => {
    setLgStep(1);
    callCloseModal();
  }

  const handleSearch = (evt) => {
    setSearch(evt.target.value);
  }

  const handleNewProfile = () => {
    callEditProfile("-1");
  }

  const handleEditProfile = (profile) => {
    callEditProfile(profile.id);
  }

  const handleCreateProfile = (protocol) => {
    setNewProfileProtocol(protocol);
    callEditProfile("0", backToDashboard, channelProfileId);
  }

  const handleCancel = () => {
    callEditProfile(null);
    setNewProfileProtocol(null);
    setLgStep(1);
    if (backToDashboard) {
      closeModal()
    }
  }

  const handleProfileSubmit = (data) => {
    if(data.id !== undefined){
      console.log('Updating profile: ', data);
      const { id, ...otherProps } = data;
      callUpdateProfile(id, otherProps);
      if (data.type === WEBRTC) {
        setLgStep(2);
      } else {
        handleCancel();
      }
    } else if (data.type === WEBRTC) {
      data.channelProfileId = channelProfileId
      console.log('New Live Guest Profile: ', data);
      callCreateTmpProfile(data);
    } else {
      console.log('Create profile: ', data);
      data.id = `${nextIndexForProfileConfig(inputProtocols)}`
      data.channelProfileId = channelProfileId
      callCreateProfile(data);
      handleCancel();
    }
  };

  const handleProfileDelete = () => {
    callDeleteProfile(profileToEdit);
    callEditProfile(null);
    if (backToDashboard) {
      closeModal()
    }
  }

  return (
    <AWModal id="ipInputProfile_modal"
             onClose={closeModal}
             open={opened}
             title={
               <div className="ip-input-profiles-settings-modal">
                <Breadcrumb>
                  <BreadcrumbItem onClick={handleCancel} tag={profileToEdit !== null ? "a" : "li"} active>
                    <Translate id="genericLabel.INGEST_PROFILES.text" />
                  </BreadcrumbItem>
                  {(profileToEdit !== null && profileToEdit === "-1") &&
                    <BreadcrumbItem active><Translate id="genericLabel.NEW_PROFILE.text" /></BreadcrumbItem>}
                    {(profileToEdit !== null && profileToEdit !== "-1") &&
                      <BreadcrumbItem active><Translate id="genericLabel.SETTINGS.text" /></BreadcrumbItem>}
                </Breadcrumb>
                { profileToEdit === null &&
                  <div className="actions">
                    <Form className="form-inline">
                      <Input id="ipInputProfile_modal_search" 
                            className="search"
                            onChange={handleSearch}
                            placeholder={translate('genericLabel.SEARCH.text')}
                            bsSize="sm"
                            value={search}/>
                    </Form>
                    <Button id="ipInputProfile_modal_addutton" size="sm" onClick={ handleNewProfile } disabled={configTooBig}>
                      <AWIcon name="add"/>
                    </Button>
                  </div>
                }
              </div>
              }>
      <div className="content">
        { profileToEdit === null &&
        <InputIPProfileList ipProfiles={ipProfiles}
                            onProfileEdit={handleEditProfile}
                            search={search}
                            inputs={inputs}/>
        }
        { (profileToEdit !== null && profileToEdit === "-1") &&
        <NewInputIPProfile  onProfileCreation={handleCreateProfile} />
        }
        { (profileToEdit !== null && profileToEdit !== "-1") &&
        <InputIPProfileForm editingProfileId={profileToEdit}
                            lgStep={lgStep}
                            protocolSet={newProfileProtocol}
                            onCancel={handleCancel}
                            backToDashboard={backToDashboard}
                            onSubmit={handleProfileSubmit}
                            onDelete={profileToEdit > 0 ? handleProfileDelete : null}/>
        }
      </div>
    </AWModal>
  )
}

IpInputProfilesSettingsModal.propTypes = propTypes;

const mapStateToProps = (state) => {
  return {
    ipProfiles: state.streamhub.inputIPProfiles,
    inputProtocols: state.config.inputProtocol,
    opened: state.dashboard.editingIpInputsProfilesSettings.open,
    profileToEdit: state.dashboard.editingIpInputsProfilesSettings.profileToEdit,
    backToDashboard: state.dashboard.editingIpInputsProfilesSettings.backToDashboard,
    channelProfileId: state.dashboard.editingIpInputsProfilesSettings.channelProfileId,
    inputs: state.streamhub.inputs,
    configTooBig: state.streamhub.configTooBig
  }
}

const mapDispatchToProps = dispatch => {
  return {
    callCreateProfile: (data) => dispatch(createInputIPProfile(data)),
    callDeleteProfile: (id) => dispatch(deleteInputIPProfile(id)),
    callUpdateProfile: (id, data) => dispatch(updateInputIPProfile(id, data)),
    callCreateTmpProfile: (data) => dispatch(createInputTmpProfile(data)),
    callEditProfile: (profileId, backToDashboard, channelProfileId) => dispatch(ipInputsProfilesSettings(true, profileId, backToDashboard, channelProfileId)),
    callCloseModal: () => dispatch(ipInputsProfilesSettings(false))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(IpInputProfilesSettingsModal));
