import PropTypes from 'prop-types';
import React, {Component} from 'react';
import { connect } from 'react-redux';
import {Button, FormGroup, Label} from 'reactstrap';
import AWPopup, {AWPopupCol, AWPopupRow} from "@aviwest/ui-kit/dist/js/components/popup";
import {Translate, withLocalize} from "react-localize-redux";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";
import {decryptPassword, updateDecryptPassword} from "../../../misc/socket.actions";

const propTypes = {
  encryptedPassword: PropTypes.string,
  decryptedPassword: PropTypes.string,
  fieldName: PropTypes.string,
  callDecryptPassword: PropTypes.func.isRequired,
  username: PropTypes.string
};

class PasswordDecrpypt extends Component {

  constructor(props){
    super(props);
    this.elementRef = React.createRef();
  }

  componentDidMount(){
    // Reset state.streamhub.decryptedPassword
    this.props.callUpdateDecryptPassword(null);
  }

  componentDidUpdate(prevProps){
    if (prevProps.decryptedPassword !== this.props.decryptedPassword && this.props.decryptedPassword) {
      this.props.setFieldValue(this.props.fieldName, this.props.decryptedPassword)
      this.props.setDecrypted()
    }

  }

  render(){
    const { encryptedPassword, callDecryptPassword, username } = this.props;
    return (

        <AWPopup direction="top-end"
                shadow={true}
                renderTrigger={({ ref, onClick, className }) => (
                <Button innerRef={ref}
                        className="no-top-padding no-bottom-padding"
                        onClick={ onClick }>
                  <AWIcon name="visibility"/>
                </Button>
                )}>
          {({ close }) => (
          <AWPopupRow>
            <AWPopupCol>
              <FormGroup style={{ minWidth: '16rem' }}>
                <Label for="password">
                  <Translate id="genericLabel.PASSWORD.text"/>
                  <AWIcon name="account" className="mx-1"/>
                  { username }
                </Label>
                <input
                  ref={this.elementRef}
                  type="password"
                  autoFocus
                  className="form-control-sm form-control"
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      callDecryptPassword(this.elementRef.current.value, encryptedPassword); close()
                    }
                  }}
                  />
              </FormGroup>
              <FormGroup className="buttons">
                <Button color="primary"
                        outline
                        size="sm"
                        onClick={() => {callDecryptPassword(this.elementRef.current.value, encryptedPassword); close()}}>
                  <Translate id="genericLabel.DECRYPT.text"/>
                </Button>
              </FormGroup>
            </AWPopupCol>
          </AWPopupRow>
          )}
        </AWPopup>

    )
  }
};

PasswordDecrpypt.propTypes = propTypes;

const mapStateToProps = (state) => {
  const { decryptedPassword } = state.streamhub;
  const { username } = state.identity
  return {
    decryptedPassword,
    username
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    callDecryptPassword: (adminPassword, encryptedPassword) => dispatch(decryptPassword(adminPassword, encryptedPassword)),
    callUpdateDecryptPassword: (password) => dispatch(updateDecryptPassword(password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(PasswordDecrpypt));