import PropTypes from "prop-types";
import React from 'react';
import {Translate} from "react-localize-redux";
import { Form, Formik } from "formik";
import { Label, Input, FormGroup, Button } from "reactstrap";
import { audioDevicePropTypes } from "../../../../utils/models-prop-types";
import { AWIcon } from "@aviwest/ui-kit";


const propTypes = {
    onSubmit: PropTypes.func.isRequired,
    intercomConfig: PropTypes.shape({
        autoStart: PropTypes.bool,
        sipRtpPortStart: PropTypes.number,
        sipRtpPortRange: PropTypes.number,
        inputDevice: PropTypes.string,
        inputMode: PropTypes.string,
        inputVolume: PropTypes.string,
        outputDevice: PropTypes.string,
        outputVolume: PropTypes.string,
        playbackFramesPerPacket: PropTypes.number
      }),
      inputAudioDevices: PropTypes.arrayOf(audioDevicePropTypes),
      inputAudioDeviceSelected: audioDevicePropTypes,
      inputs: PropTypes.object.isRequired,
      config: PropTypes.object,
      onRefreshAES67Sources:PropTypes.func,
      aes67SourcesList: PropTypes.object,

};

const InputRoutingForm = (props) => {
    const { 
        inputAudioDeviceSelected,
        inputs,
        config,
        onRefreshAES67Sources,
        aes67SourcesList,
        intercomConfig
     } = props;

    const handleSubmit = (values, { resetForm }) => {
        const activeInputsChannels=[];
        inputs.forEach((value, index) => {
            const activeInputChannels = [];
            values.inputDeviceValues[index].forEach((value, index) => {
            if(value === true){
                activeInputChannels.push(index);
                }
             });
             activeInputsChannels.push(JSON.stringify(activeInputChannels));
        });
        const data = {
            ...values,
            inputs,
            activeInputsChannels
        }
        props.onSubmit(data);
        values.edited=false;
        resetForm(values);

    }

    const handleValidation = (values) => {
        const errors ={};

        return errors;
    }

    const mapValues =(maxEntries, audioMapString) => {
        const audioMap = JSON.parse(audioMapString);
        return [...Array(maxEntries)].map((_, i) => {
          return audioMap.indexOf(i) !== -1;
        });
      };

    const init = (inputAudioDeviceSelected, inputs, intercomChannelProfile ) =>{
        var inputDeviceValues=[];
        if(inputAudioDeviceSelected){
            inputs.forEach((element, index) => {
                let temp = mapValues(inputAudioDeviceSelected.max_entries, intercomChannelProfile[element.id].audioInputMap);
            inputDeviceValues[index]=temp;
            });
        }
        return inputDeviceValues;
    }

    const initAES67 = ( intercomChannelProfile,maxEntries) =>{
        var aes67SinkSource=[];
        for (let index = 1; index <= maxEntries; index++) {
            const element = intercomChannelProfile[index];
            aes67SinkSource[index] = element.aes67SinkSource;
        }
        return aes67SinkSource;
    }
    if(!inputAudioDeviceSelected){
        return null;
    }

    const addLine=(values)=>{
        values.edited=true
        values.nbInputVisible=values.nbInputVisible+1 > inputAudioDeviceSelected.max_entries ? inputAudioDeviceSelected.max_entries : values.nbInputVisible+1;
    }
    const removeLine=(values)=>{
        values.edited=true;
        values.nbInputVisible= values.nbInputVisible-1 <= 0 ? 1 : values.nbInputVisible-1;
    }
    
    return(
        
        <Formik initialValues={{
            edited:false,
            inputDeviceValues: init(inputAudioDeviceSelected,inputs, config.intercomChannelProfile),
            inputAES67SourceValues: initAES67(config.intercomChannelProfile, inputAudioDeviceSelected.max_entries),
            nbInputVisible: intercomConfig.nbInputVisible < inputAudioDeviceSelected.max_entries ? intercomConfig.nbInputVisible : inputAudioDeviceSelected.max_entries
          }}
          validateOnBlur={false}
          validateOnChange={true}
          validate={ handleValidation }
          onSubmit={ handleSubmit }>
        {({
          values,
          errors,
          dirty,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
            <Form onSubmit={handleSubmit}>
                <table className="routing">
                <tr className="label_routing">
                    { inputs.map((value, key) => {
                                return <td> <Label className="vertical_routing" check key={key}>
                                    {value.name}
                                </Label></td>
                            })}
                            <td className="background_routing"> 
                                <div >
                                    <span className="bottom_routing">Input Device</span>
                                    <span className="top_routing">Streamhub Inputs</span>
                                    <div className="line_routing"></div>
                                </div>
                            </td>
                            { inputAudioDeviceSelected.is_aes67 &&
                            <> <td className="aes67check"></td>
                                <td className="aes67">
                                    <Button color="secondary" onClick={onRefreshAES67Sources}>
                                      <AWIcon name="refresh"/>
                                      <span>AES-67 Sources</span>
                                    </Button>
                                </td><td className="aes67label"></td>
                                </>
                            }
                            </tr>
                    { [...Array(values.nbInputVisible)].map((value1, key1) => {
                        return <tr key={key1}>
                            { inputs.map((value, key) => {
                                return <td><FormGroup check  className="tata">
                                    <Input  
                                            type="checkbox"
                                            name={`inputDeviceValues[${key}][${key1}]`}
                                            onChange={handleChange}
                                            checked={values.inputDeviceValues[key][key1]}/>
                                </FormGroup></td>
                            }) }
                            <td className="label_right_routing">{`i.${key1 + 1}`}</td>
                            {inputAudioDeviceSelected.is_aes67 &&
                            <><td className="aes67check"></td>
                            <td className="aes67">
                                <Input type="select"
                                        name={`inputAES67SourceValues[${key1+1}]`}
                                        id="AES67Settings_inputMode"
                                        value={values.inputAES67SourceValues[key1+1]}
                                        onChange={handleChange}>
                                    <option value=""></option>
                                { aes67SourcesList.map((source, index) => (
                                <option key={`source${index}`} value={source}>{ source }</option>
                                ))}
                                </Input>
                            </td></>}
                        </tr>
                    })}
                </table>
                <FormGroup className="flex-left mb-0 mt-3">
                    <Button color="primary"
                            disabled={values.nbInputVisible>=inputAudioDeviceSelected.max_entries}
                            onClick={()=> addLine(values)}>
                        <AWIcon name="add" />
                    </Button>
                    <Button color="primary"
                            disabled={values.nbInputVisible<=1}
                            onClick={()=>removeLine(values)}>
                        <AWIcon name="remove" />
                    </Button>
                </FormGroup>
                <FormGroup className="buttons">
                    <Button color="primary"
                            disabled={(isSubmitting || !dirty) && !values.edited}
                            type="submit">
                        <Translate id="genericLabel.SAVE.text"/>
                    </Button>
                </FormGroup>
            </Form>
        )}
        </Formik>
        
    );
};
InputRoutingForm.prototype = propTypes;

export default InputRoutingForm;