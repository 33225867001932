import {createSelector} from "reselect";

const getCapabilities = (state) => state.capabilities;
const getChannelType = (state) => state.capabilities.channelType;
const getStreamingOutputMode = (state) => state.capabilities.streamingOutputMode;
const getOutputId = (state, props) => props.outputId;

export const getAvailableIPInputProtocols = createSelector(
  [ getChannelType ],
  (channelTypeObj) => {
    if(!channelTypeObj){
      return [];
    }
    return Object.values(channelTypeObj).map(type => type.name);
  }
);

export const getAvailableIPOutputProtocols = createSelector(
  [ getStreamingOutputMode ],
  (streamingOutputModeObj) => {
    if(!streamingOutputModeObj){
      return [];
    }
    return Object.values(streamingOutputModeObj).map(type => type.name);
  }
);

export const getEncoderCapabilities = createSelector(
  [ getCapabilities ],
  (capabilities) => {
    return {
      audioTypes: capabilities.encoderAudioType,
      audioChannelModes: capabilities.encoderAudioChannelMode,
      audioChannelModesVR: capabilities.encoderAudioChannelModeVR,
      chromaSampling: capabilities.encoderChromaSampling,
      // Mapping quality from string to array
      videoTypes: capabilities.encoderVideoType.map(videoType => {
        videoType.quality = videoType.quality.split(',');
        return videoType
      }),
      outputStandards: capabilities.encoderOutputStandard,
      gopSizes: capabilities.encoderGOPSize
    };
  }
);

export const getAvailableDestinationInputs = createSelector(
 [ getCapabilities ],
 (capabilities) => {
   if(!capabilities.destChannels){
     return [];
   }
   return Object.values(capabilities.destChannels).map(type => type.name);
 }
);

export const getAvailableFTPTransferModes = createSelector(
 [ getCapabilities ],
 (capabilities) => {
   if(!capabilities.ftpTransferMode){
     return [];
   }
   return Object.values(capabilities.ftpTransferMode).map(mode => mode.name);
 }
);

export const getAvailableFTPTransferTypes = createSelector(
  [ getCapabilities ],
  (capabilities) => {
    if(!capabilities.fileTransferType){
      return [];
    }
    return Object.values(capabilities.fileTransferType).map(type => type.name);
  }
);

export const getAvailableHardwareOutputStandards = createSelector(
  [ getCapabilities, getOutputId ],
  (capabilities, outputId) => {
    if(!capabilities[`outputStandard${outputId}`]){
      return [];
    }
    return Object.values(capabilities[`outputStandard${outputId}`]).map(standard => standard.name);
  }
);

export const getAvailableNDIOutputStandards = createSelector(
  [ getCapabilities ],
  (capabilities) => {
    if(!capabilities.NDIOutputStandard){
      return [];
    }
    return Object.values(capabilities.NDIOutputStandard).map(standard => standard.name);
  }
);

export const getAvailableLostStreamModes = createSelector(
  [ getCapabilities ],
  (capabilities) => {
    if(!capabilities.lostStreamMode){
      return [];
    }
    return capabilities.lostStreamMode;
  }
);

export const getAvailableEncoderLostStreamModes = createSelector(
  [ getCapabilities ],
  (capabilities) => {
    if(!capabilities.encoderLostStreamMode){
      return [];
    }
    return capabilities.encoderLostStreamMode;
  }
);

export const getAvailableMosaicOutputStandards = createSelector(
  [ getCapabilities ],
  (capabilities) => {
    if(!capabilities.mosaicOutputStandard){
      return [];
    }
    return Object.values(capabilities.mosaicOutputStandard).map(standard => standard.name);
  }
);

export const getAvailableMosaicAudioChannelModes = createSelector(
  [ getCapabilities ],
  (capabilities) => {
    if(!capabilities.mosaicAudioChannelMode){
      return [];
    }
    return Object.values(capabilities.mosaicAudioChannelMode).map(standard => standard.name);
  }
);

export const getAvailableReceivedFileTranscoder = createSelector(
  [ getCapabilities ],
  (capabilities) => {
    // Resolutions
    let resolutions = [];
    if(capabilities.transcoderReceivedResolution){
      resolutions = capabilities.transcoderReceivedResolution.map(capability => capability.name);
    }
    // Framerates
    const frameRates = {};
    if(capabilities.transcoderReceivedFrmRate){
      if(Array.isArray(capabilities.transcoderReceivedFrmRate)){
        //Old format: common framerates through every resolutions
        const commonFrameRates = capabilities.transcoderReceivedFrmRate.map(capability => capability.name);
        resolutions.forEach(resolution => {
          frameRates[resolution] = commonFrameRates;
        });
      }
      else {
        //New format: every resolution has its own framerates
        Object.keys(capabilities.transcoderReceivedFrmRate).forEach(key => {
          frameRates[key] = capabilities.transcoderReceivedFrmRate[key].map(capability => capability.name);
        });
      }
    }
    return {
      resolutions,
      scanModes: capabilities.transcoderReceivedScanMode ? capabilities.transcoderReceivedScanMode.map(capability => capability.name) : [],
      frameRates,
      formats: capabilities.transcoderReceivedFormat ? capabilities.transcoderReceivedFormat.map(capability => capability.name) : []
    }
  }
);

export const getAvailableRecordedFileTranscoder = createSelector(
  [ getCapabilities ],
  (capabilities) => {
    // Resolutions
    let resolutions = [];
    if(capabilities.transcoderRecordedResolution){
      resolutions = capabilities.transcoderRecordedResolution.map(capability => capability.name);
    }
    // Framerates
    const frameRates = {};
    if(capabilities.transcoderRecordedFrmRate){
      if(Array.isArray(capabilities.transcoderRecordedFrmRate)){
        //Old format: common framerates through every resolutions
        const commonFrameRates = capabilities.transcoderRecordedFrmRate.map(capability => capability.name);
        resolutions.forEach(resolution => {
          frameRates[resolution] = commonFrameRates;
        });
      }
      else {
        //New format: every resolution has its own framerates
        Object.keys(capabilities.transcoderRecordedFrmRate).forEach(key => {
          frameRates[key] = capabilities.transcoderRecordedFrmRate[key].map(capability => capability.name);
        });
      }
    }
    return {
      resolutions,
      scanModes: capabilities.transcoderRecordedScanMode ? capabilities.transcoderRecordedScanMode.map(capability => capability.name) : [],
      frameRates,
      formats: capabilities.transcoderRecordedFormat ? capabilities.transcoderRecordedFormat.map(capability => capability.name) : []
    }
  }
);

export const getAvailableDecoderCapabilities = createSelector(
  [ getCapabilities ],
  (capabilities) => {
    if(!capabilities.decoderCapabilities){
      return [];
    }
    return capabilities.decoderCapabilities;
  }
);

export const getAvailableVideoCards = createSelector(
  [ getCapabilities ],
  (capabilities) => {
    if(!capabilities.videoCard){
      return null;
    }
    return capabilities.videoCard.map((videoCard, index) => {
      return {
        id: `${index}`,
        ...videoCard
      }
    });
  }
);

export const getAvailableVideoFrameRates = createSelector(
  [ getCapabilities ],
  (capabilities) => {
    if(!capabilities.videoFrameRate){
      return [];
    }
    return capabilities.videoFrameRate.map(frameRate => frameRate.name);
  }
);

export const getAvailableSrtKeyLengths = createSelector(
  [ getCapabilities ],
  (capabilities) => {
    if(!capabilities.srtKeyLength){
      return [];
    }
    // Do not show 'Disabled' value (Task #16694)
    return capabilities.srtKeyLength.map(standard => standard.name).slice(1);
  }
);

export const getEncoderPresets = createSelector(
  [ getCapabilities ],
  (capabilities) => {
    if(!capabilities.encoderPresets){
      return [];
    }
    return capabilities.encoderPresets;
  }
);

export const getAvailableLiveGuestResolutions = createSelector(
  [ getCapabilities ],
  (capabilities) => {
    if(!capabilities.liveGuestResolutions){
      return [];
    }
    return capabilities.liveGuestResolutions;
  }
);