import AWBadgeLabel from '@aviwest/ui-kit/dist/js/components/badge-label';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { formatBitrate } from "../../../../../utils/global-utils";

const propTypes = {
  inputId: PropTypes.string.isRequired,
  totalRxBitrate: PropTypes.number,
  totalBitrate: PropTypes.number
};

const BitrateBadge = (props) => {
  if (!props.totalRxBitrate && !props.totalBitrate) {
    return null;

  }
  if (props.totalRxBitrate) {
    return (
      <AWBadgeLabel fill className="bitrate-badge">{formatBitrate(props.totalRxBitrate)}</AWBadgeLabel>
    );
  }
  if (props.totalBitrate) {
    return (
      <AWBadgeLabel fill className="bitrate-badge">{formatBitrate(props.totalBitrate)}</AWBadgeLabel>
    );
  }
};

BitrateBadge.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  const stats = state.datastore.inputStreamStats[ownProps.inputId];
  return {
    totalRxBitrate: stats ? stats.data[stats.data.length - 1].totalRxBitrate : null,
    totalBitrate: stats ? stats.data[stats.data.length - 1].totalBitrate : null
  }
};

export default connect(mapStateToProps)(BitrateBadge);