import React from "react";
import PropTypes from 'prop-types';

const propTypes = {
  width: PropTypes.number,
};

const StreamHubLogo = ({width = 170}) => {
  return (
   <svg viewBox="-15 -15 433.70001 125.599998" width={width} height={width / 3}
   >
<style type="text/css">
	{".st0{fill:#fff}"}
   {".st1{fill:#ec502e"}
</style>
<g id="g22" transform="translate(-127.3,-91.9)">
	<path className="st0" d="m 127.3,177.7 3.7,-4.4 c 5.5,5 10.9,7.5 18.2,7.5 7.1,0 11.9,-3.8 11.9,-9 v -0.2 c 0,-4.9 -2.7,-7.8 -13.8,-10.1 -12.2,-2.7 -17.9,-6.6 -17.9,-15.4 V 146 c 0,-8.4 7.4,-14.5 17.5,-14.5 7.8,0 13.3,2.2 18.7,6.5 l -3.5,4.6 c -4.9,-4 -9.9,-5.8 -15.4,-5.8 -6.9,0 -11.3,3.8 -11.3,8.6 v 0.2 c 0,5 2.7,7.8 14.4,10.3 11.9,2.6 17.3,6.9 17.3,15 v 0.2 c 0,9.1 -7.6,15 -18.2,15 -8.3,0.1 -15.2,-2.7 -21.6,-8.4 z"/>
	<path className="st0" d="m 178.6,175.1 v -23.7 h -5.5 v -5.2 h 5.5 v -11.9 h 5.9 v 11.9 H 197 v 5.2 h -12.5 v 23 c 0,4.8 2.7,6.5 6.6,6.5 2,0 3.6,-0.4 5.7,-1.4 v 5 c -2.1,1.1 -4.3,1.7 -7.1,1.7 -6.3,0 -11.1,-3.1 -11.1,-11.1 z"/>
	<path className="st0" d="m 205.7,146.2 h 5.9 v 10.3 c 2.9,-6.5 8.6,-11.2 16,-10.9 v 6.3 h -0.5 c -8.6,0 -15.6,6.2 -15.6,18 v 15.7 h -5.9 v -39.4 z"/>
	<path className="st0" d="m 230.9,166 v -0.2 c 0,-11.3 8,-20.4 18.8,-20.4 11.6,0 18.3,9.3 18.3,20.7 0,0.8 0,1.2 -0.1,1.9 h -31.2 c 0.8,8.5 6.8,13.3 13.8,13.3 5.4,0 9.2,-2.2 12.4,-5.5 l 3.6,3.3 c -4,4.4 -8.7,7.4 -16.2,7.4 -10.6,-0.1 -19.4,-8.4 -19.4,-20.5 z m 31.2,-2.3 c -0.6,-7.1 -4.7,-13.4 -12.5,-13.4 -6.8,0 -12,5.7 -12.8,13.4 z"/>
	<path className="st0" d="m 273.8,174.1 v -0.2 c 0,-8.3 6.8,-12.7 16.8,-12.7 5,0 8.6,0.7 12.1,1.7 v -1.4 c 0,-7.1 -4.3,-10.7 -11.7,-10.7 -4.6,0 -8.3,1.2 -11.9,2.9 l -1.7,-4.8 c 4.3,-2 8.6,-3.3 14.3,-3.3 5.5,0 9.8,1.4 12.7,4.3 2.7,2.7 4,6.5 4,11.5 v 24 h -5.6 v -5.9 c -2.7,3.6 -7.3,6.8 -14.2,6.8 -7.4,0 -14.8,-4.2 -14.8,-12.2 z m 29,-3.1 v -3.8 c -2.9,-0.8 -6.8,-1.7 -11.6,-1.7 -7.4,0 -11.5,3.2 -11.5,8.1 v 0.2 c 0,4.9 4.6,7.8 9.9,7.8 7.2,0.1 13.2,-4.3 13.2,-10.6 z"/>
	<path className="st0" d="m 319.2,146.2 h 5.9 v 6.6 c 2.6,-3.9 6.1,-7.4 12.7,-7.4 6.4,0 10.5,3.4 12.6,7.8 2.8,-4.3 7,-7.8 13.8,-7.8 9,0 14.6,6.1 14.6,15.8 v 24.3 H 373 v -23 c 0,-7.6 -3.8,-11.9 -10.2,-11.9 -5.9,0 -10.9,4.4 -10.9,12.2 v 22.6 h -5.8 v -23.1 c 0,-7.4 -3.9,-11.7 -10.1,-11.7 -6.2,0 -10.9,5.2 -10.9,12.4 v 22.4 h -5.9 z"/>
	<path className="st0" d="m 390.8,132.3 h 6 v 23.6 h 30.7 v -23.6 h 6 v 53.2 h -6 v -23.9 h -30.7 v 23.9 h -6 z"/>
	<path className="st0" d="m 445.4,170.6 v -24.4 h 5.9 v 23 c 0,7.3 4,11.9 10.9,11.9 6.7,0 11.8,-4.9 11.8,-12.3 v -22.5 h 5.8 v 39.3 H 474 v -6.8 c -2.7,4.3 -6.7,7.7 -13.5,7.7 -9.5,-0.2 -15.1,-6.6 -15.1,-15.9 z"/>
	<path className="st0" d="m 497.2,178 v 7.5 h -5.9 V 130 h 5.9 v 24.1 c 3.2,-4.7 7.8,-8.7 15,-8.7 9.4,0 18.8,7.4 18.8,20.4 v 0.2 c 0,12.8 -9.3,20.4 -18.8,20.4 -7.3,-0.1 -12,-4 -15,-8.4 z M 525,166 v -0.2 c 0,-9.3 -6.4,-15.2 -13.8,-15.2 -7.3,0 -14.2,6.2 -14.2,15.1 v 0.2 c 0,9.1 6.9,15.2 14.2,15.2 7.5,0 13.8,-5.6 13.8,-15.1 z"/>
</g>
<g id="g44" transform="translate(-127.3,-91.9)">
	<path className="st0" d="M 206.4,115.8 195.9,92.2 h 5.1 l 6.4,14.3 c 1,-2.4 2.1,-4.8 3.2,-7.2 1.1,-2.4 2.1,-4.8 3.2,-7.1 h 5.1 l -10.4,23.6 c 0,0 -2.1,0 -2.1,0 z"/>
	<path className="st0" d="M 225.4,115.6 V 92.2 h 4.6 v 23.4 z"/>
	<path className="st0" d="m 245.2,115.8 c -1,0 -2,-0.1 -3,-0.4 -1,-0.3 -1.9,-0.7 -2.7,-1.2 v 0 c -0.6,-0.4 -1.1,-0.8 -1.6,-1.2 -0.5,-0.4 -0.8,-0.8 -1,-1 l 3.5,-3.2 c 0.2,0.2 0.4,0.4 0.8,0.7 0.3,0.3 0.7,0.5 1.2,0.8 0.4,0.3 0.9,0.5 1.4,0.7 0.5,0.2 1,0.3 1.4,0.3 0.4,0 0.8,0 1.1,-0.1 0.4,-0.1 0.7,-0.2 1,-0.4 0.3,-0.2 0.6,-0.4 0.8,-0.8 0.2,-0.3 0.3,-0.7 0.3,-1.2 0,-0.2 -0.1,-0.4 -0.2,-0.6 -0.1,-0.2 -0.3,-0.4 -0.6,-0.7 -0.3,-0.2 -0.7,-0.5 -1.3,-0.8 -0.5,-0.3 -1.2,-0.6 -2,-0.9 -0.9,-0.4 -1.8,-0.7 -2.6,-1.2 -0.8,-0.4 -1.5,-0.9 -2.1,-1.5 -0.6,-0.6 -1,-1.2 -1.4,-1.9 -0.3,-0.7 -0.5,-1.5 -0.5,-2.5 0,-1 0.2,-1.9 0.5,-2.7 0.4,-0.8 0.9,-1.6 1.5,-2.2 0.7,-0.6 1.4,-1.1 2.4,-1.4 0.9,-0.3 1.9,-0.5 3.1,-0.5 0.6,0 1.2,0.1 1.9,0.2 0.7,0.2 1.3,0.4 2,0.6 0.6,0.2 1.3,0.5 1.8,0.9 0.6,0.3 1,0.7 1.4,1 l -2.9,3.6 c -0.4,-0.3 -0.9,-0.6 -1.3,-0.8 -0.4,-0.2 -0.8,-0.4 -1.3,-0.6 -0.5,-0.2 -1,-0.3 -1.5,-0.3 -0.9,0 -1.7,0.2 -2.2,0.6 -0.5,0.4 -0.8,0.9 -0.8,1.6 0,0.3 0.1,0.6 0.3,0.9 0.2,0.3 0.4,0.5 0.8,0.7 0.3,0.2 0.7,0.4 1.1,0.6 0.4,0.2 0.9,0.3 1.3,0.5 1.3,0.5 2.5,1 3.4,1.6 0.9,0.5 1.6,1.1 2.2,1.7 0.6,0.6 1,1.2 1.2,1.9 0.2,0.7 0.4,1.4 0.4,2.2 0,1 -0.2,1.9 -0.6,2.8 -0.4,0.9 -1,1.6 -1.7,2.3 -0.7,0.6 -1.5,1.1 -2.5,1.5 -0.8,0.2 -1.9,0.4 -3,0.4 z"/>
	<path className="st0" d="M 260.1,115.6 V 92.2 h 4.6 v 23.4 z"/>
	<path className="st0" d="m 301.6,92 h 2 l 12.6,14 V 92.2 h 4.6 v 23.6 h -2.1 l -12.6,-14 v 13.8 h -4.6 V 92 Z"/>
	<g id="g40">
		<path className="st0" d="m 148.8,92.2 v 23.4 h -4.6 v -9.4 h -9.3 v 9.4 h -4.6 V 92.2 h 4.6 v 9.4 h 9.3 v -9.4 z"/>
		<path className="st0" d="M 184.8,115.6 V 92.2 h 4.6 v 23.4 z"/>
		<path className="st0" d="m 167.7,92 h -2 l -10.4,23.6 h 5.1 c 0.3,-0.6 6.3,-14.3 6.3,-14.3 l 6.4,14.3 h 5.1 z"/>
	</g>
	<path className="st0"
   d="m 294,99.1 c -0.7,-1.5 -1.6,-2.7 -2.7,-3.8 -1.1,-1.1 -2.4,-1.9 -3.8,-2.5 -1.4,-0.6 -2.9,-0.9 -4.4,-0.9 -1.7,0 -3.3,0.3 -4.8,1 -1.5,0.7 -2.7,1.5 -3.8,2.6 -1.1,1.1 -1.9,2.4 -2.5,3.8 -0.6,1.5 -0.9,3 -0.9,4.5 0,1.8 0.3,3.4 1,4.9 0.7,1.5 1.6,2.7 2.7,3.7 1.1,1 2.4,1.8 3.8,2.4 1.4,0.6 2.9,0.8 4.4,0.8 1.7,0 3.3,-0.3 4.8,-1 1.5,-0.7 2.7,-1.5 3.8,-2.7 1.1,-1.1 1.9,-2.4 2.5,-3.8 0.6,-1.4 0.9,-2.9 0.9,-4.5 0,-1.4 -0.3,-3 -1,-4.5 z m -4.1,7.7 c -0.4,0.9 -0.9,1.7 -1.6,2.3 -0.7,0.7 -1.4,1.2 -2.3,1.6 -0.9,0.4 -1.8,0.6 -2.8,0.6 -1,0 -2,-0.2 -2.9,-0.6 -0.9,-0.4 -1.7,-0.9 -2.4,-1.6 -0.7,-0.7 -1.2,-1.4 -1.6,-2.3 -0.4,-0.9 -0.6,-1.8 -0.6,-2.9 0,-1 0.2,-2 0.6,-2.9 0.4,-0.9 0.9,-1.7 1.6,-2.4 0.7,-0.7 1.4,-1.2 2.3,-1.6 0.9,-0.4 1.9,-0.6 2.9,-0.6 1,0 1.9,0.2 2.8,0.6 0.9,0.4 1.7,0.9 2.3,1.6 0.7,0.7 1.2,1.5 1.6,2.4 0.4,0.9 0.6,1.8 0.6,2.9 0.1,1 -0.1,2 -0.5,2.9 z"/>
</g>
</svg>
  );
}

StreamHubLogo.propTypes = propTypes;

export default StreamHubLogo;
