import PropTypes from "prop-types";
import React from "react";
import {Translate} from "react-localize-redux";
import {Button} from "reactstrap";
import AWModal from "@aviwest/ui-kit/dist/js/components/modal";

const propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

const RestartStreamhubModal = (props) => {
  const { onCancel, onConfirm } = props;
  return (
  <AWModal id="restartSH_modal"
           confirm={true}
           icon="refresh"
           open={true}
           title={<Translate id="genericLabel.RESTART_STREAMHUB.text"/>}>
      <div className="confirm-text">
        <Translate id="genericLabel.CONFIRM_RESTART_STREAMHUB.text"/>
        <div className="indicator"><Translate id="genericLabel.CONNECTED_FIELD_UNITS_WILL_BE_DISCONNECTED.text"/></div>
      </div>
      <div className="buttons">
        <Button id="restartSH_modal_cancelButton"
                className="btn-cancel"
                size="lg"
                onClick={onCancel}>
          <Translate id="genericLabel.NO.text"/>
        </Button>
        <Button id="restartSH_modal_confirmButton"
                color="primary"
                size="lg"
                onClick={onConfirm}>
          <Translate id="genericLabel.YES.text"/>
        </Button>
      </div>
    </AWModal>
  );
};

RestartStreamhubModal.propTypes = propTypes;

export default RestartStreamhubModal;