import React, {Component} from "react";

import Logger from "../../utils/logger";

const withLogger = (WrappedComponent) => {
  // ...and returns another component...
  return class extends Component {
    render() {
      return (
        <WrappedComponent awlogger={Logger} { ...this.props }/>
      );
    }
  };
};

export default withLogger;