import PropTypes from 'prop-types';
import React, {Component} from 'react';
import { Button, Input, Label } from 'reactstrap';
import {Translate} from "react-localize-redux";
import AWPopup, {AWPopupCol, AWPopupRow} from "@aviwest/ui-kit/dist/js/components/popup";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";


export const hasAtLeastOneFilter = (filters) => {
  return filters.info === false ||
    filters.warning === false ||
    filters.error === false;
};

const filtersShape = PropTypes.shape({
  info: PropTypes.bool,
  warning: PropTypes.bool,
  error: PropTypes.bool
});

const propTypes = {
  onChange: PropTypes.func.isRequired,
  filters: filtersShape.isRequired
};

class LogFilters extends Component {
  constructor(props){
    super(props);

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleClearFilters = this.handleClearFilters.bind(this);

    this.state = {
      filters: props.filters,
      hasAtLeastOneFilter: hasAtLeastOneFilter(props.filters)
    }
  }

  componentDidUpdate(newProps){
    const { filters } = this.state;
    if(newProps.filters.info !== filters.info ||
      newProps.filters.warning !== filters.warning ||
      newProps.filters.error !== filters.error){
      this.setState({
        filters: newProps.filters,
        hasAtLeastOneFilter: hasAtLeastOneFilter(newProps.filters)
      });
    }
  }

  handleCheckboxChange(name, checked) {
    const state = { ...this.state };
    state.filters[name] = checked;
    state.hasAtLeastOneFilter = hasAtLeastOneFilter(state.filters);
    this.setState(state);
    this.props.onChange({ ...state.filters });
  }

  handleClearFilters(){
    const newState = {
      hasAtLeastOneFilter: false,
      filters: {
        info: true,
        warning: true,
        error: true
      }
    };
    this.setState(newState);
    this.props.onChange(newState.filters);
  }

  render(){
    const { filters, hasAtLeastOneFilter } = this.state;
    const {
      info,
      warning,
      error,
    } = filters;
    return (
      <AWPopup arrow={true}
               direction="bottom"
               portal={true}
               shadow={true}
               renderTrigger={({ onClick, ref, className }) => (
               <Button className={`basic ${className}`}
                       innerRef={ref}
                       onClick={onClick}>
                 <AWIcon name="filter"
                         className={hasAtLeastOneFilter ? 'primary' : undefined}/>
               </Button>
             )}>
        {() => (
          <div className="log-filters">
            <div className="aw-popup-header">
              <div className="title">
                <Translate id="genericLabel.FILTERS.text"/>
              </div>
              <Button className="basic action"
                      onClick={this.handleClearFilters}>
                <Translate id="genericLabel.FILTERS_ALL.text"/>
              </Button>
            </div>
            <AWPopupRow>
              <AWPopupCol>
                { info !== undefined &&
                <div>
                  <Label className="label info"
                         check>
                    <Input checked={info}
                           onChange={(e) => this.handleCheckboxChange('info', e.target.checked)}
                           type="checkbox"
                    />
                    {' '}
                    <Translate id="genericLabel.INFO.text"/>
                  </Label>
                </div>
                }
                { warning !== undefined &&
                <div>
                  <Label className="label warning"
                         check>
                    <Input checked={warning}
                           onChange={(e) => this.handleCheckboxChange('warning', e.target.checked)}
                           type="checkbox"
                    />
                    {' '}
                    <Translate id="genericLabel.WARNING.text"/>
                  </Label>
                </div>
                }
                { error !== undefined &&
                <div>
                  <Label className="label error"
                         check>
                    <Input checked={error}
                           onChange={(e) => this.handleCheckboxChange('error', e.target.checked)}
                           type="checkbox"
                    />
                    {' '}
                    <Translate id="genericLabel.ERROR.text"/>
                  </Label>
                </div>
                }
              </AWPopupCol>
            </AWPopupRow>
          </div>
        )}
      </AWPopup>
    );
  }
}

LogFilters.propTypes = propTypes;

export default LogFilters;

export const filtersPropTypes = filtersShape;