import {Redirect, Route, withRouter} from "react-router";
import React from "react";
import {connect} from "react-redux";
import DefaultLayout from "../layouts/default-layout";

const ProtectedRoute = ({ authenticated, component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    authenticated === true
      ? <DefaultLayout {...props}><Component {...props} /></DefaultLayout>
      : <Redirect to={{
        pathname: '/login',
        state: { from: props.location }
      }} />
  )} />
);

const mapStateToProps = (state) => {
  const { login } = state;
  return {
    authenticated: login.authenticated
  };
};

export default withRouter(connect(mapStateToProps)(ProtectedRoute))