import PropTypes from 'prop-types';
import React from 'react';
import {Translate, withLocalize} from 'react-localize-redux';
import { Alert, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import {Formik} from "formik";

import { isEmptyString, isPasswordValid } from '../../../../utils/string-utils';
import {USER_ROLE_ADMIN, USER_ROLE_USER, USER_ROLE_VIEWER} from "../../../../constants";
import PasswordRevealInput from "../../../common/password-reveal-input";
import { Fragment } from 'react';

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  role: PropTypes.oneOf([USER_ROLE_USER, USER_ROLE_ADMIN, USER_ROLE_VIEWER]),
  enable: PropTypes.bool,
  sessionTimeout: PropTypes.number,
  hasPrivilege: PropTypes.bool,
};

const loginValues = {
  [USER_ROLE_ADMIN]: 'admin',
  [USER_ROLE_USER]: 'aviwest',
  [USER_ROLE_VIEWER]: 'viewer'
}

const PasswordForm = (props) => {

  const { role, translate, onSubmit, enable, sessionTimeout, sessionsCount, hasPrivilege } = props;

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    let data = values;
    data.role = role;
    if(data.role !== USER_ROLE_ADMIN){
      delete data.oldPassword;
    }
    onSubmit(data);
    resetForm(values);
  };

  const handleValidation = (values) => {
    const errors = {};
    if(role === USER_ROLE_ADMIN && isEmptyString(values.oldPassword)){
      errors.oldPassword = 'genericLabel.REQUIRED_FIELD.text';
    }
    if (!role || role === USER_ROLE_ADMIN || values.enable) {
      if(!isEmptyString(values.newPassword) && !isPasswordValid(values.newPassword)){
        errors.newPassword = 'genericLabel.INVALID_FORMAT.text';
      }
      if(values.newPassword !== values.newPasswordConfirmation){
        errors.newPasswordConfirmation = 'genericLabel.PASSWORD_MISMATCH.text';
      }
    }
    return errors;
  };
  
  return (
    <Fragment>
      { role && sessionsCount > 1 &&
      <Alert color="warning">
        <Translate id="genericLabel.CONNECTED_USERS_WILL_BE_DISCONNECTED.text"/>
      </Alert>
      }
      <Formik initialValues={{
                oldPassword: '',
                newPassword: '',
                newPasswordConfirmation: '',
                enable: enable ? enable : false,
                sessionTimeout: sessionTimeout ? sessionTimeout : undefined,
                hasPrivilege: hasPrivilege ? hasPrivilege : false,
              }}
              validate={ handleValidation }
              validateOnBlur={false}
              validateOnChange={true}
              onSubmit={ handleSubmit }>
        {({
            values,
            errors,
            dirty,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
          <Form className="password-form"
                onSubmit={handleSubmit}>

            <FormGroup>
              <Label for="login">
                <Translate id="genericLabel.LOGIN.text"/>
              </Label>
              <Input required
                id={role? "security_"+role+"_login" : "security_samba_login"}
                type="text"
                name="login"
                value={loginValues[role] || 'user'}
                onBlur={handleBlur}
                onChange={handleChange}
                readOnly
                disabled/>
            </FormGroup>

            { role === USER_ROLE_ADMIN &&
            <FormGroup>
              <Label for="oldPassword">
                <Translate id="genericLabel.PASSWORD.text"/>
              </Label>
              <PasswordRevealInput required
                                  name="oldPassword"
                                  id="security_ADMIN_oldPassword"
                                  invalid={errors.oldPassword !== undefined}
                                  placeholder={translate("genericLabel.PASSWORD.text")}
                                  value={values.oldPassword}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  error={errors.oldPassword}/>
            </FormGroup>
            }

            { role && role !== USER_ROLE_ADMIN &&
            <FormGroup check>
              <Label check>
                <Input type="checkbox"
                      name="enable"
                      id={"security_"+role+"_login"}
                      onChange={handleChange}
                      checked={values.enable}/>{' '}
                <Translate id="genericLabel.ENABLE_ACCOUNT.text"/>
              </Label>
            </FormGroup>
            }
            
            { role === USER_ROLE_USER && role !== USER_ROLE_ADMIN && values.enable &&
              <FormGroup check>
                <Label check>
                  <Input type="checkbox"
                        name="hasPrivilege"
                        id={"security_"+role+"_hasPrivilege"}
                        onChange={handleChange}
                        checked={values.hasPrivilege}/>{' '}
                  Enable control of profiles
                </Label>
              </FormGroup>
              }

            { (role === USER_ROLE_ADMIN || values.enable) &&
            <FormGroup>
              <Label for="sessionTimeout">
                <Translate id="genericLabel.SESSION_TIMEOUT.text"/>
              </Label>
              <Input type="select"
                    name="sessionTimeout"
                    id="security_ADMIN_sessionTimeout"
                    value={values.sessionTimeout}
                    onChange={handleChange}>
                <option value="">{translate("genericLabel.NEVER.text")}</option>
                <option value={60000}>1 {translate("genericLabel.MINUTE.text")}</option>
                <option value={120000}>2 {translate("genericLabel.MINUTES.text")}</option>
                <option value={300000}>5 {translate("genericLabel.MINUTES.text")}</option>
                <option value={600000}>10 {translate("genericLabel.MINUTES.text")}</option>
                <option value={1800000}>30 {translate("genericLabel.MINUTES.text")}</option>
                <option value={3600000}>1 {translate("genericLabel.HOUR.text")}</option>
                <option value={14400000}>4 {translate("genericLabel.HOURS.text")}</option>
                <option value={43200000}>12 {translate("genericLabel.HOURS.text")}</option>
                <option value={86400000}>24 {translate("genericLabel.HOURS.text")}</option>
              </Input>
            </FormGroup>
            }

            { (!role || role === USER_ROLE_ADMIN || values.enable) &&
            <FormGroup>
              <Label for="newPassword">
                <Translate id="genericLabel.NEW_PASSWORD.text"/>
              </Label>
              <PasswordRevealInput
                                  name="newPassword"
                                  id={role? "security_"+role+"_newPassword" : "security_samba_newPassword"}
                                  invalid={errors.newPassword !== undefined}
                                  placeholder={translate("genericLabel.NEW_PASSWORD.text")}
                                  value={values.newPassword}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  error={errors.newPassword}/>
              <div className="indicator">
                <Translate id="genericLabel.PASSWORD_HELP.text"/>
              </div>
            </FormGroup>
            }

            { (!role || role === USER_ROLE_ADMIN || values.enable) &&
            <FormGroup>
              <Label for="newPasswordConfirmation">
                <Translate id="genericLabel.CONFIRM_PASSWORD.text"/>
              </Label>
              <PasswordRevealInput
                                  name="newPasswordConfirmation"
                                  id={role? "security_"+role+"_newPasswordConfirmation" : "security_samba_newPasswordConfirmation"}
                                  invalid={errors.newPasswordConfirmation !== undefined}
                                  placeholder={translate("genericLabel.CONFIRM_PASSWORD.text")}
                                  value={values.newPasswordConfirmation}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  error={errors.newPasswordConfirmation}/>
            </FormGroup>
            }

            <FormGroup className="buttons">
              <Button id={role? "security_"+role+"_updateButton" : "security_samba_updateButton"}
                      color="primary"
                      disabled={isSubmitting || !dirty}
                      type="submit">
                <Translate id="genericLabel.UPDATE.text" />
              </Button>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </Fragment>
  )
};

PasswordForm.propTypes = propTypes;

export default withLocalize(PasswordForm);