import {
  FILES_UPLOAD_DIRECTORY_CONTENT,
  UPDATE_FILES_UPLOAD_DIRECTORY_CONTENT,
  RENAME_STUDIO_FILE, FILES_DELETE_FILE, FILES_PREVIEW_VIDEO_FILE, FILES_LAYOUT_CHANGE
} from '../../../constants';

export const changeFilesLayout = (layout) => {
  return {
    type: FILES_LAYOUT_CHANGE,
    layout
  }
};

export const uploadDirectoryContent = (path) => {
  return {
    type: FILES_UPLOAD_DIRECTORY_CONTENT,
    path: path
  }
};

export const updateUploadDirectoryContent = (data) => {
  return {
    type: UPDATE_FILES_UPLOAD_DIRECTORY_CONTENT,
    files: data
  }
};

export const renameStudioFile = (oldPath, newPath) => {
  return {
    type: RENAME_STUDIO_FILE,
    oldPath: oldPath,
    newPath: newPath
  }
};

export const deleteStudioFiles = (items) => {
  return {
    type: FILES_DELETE_FILE,
    items
  }
};

export const previewVideoFile = (item) => {
  return {
    type: FILES_PREVIEW_VIDEO_FILE,
    item
  }
};
