import {
  BANNER_DISMISS_MESSAGE,
  BANNER_QUEUE_MESSAGE
} from '../constants';

import {presentBanner, dismissBanner, overrideBanner} from "./banner.actions";

import Logger from '../utils/logger';

export default (store) => (next) => (action) => {
  next(action);
  if(action.type === BANNER_QUEUE_MESSAGE || action.type === BANNER_DISMISS_MESSAGE){
      setTimeout(() => {
        Logger.info('[BannerMiddleware] Checking queue');
        const { banner } = store.getState();
        const presentedBanner = banner.presentedBanner;
        const nextBanner = banner.queue
            .sort((banner1, banner2) => banner1.priority < banner2.priority ? 1 : -1)[0];
        if(presentedBanner && nextBanner && presentedBanner.priority < nextBanner.priority){
          Logger.info('[BannerMiddleware] Overriding with banner: ', nextBanner);
          if (presentedBanner.priority === 0) {
            store.dispatch(dismissBanner(presentedBanner.id));
          }
          store.dispatch(overrideBanner(nextBanner.id));
          if(nextBanner.autoDismissAfter){
            Logger.info('[BannerMiddleware] Should hide banner after ', nextBanner.autoDismissAfter);
            setTimeout(() => {
              Logger.info('[BannerMiddleware] Hiding banner ', nextBanner);
              store.dispatch(dismissBanner(nextBanner.id));
            }, nextBanner.autoDismissAfter);
          }
        }
        else if(nextBanner && !presentedBanner){
          Logger.info('[BannerMiddleware] Showing banner ', nextBanner);
          store.dispatch(presentBanner(nextBanner.id));
          if(nextBanner.autoDismissAfter){
            Logger.info('[BannerMiddleware] Should hide banner after ', nextBanner.autoDismissAfter);
            setTimeout(() => {
              Logger.info('[BannerMiddleware] Hiding banner ', nextBanner);
              store.dispatch(dismissBanner(nextBanner.id));
            }, nextBanner.autoDismissAfter);
          }
        }
      }, 100);
  }
}