import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Translate} from "react-localize-redux";
import AWCircularProgress from "@aviwest/ui-kit/dist/js/components/circular-progress";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";
import {RECORD_STATUS_ON} from '../../../../../constants';

const propTypes = {
  deviceRecordStatus: PropTypes.string.isRequired,
  deviceForwardInfo: PropTypes.shape({
    progress: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    fileListCount: PropTypes.number.isRequired
  })
};

class ForwardInfo extends Component {
  render(){
    const { deviceForwardInfo, deviceRecordStatus } = this.props;
    if(!deviceForwardInfo){
      return null;
    }
    return (
        <div className="forward-info">
          { deviceRecordStatus === RECORD_STATUS_ON &&
          <div className="forward-info-status">
            <AWIcon name="sd_card"/>
          </div>
          }
          <AWCircularProgress progress={deviceForwardInfo.progress} size={70} strokeWidth={5}/>
          <div>{ deviceForwardInfo.fileListCount } <Translate id={`genericLabel.${deviceForwardInfo.fileListCount > 1 ? 'FILES' : 'FILE'}.text`}/></div>
        </div>
    );
  }
}

ForwardInfo.propTypes = propTypes;
export default ForwardInfo;