import PropTypes from "prop-types";
import React from 'react';
import { connect } from 'react-redux';
import {Translate} from "react-localize-redux";

import Form from "./form";
import {updateIntercomSettings, updateIntercomInputSettings, updateIntercomOutputSettings, getaes67SourcesList} from "./intercom.settings.actions";
import {Link} from "react-router-dom";
import { Nav, NavItem,NavLink } from "reactstrap";
import {Redirect, Route, Switch, withRouter} from "react-router";

import FormInputRouting from "./form-inputRouting";
import FormOutputRouting from "./form-outputRouting";
import AES67FormSetting from "./form-aes67"

const propTypes = {
  intercomConfig: PropTypes.object,
  inputAudioDevices: PropTypes.array,
  outputAudioDevices: PropTypes.array,
  forbiddenUDPPorts: PropTypes.arrayOf(PropTypes.number).isRequired,
  inputs: PropTypes.array,
  config: PropTypes.object
};

const IntercomSettings = (props) => {

    const {
      location,
      intercomConfig,
      inputAudioDevices,
      inputAudioDeviceSelected,
      outputAudioDevices,
      outputAudioDeviceSelected,
      forbiddenUDPPorts,
      inputs,
      config,
      networkInterfaces,

      updateIntercomSettings,
      updateIntercomInputSettings,
      updateIntercomOutputSettings,
      getaes67SourcesList,
      aes67SourcesList,
      hasAES67
    } = props;
    const hasPath = (path) => {
      return location.pathname.includes(path);
    };
    return (
      <div className="settings-section">
          <div className="header">
            <div className="title">
            <Translate id="genericLabel.INTERCOM.text"/>
            </div>
          </div>
          <Nav tabs>
        <NavItem>
          <NavLink id="intercom_general"
                   active={hasPath('/general')}
                   className="aw-nav-link"
                   tag={Link}
                   to="/settings/intercom/general">
            <Translate id="genericLabel.GENERAL.text"/>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink id="intercom_inputRouting"
                   active={hasPath('/inputRouting')}
                   className="aw-nav-link"
                   tag={Link}
                   to="/settings/intercom/inputRouting"
                   onClick={()=>getaes67SourcesList()}>
            Input Routing
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink id="intercom_outputRouting"
                   active={hasPath('/outputRouting')}
                   className="aw-nav-link"
                   tag={Link}
                   to="/settings/intercom/outputRouting">
            Output Routing
          </NavLink>
        </NavItem>
        { false &&
          <NavItem>
          <NavLink id="intercom_aes67"
                   active={hasPath('/aes67')}
                   className="aw-nav-link"
                   tag={Link}
                   to="/settings/intercom/aes67">
            AES67
          </NavLink>
        </NavItem>}
      </Nav>
      <div className="content pretty-scroll">
     <Switch>
          <Route exact path="/settings/intercom/general">
                {intercomConfig &&
                    <Form config={intercomConfig}
                          forbiddenUDPPorts={forbiddenUDPPorts}
                          inputAudioDevices={inputAudioDevices}
                          outputAudioDevices={outputAudioDevices}
                          networkInterfaces={networkInterfaces}
                          onSubmit={updateIntercomSettings}
                          hasAES67={hasAES67}/>}
          </Route>
          <Route exact path="/settings/intercom/inputRouting">
                   <FormInputRouting intercomConfig={intercomConfig}
                          inputAudioDevices={inputAudioDevices}
                          inputAudioDeviceSelected={inputAudioDeviceSelected}
                          inputs={inputs}
                          config={config}
                          onSubmit={updateIntercomInputSettings}
                          onRefreshAES67Sources={getaes67SourcesList}
                          aes67SourcesList={aes67SourcesList}/>
          </Route>       
          <Route exact path="/settings/intercom/outputRouting">
                   <FormOutputRouting intercomConfig={intercomConfig}
                          outputAudioDevices={outputAudioDevices}
                          outputAudioDeviceSelected={outputAudioDeviceSelected}
                          inputs={inputs}
                          config={config}
                          onSubmit={updateIntercomOutputSettings}/>
          </Route>
          <Route exact path="/settings/intercom/aes67">
                    <AES67FormSetting />
          </Route>     
            <Redirect from="/settings/intercom*" to="/settings/intercom/general"/>
      </Switch> 
      </div>
        </div>
    );
  }

IntercomSettings.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  const { inputAudioDevices, outputAudioDevices } = state.streamhub;
  const forbiddenUDPPorts = state.streamhub.udpUsedPort.atpGeneralBasePorts.concat(
    state.streamhub.udpUsedPort.LiveGuestPorts,
    state.streamhub.udpUsedPort.inputProtocolPorts,
    state.streamhub.udpUsedPort.channelPorts,
    state.streamhub.udpUsedPort.remoteControlPorts);
  return {
    intercomConfig:state.config ? state.config.intercom : null,
    forbiddenUDPPorts,
    inputAudioDevices,
    outputAudioDevices,
    inputAudioDeviceSelected: state.streamhub.inputAudioDeviceSelected,
    outputAudioDeviceSelected: state.streamhub.outputAudioDeviceSelected,
    inputs: state.streamhub.inputs,
    networkInterfaces :state.streamhub.networkInterface, 
    config : state.config,
    aes67SourcesList: state.datastore.aes67SourcesList, 
    hasAES67: state.license.details.AES67,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateIntercomSettings: (data) => dispatch(updateIntercomSettings(data)),
    updateIntercomInputSettings: (data) => dispatch(updateIntercomInputSettings(data)),
    updateIntercomOutputSettings: (data) => dispatch(updateIntercomOutputSettings(data)),
    getaes67SourcesList: () => dispatch(getaes67SourcesList())
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IntercomSettings));