import {
  RECEIVED_NDI_STREAMS_CHANGE,
  RECEIVED_AES67_SOURCES_CHANGE,
  RECEIVED_STREAM_STATS,
  RECEIVED_MONITOR_INFO,
  RECEIVED_TRANSCODER_STATUS,
  RECEIVED_CHANNEL_STATUS,
  RECEIVED_OUTPUT_STATUS,
  RECEIVED_OUTPUT_STATS,
  RECEIVED_INPUT_INTERCOM_INFO,
  RECEIVED_INPUT_INTERCOM_VOLUME,
  RECEIVED_ENCODERS_PREVIEW
} from '../constants';

const initialState = {
  ndiStreams: [],
  inputStreamStats: {},
  encoderStreamStats: {},
  hardwareOutputStreamStats: {},
  ipOutputStreamStats: {},
  inputPlaybackStats: {},
  inputIntercomInfo: {},
  monitorStreamStats: {},
  servicesMonitorStats: [],
  aes67SourcesList: [],
};

// We simply don't use 'switch' because of scope variable name collision problems

const datastoreReducer = (state = initialState, action) => {

  if (action.type === RECEIVED_NDI_STREAMS_CHANGE) {
    const { ndiStreams } = action.data;
    return {
      ...state,
      ndiStreams: ndiStreams ? Object.values(ndiStreams).map(stream => stream.name) : []
    };
  }

  if (action.type === RECEIVED_AES67_SOURCES_CHANGE) {
    const { sources } = action.data;
     return {
      ...state,
      aes67SourcesList: sources ? Object.values(sources).map(stream => stream.name) : []
    }; 
  }

  if (action.type === RECEIVED_ENCODERS_PREVIEW){
    const { encoderId, audioBitrate, videoBitrate } = action.data;
    const encoderStreamStats = state.encoderStreamStats;
    if(audioBitrate && videoBitrate){
      const timestamp = new Date().getTime();
      let streamStatsForKey = {...encoderStreamStats[encoderId]};
      if (!streamStatsForKey) {
        streamStatsForKey = {};
      }

      // Stream (cumul)
      if (!streamStatsForKey.data) {
        streamStatsForKey.data = [];
        // Insert null values to avoid resizing graph until it reachs 250 values
        streamStatsForKey.data = [
          {hidden: true, timestamp: timestamp - 250000, totalBitrate: 0, videoBitrate: 0, audioBitrate: 0},
          {timestamp: timestamp, totalBitrate: 0, videoBitrate: 0, audioBitrate: 0}
        ];
      }
      // Keeping only the last 250 values
      if (streamStatsForKey.data.length >= 250) {
        streamStatsForKey.data.shift();
      }
      else {
        // Update null value to avoid graph shifting (Bugs #14878)
        streamStatsForKey.data[0].timestamp = streamStatsForKey.data[0].timestamp + 1000
      }

      // Adding new data
      const newData = {
        timestamp
      };

      // Handling data
      newData.videoBitrate = videoBitrate * 1000; //Bitrate received in kb -> transform to b
      newData.audioBitrate = audioBitrate * 1000;
      newData.totalBitrate = newData.videoBitrate + newData.audioBitrate; //Bitrate received in kb -> transform to b,
      streamStatsForKey.data.push(newData);
      if(streamStatsForKey.maxTotalBitrate == null || streamStatsForKey.maxTotalBitrate < newData.totalBitrate) {
        streamStatsForKey.maxTotalBitrate = newData.totalBitrate;
      }
      if(streamStatsForKey.maxVideoBitrate == null || streamStatsForKey.maxVideoBitrate < newData.videoBitrate) {
        streamStatsForKey.maxVideoBitrate = newData.videoBitrate;
      }
      if(streamStatsForKey.maxAudioBitrate == null || streamStatsForKey.maxAudioBitrate < newData.audioBitrate) {
        streamStatsForKey.maxAudioBitrate = newData.audioBitrate;
      }

      encoderStreamStats[encoderId] = streamStatsForKey;
    }

    return {
      ...state,
      encoderStreamStats
    };
  }

  if (action.type === RECEIVED_STREAM_STATS) {
    const inputStreamStats = state.inputStreamStats;
    const inputPlaybackStats = state.inputPlaybackStats;
    const timestamp = new Date().getTime();
    Object.keys(action.data).forEach(key => {
      let streamStatsForKey = {...inputStreamStats[key]};
      let playbackStatsForKey = {...inputPlaybackStats[key]};
      const data = action.data[key];
      if (!streamStatsForKey) {
        streamStatsForKey = {};
      }
      if(!playbackStatsForKey){
        playbackStatsForKey = {};
      }
      // Stream (cumul)
      if (!streamStatsForKey.data) {
        // Insert null values to avoid resizing graph until it reachs 250 values
        streamStatsForKey.data = [
          {hidden: true, timestamp: timestamp - 250000, totalBitrate: 0, videoBitrate: 0, totalVideoLostPackets: 0, videoLostPackets: 0, audioBitrate: 0, totalAudioLostPackets: 0, audioLostPackets: 0, totalRxBitrate: 0, totalTxBitrate: 0, totalRtt: 0, totalLostPackets: 0},
          {timestamp: timestamp, totalBitrate: 0, videoBitrate: 0, totalVideoLostPackets: 0, videoLostPackets: 0, audioBitrate: 0, totalAudioLostPackets: 0, audioLostPackets: 0, totalRxBitrate: 0, totalTxBitrate: 0, totalRtt: 0, totalLostPackets: 0}
        ];
      }
      // Keeping only the last 250 values
      if (streamStatsForKey.data.length >= 250) {
        streamStatsForKey.data.shift();
      }
      else {
        // Update null value to avoid graph shifting (Bugs #14878)
        streamStatsForKey.data[0].timestamp = streamStatsForKey.data[0].timestamp + 1000
      }

      // Adding new data
      const newData = {
        timestamp
      };

      if(data.video && data.audio){
        newData.totalBitrate = (data.video[0].rx_bitrate + data.audio[0].rx_bitrate) * 1000; //Bitrate received in kb -> transform to b,
        newData.videoBitrate = data.video[0].rx_bitrate * 1000; //Bitrate received in kb -> transform to b
        newData.videoLostPackets = data.video[0].cur_rx_lost_packets;
        newData.totalVideoLostPackets = data.video[0].tot_rx_lost_packets;
        newData.audioBitrate = data.audio[0].rx_bitrate * 1000; //Bitrate received in kb -> transform to b
        newData.audioLostPackets = data.audio[0].cur_rx_lost_packets;
        newData.totalAudioLostPackets = data.audio[0].tot_rx_lost_packets;
        streamStatsForKey.mpegtsUpMode = false;

        // Check for undefined results
        newData.totalBitrate = newData.totalBitrate ? newData.totalBitrate : 0;
        newData.videoBitrate = newData.videoBitrate ? newData.videoBitrate : 0;
        newData.videoLostPackets = newData.videoLostPackets ? newData.videoLostPackets : 0;
        newData.totalVideoLostPackets = newData.totalVideoLostPackets ? newData.totalVideoLostPackets : 0;
        newData.audioBitrate = newData.audioBitrate ? newData.audioBitrate : 0;
        newData.audioLostPackets = newData.audioLostPackets ? newData.audioLostPackets : 0;
        newData.totalAudioLostPackets = newData.totalAudioLostPackets ? newData.totalAudioLostPackets : 0;
      }
      else if (data.mpegtsUp) {
        newData.totalBitrate = data.mpegtsUp[0].rx_bitrate * 1000; //Bitrate received in kb -> transform to b,
        newData.videoBitrate = data.mpegtsUp[0].rx_bitrate * 1000; //Bitrate received in kb -> transform to b
        newData.videoLostPackets = data.mpegtsUp[0].cur_rx_lost_packets;
        newData.totalVideoLostPackets =  data.mpegtsUp[0].tot_rx_lost_packets;
        newData.audioBitrate = 0
        newData.audioLostPackets = 0;
        newData.totalAudioLostPackets = 0;
        streamStatsForKey.mpegtsUpMode = true;
      }
      else {
        newData.totalBitrate = 0;
        newData.videoBitrate = 0;
        newData.videoLostPackets = 0;
        newData.totalVideoLostPackets = 0;
        newData.audioBitrate = 0;
        newData.audioLostPackets = 0;
        newData.totalAudioLostPackets = 0;
      }
      // Do not reset currentNetworkInterfaces to keep curves all the duration of the live (Task #16244)
      if (!streamStatsForKey.currentNetworkInterfaces) {
        streamStatsForKey.currentNetworkInterfaces = [];
        streamStatsForKey.maxRxBitrate = {};
        streamStatsForKey.maxTxBitrate = {};
      }
      if(data.link){
        let totalRxBitrate = 0;
        let totalTxBitrate = 0;
        let totalRtt = 0;
        let totalLostPackets = 0;
        data.link.forEach(link => {
          if (!streamStatsForKey.currentNetworkInterfaces.includes(link.name)) {
            streamStatsForKey.currentNetworkInterfaces.push(link.name)
          }
          newData[link.name] = { rxBitrate: link.rx_bitrate * 1000, txBitrate: link.tx_bitrate * 1000, lostPacketsCumul: link.rx_lost_nb_packets, lostPackets: link.rx_lost_nb_packets_current, rtt: link.rtt > 0 ? link.rtt : 0 };
          totalRxBitrate += link.rx_bitrate * 1000;
          streamStatsForKey.maxRxBitrate[link.name] = !streamStatsForKey.maxRxBitrate[link.name] || link.rx_bitrate * 1000 > streamStatsForKey.maxRxBitrate[link.name] ? link.rx_bitrate * 1000 : streamStatsForKey.maxRxBitrate[link.name];
          totalTxBitrate += link.tx_bitrate * 1000;
          streamStatsForKey.maxTxBitrate[link.name] = !streamStatsForKey.maxTxBitrate[link.name] || link.tx_bitrate * 1000 > streamStatsForKey.maxTxBitrate[link.name] ? link.tx_bitrate * 1000 : streamStatsForKey.maxTxBitrate[link.name];
          totalRtt += link.rtt > 0 ? link.rtt : 0;
          totalLostPackets += link.rx_lost_nb_packets_current;
        });
        newData.totalRxBitrate = totalRxBitrate;
        newData.totalTxBitrate = totalTxBitrate;
        newData.totalRtt = totalRtt;
        newData.totalLostPackets = totalLostPackets;
      }

      streamStatsForKey.data.push(newData);

      // Live duration
      streamStatsForKey.liveDurationInMinutes = null;
      if(data.elapsedMinutes !== undefined){
        streamStatsForKey.liveDurationInMinutes = data.elapsedMinutes;
      }
      if(data.elapsedHours !== undefined){
        streamStatsForKey.liveDurationInMinutes = (streamStatsForKey.liveDurationInMinutes === null) ? data.elapsedHours * 60 : streamStatsForKey.liveDurationInMinutes + data.elapsedHours * 60
      }

      // Keeping the max
      const maxObject = streamStatsForKey.data.reduce((prev, current) => {
        return {
          totalBitrate: prev.totalBitrate > current.totalBitrate ? prev.totalBitrate : current.totalBitrate,
          videoLostPackets: prev.videoLostPackets > current.videoLostPackets ? prev.videoLostPackets : current.videoLostPackets,
          audioLostPackets: prev.audioLostPackets > current.audioLostPackets ? prev.audioLostPackets : current.audioLostPackets,
          totalRxBitrate: prev.totalRxBitrate > current.totalRxBitrate ? prev.totalRxBitrate : current.totalRxBitrate,
          totalTxBitrate: prev.totalTxBitrate > current.totalTxBitrate ? prev.totalTxBitrate : current.totalTxBitrate,
          totalRtt: prev.totalRtt > current.totalRtt ? prev.totalRtt : current.totalRtt,
          totalLostPackets: prev.totalLostPackets > current.totalLostPackets ? prev.totalLostPackets : current.totalLostPackets,
        }
      });
      streamStatsForKey.maxTotalBitrate = maxObject.totalBitrate > 100 ? maxObject.totalBitrate : 100;
      streamStatsForKey.maxTotalRxBitrate = maxObject.totalRxBitrate;
      streamStatsForKey.maxTotalTxBitrate = maxObject.totalTxBitrate > 80000 ? maxObject.totalTxBitrate : 80000;
      streamStatsForKey.maxTotalRtt = maxObject.totalRtt > 20 ? maxObject.totalRtt : 20;
      streamStatsForKey.maxTotalLostPackets = maxObject.totalLostPackets > 5 ? maxObject.totalLostPackets : 5;
      streamStatsForKey.maxVideoLoss = maxObject.videoLostPackets > 10 ? maxObject.videoLostPackets : 10;
      streamStatsForKey.maxAudioLoss = maxObject.audioLostPackets > 10 ? maxObject.audioLostPackets : 10;
      if(data.filePosition!==undefined){
        playbackStatsForKey.filePosition = data.filePosition;
        playbackStatsForKey.fileDuration = data.fileDuration;
      }

      inputStreamStats[key] = streamStatsForKey;
      inputPlaybackStats[key] = playbackStatsForKey;
    });
    return {
      ...state,
      inputStreamStats,
      inputPlaybackStats
    };
  }

  if (action.type === RECEIVED_CHANNEL_STATUS){
    // Clear stats (Bugs #15613 && Bugs #15906)
    const inputStreamStats = state.inputStreamStats;
    const inputPlaybackStats = state.inputPlaybackStats;
    if (action.data.channel) {
      action.data.channel.forEach((channel, index) => {
        if (channel.connectionStatus !== 1) {
          inputStreamStats[index + 1] = null
          inputPlaybackStats[index + 1] = null
        }
      })
    }

    return {
      ...state,
      inputStreamStats,
      system: {
        ...state.system,
        storageCapacity: action.data.storageCapacity,
        storagePercent: action.data.storageLevel
      }
    }
  }

  if (action.type === RECEIVED_OUTPUT_STATUS){
    // Clear stats (Bugs #15906)
    const ipOutputStreamStats = state.ipOutputStreamStats;
    action.data.IPOutput.forEach((IPOutput) => {
      if (IPOutput.status !== 2) {
        ipOutputStreamStats[IPOutput.configKey] = null
      }
    })

    return {
      ...state,
      ipOutputStreamStats,
    }
  }

  if (action.type === RECEIVED_OUTPUT_STATS) {
    const hardwareOutputStreamStats = state.hardwareOutputStreamStats;
    const ipOutputStreamStats = state.ipOutputStreamStats;
    const timestamp = new Date().getTime();
    const { IPOutput, output } = action;
    if(output && output.length > 0){
      output.filter(data => data.bitrate !== undefined)
        .forEach(data => {
          let statsForKey = {...hardwareOutputStreamStats[data.outputId]};
          if (!statsForKey) {
            statsForKey = {};
          }
          // Stream (cumul)
          if (!statsForKey.data) {
            statsForKey.data = [];
          }
          // Keeping only the last 250 values
          if (statsForKey.data.length >= 250) {
            statsForKey.data.shift();
          }

          // Preparing packet loss
          if (!statsForKey.videoTotalLoss) {
            statsForKey.videoTotalLoss = data.outVideoPktDrop;
          }
          if (!statsForKey.audioTotalLoss) {
            statsForKey.audioTotalLoss = data.outAudioPktDrop;
          }

          // Adding new data
          statsForKey.data.push({
            timestamp,
            totalBitrate: data.bitrate * 1000, //Bitrate received in kb -> transform to b
            videoLostPackets: data.outVideoPktDrop - statsForKey.videoTotalLoss,
            audioLostPackets: data.outAudioPktDrop - statsForKey.audioTotalLoss
          });
          statsForKey.videoTotalLoss = data.outVideoPktDrop;
          statsForKey.audioTotalLoss = data.outAudioPktDrop;

          // Keeping the max
          const maxObject = statsForKey.data.reduce((prev, current) => {
            const totalBitrate = prev.totalBitrate > current.totalBitrate ? prev.totalBitrate : current.totalBitrate;
            const videoLostPackets = prev.videoLostPackets > current.videoLostPackets ? prev.videoLostPackets : current.videoLostPackets;
            const audioLostPackets = prev.audioLostPackets > current.audioLostPackets ? prev.audioLostPackets : current.audioLostPackets;
            return {
              totalBitrate,
              videoLostPackets,
              audioLostPackets
            }
          });
          statsForKey.maxTotalBitrate = maxObject.totalBitrate > 100 ? maxObject.totalBitrate : 100;
          statsForKey.maxVideoLoss = maxObject.videoLostPackets > 5 ? maxObject.videoLostPackets : 5;
          statsForKey.maxAudioLoss = maxObject.audioLostPackets > 5 ? maxObject.audioLostPackets : 5;

          hardwareOutputStreamStats[data.configKey] = statsForKey;
        });
    }
    if(IPOutput){
      IPOutput.filter(data => data.bitrate !== undefined)
        .forEach(data => {
          let statsForKey = {...ipOutputStreamStats[data.configKey]};
          if (!statsForKey) {
            statsForKey = {};
          }
          // Stream (cumul)
          if (!statsForKey.data) {
            // Insert null values to avoid resizing graph until it reachs 250 values
            statsForKey.data = [
              {
                hidden: true,
                timestamp: timestamp - 250000,
                totalBitrate: 0,
                videoLostPackets: 0,
                audioLostPackets: 0
              },
              {
                timestamp: timestamp,
                totalBitrate: 0,
                videoLostPackets: 0,
                audioLostPackets: 0
              }
            ];
          }
          // Keeping only the last 250 values
          if (statsForKey.data.length >= 250) {
            statsForKey.data.shift();
          }
          else {
            // Update null value to avoid graph shifting (Bugs #14878)
            statsForKey.data[0].timestamp = statsForKey.data[0].timestamp + 1000
          }

          // Preparing packet loss
          if (!statsForKey.videoTotalLoss) {
            statsForKey.videoTotalLoss = data.outVideoPktDrop;
          }
          if (!statsForKey.audioTotalLoss) {
            statsForKey.audioTotalLoss = data.outAudioPktDrop;
          }

          // Adding new data
          statsForKey.data.push({
            timestamp,
            totalBitrate: data.bitrate * 1000, //Bitrate received in kb -> transform to b
            videoLostPackets: data.outVideoPktDrop - statsForKey.videoTotalLoss,
            audioLostPackets: data.outAudioPktDrop - statsForKey.audioTotalLoss
          });
          statsForKey.videoTotalLoss = data.outVideoPktDrop;
          statsForKey.audioTotalLoss = data.outAudioPktDrop;

          // Keeping the max
          const maxObject = statsForKey.data.reduce((prev, current) => {
            const totalBitrate = prev.totalBitrate > current.totalBitrate ? prev.totalBitrate : current.totalBitrate;
            const videoLostPackets = prev.videoLostPackets > current.videoLostPackets ? prev.videoLostPackets : current.videoLostPackets;
            const audioLostPackets = prev.audioLostPackets > current.audioLostPackets ? prev.audioLostPackets : current.audioLostPackets;
            return {
              totalBitrate,
              videoLostPackets,
              audioLostPackets
            }
          });
          statsForKey.maxTotalBitrate = maxObject.totalBitrate > 100 ? maxObject.totalBitrate : 100;
          statsForKey.maxVideoLoss = maxObject.videoLostPackets > 5 ? maxObject.videoLostPackets : 5;
          statsForKey.maxAudioLoss = maxObject.audioLostPackets > 5 ? maxObject.audioLostPackets : 5;

          ipOutputStreamStats[data.configKey] = statsForKey;
        });
    }
    return {
      ...state,
      hardwareOutputStreamStats,
      ipOutputStreamStats
    };
  }

  if (action.type === RECEIVED_MONITOR_INFO) {
    const timestamp = new Date().getTime();
    const servicesMonitorStats = state.servicesMonitorStats;
    if(action.services){
    Object.keys(action.services).forEach(id=>{
      let servicesStatsForkey = {...servicesMonitorStats[id]};
         if (!servicesStatsForkey.data) { 
          servicesStatsForkey.data = [];
          servicesStatsForkey.data = [//initialisation
          {timestamp: timestamp, cpu: 0, mem: 0, svcName : action.services[id].svcName ,threads: 0, type : action.services[id].type}
        ]; 
        }

         // Keeping only the last 500 values
        if (servicesStatsForkey.data.length >= 500) {
          servicesStatsForkey.data.shift();
        } 
            // Adding new data
        const newData = {
          timestamp
        };
      //handling data
        newData.cpu = action.services[id].cpu;
        newData.mem = action.services[id].mem;
        newData.threads = action.services[id].threads;
        newData.svcName =action.services[id].svcName;
        newData.type =action.services[id].type;

        //keeping the max
        const maxObject = servicesStatsForkey.data.reduce((prev, current) => {
          const maxCpu = prev.cpu > current.cpu ? prev.cpu : current.cpu;
          const maxMem = prev.mem > current.mem ? prev.mem : current.mem;
          return {
            maxCpu,
            maxMem,
          }
        });

        servicesStatsForkey.maxCpu = maxObject.maxCpu;
        servicesStatsForkey.maxMem = maxObject.maxMem;
        servicesStatsForkey.data.push(newData);
        servicesMonitorStats[id] = servicesStatsForkey;
      })
    }
    return {
      ...state,
      system: {
        ...state.system,
        servicesMonitorStats,
        cpuPercent: action.cpuPercent,
        memoryPercent: action.memPercent,
        memoryTotal: action.memTotal,
        interfaces: action.interfaces
      }
    }
  }

  if (action.type === RECEIVED_TRANSCODER_STATUS) {
    return {
      ...state,
      transcoderStatus: {
        ...state.transcoderStatus,
        status: action.status,
        queueSize: action.queueSize
      }
    }
  }

  if (action.type === RECEIVED_INPUT_INTERCOM_INFO || action.type === RECEIVED_INPUT_INTERCOM_VOLUME){
    const existingInputIntercomInfo = state.inputIntercomInfo;
    const newInputIntercomInfo = {};
    action.data.channels.forEach((channel, index) => {
      let inputId = `${index + 1}`;
      let existing = existingInputIntercomInfo[inputId];
      newInputIntercomInfo[inputId] = {
        ...existing,
        ...channel
      };
    });
    return {
      ...state,
      inputIntercomInfo: newInputIntercomInfo
    }
  }

  return state;
};

export default datastoreReducer;