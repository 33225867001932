import {
  LOGGED_IN,
  LOGGED_OUT,
  LOGIN,
  LOGIN_ERROR,
  LOGOUT,
  LOGOUT_ERROR
} from '../../../constants';

const initialState = {
  authenticated: false,
  error: null,
  loading: false,
};

const loginReducer = (state = initialState, action) => {
  switch(action.type){
    case LOGIN:
      return { ...state, loading: true, authenticated: false };
    case LOGGED_IN:
      return { ...state, loading: false, authenticated: true, error: null };
    case LOGIN_ERROR:
      return { ...state, loading: false, authenticated: false, error: action.error };
    case LOGOUT:
      return initialState;
    case LOGGED_OUT:
      return initialState;
    case LOGOUT_ERROR:
      return initialState;
    default:
      return state;
  }
};

export default loginReducer;