import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Translate} from "react-localize-redux";

const propTypes = {
  deviceForwardInfo: PropTypes.shape({
    progress: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired
  })
};

class ForwardDetail extends Component {
  render(){
    const { deviceForwardInfo } = this.props;
    if(!deviceForwardInfo){
      return null;
    }
    return (
      <div className="forward-detail">
        <div className="filename">{ deviceForwardInfo.name }</div>
        <div className="filesize">
          { deviceForwardInfo.message === '' &&
            <span>{ deviceForwardInfo.size }</span>
          }
          { deviceForwardInfo.message !== '' &&
            <Translate id={`forwardInfo.${deviceForwardInfo.message}.text`}/>
          }
        </div>
      </div>
    );
  }
}

ForwardDetail.propTypes = propTypes;
export default ForwardDetail;