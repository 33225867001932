import React from 'react';
import {Translate} from "react-localize-redux";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";
import {Button} from "reactstrap";
import AWPopup, {AWPopupCol, AWPopupRow} from "@aviwest/ui-kit/dist/js/components/popup";

import { inputPropTypes } from "../../../../../utils/models-prop-types";

const propTypes = {
  input: inputPropTypes.isRequired
};

const MetaData = (props) => {
  let { deviceMetaData } = props.input;

  if(!deviceMetaData){
    return null;
  }
  return (
    <AWPopup arrow={false}
           portal={true}
           shadow={true}
           direction="bottom"
           renderTrigger={({ ref, onClick, className }) => (
             <Button id="input_metadata_preview"
                     className={`basic ${className}`}
                     innerRef={ref}
                     onClick={onClick}
                     type="button">
               <AWIcon name="preview"/>
             </Button>
           )}>
      {({ close }) => (
      <div className="meta-data">
        <div className="aw-popup-header">
          <div className="title">
            <Translate id="genericLabel.METADATA.text"/>
          </div>
        </div>
        <AWPopupRow>
          <AWPopupCol>
            <div dangerouslySetInnerHTML={{__html: deviceMetaData}} />
          </AWPopupCol>
        </AWPopupRow>
      </div>
      )}
    </AWPopup>
  );
};

MetaData.propTypes = propTypes;

export default MetaData;