import {
  FILE_LAYOUT_INLINE, FILES_LAYOUT_CHANGE,
  FILES_PREVIEW_VIDEO_FILE,
  FILES_UPLOAD_DIRECTORY_CONTENT,
  UPDATE_FILES_UPLOAD_DIRECTORY_CONTENT,
} from '../../../constants';

const initialState = {
  path: '/data/media',
  layout: FILE_LAYOUT_INLINE,
  files: [],
  breadcrumbs: [],
  previewFile: null,
  loading: false
};

const filesReducer = (state = initialState, action) => {

  if(action.type === UPDATE_FILES_UPLOAD_DIRECTORY_CONTENT) {
    return {
      ...state,
      files: action.files.filter(file => !file.filename.startsWith('.')),
      loading: false
    };
  }

  if(action.type === FILES_UPLOAD_DIRECTORY_CONTENT) {
    let breadcrumbs = [{
      path: '/data/media',
      label: 'File explorer'
    }];
    let path = '';
    action.path.split('/').filter(el => el !== '').forEach((element) => {
      path = path + '/' + element;
      if (path !== '/data' && path !== '/data/media') {
        breadcrumbs.push({path: path, label: element});
      }
    });
    return {
      ...state,
      loading: true,
      path: action.path,
      breadcrumbs: breadcrumbs
    };
  }

  if(action.type === FILES_PREVIEW_VIDEO_FILE){
    return {
      ...state,
      previewFile:
      action.item
    };
  }

  if(action.type === FILES_LAYOUT_CHANGE){
    return {
      ...state,
      layout: action.layout
    };
  }

  return state;
};

export default filesReducer;