import {Formik} from "formik";
import PropTypes from "prop-types";
import React, {useEffect, useMemo, useState} from "react";
import {Button, Form, FormFeedback, FormGroup, Input, Label, Col, Alert} from "reactstrap";
import { Translate, withLocalize } from "react-localize-redux";

import { generateUniqueId, isEmptyString, isGroupValid } from "../../../../utils/string-utils";
import {DEJITTER_BUFFER_DEFAULT, WEBRTC} from "../../../../constants";
import AWConfirm from "@aviwest/ui-kit/dist/js/components/confirm";
import HelpLayout from "../../../common/help-layout";
import AWLoader from "@aviwest/ui-kit/dist/js/components/loader";
import AWDateInput from "@aviwest/ui-kit/dist/js/components/date-input";
import DayJS from "dayjs";
import PasswordRevealInput from "../../../common/password-reveal-input";
import { inputIpProfilePropTypes } from "../../../../utils/models-prop-types";

const propTypes = {
  config: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    uniqueId: PropTypes.string

  }),
  forbiddenNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  tmpProfilesLoading: PropTypes.object,
  liveGuestResolutions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  alert: PropTypes.node,
  step: PropTypes.number.isRequired,
  inputIPProfiles: PropTypes.arrayOf(inputIpProfilePropTypes).isRequired,
  protocol: PropTypes.node
};

const LiveGuestForm = (props) => {
  const { config, forbiddenNames, translate, tmpProfilesLoading, liveGuestResolutions, alert, inputIPProfiles, step, protocol, orionConnectionStatus } = props;
  const [submittingProfile, setSubmittingProfile] = useState(null);
  //const [step, setStep] = useState(1);
  const [profileSaved, setProfileSaved] = useState(null);

  const mailSubject = "[LiveGuest] Link to your call";
  const mailBody = useMemo(() => {
    if (submittingProfile && profileSaved)
    return `Hello ${submittingProfile.guestName}, %0D%0A%0D%0A
You have been invited to the event ${submittingProfile.hostName}.%0D%0A%0D%0A
You can click the link below to join this LiveGuest Call :%0D%0A%0D%0A
${profileSaved.uri}`;
  }, [submittingProfile, profileSaved]);

  useEffect(() => {
    if (submittingProfile != null && tmpProfilesLoading[submittingProfile.name]) {
      if(tmpProfilesLoading[submittingProfile.name].loading === false && tmpProfilesLoading[submittingProfile.name].errorCode == null){
        const profile = inputIPProfiles.find((el) => el.name === submittingProfile.name);
        if(profile) {
          //setSubmittingProfileName(null);
          //props.onCancel();
          setProfileSaved(profile);
        }
      }
    } else if (submittingProfile) {
      const profile = inputIPProfiles.find((el) => el.name === submittingProfile.name);
      if(profile) {
        setProfileSaved(profile);
      }
    }
  },
    //eslint-disable-next-line
    [submittingProfile, tmpProfilesLoading, inputIPProfiles]
  );

  const defaultResolution = useMemo(() => {
    if (!liveGuestResolutions || liveGuestResolutions.length === 0 || liveGuestResolutions.some(resolution => resolution.name === '1280x720')) {
      return '1280x720';
    } else {
      return liveGuestResolutions[0].name;
    }
  }, [liveGuestResolutions])

  const formatResolution = (resolution) => {
    const sizes = resolution.split('x');
    if (sizes.length === 2) {
      return `${sizes[1]}p`;
    } else {
      return resolution;
    }
  }

  const handleCopyToClipboard = (value) => {
    //Copying
    const el = document.createElement('textarea');
    el.value = value;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  const handleFormSubmit = (values) => {
      const startTimeParts = values.startTime.split(':');
      const hour = parseInt(startTimeParts[0]);
      const minute = parseInt(startTimeParts[1]);
      const finalStartDate = DayJS(values.startDate).set('hour', hour).set('minute', minute);
      const finalValues = {
          ...values,
          type: WEBRTC,
          startDate: finalStartDate.toDate(),
          duration: parseInt(values.duration),
      };
      delete finalValues.startTime;
      if (!values.passwordProtected) {
        finalValues.password = "";
      }
      setSubmittingProfile(finalValues);
      props.onSubmit(finalValues);
  };

  const handleValidation = (values) => {
    const errors = {};

  if (isEmptyString(values.name)) {
      errors.name = 'genericLabel.REQUIRED_FIELD.text';
  } else if (values.name.length >= 32) { // Bugs #16378
      errors.name = 'genericLabel.TOO_LONG.text';
  } else if (forbiddenNames.indexOf(values.name) !== -1) {
      errors.name = 'genericLabel.DUPLICATED_VALUES.text';
  }
    else if(!isGroupValid(values.name)){
      errors.name = 'genericLabel.INVALID_FORMAT.text'
  }

    if (isEmptyString(values.guestName)) {
      errors.guestName = 'genericLabel.REQUIRED_FIELD.text';
    }

    if (isEmptyString(values.guestEmail)) {
      errors.guestEmail = 'genericLabel.REQUIRED_FIELD.text';
    }

    if (isEmptyString(values.hostName)) {
      errors.hostName = 'genericLabel.REQUIRED_FIELD.text';
    }

    if (isEmptyString(values.startDate)) {
      errors.startDate = 'genericLabel.REQUIRED_FIELD.text';
    }

    if (isEmptyString(values.startTime)) {
      errors.startTime = 'genericLabel.REQUIRED_FIELD.text';
    } else {
        const startTimeParts = values.startTime.split(':');
        if(startTimeParts.length < 2){
            errors.startTime = 'genericLabel.INVALID_FORMAT.text';
        }
    }

    if (values.passwordProtected && isEmptyString(values.password)) {
      errors.password = 'genericLabel.REQUIRED_FIELD.text';
    }

    if (isEmptyString(values.standard)) {
      errors.standard = 'genericLabel.REQUIRED_FIELD.text';
    }

    return errors;
  };

  return (
    <Formik initialValues={{
      id: config ? config.id : undefined,
      type: translate('genericLabel.WEB_RTC_INPUT.text'),
      identifier: config ? config.identifier : undefined,
      name: config ? config.name : '',
      guestName: config ? config.guestName : '',
      guestEmail: config ? config.guestEmail : '',
      hostName: config ? config.hostName : '',
      startDate: config ? new Date(config.startDate) : new Date(),
      startTime: config ? DayJS(config.startDate).format('HH:mm') : DayJS().format('HH:mm'),
      duration: config ? config.duration : 3600,
      passwordProtected: config ? (config.password != null && config.password !== "") : false,
      password: config && config.password ? config.password : '',
      resolution: config ? config.resolution : defaultResolution,
      dejitterBuffer: DEJITTER_BUFFER_DEFAULT,
      uniqueId: config && config.uniqueId ? config.uniqueId : generateUniqueId()
    }}
    validate={handleValidation}
    validateOnBlur={false}
    validateOnChange={true}
    onSubmit={handleFormSubmit}>
      {({
        values,
        errors,
        dirty,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        resetForm,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit}>

          <HelpLayout
            filename={`c_sh_live_guest_profile.html`}
            form={step === 1 && profileSaved === null ?
              <fieldset disabled={config && config.enable === true}>
              {alert}
              {protocol}
              <FormGroup>
                {!orionConnectionStatus &&
                  <Alert color="danger" className="mt-4">
                    <Translate id="genericLabel.NOT_CONNECTED_LIVEGUEST.text" />
                  </Alert>
                }
                  <Label for="name">
                      <Translate id="genericLabel.NAME.text" />
                  </Label>
                  <Input type="text"
                          name="name"
                          id="inputsProfile_liveGuest_name"
                          invalid={errors.name !== undefined}
                          placeholder={translate('genericLabel.NAME.text')}
                          value={values.name}
                          onChange={handleChange} />
                  <Input type="hidden"
                     name="uniqueId"
                     id="uniqueId"
                     readOnly={true}
                     value={values.uniqueId}/>
                  <FormFeedback>
                      <Translate id={errors.name} />
                  </FormFeedback>
              </FormGroup>

              <FormGroup>
                <Label for="guestName">
                  <Translate id="genericLabel.GUEST_NAME.text" />
                </Label>
                <Input type="text"
                  name="guestName"
                  id="inputsProfile_liveGuest_guestName"
                  invalid={errors.guestName !== undefined}
                  placeholder={translate('genericLabel.GUEST_NAME.text')}
                  value={values.guestName}
                  onChange={handleChange} />
                <FormFeedback>
                  <Translate id={errors.guestName} />
                </FormFeedback>
              </FormGroup>

              <FormGroup>
                <Label for="guestEmail">
                  <Translate id="genericLabel.GUEST_EMAIL.text" />
                </Label>
                <Input type="text"
                  name="guestEmail"
                  id="inputsProfile_liveGuest_guestEmail"
                  invalid={errors.guestEmail !== undefined}
                  placeholder={translate('genericLabel.GUEST_EMAIL.text')}
                  value={values.guestEmail}
                  onChange={handleChange} />
                <FormFeedback>
                  <Translate id={errors.guestEmail} />
                </FormFeedback>
              </FormGroup>

              <FormGroup>
                <Label for="hostName">
                  <Translate id="genericLabel.HOST_NAME.text" />
                </Label>
                <Input type="text"
                  name="hostName"
                  id="inputsProfile_liveGuest_hostName"
                  invalid={errors.hostName !== undefined}
                  placeholder={translate('genericLabel.HOST_NAME.text')}
                  value={values.hostName}
                  onChange={handleChange} />
                <FormFeedback>
                  <Translate id={errors.hostName} />
                </FormFeedback>
              </FormGroup>

              <FormGroup row>
                <Col col="5">
                <Label for="startDate">
                  <Translate id="genericLabel.START_DATE.text" />
                </Label>
                    <AWDateInput inputProps={{
                                        name: "startDate",
                                        id: "inputsProfile_liveGuest_startDate",
                                        invalid: errors.startDate != null,
                                        placeholder: translate('genericLabel.START_DATE.text'),
                                    }}
                                 popupProps={{ direction: "right-end" }}
                                 value={values.startDate}
                                 onChange={(date, formattedDate) => setFieldValue("startDate", formattedDate)} 
                                 />
                 <FormFeedback>
                  <Translate id={errors.startDate} />
                </FormFeedback>
                 </Col>

                <Col col="4">
                  <Label for="startTime">
                    <Translate id="genericLabel.START_TIME.text" />
                  </Label>
                  <Input type="time"
                    name="startTime"
                    id="inputsProfile_liveGuest_startTime"
                    invalid={errors.startTime !== undefined}
                    placeholder={translate('genericLabel.START_TIME.text')}
                    value={values.startTime}
                    onChange={handleChange} />
                  <FormFeedback>
                    <Translate id={errors.startTime} />
                  </FormFeedback>
                </Col>
                  <Col col="3">
                      <Label for="duration">
                          <Translate id="genericLabel.DURATION.text" />
                      </Label>
                      <Input type="select"
                             name="duration"
                             id="inputsProfile_liveGuest_duration"
                             invalid={errors.duration != null}
                             placeholder={translate('genericLabel.DURATION.text')}
                             value={values.duration}
                             onChange={handleChange}>
                          <option value={3600} id="1h">1h</option>
                          <option value={12 * 3600} id="12h">12h</option>
                          <option value={24 * 3600} id="24h">24h</option>
                          <option value={3 * 24 * 3600} id="3 days">3 {translate('genericLabel.DAYS.text')}</option>
                          <option value={7 * 24 * 3600} id="7 days">7 {translate('genericLabel.DAYS.text')}</option>
                          <option value={30 * 24 * 3600} id="30 days">30 {translate('genericLabel.DAYS.text')}</option>
                          <option value={0} id="unlimited">{translate('genericLabel.UNLIMITED.text')}</option>
                      </Input>
                      <FormFeedback>
                          <Translate id={errors.duration} />
                      </FormFeedback>
                  </Col>
              </FormGroup>

              <FormGroup check>
                <Label check>
                  <Input type="checkbox"
                        name="passwordProtected"
                        id="inputsProfile_liveGuest_passwordProtected"
                        onChange={handleChange}
                        checked={values.passwordProtected}/>{' '}
                  <Translate id="genericLabel.PASSWORD_PROTECTED.text"/>
                </Label>
              </FormGroup>

              { values.passwordProtected && <FormGroup>
                <PasswordRevealInput name="password"
                                  setFieldValue={setFieldValue}
                                  id="inputsProfile_liveGuest_password"
                                  invalid={errors.password !== undefined}
                                  placeholder={ translate('genericLabel.PASSWORD.text') }
                                  value={values.password}
                                  onChange={handleChange}
                                  error={errors.password}/>
                </FormGroup>
              }

              <FormGroup>
                <Label for="resolution">
                  <Translate id="genericLabel.TARGET_RESOLUTION.text" />
                </Label>
                <Input type="select"
                  name="resolution"
                  id="inputsProfile_liveGuest_resolution"
                  invalid={errors.standard !== undefined}
                  placeholder={translate('genericLabel.TARGET_RESOLUTION.text')}
                  value={values.resolution}
                  onChange={handleChange}>
                    {liveGuestResolutions.map(resolution => <option key={resolution.name} value={resolution.name}>{formatResolution(resolution.name)}</option>)}
                </Input>
                <FormFeedback>
                  <Translate id={errors.standard} />
                </FormFeedback>
              </FormGroup>

                <AWLoader active={tmpProfilesLoading[values.name] != null && tmpProfilesLoading[values.name].loading} position="centered"/>
                {tmpProfilesLoading[values.name] != null && tmpProfilesLoading[values.name].errorCode && (
                    <Alert color="warning" title={`Code: ${tmpProfilesLoading[values.name].errorCode}`}>
                        <Translate id="genericLabel.LIVE_GUEST_IP_PROFILE_FAILED.text"/>
                    </Alert>
                )}
            </fieldset> :
            profileSaved && submittingProfile && <div>
              <h3 className="aw-heading py-0"><span className="title"><Translate id="genericLabel.SHARE_LIVEGUEST_LINK.text"/></span></h3>
              <span className="text-secondary"><Translate id="genericLabel.SHARE_LINK_WITH_GUEST.text" data={{ guestName: submittingProfile.guestName }}/></span>
              <p>{profileSaved.uri}</p>
              <Button id="inputsProfile_liveGuest_copyClipboard" color="primary" outline onClick={() => handleCopyToClipboard(profileSaved.uri)} ><Translate id="genericLabel.COPY_TO_CLIPBOARD.text" /></Button>

              <h3 className="aw-heading mt-4 py-0"><span className="title"><Translate id="genericLabel.SEND_EMAIL.text"/></span></h3>
              <p className="text-secondary"><Translate id="genericLabel.SEND_EMAIL_TO_GUEST.text" data={{ guestName: submittingProfile.guestName }}/></p>
              <FormGroup>
                <Label for="guestEmail">
                  <Translate id="genericLabel.GUEST_EMAIL.text" />
                </Label>
                <Input type="text"
                  name="guestEmail"
                  id="inputsProfile_liveGuest_guestEmail"
                  invalid={errors.guestEmail !== undefined}
                  placeholder={translate('genericLabel.GUEST_EMAIL.text')}
                  value={values.guestEmail}
                  onChange={handleChange} />
                <FormFeedback>
                  <Translate id={errors.guestEmail} />
                </FormFeedback>
              </FormGroup>
              <Button id="inputsProfile_liveGuest_sendEmailButton" color="primary" outline className="mb-3" href={`mailto:${values.guestEmail}?subject=${mailSubject}&body=${mailBody}`} target="_blank" ><Translate id="genericLabel.SEND_EMAIL.text" /></Button>
            </div>}

            buttons={<FormGroup className="buttons">
              {step === 1 && profileSaved === null ?
                <>{props.onDelete && !props.backToDashboard &&
                  <AWConfirm
                    onConfirm={props.onDelete}
                    confirmationText={props.translate("genericLabel.SECOND_CLICK_CONFIRM.text")}
                    render={(ref, onClick) => (
                      <Button id="inputsProfile_liveGuest_deleteButton" 
                        innerRef={ref}
                        onClick={onClick}
                        disabled={config && config.enable === true}
                        className="mr-auto"
                        outline
                        color="primary">
                        <Translate id="genericLabel.DELETE.text" />
                      </Button>
                    )} />
                  }
                  {props.onCancel && !props.backToDashboard &&
                    <Button id="inputsProfile_liveGuest_cancelButton" 
                      onClick={props.onCancel}
                      outline
                      color="primary">
                      <Translate id="genericLabel.CANCEL.text" />
                    </Button>
                  }
                  <Button id="inputsProfile_liveGuest_saveButton"
                    color="primary"
                    disabled={!dirty}
                    type="submit">
                    <Translate id="genericLabel.SAVE.text" />
                  </Button>
                </> :
                  <Button id="inputsProfile_liveGuest_closeButton"
                    onClick={props.onCancel}
                    color="primary"
                    type="button">
                    <Translate id="genericLabel.CLOSE.text" />
                  </Button>
              }
            </FormGroup>} />
        </Form>
      )}
    </Formik>
  );
};

LiveGuestForm.propTypes = propTypes;

export default withLocalize(LiveGuestForm);