import React, { useMemo } from 'react';
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";
import { connect } from 'react-redux';
import { encoderPropTypes, inputIpProfilePropTypes, inputPropTypes } from "../../../../../utils/models-prop-types";
import Badge from 'reactstrap/lib/Badge';
import PropTypes from 'prop-types';
import { inputDisplayName, inputMatchingIPProfile } from '../../../../../utils/global-utils';

const propTypes = {
  input: inputPropTypes.isRequired,
  inputs: PropTypes.arrayOf(inputPropTypes).isRequired,
  encoders: PropTypes.arrayOf(encoderPropTypes).isRequired,
  inputIPProfiles: PropTypes.arrayOf(inputIpProfilePropTypes).isRequired,
};

const VideoReturnInfo = (props) => {
  const { inputs, encoders, inputIPProfiles } = props;
  const { videoIFBEncoderIdx, videoIFBSourceIdx } = props.input;

  const videoSource = useMemo(() => {
    if (!inputs || !encoders || videoIFBEncoderIdx === undefined) {
      return null;
    }
    if (videoIFBEncoderIdx === -1) {
      const matchingInput = inputs.find(input => input.id === (videoIFBSourceIdx + 1).toString());
      return matchingInput ? inputDisplayName(matchingInput, inputMatchingIPProfile(inputIPProfiles, matchingInput)) : null;
    } else {
      const matchingEncoder = encoders.find(encoder => encoder.id === (videoIFBEncoderIdx + 1).toString());
      return matchingEncoder ? matchingEncoder.displayName : null;
    }
  }, [inputs, encoders, videoIFBEncoderIdx, videoIFBSourceIdx, inputIPProfiles])

  return (videoSource ?
    <Badge className="text-clip">
      <AWIcon name="video_return" style={{ verticalAlign: 'top' }}/> {videoSource}
    </Badge> : null
  );
};

VideoReturnInfo.propTypes = propTypes;

const mapStateToProps = (state) => {
  return {
    inputs: state.streamhub.inputs,
    encoders: state.streamhub.encoders,
    inputIPProfiles: state.streamhub.inputIPProfiles
  }
};

export default connect(mapStateToProps)(VideoReturnInfo);