import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Button, Table} from "reactstrap";
import {Translate, withLocalize} from "react-localize-redux";
import AWConfirm from "@aviwest/ui-kit/dist/js/components/confirm";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

import {fileTransferPropTypes} from "../../../../utils/models-prop-types";
import Empty from "../../../common/empty";
import {isEmptyString} from "../../../../utils/string-utils";

const matchingSearch = (fileTransfer, search) => {
  if(search.length === 0){
    return true
  }
  const lowerCaseSearch = search.toLowerCase();
  return fileTransfer.name.toLowerCase().indexOf(lowerCaseSearch) !== -1;
};

const propTypes = {
  search: PropTypes.string.isRequired,
  fileTransfers: PropTypes.arrayOf(fileTransferPropTypes).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

class FileTransferList extends Component {

  render() {
    const {
      fileTransfers,
      onEdit,
      onDelete,
      search,
      translate
    } = this.props;
    const matchingFileTransfers = fileTransfers.filter(fileTransfer => matchingSearch(fileTransfer, search));
    if(matchingFileTransfers.length === 0){
      if(!isEmptyString(search)){
        return (
          <Empty icon="search"
                 text={<Translate id="genericLabel.FTP_SETTINGS_SEARCH_MATCH_EMPTY.text"/>}
                 subText={`"${search}"`}/>
        );
      }
      else {
        return (
          <Empty icon="upload_cloud"
                 text={<Translate id="genericLabel.FTP_SETTINGS_EMPTY.text"/>}/>
        );
      }
    }
    return (
      <Table borderless
             className="aw-table"
             hover>
        <tbody>
        { matchingFileTransfers
          .map(fileTransfer => {
            let className = undefined;
            if(!fileTransfer.enabled){
              className = 'disabled';
            }
            return <tr key={ fileTransfer.name }
                       className={ className }>
              <td className="name text-clip" id={"settings_FileTransfer_"+fileTransfer.id+"_name"}>
                { fileTransfer.name }
              </td>
              <td className="metadata">
                <div aria-label={fileTransfer.uri}
                     className="metadata-container">
                  <div className="metadata-value text-clip" id={"settings_FileTransfer_"+fileTransfer.name+"_uri"}>
                    { fileTransfer.uri }
                  </div>
                </div>
              </td>
              <td className="actions">
                <div className="actions-menu">
                  <div className="menu">
                    <Button id={"settings_FileTransfer_"+fileTransfer.name+"_editButton"}
                            className="basic"
                            onClick={() => onEdit(fileTransfer)}>
                      <AWIcon name="edit"/>
                    </Button>

                    <AWConfirm onConfirm={() => onDelete(fileTransfer)}
                               confirmationText={ translate("genericLabel.SECOND_CLICK_CONFIRM.text") }
                               render={(ref, onClick) => (
                               <Button id={"settings_FileTransfer_"+fileTransfer.name+"_deleteButton"}
                                       innerRef={ref} className="basic"
                                       onClick={onClick}>
                                 <AWIcon name="delete"/>
                               </Button>
                               )} />
                  </div>
                </div>
              </td>
            </tr>;
          }) }
        </tbody>
      </Table>
    );
  }
}

FileTransferList.propTypes = propTypes;

export default withLocalize(FileTransferList);