import {Formik} from "formik";
import PropTypes from "prop-types";
import React, { Component } from 'react';
import {Translate, withLocalize} from "react-localize-redux";
import { connect } from 'react-redux';
import {Button, Form, FormFeedback, FormGroup, Input, Label, Alert, Row, Col} from "reactstrap";
import RestartStreamhubModal from "../../tools/system/restart-streamhub-modal";
import {isAESKeyValid, isEmptyString, isTunIPValid} from "../../../../utils/string-utils";
import {isPortInRange} from "../../../../utils/global-utils";
import {licensePropTypes} from "../../../../utils/models-prop-types";
import PasswordRevealInput from "../../../common/password-reveal-input";

import {
  forbiddenTCPPorts
} from "../../../../constants";

const propTypes = {
  config: PropTypes.shape({
    authentificationRetryMax: PropTypes.number,
    ebonding: PropTypes.shape({
      atpGeneralBasePort: PropTypes.number,
      username: PropTypes.string,
      password: PropTypes.string,
    }).isRequired,
    'abus-proxy': PropTypes.shape({
      tcpPort: PropTypes.number.isRequired
    }).isRequired,
    tun: PropTypes.shape({
      baseTunIPAddr: PropTypes.string.isRequired
    }).isRequired,
    tunRemoteControl: PropTypes.shape({
      webProxyPort: PropTypes.number.isRequired,
      webProxyPortHttps: PropTypes.number.isRequired
    }).isRequired,
    AESkey: PropTypes.string,
  }).isRequired,
  license: licensePropTypes.isRequired,
  forbiddenTCPPorts: PropTypes.arrayOf(PropTypes.number),
  forbiddenUDPPorts: PropTypes.arrayOf(PropTypes.number),
  decryptedPassword: PropTypes.string,
  onSubmit: PropTypes.func.isRequired
};

class NetworkSettingsForm extends Component {

  constructor(props){
    super(props);

    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleValidate = this.handleValidate.bind(this);
    this.state = {
      confirmModalOpened: false
    };
  }

  handleConfirm(value) {
    this.setState({
      confirmModalOpened: value
    });
  }

  handleSubmit(values, { setSubmitting }){
    let data = values;
    this.props.onSubmit(data);
    setSubmitting(false);
  }

  handleValidate(values){
    const errors = {};
    const { AESkey } = this.props.config;
    //Base port
    const forbiddenBasePorts = this.props.forbiddenUDPPorts;/*concat(values.abusProxyTcpPort);*/
    if(isEmptyString(values.atpGeneralBasePort)){
      errors.atpGeneralBasePort = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if(!isPortInRange(values.atpGeneralBasePort, 1024, 65535)){
      errors.atpGeneralBasePort = 'genericLabel.BASE_PORT_HELP.text';
    }
    else if(forbiddenBasePorts.indexOf(values.atpGeneralBasePort) !== -1){
      errors.atpGeneralBasePort = 'genericLabel.PORT_ALREADY_USED.text';
    }

    //Manager port
    const forbiddenManagerPorts = this.props.forbiddenTCPPorts;/*.concat(values.atpGeneralBasePort);*/
    if(isEmptyString(values.abusProxyTcpPort)){
      errors.abusProxyTcpPort = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if(!isPortInRange(values.abusProxyTcpPort, 1024, 65535)){
      errors.abusProxyTcpPort = 'genericLabel.MANAGER_PORT_HELP.text';
    }
    else if(forbiddenManagerPorts.indexOf(values.abusProxyTcpPort) !== -1){
      errors.abusProxyTcpPort = 'genericLabel.PORT_ALREADY_USED.text';
    }

    //Username
    if(isEmptyString(values.username)){
      errors.username = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if(values.username.length < 6){
      errors.username = 'genericLabel.TOO_SHORT.text';
    }
    else if(values.username.length > 20){
      errors.username = 'genericLabel.TOO_LONG.text';
    }

    //Password
    if(isEmptyString(values.password)){
      errors.password = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if(values.password.length < 6 && values.password !== this.props.config.ebonding.password){
      errors.password = 'genericLabel.TOO_SHORT.text';
    }
    else if(values.password.length > 20 && values.password !== this.props.config.ebonding.password){
      errors.password = 'genericLabel.TOO_LONG.text';
    }

    //AESkey
    if(this.props.license.AES > 0 && (values.aesKey !== AESkey || isEmptyString(values.aesKey))){
      if(isEmptyString(values.aesKey)){
        errors.aesKey = 'genericLabel.REQUIRED_FIELD.text';
      }
      else if(values.aesKey.length < 6){
        errors.aesKey = 'genericLabel.TOO_SHORT.text';
      }
      else if(values.aesKey.length > 32){
        errors.aesKey = 'genericLabel.TOO_LONG.text';
      }
      else if(!isAESKeyValid(values.aesKey)){
        errors.aesKey = 'genericLabel.INVALID_FORMAT.text';
      }
    }

    //Tun IP
    if(values.baseTunIPAddr.length === 0){
      errors.baseTunIPAddr = 'genericLabel.REQUIRED_FIELD.text';
    }
    if(!isTunIPValid(values.baseTunIPAddr)){
      errors.baseTunIPAddr = 'genericLabel.IP_TUNNELING_PATTERN.text';
    }

    //tunRemoteControl http
    if(isEmptyString(values.webProxyPort)){
      errors.webProxyPort = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if(!isPortInRange(values.webProxyPort, 8884, 65535)){
      errors.webProxyPort = 'genericLabel.INVALID_FORMAT.text';
    }
    if(forbiddenManagerPorts.indexOf(values.webProxyPort) !== -1 && values.webProxyPort!== this.props.config.tunRemoteControl.webProxyPort){
      errors.webProxyPort = 'genericLabel.PORT_ALREADY_USED.text';
    }

    //tunRemoteControl https
    if(isEmptyString(values.webProxyPortHttps)){
      errors.webProxyPortHttps = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if(!isPortInRange(values.webProxyPortHttps, 8884, 65535)){
      errors.webProxyPortHttps = 'genericLabel.INVALID_FORMAT.text';
    }
    if(forbiddenManagerPorts.indexOf(values.webProxyPortHttps) !== -1 && values.webProxyPortHttps!== this.props.config.tunRemoteControl.webProxyPortHttps){
      errors.webProxyPortHttps = 'genericLabel.PORT_ALREADY_USED.text';
    }

    if(values.webProxyPort === values.webProxyPortHttps) {
      errors.webProxyPortHttps = 'genericLabel.DUPLICATED_VALUES.text';
      errors.webProxyPort = 'genericLabel.DUPLICATED_VALUES.text';
    }
    if(values.webProxyPort === values.abusProxyTcpPort) {
      errors.abusProxyTcpPort = 'genericLabel.DUPLICATED_VALUES.text';
      errors.webProxyPort = 'genericLabel.DUPLICATED_VALUES.text';
    }
    if(values.webProxyPortHttps === values.abusProxyTcpPort) {
      errors.webProxyPortHttps = 'genericLabel.DUPLICATED_VALUES.text';
      errors.abusProxyTcpPort = 'genericLabel.DUPLICATED_VALUES.text';
    }
    return errors;
  }

  render() {
    const { config, translate, license, decryptedPassword } = this.props;
    return (
      <Formik initialValues={{
                abusProxyTcpPort: config['abus-proxy'].tcpPort,
                atpGeneralBasePort: config.ebonding.atpGeneralBasePort,
                username: config.ebonding.username,
                password: config.ebonding.password,
                AESkeyEnable: config.AESkeyEnable!==null ? config.AESkeyEnable : 'true',
                aesKey: config.AESkey ? config.AESkey : '',
                baseTunIPAddr: config.tun.baseTunIPAddr,
                webProxyPort: config.tunRemoteControl.webProxyPort,
                webProxyPortHttps: config.tunRemoteControl.webProxyPortHttps
              }}
              validate={ this.handleValidate }
              validateOnBlur={false}
              validateOnChange={true}
              onSubmit={ this.handleSubmit }>
        {({
            values,
            errors,
            dirty,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => {
          // Checking changement others than decrypted password (Bugs #17597)
          const hasChanged = () => {
            return values.abusProxyTcpPort !== config['abus-proxy'].tcpPort
              || values.atpGeneralBasePort !== config.ebonding.atpGeneralBasePort
              || values.username !== config.ebonding.username
              || (values.password !== config.ebonding.password && decryptedPassword !== values.password)
              || (values.aesKey !== '' && values.aesKey !== config.AESkey)
              || values.baseTunIPAddr !== config.tun.baseTunIPAddr
              || values.webProxyPort !== config.tunRemoteControl.webProxyPort
              || values.webProxyPortHttps !== config.tunRemoteControl.webProxyPortHttps
              || values.AESkeyEnable !== config.AESkeyEnable
          }
          return (
          <Form onSubmit={ handleSubmit }>
            { hasChanged() &&
            <Alert color="warning">
              <Translate id="genericLabel.RESTART_STREAMHUB_TO_TAKE_CHANGES_INTO_ACCOUNT.text"/>
            </Alert>
            }
            <FormGroup>
              <Label for="atpGeneralBasePort">
                <Translate id="genericLabel.BASE_PORT.text"/>
              </Label>
              <Input type="number"
                     name="atpGeneralBasePort"
                     id="network_product_atpGeneralBasePort"
                     invalid={errors.atpGeneralBasePort !== undefined}
                     placeholder={ translate('genericLabel.BASE_PORT.text') }
                     value={values.atpGeneralBasePort}
                     onBlur={handleBlur}
                     onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.atpGeneralBasePort} />
              </FormFeedback>
              <div className="indicator">
                <Translate id="genericLabel.BASE_PORT_HELP.text" />
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="abusProxyTcpPort">
                <Translate id="genericLabel.MANAGER_PORT.text"/>
              </Label>
              <Input type="number"
                     name="abusProxyTcpPort"
                     id="network_product_abusProxyTcpPort"
                     invalid={errors.abusProxyTcpPort !== undefined}
                     placeholder={ translate('genericLabel.MANAGER_PORT.text') }
                     value={values.abusProxyTcpPort}
                     onBlur={handleBlur}
                     onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.abusProxyTcpPort} />
              </FormFeedback>
              <div className="indicator">
                <Translate id="genericLabel.MANAGER_PORT_HELP.text" />
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="username">
                <Translate id="genericLabel.USERNAME.text"/>
              </Label>
              <Input type="text"
                     name="username"
                     id="network_product_username"
                     invalid={errors.username !== undefined}
                     placeholder={ translate('genericLabel.USERNAME.text') }
                     value={values.username}
                     onBlur={handleBlur}
                     onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.username} />
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="password">
                <Translate id="genericLabel.PASSWORD.text"/>
              </Label>
              <PasswordRevealInput name="password"
                                   id="network_product_password"
                                   setFieldValue={setFieldValue}
                                   invalid={errors.password !== undefined}
                                   placeholder={ translate('genericLabel.PASSWORD.text') }
                                   value={values.password}
                                   onBlur={handleBlur}
                                   onChange={handleChange}
                                   error={errors.password}/>
            </FormGroup>
            { license.AES  &&
              <FormGroup check>
              <Label check>
                <Input id="security_ndi_AESkeyEnable"
                       type="checkbox"
                       name="AESkeyEnable"
                       onChange={handleChange}
                       checked={values.AESkeyEnable}/>{' '}
                Enable AES
              </Label>
            </FormGroup>
              }
            {values.AESkeyEnable && license.AES  &&
              <FormGroup>
                <Label for="aesKey">
                  <Translate id="genericLabel.AES_KEY.text"/>
                </Label>
                <PasswordRevealInput name="aesKey"
                                    id="network_product_aesKey"
                                    invalid={errors.aesKey !== undefined}
                                    placeholder={translate('genericLabel.AES_KEY.text')}
                                    value={values.aesKey}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    disableeye={values.aesKey === config.AESkey}
                                    error={errors.aesKey}/>
              </FormGroup>
            }
            
            <FormGroup>
              <Label for="baseTunIPAddr">
                <Translate id="genericLabel.IP_TUNNELING.text"/>
              </Label>
              <Input type="text"
                     name="baseTunIPAddr"
                     id="network_product_baseTunIPAddr"
                     invalid={errors.baseTunIPAddr !== undefined}
                     placeholder={ translate('genericLabel.IP_TUNNELING.text') }
                     value={values.baseTunIPAddr}
                     onBlur={handleBlur}
                     onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.baseTunIPAddr} />
              </FormFeedback>
              <div className="indicator">
                <Translate id="genericLabel.IP_TUNNELING_HELP.text" />
              </div>
            </FormGroup>
            <Row form>
              <Col>
            <FormGroup>
              <Label for="webProxyPort">
                <Translate id="genericLabel.WEB_PROXY_PORT.text"/> HTTP
              </Label>
              <Input type="number"
                     name="webProxyPort"
                     id="network_product_webProxyPort"
                     invalid={errors.webProxyPort !== undefined}
                     placeholder={ translate('genericLabel.WEB_PROXY_PORT.text') }
                     value={values.webProxyPort}
                     min={8884}
                     max={65535}
                     onBlur={handleBlur}
                     onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.webProxyPort} />
              </FormFeedback>
              <div className="indicator">
                <Translate id="genericLabel.WEB_PROXY_PORT_HELP.text" />
              </div>
            </FormGroup>
              </Col>
              <Col>
            <FormGroup>
              <Label for="webProxyPortHttps">
                <Translate id="genericLabel.WEB_PROXY_PORT.text"/> HTTPS
              </Label>
              <Input type="number"
                     name="webProxyPortHttps"
                     id="network_product_webProxyPortHttps"
                     invalid={errors.webProxyPortHttps !== undefined}
                     placeholder={ translate('genericLabel.WEB_PROXY_PORT.text') }
                     value={values.webProxyPortHttps}
                     min={8884}
                     max={65535}
                     onBlur={handleBlur}
                     onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.webProxyPortHttps} />
              </FormFeedback>
              <div className="indicator">
                <Translate id="genericLabel.WEB_PROXY_PORT_HELP.text" />
              </div>
            </FormGroup>
              </Col>
            </Row>
            <FormGroup className="buttons">
              <Button id="network_product_saveButton"
                      disabled={isSubmitting || !hasChanged()}
                      color="primary"
                      onClick={() => this.handleConfirm(true)}>
                <Translate id="genericLabel.SAVE.text"/>
              </Button>
            </FormGroup>
            { this.state.confirmModalOpened &&
            <RestartStreamhubModal onCancel={() => this.handleConfirm(false)}
                                   onConfirm={() => handleSubmit()}/>
            }
          </Form>
        )}}
      </Formik>
    );
  }
}

NetworkSettingsForm.propTypes = propTypes;

const mapStateToProps = (state) => {
  // TCP Ports
  const configTCPPorts = [];
  if(state.config.gpimng){
    configTCPPorts.push(state.config.gpimng.alarmTcpPort);
  }
  if(state.config.streamingOutput){
    Object.keys(state.config.streamingOutput).forEach(element => {
      if(state.config.streamingOutput[element].mode==="RTSP"){
        configTCPPorts.push(state.config.streamingOutput[element].port);
      }
    });
  }

  // UDP Ports
  const forbiddenUDPPorts = state.streamhub.udpUsedPort.intercomPorts.concat(
    state.streamhub.udpUsedPort.LiveGuestPorts,
    state.streamhub.udpUsedPort.inputProtocolPorts,
    state.streamhub.udpUsedPort.intercomPorts,
    state.streamhub.udpUsedPort.channelPorts,
    state.streamhub.udpUsedPort.remoteControlPorts);
  return {
    forbiddenUDPPorts,
    forbiddenTCPPorts: forbiddenTCPPorts.concat(configTCPPorts),
    decryptedPassword: state.streamhub.decryptedPassword
  }
};
export default withLocalize(connect(mapStateToProps)(NetworkSettingsForm));