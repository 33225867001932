import PropTypes from "prop-types";
import React, {Component} from 'react';

import {connect} from "react-redux";
import {outputHardwareEditSettings} from "../../dashboard.actions";
import GeneralForm from "./general-form";
import {Translate} from "react-localize-redux";
import {updateOutputSettings} from "../../../settings/settings.actions";
import AWModal from "@aviwest/ui-kit/dist/js/components/modal";
import {Breadcrumb, BreadcrumbItem} from "reactstrap";
import { outputMatchingVideoCard } from "../../../../../utils/global-utils";
import { getAvailableVideoCards } from "../../../../../misc/capabilities.selectors";
import { VIDEO_CARD_TYPE_RIVERMAX } from "../../../../../constants";
import SMPTE2110Form from "./smpte-form";

const propTypes = {
  callCloseModal: PropTypes.func.isRequired,
  callUpdateOutputSettings: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  outputId: PropTypes.string,
  videoCardId : PropTypes.string
};

class OutputHardwareSettingsModal extends Component {

  constructor(props){
    super(props);

    this.handleGeneralFormSubmit = this.handleGeneralFormSubmit.bind(this);
  }

  handleGeneralFormSubmit(data){
    this.props.callUpdateOutputSettings(this.props.outputId, data);
    this.props.callCloseModal();
  }

  render(){
    return (
    <AWModal id="outputHardwareSetting_modal"
             onClose={() => this.props.callCloseModal()}
             open={this.props.opened}
             title={
              <Breadcrumb>
                <BreadcrumbItem active>
                  <Translate id="genericLabel.SETTINGS.text"/>
                </BreadcrumbItem>
                <BreadcrumbItem>{ this.props.outputName }</BreadcrumbItem>
              </Breadcrumb>}>
          { this.props.opened &&
          <div className="settings">
            {((this.props.videoCard && this.props.videoCard.sdiCardType !==VIDEO_CARD_TYPE_RIVERMAX) || !this.props.videoCard )&&
              <GeneralForm outputId={ this.props.outputId }
                           onSubmit={ this.handleGeneralFormSubmit }/>
            }
            {this.props.videoCard && this.props.videoCard.sdiCardType ===VIDEO_CARD_TYPE_RIVERMAX &&
              <SMPTE2110Form outputId={ this.props.outputId }
                           onSubmit={ this.handleGeneralFormSubmit }/>
            }
          </div>
          }
        </AWModal>
    );
  }

}

OutputHardwareSettingsModal.propTypes = propTypes;

const mapStateToProps = (state) => {
  var videoCardId = null;
  var videoCard = null;
  if(state.dashboard.editingHardwareOutput){
    videoCardId = state.dashboard.editingHardwareOutput ? state.streamhub.hardwareOutputs.find(output => output.id === state.dashboard.editingHardwareOutput).videoCardId : null;
    videoCard = outputMatchingVideoCard(getAvailableVideoCards(state), state.streamhub.hardwareOutputs.find(output => output.id === state.dashboard.editingHardwareOutput))
  }
  return {
    videoCardId,
    videoCard,
    opened: state.dashboard.editingHardwareOutput !== null,
    outputId: state.dashboard.editingHardwareOutput,
    outputName: state.dashboard.editingHardwareOutput ? state.config.basebandPlayer[state.dashboard.editingHardwareOutput].name : null
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    callCloseModal: () => dispatch(outputHardwareEditSettings(null)),
    callUpdateOutputSettings: (outputId, data) => dispatch(updateOutputSettings(outputId, data)),

  }
};

export default connect(mapStateToProps, mapDispatchToProps)(OutputHardwareSettingsModal);