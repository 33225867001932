import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Translate, withLocalize} from "react-localize-redux";
import React, {Component, Fragment} from "react";
import {push} from "connected-react-router";
import {withRouter} from "react-router";
import {Button} from "reactstrap";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

import {
  USER_ROLE_ADMIN,
  DASHBOARD_LAYOUT_FULLSIZE,
  DASHBOARD_LAYOUT_GRID,
  DASHBOARD_LAYOUT_INLINE,
  INPUT_TYPE_DEVICE,
  STATUS_OFF,
  USER_ROLE_USER
} from "../../../../constants";
import Filters, {filtersPropTypes, hasAtLeastOneFilter} from "../../../common/dashboard-filters";
import LayoutSelector from "../../../common/layout-selector";
import AWInput from "./input";
import {inputMatchingIPProfile} from "../../../../utils/global-utils";
import MultiView from "./multi-view";
import Empty from "../../../common/empty";
import {
  getAvailableInputIPProfiles,
  getVisibleInputs,
  getVisibleMultiView
} from "../dashboard.selectors";
import {
  changeInputsFilters,
  changeInputsLayout,
  inputAttachIPProfile, inputChangeLatency,
  inputChangeVideoCappedBitrate, inputCloseDetails,
  inputDetachIPProfile,
  inputDisconnect,
  inputEditSettings,
  inputEnterDetails,
  inputPlaybackStart,
  inputPlaybackStop,
  inputPlaybackLoopOn,
  inputPlaybackLoopOff,
  inputVideoIFBEncoderBind,
  inputVideoIFBSourceBind,
  inputRemoteControl,
  inputEjectVideoIFB,
  inputRemoteStartLive,
  inputRemoteStopLive,
  inputRemoteStopForward,
  inputRemoteSwitchProfile,
  inputResetDroppedPacketsCounter,
  inputStart,
  inputStartIntercom,
  inputStartRecord,
  inputStop,
  inputStopIntercom,
  inputStopRecord,
  inputSubscribe,
  inputUnsubscribe,
  ipInputsProfilesSettings
} from "../dashboard.actions";
import InputSettingsModal from "./input-settings-modal";
import MultiViewSettingsModal from "./multi-view-settings-modal/";
import MultiViewModal from "./multi-view-modal/";

import {inputIpProfilePropTypes, inputPropTypes, multiViewPropTypes} from "../../../../utils/models-prop-types";
import AWLoader from "@aviwest/ui-kit/dist/js/components/loader";
import AWControlGrid from "@aviwest/ui-kit/dist/js/components/control-grid";
import { canSetIPInput } from "../../../../misc/license.selectors";
import { openHelpModal } from "../../../../misc/help.actions";

const propTypes = {
  hasPrivilege: PropTypes.bool,
  callChangeInputsFilters: PropTypes.func.isRequired,
  callChangeInputsLayout: PropTypes.func.isRequired,
  callOpenIpInputsProfilesSettings: PropTypes.func.isRequired,
  callOpenNewIpInputProfileSettings: PropTypes.func.isRequired,
  callOpenIpInputProfileSettings: PropTypes.func.isRequired,
  callInputAttachIPProfile: PropTypes.func.isRequired,
  callInputDetachIPProfile: PropTypes.func.isRequired,
  callInputDisconnect: PropTypes.func.isRequired,
  callInputEditSettings: PropTypes.func.isRequired,
  callInputLatencyChange: PropTypes.func.isRequired,
  callInputPlaybackStart: PropTypes.func.isRequired,
  callInputPlaybackStop: PropTypes.func.isRequired,
  callInputPlaybackLoopOn: PropTypes.func.isRequired,
  callInputPlaybackLoopOff: PropTypes.func.isRequired,
  callInputEjectVideoIFB: PropTypes.func.isRequired,
  callInputRemoteStartLive: PropTypes.func.isRequired,
  callInputRemoteStopLive: PropTypes.func.isRequired,
  callInputRemoteStopForward: PropTypes.func.isRequired,
  callInputRemoteSwitchProfile: PropTypes.func.isRequired,
  callInputRemoteControl: PropTypes.func.isRequired,
  callInputStart: PropTypes.func.isRequired,
  callInputStop: PropTypes.func.isRequired,
  callInputStartIntercom: PropTypes.func.isRequired,
  callInputStopIntercom: PropTypes.func.isRequired,
  callInputStartRecord: PropTypes.func.isRequired,
  callInputStopRecord: PropTypes.func.isRequired,
  callInputSubscribe: PropTypes.func.isRequired,
  callInputChangeVideoCappedBitrate: PropTypes.func.isRequired,
  callInputResetDroppedPacketsCounter: PropTypes.func.isRequired,
  showInputDetails: PropTypes.func.isRequired,
  closeInputDetails: PropTypes.func.isRequired,
  openHelpModal: PropTypes.func.isRequired,

  availableInputIPProfiles: PropTypes.arrayOf(inputIpProfilePropTypes).isRequired,
  inputIPProfiles: PropTypes.arrayOf(inputIpProfilePropTypes).isRequired,
  inputsCount: PropTypes.number.isRequired,
  inputsFilters: filtersPropTypes.isRequired,
  inputsLayout: PropTypes.oneOf([DASHBOARD_LAYOUT_GRID, DASHBOARD_LAYOUT_INLINE]).isRequired,
  inputsVisible: PropTypes.arrayOf(inputPropTypes).isRequired,
  selectedInput: inputPropTypes,

  multiView: multiViewPropTypes,
  mobileOutputsVisible: PropTypes.bool.isRequired,
  ready: PropTypes.bool.isRequired,
  canSetIPInput: PropTypes.bool.isRequired,
};

class InputSection extends Component {

  componentDidMount(){
    if(this.props.selectedInput && this.props.selectedInput.type === INPUT_TYPE_DEVICE){
      this.props.callInputSubscribe(this.props.selectedInput);
    }
  }
  componentDidUpdate(newProps){
    if (this.props.selectedInput && this.props.selectedInput.status === STATUS_OFF && this.props.ready) {
      this.props.closeInputDetails(this.props.selectedInput)
    }
  }

  render(){
    const {
      hasPrivilege,
      availableInputIPProfiles,
      inputIPProfiles,
      inputsCount,
      inputsFilters,
      inputsLayout,
      inputsVisible,
      selectedInput,
      multiView,
      mobileOutputsVisible,
      ready,
      canSetIPInput,
      openHelpModal,
      translate
    } = this.props;
    const atLeastOneInputsFilter = hasAtLeastOneFilter(inputsFilters);
    return (
      <Fragment>
        <div className={ `layout-section vertical inputs${mobileOutputsVisible ? ' hidden': ''}` }>
          { selectedInput == null &&
          <div className="section-header">

            <div className="title">
              <Translate id="genericLabel.INPUTS.text"/>
              <Button id="dashboard_input_infoCircleButton"
                      className="basic icon"
                      size="sm"
                      onClick={() => openHelpModal("c_sh_inputs_overview.html")}>
                <AWIcon name="info_circle"/>
              </Button>
            </div>
            <div className="display-options">
              { hasPrivilege && canSetIPInput &&
                <Button id="dashboard_input_optionButton"
                        className="basic icon" title={translate('genericLabel.INGEST_PROFILES.text')}
                        size="sm"
                        onClick={() => this.props.callOpenIpInputsProfilesSettings()}>
                  <AWIcon name="options"/>
                </Button>
              }

              <Filters filters={inputsFilters}
                       id="input"
                       onChange={(filters) => this.props.callChangeInputsFilters(filters)}/>
              <LayoutSelector onChange={(layout) => this.props.callChangeInputsLayout(layout)} selected={inputsLayout} id="dashboard_input"/>
            </div>
          </div>
          }
          <AWControlGrid layout={selectedInput ? 'FULLSIZE' : inputsLayout} className={`section-content pretty-scroll ${selectedInput ? 'fullsize-detail' : ''}`}>
            { ready && selectedInput != null &&
            <AWInput key={ selectedInput.id }
                     input={ selectedInput }
                     ipProfile={ inputMatchingIPProfile(inputIPProfiles, selectedInput) }
                     ipProfiles={ availableInputIPProfiles }
                     layout={ DASHBOARD_LAYOUT_FULLSIZE }
                     onDisconnect={ this.props.callInputDisconnect }
                     onEditSettings={ this.props.callInputEditSettings }
                     onIntercomStart={ this.props.callInputStartIntercom }
                     onIntercomStop={ this.props.callInputStopIntercom }
                     onIPProfileChange={ (input, profileId) => profileId == null ? this.props.callInputDetachIPProfile(input) : this.props.callInputAttachIPProfile(input, profileId) }
                     onIPProfileCreate={ () => this.props.callOpenNewIpInputProfileSettings(selectedInput) }
                     onIPProfileEdit={ this.props.callOpenIpInputProfileSettings }
                     onLatencyChange={ this.props.callInputLatencyChange }
                     onPlaybackStart={ this.props.callInputPlaybackStart }
                     onPlaybackStop={ this.props.callInputPlaybackStop }
                     onPlaybackLoopOn={ this.props.callInputPlaybackLoopOn }
                     onPlaybackLoopOff={ this.props.callInputPlaybackLoopOn }
                     onVideoIFBEncoderBind={ this.props.callInputVideoIFBEncoderBind}
                     onVideoIFBSourceBind={ this.props.callInputVideoIFBSourceBind}
                     onRecordStart={ this.props.callInputStartRecord }
                     onRecordStop={ this.props.callInputStopRecord }
                     onEjectVideoIFB={ this.props.callInputEjectVideoIFB }
                     onRemoteLiveStart={ this.props.callInputRemoteStartLive }
                     onRemoteLiveStop={ this.props.callInputRemoteStopLive }
                     onRemoteForwardStop={ this.props.callInputRemoteStopForward }
                     onRemoteProfileSwitch={ this.props.callInputRemoteSwitchProfile }
                     onRemoteControl={ this.props.callInputRemoteControl }
                     onResetDroppedPackets={ this.props.callInputResetDroppedPacketsCounter }
                     onStart={ this.props.callInputStart }
                     onStop={ this.props.callInputStop }
                     onShowDetails={this.props.showInputDetails}
                     onCloseDetails ={ this.props.closeInputDetails }
                     onVideoCappedBitrateChange={ this.props.callInputChangeVideoCappedBitrate }/>
            }
            { ready && selectedInput == null && inputsVisible.map(input =>
              <AWInput key={ input.id }
                       input={ input }
                       ipProfile={ inputMatchingIPProfile(inputIPProfiles, input) }
                       ipProfiles={ availableInputIPProfiles }
                       layout={ inputsLayout }
                       onDisconnect={ this.props.callInputDisconnect }
                       onEditSettings={ this.props.callInputEditSettings }
                       onIntercomStart={ this.props.callInputStartIntercom }
                       onIntercomStop={ this.props.callInputStopIntercom }
                       onIPProfileChange={ (input, profileId) => profileId == null ? this.props.callInputDetachIPProfile(input) : this.props.callInputAttachIPProfile(input, profileId) }
                       onIPProfileCreate={ () => this.props.callOpenNewIpInputProfileSettings(input) }
                       onIPProfileEdit={ this.props.callOpenIpInputProfileSettings }
                       onLatencyChange={ this.props.callInputLatencyChange }
                       onPlaybackStart={ this.props.callInputPlaybackStart }
                       onPlaybackStop={ this.props.callInputPlaybackStop }
                       onPlaybackLoopOn={ this.props.callInputPlaybackLoopOn }
                       onPlaybackLoopOff={ this.props.callInputPlaybackLoopOff }
                       onVideoIFBEncoderBind={ this.props.callInputVideoIFBEncoderBind}
                       onVideoIFBSourceBind={ this.props.callInputVideoIFBSourceBind}
                       onRecordStart={ this.props.callInputStartRecord }
                       onRecordStop={ this.props.callInputStopRecord }
                       onEjectVideoIFB={ this.props.callInputEjectVideoIFB }
                       onRemoteLiveStart={ this.props.callInputRemoteStartLive }
                       onRemoteLiveStop={ this.props.callInputRemoteStopLive }
                       onRemoteForwardStop={ this.props.callInputRemoteStopForward }
                       onRemoteProfileSwitch={ this.props.callInputRemoteSwitchProfile }
                       onRemoteControl={ this.props.callInputRemoteControl }
                       onResetDroppedPackets={ this.props.callInputResetDroppedPacketsCounter }
                       onStart={ this.props.callInputStart }
                       onStop={ this.props.callInputStop }
                       onShowDetails={ this.props.showInputDetails }
                       onCloseDetails ={ this.props.closeInputDetails }
                       onVideoCappedBitrateChange={ this.props.callInputChangeVideoCappedBitrate }/>)
            }
            { ready && multiView && selectedInput == null &&
            <MultiView layout={inputsLayout}/>
            }
            { ready && inputsVisible.length === 0 && atLeastOneInputsFilter &&
            <Empty icon="filter"
                   text={<Translate id="genericLabel.INPUTS_FILTER_MATCH_EMPTY.text"/>}
                   subText={`(0/${inputsCount})`}/>
            }
            { !ready && <AWLoader active={true} position="centered"/> }
          </AWControlGrid>
        </div>
        <InputSettingsModal/>
        <MultiViewSettingsModal/>
        <MultiViewModal/>
      </Fragment>
    )
  }
}

InputSection.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  const {
    ready,
    inputsFilters,
    inputsLayout,
    mobileOutputsVisible,
  } = state.dashboard;

  const {
    inputs,
    inputIPProfiles,
  } = state.streamhub;

  let hasPrivilege = false;
  if(state.config.auth){
    hasPrivilege = state.identity.role === USER_ROLE_ADMIN || (state.identity.role === USER_ROLE_USER && state.config.auth.user.hasPrivilege);
  }

  return {
    hasPrivilege,
    ready,
    availableInputIPProfiles: getAvailableInputIPProfiles(state),
    inputIPProfiles,
    selectedInput: (ownProps.match.params.inputId) ? inputs.find(input => input.id === ownProps.match.params.inputId) : null,
    inputsCount: inputs.length,
    inputsVisible: getVisibleInputs(state),
    multiView: getVisibleMultiView(state),
    inputsFilters,
    inputsLayout,
    mobileOutputsVisible,
    canSetIPInput: canSetIPInput(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    callChangeInputsFilters: (filters) => dispatch(changeInputsFilters(filters)),
    callChangeInputsLayout: (layout) => dispatch(changeInputsLayout(layout)),
    callOpenIpInputsProfilesSettings: () => dispatch(ipInputsProfilesSettings(true)),
    callOpenNewIpInputProfileSettings: (input) => dispatch(ipInputsProfilesSettings(true, "-1", true, parseInt(input.id))),
    callOpenIpInputProfileSettings: (profileId) => dispatch(ipInputsProfilesSettings(true, profileId, true)),
    callInputAttachIPProfile: (input, profileId) => dispatch(inputAttachIPProfile(input, profileId)),
    callInputDetachIPProfile: (input, profileId) => dispatch(inputDetachIPProfile(input, profileId)),
    callInputDisconnect: (input) => dispatch(inputDisconnect(input)),
    callInputEditSettings: (id) => dispatch(inputEditSettings(id)),
    callInputPlaybackStart: (input, file) => dispatch(inputPlaybackStart(input, file)),
    callInputPlaybackStop: (input) => dispatch(inputPlaybackStop(input)),
    callInputPlaybackLoopOn: (input) => dispatch(inputPlaybackLoopOn(input)),
    callInputPlaybackLoopOff: (input) => dispatch(inputPlaybackLoopOff(input)),
    callInputVideoIFBEncoderBind: (input, encoderId) => dispatch(inputVideoIFBEncoderBind(input.id, encoderId)),
    callInputVideoIFBSourceBind: (input, sourceId) => dispatch(inputVideoIFBSourceBind(input.id, sourceId)),
    callInputEjectVideoIFB: (input) => dispatch(inputEjectVideoIFB(input)),
    callInputRemoteStartLive: (input) => dispatch(inputRemoteStartLive(input)),
    callInputRemoteStopLive: (input) => dispatch(inputRemoteStopLive(input)),
    callInputRemoteStopForward: (input) => dispatch(inputRemoteStopForward(input)),
    callInputRemoteSwitchProfile: (input, profileId) => dispatch(inputRemoteSwitchProfile(input, profileId)),
    callInputStart: (input) => dispatch(inputStart(input)),
    callInputStop: (input) => dispatch(inputStop(input)),
    callInputStartIntercom: (input) => dispatch(inputStartIntercom(input)),
    callInputStopIntercom: (input) => dispatch(inputStopIntercom(input)),
    callInputStartRecord: (input) => dispatch(inputStartRecord(input)),
    callInputStopRecord: (input) => dispatch(inputStopRecord(input)),
    callInputSubscribe: (input) => dispatch(inputSubscribe(input)),
    callInputChangeVideoCappedBitrate: (input, videoCappedBitrate) => dispatch(inputChangeVideoCappedBitrate(input, videoCappedBitrate)),
    callInputResetDroppedPacketsCounter: (input) => dispatch(inputResetDroppedPacketsCounter(input)),
    callInputRemoteControl: (input) => dispatch(inputRemoteControl(input.id)),
    callInputLatencyChange: (input, latency) => dispatch(inputChangeLatency(input, latency)),
    showInputDetails: (input) => {
      if(input.type === INPUT_TYPE_DEVICE){
        dispatch(inputSubscribe(input));
      }
      dispatch(inputEnterDetails(input));
      dispatch(push(`/dashboard/${input.id}`));
    },
    closeInputDetails: (input) => {
      if(input && input.type === INPUT_TYPE_DEVICE){
        dispatch(inputUnsubscribe(input));
      }
      dispatch(inputCloseDetails(input));
      dispatch(push('/dashboard'));
    },
    openHelpModal: (filename) => dispatch(openHelpModal(filename, true)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLocalize(InputSection)));