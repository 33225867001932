import PropTypes from "prop-types";
import React, {Fragment} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {Button, Form, FormGroup} from "reactstrap";
import {Formik} from "formik";
import Dropzone from "react-dropzone";
import AWModal from "@aviwest/ui-kit/dist/js/components/modal";
import AWCircularProgress from "@aviwest/ui-kit/dist/js/components/circular-progress";

import {hasFileExtension} from "../../../../utils/global-utils";

const propTypes = {
  uploading: PropTypes.bool.isRequired,
  uploadProgress: PropTypes.number.isRequired,
  uploadResult: PropTypes.oneOf([1, 2]), // 1: error, 2: ready to reboot
  onCancel: PropTypes.func.isRequired,
  onInterrupt: PropTypes.func.isRequired,
  onReboot: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired
};

const UpdateFirmwareModal = (props) => {
  const { onCancel, onReboot, onInterrupt, onUpdate, uploading, uploadProgress, uploadResult } = props;

  const handleFormSubmit = (values) => {
    onUpdate(values.file);
  };

  const handleValidation = (values) => {
    const errors = {};
    if(!values.file){
      errors.file = props.translate('genericLabel.REQUIRED_FIELD.text');
    }
    if(values.file && !hasFileExtension(values.file.name, 'fw')){
      errors.file = props.translate('genericLabel.INVALID_EXTENSION.text', { 'param.extension': '.fw' });
    }
    return errors;
  };

  return (
    <AWModal id="updateFirmware_modal"
             confirm={true}
             icon="update"
             onClose={ onCancel }
             open={true}
             title={<Translate id="genericLabel.UPDATE_STREAMHUB.text"/>}>
      <Formik initialValues={{
                file: null
              }}
              validate={ handleValidation }
              validateOnBlur={true}
              validateOnChange={true}
              onSubmit={ handleFormSubmit }>
        {({
            values,
            errors,
            dirty,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            /* and other goodies */
          }) => (
            <Form onSubmit={handleSubmit}>
              { uploading === false && uploadResult === null &&
                <Fragment>
                  <div className="confirm-text">
                    <Translate id="genericLabel.CONFIRM_UPDATE_STREAMHUB.text"/>
                    <div id="updateFirmware_modal_warning" className="indicator"><Translate id="genericLabel.CONNECTED_FIELD_UNITS_WILL_BE_DISCONNECTED.text"/></div>
                  </div>
                  <FormGroup>
                    <Dropzone id="updateFirmware_modal_dropzone" 
                              multiple={false}
                              accept=".fw"
                              onDrop={files => setFieldValue('file', files[0])}>
                      {({getRootProps, getInputProps, isDragActive, acceptedFiles}) => (
                        <div className={`dropzone ${isDragActive ? 'target' : (errors.file != null ? 'error' : '')}`} {...getRootProps()}>
                            <span className="placeholder">
                              {acceptedFiles.length === 0 && <Translate id="genericLabel.FIRMWARE_DROPZONE_PLACEHOLDER.text"/>}
                              {acceptedFiles.length > 0 &&
                              <div className="filename">
                                {acceptedFiles.map(acceptedFile => acceptedFile.name)}
                              </div>
                              }
                              {errors.file != null && errors.file}
                            </span>
                          <input {...getInputProps()} />
                        </div>
                      )}
                    </Dropzone>
                  </FormGroup>
                  <FormGroup className="buttons">
                    <Button id="updateFirmware_modal_updateButton" 
                            color="primary"
                            disabled={!dirty}
                            size="lg"
                            type="submit">
                    <Translate id="genericLabel.UPDATE.text"/>
                    </Button>
                  </FormGroup>
                </Fragment>
              }
              { uploading === true &&
              <div className="upload-progress">
                <AWCircularProgress progress={uploadProgress}/>
                <div className="buttons">
                  <Button id="updateFirmware_modal_interruptButton" 
                          onClick={onInterrupt}
                          size="lg">
                    <Translate id="genericLabel.CANCEL.text"/>
                  </Button>
                </div>
              </div>
              }
              { uploading === false && uploadResult !== null &&
                <Fragment>
                  <div className="confirm-text">
                    { uploadResult === 1 && <Translate id="genericLabel.FAILED_UPDATE_STREAMHUB.text"/> }
                    { uploadResult === 1 && <div className="indicator"><Translate id="genericLabel.INVALID_FIRMWARE_PACKAGE.text"/></div> }
                    { uploadResult === 2 && <Translate id="genericLabel.CONFIRM_UPDATE_STREAMHUB_RESTART.text"/> }
                  </div>
                  { uploadResult === 1 &&
                  <Button id="updateFirmware_modal_okInterruptButton" 
                          onClick={onInterrupt}
                          size="lg">
                    <Translate id="genericLabel.OK.text"/>
                  </Button>
                  }
                  { uploadResult === 2 &&
                  <FormGroup className="buttons">
                      <Button id="updateFirmware_modal_rebootCancelButton" 
                              className="btn-cancel"
                              size="lg"
                              onClick={onInterrupt}>
                        <Translate id="genericLabel.NO.text"/>
                      </Button>
                      <Button id="updateFirmware_modal_rebootConfirmButton" 
                              color="primary"
                              onClick={onReboot}
                              size="lg">
                        <Translate id="genericLabel.YES.text"/>
                      </Button>
                  </FormGroup>
                  }
                </Fragment>
              }
            </Form>
          )}
      </Formik>
    </AWModal>
  );
};

UpdateFirmwareModal.propTypes = propTypes;

export default withLocalize(UpdateFirmwareModal);