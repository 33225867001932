import PropTypes from 'prop-types';
import React from 'react';
import { AWControlGridItem } from '@aviwest/ui-kit/dist/js/components/control-grid';
import AWIcon from '@aviwest/ui-kit/dist/js/components/icon';

const propTypes = {
    onClick: PropTypes.func.isRequired
};

const NewOutputButton = (props) => {
    const {
        onClick
    } = props;
    return (
        <AWControlGridItem className="aw-output new" available collapsed onClick={onClick} id="dashboard_output_newButton">
            <AWIcon name="add" />
        </AWControlGridItem>
    );
};

NewOutputButton.propTypes = propTypes;

export default NewOutputButton;