import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import AWVuMeter from "@aviwest/ui-kit/dist/js/components/vu-meter";
import AWRxStoreFactory from '@aviwest/ui-kit/dist/js/rx/rx-store-factory';

const min = -50;
const max = 0;

const propTypes = {
  encoderId: PropTypes.string,
  inputId: PropTypes.string,
  outputId: PropTypes.string,
  ndiOutputId: PropTypes.string,
  horizontal: PropTypes.bool,
  limit: PropTypes.number,
  showDisabledBars: PropTypes.bool,
  showScale: PropTypes.bool
};

const defaultProps = {
  horizontal: false,
  limit: 8,
  showDisabledBars: true,
  showScale: true
};

const AudioLevels = (props) => {
  const { inputId, outputId, ndiOutputId, encoderId, horizontal, limit, showDisabledBars, showScale } = props;

  const [outputLevel, setOutputLevel] = useState();
  const [ndiOutputLevel, setNdiOutputLevel] = useState();
  const [encoderLevel, setEncoderLevel] = useState();
  const [inputLevel, setInputLevel] = useState();

  useEffect(() => {
    const subscriber = AWRxStoreFactory.getBasicStore('inputAudioLevels').getObservable(inputId).subscribe(setInputLevel);
    return () => {
      subscriber.unsubscribe();
    };
  }, [inputId]);

  useEffect(() => {
    const subscriber = AWRxStoreFactory.getBasicStore('outputAudioLevels').getObservable(outputId).subscribe(setOutputLevel);
    return () => {
      subscriber.unsubscribe();
    };
  }, [outputId]);

  useEffect(() => {
    const subscriber = AWRxStoreFactory.getBasicStore('ndiOutputAudioLevels').getObservable(ndiOutputId).subscribe(setNdiOutputLevel);
    return () => {
      subscriber.unsubscribe();
    };
  }, [ndiOutputId]);

  useEffect(() => {
    const subscriber = AWRxStoreFactory.getBasicStore('encoderAudioLevels').getObservable(encoderId).subscribe(setEncoderLevel);
    return () => {
      subscriber.unsubscribe();
    };
  }, [encoderId]);

  let levels = null;

  // For outputs, we try to get the physical preview, otherwise, we get the input one
  if (outputId && outputLevel) {
    levels = outputLevel;
  }
  else if (ndiOutputId && ndiOutputLevel) {
    levels = ndiOutputLevel;
  }
  else if (encoderId && encoderLevel) {
    levels = encoderLevel;
  }
  else if (inputId && inputLevel) {
    levels = inputLevel;
  }

  if (!levels) {
    return null;
  }
  //If we have more than 8 audio channels, we display 16, else we stay at 8
  const varLimit = levels.length > 8 ? 16 : limit;
  return (
    <div className={`audio-levels ${(levels.length>=8 && horizontal )? "vu_resize" : ""}`}>
      {[...Array(varLimit)].map((x, i) => {
        if (!levels[i] || (levels[i].decay === min && levels[i].peak === min)) {
          return (showDisabledBars ? <AWVuMeter key={i} disabled={!levels[i] ? true : false} peakValue={0} decayValue={0} /> : null)
        }
        if(horizontal && varLimit >= 8){
          return (
            <div className="">
              {i<8 &&
                <AWVuMeter key={i}
                           horizontal={horizontal}
                           decayValue={levels[i].decay ? levels[i].decay : 0}
                           peakValue={levels[i].peak ? levels[i].peak : 0}
                           min={min}
                           max={max} />
              }
              {i>=8 && <div className='vu_right'>
                <AWVuMeter key={i}
                          horizontal={horizontal}
                          decayValue={levels[i].decay ? levels[i].decay : 0}
                          peakValue={levels[i].peak ? levels[i].peak : 0}
                          min={min}
                          max={max} /></div>
              }
          </div>
            );
        }
        return (
          <AWVuMeter key={i}
          horizontal={horizontal}
          decayValue={levels[i].decay ? levels[i].decay : 0}
          peakValue={levels[i].peak ? levels[i].peak : 0}
          min={min}
          max={max} />
          );
        }
      )}

      { showScale && <div className="audio-scale">
        <div className="scale">
          <div className="scale-min"><span>{max} dB</span></div>
          <div className="scale-danger"><span>-6 dB</span></div>
          <div className="scale-warning"><span>-18 dB</span></div>
          <div className="scale-max"><span>{min} dB</span></div>
        </div>
      </div>}
    </div>
  );
};

AudioLevels.propTypes = propTypes;
AudioLevels.defaultProps = defaultProps;

export default AudioLevels;