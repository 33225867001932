import {
  SETTINGS_FILE_TRANSFER_CREATE,
  SETTINGS_FILE_TRANSFER_DELETE,
  SETTINGS_FILE_TRANSFER_TEST,
  SETTINGS_FILE_TRANSFER_TEST_RESET,
  SETTINGS_FILE_TRANSFER_UPDATE
} from "../../../../constants";

export const createFileTransfer = (fileTransfer) => {
  return {
    type: SETTINGS_FILE_TRANSFER_CREATE,
    fileTransfer
  }
};

export const updateFileTransfer = (fileTransfer) => {
  return {
    type: SETTINGS_FILE_TRANSFER_UPDATE,
    fileTransfer
  }
};

export const deleteFileTransfer = (fileTransferId) => {
  return {
    type: SETTINGS_FILE_TRANSFER_DELETE,
    fileTransferId
  }
};

export const testFileTransfer = (fileTransfer) => {
  return {
    type: SETTINGS_FILE_TRANSFER_TEST,
    fileTransfer
  }
};

export const testFileTransferReset = () => {
  return {
    type: SETTINGS_FILE_TRANSFER_TEST_RESET
  }
};