import PropTypes from "prop-types";
import React, {Fragment} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {Alert, Col, Row} from "reactstrap";
import DayJs from "dayjs";

import { licenseFeaturesPropTypes } from "../../../utils/models-prop-types";
import {LICENSE_STATUS_INVALID, LICENSE_STATUS_VALID, LICENSE_MS_TO_EXPIRATION} from "../../../constants";

const LICENSE_DATE_FORMAT = 'YYYY-MM-DD';

const propTypes = {
  features: licenseFeaturesPropTypes,
  status: PropTypes.oneOf([LICENSE_STATUS_VALID, LICENSE_STATUS_INVALID]),
  translate: PropTypes.func.isRequired, 
  message: PropTypes.string
};

const formatExpirationDate = (dateString, translate) => {
  if(dateString === '' || dateString === undefined ){
    return translate('genericLabel.PERMANENT.text');
  }
  return (
    <span className={(Date.parse(dateString) - Date.now()) < LICENSE_MS_TO_EXPIRATION ? 'text-warning' : ''}>
      {DayJs(dateString, LICENSE_DATE_FORMAT).format('YYYY-MM-DD')}
      { (Date.parse(dateString) - Date.now()) < 0 &&
        <span className="ml-1">(<Translate id="genericLabel.EXPIRED.text"/>)</span>
      }
    </span>
  )
};

const LicenseFeature = ({ name, label, features, translate }) => {
  const feature = features[name];
  if(!feature){
    return null;
  }
  let value = '';
  let details = null;
  let expirationDate = null;
  if(Array.isArray(feature)){
    // Count only not expirated feature (Bugs #17377)
    value = feature.reduce((total, currentFeature) => {
      if (currentFeature.expirationDate === ''
          || currentFeature.expirationDate === undefined
          || (Date.parse(currentFeature.expirationDate) - Date.now()) >= 0) {
        return total + currentFeature.count
      }
      else {
        return total
      }
    }, 0 );
    details = (
      <ul className="secondary">
        { feature.map((feat, index) => (
            <li id={name+'_values_'+index} 
                key={index} 
                value={feat.count} 
                date={DayJs(feat.expirationDate, LICENSE_DATE_FORMAT).format('YYYY-MM-DD') }>
                  {feat.count} {formatExpirationDate(feat.expirationDate, translate)}
            </li>
        ))}
      </ul>
    );
  }
  else {
    value = feature.enabled ? translate('genericLabel.YES.text') : translate('genericLabel.NO.text');
    expirationDate = feature.enabled ? formatExpirationDate(feature.expirationDate, translate) : null;
  }
  return (
    <Fragment>
      {label}
      <span id={name+'_total'} className="value">{value}</span> { expirationDate && <span id={name+'_expirationDate'} className="expiration-date">{expirationDate}</span> }
      {details}
    </Fragment>
  )
};

const LicenseDetails = (props) => {

  const {hardwareIdentifier, details, features, status, translate, orionConnectionStatus, hotdeployInProgress, message } = props;
  if(!features){
    return null;
  }
  const licenseExpirationDate = formatExpirationDate(features.expirationDate, translate);
  return (
    <div className="license">
      <div className="status">
        <Translate id="genericLabel.LICENSE_STATUS.text"/>: <span id='licenseStatus' className={ status === LICENSE_STATUS_VALID ? 'valid' : 'invalid' }>{ status }</span>
        { !orionConnectionStatus &&
        <span id='orionConnectionStatus' className="badge badge-dark"><Translate id="genericLabel.LICENSE_STANDALONE.text"/></span>
        }
        { orionConnectionStatus &&
        <span  id='orionConnectionStatus' className={`badge ${hotdeployInProgress ? 'badge-warning' : 'badge-success'}`}><Translate id="genericLabel.LICENSE_CONNECTED.text"/></span>
        }
        <span id='license_expirationDate' className="badge badge-dark ml-2">{licenseExpirationDate}</span>
      </div>
      <Row className="row details">
        <Col xs={12} sm={6}>
          <ul>
            <li id='license_hardwareIdentifier'>
              <Translate id="genericLabel.HARDWARE_ID.text"/>
              <span id='hardwareIdentifier.value' className="value">{ hardwareIdentifier }</span>
            </li>
            <li id='license_input'>
              <LicenseFeature features={features} label={ translate('genericLabel.INPUTS.text') } name="input" translate={translate}/>
            </li>
            { details['DMNGApp'] !== 0 &&
            <li id='license_DMNGApp'>
              <LicenseFeature features={features} label={ 'DMNG App' } name="DMNGApp" translate={translate}/>
            </li>
            }
            { details['DMNGApp'] === 0 &&
            <li id='license_MOJOPRO'>
              <LicenseFeature features={features} label={ translate('genericLabel.MOJOPRO.text') } name="MOJOPRO" translate={translate}/>
            </li>
            }
            <li  id='license_IPInput'>
              <LicenseFeature features={features} label={ translate('genericLabel.STANDARD_IP_PROTOCOLS.text') } name="IPInput" translate={translate}/>
            </li>
            <li  id='license_maxActiveOutputs'>
              <LicenseFeature features={features} label={ translate('genericLabel.MAX_OUTPUTS.text') } name="activeOutputs" translate={translate}/>
            </li>
            <li  id='license_physicalOutput'>
              <LicenseFeature features={features} label={ translate('genericLabel.LOCAL_OUTPUTS.text') } name="physicalOutput" translate={translate}/>
            </li>
            <li  id='license_IPOutput'>
              <LicenseFeature features={features} label={ translate('genericLabel.CONTRIBUTION_CHANNELS.text') } name="IPOutput" translate={translate}/>
            </li>
            <li  id='license_NDIInput'>
              <LicenseFeature features={features} label={ translate('genericLabel.NDI_INPUTS.text') } name="NDIInput" translate={translate}/>
            </li>
            <li  id='license_NDIOutput'>
              <LicenseFeature features={features} label={ translate('genericLabel.NDI_OUTPUTS.text') } name="NDIOutput" translate={translate}/>
            </li>
            <li  id='license_WebRTCInput'>
              <LicenseFeature features={features} label={ translate('genericLabel.WEB_RTC_INPUT.text') } name="WebRTCInput" translate={translate}/>
            </li>
            <li  id='license_SRTOnly'>
              <LicenseFeature features={features} label={ translate('genericLabel.SRT_ONLY.text') } name="SRTOutputsOnly" translate={translate}/>
            </li>
          </ul>
        </Col>
        <Col xs={12} sm={6}>
          <ul>
            <li id='license_encoder'>
              <LicenseFeature features={features} label={ translate('genericLabel.ENCODERS.text') } name="encoder" translate={translate}/>
            </li>
            <li id='license_FTPPush'>
              <LicenseFeature features={features} label={ translate('genericLabel.FILE_TRANSFER_DESTINATIONS.text') } name="FTPPush" translate={translate}/>
            </li>
            <li id='license_playback'>
              <LicenseFeature features={features} label={ translate('genericLabel.PLAYBACK.text') } name="playback" translate={translate}/>
            </li>
            <li id='license_record'>
              <LicenseFeature features={features} label={ translate('genericLabel.RECORD.text') } name="record" translate={translate}/>
            </li>
            <li id='license_intercom'>
              <LicenseFeature features={features} label={ translate('genericLabel.INTERCOM.text') } name="intercom" translate={translate}/>
            </li>
            <li id='license_AES67'>
              <LicenseFeature features={features} label={ translate('genericLabel.AES67_INTERCOM.text') } name="AES67" translate={translate}/>
            </li>
            <li id='license_AES'>
              <LicenseFeature features={features} label={ translate('genericLabel.AES_ENCRYPTION.text') } name="AES" translate={translate}/>
            </li>
            <li id='license_FourK'>
              <LicenseFeature features={features} label={ translate('genericLabel.UHD.text') } name="FourK" translate={translate}/>
            </li>
            <li id='license_multiView'>
              <LicenseFeature features={features} label={ translate('genericLabel.MULTI_VIEW.text') } name="multiView" translate={translate}/>
            </li>
            <li id='license_HotSpot'>
              <LicenseFeature features={features} label={ translate('genericLabel.HOT_SPOT.text') } name="HotSpot" translate={translate}/>
            </li>
            <li id='license_videoReturn'>
              <LicenseFeature features={features} label={ translate('genericLabel.VIDEO_RETURN.text') } name="videoReturn" translate={translate}/>
            </li>
            {(features.HotSpot_PayAsYouGo || features.MOJOPRO_PayAsYouGo) &&
            <li id='license_PayAsYouGo'>
              <Translate id="genericLabel.PAY_AS_YOU_GO.text"/>
              <ul className="secondary">
                <li id='license_PayAsYouGo.HotSpot'>
                  <LicenseFeature features={features} label={ translate('genericLabel.HOT_SPOT.text') } name="HotSpot_PayAsYouGo" translate={translate}/>
                </li>
                <li id='license_PayAsYouGo.MOJOPRO'>
                  <LicenseFeature features={features} label={ translate('genericLabel.MOJOPRO.text') } name="MOJOPRO_PayAsYouGo" translate={translate}/>
                </li>
              </ul>
            </li>
            }
          </ul>
        </Col>
      </Row>
      {features.hardwareIdentifier && features.hardwareIdentifier.toLowerCase() !== hardwareIdentifier.toLowerCase() &&
          <Alert color="danger">
            Your license is not matching your hardware
          </Alert>
          }
          {!!message &&
              <Alert color="danger">
                {message}
              </Alert>
              }
    </div>
  )
};

LicenseDetails.propTypes = propTypes;

export default withLocalize(LicenseDetails);
