import PropTypes from "prop-types";
import React, {useState} from "react";
import {Button, Collapse} from "reactstrap";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  renderTitle: PropTypes.func.isRequired,
};

const Accordion = (props) => {

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => setIsOpen(!isOpen);

  const { children, renderTitle, className } = props;

  return (
    <div className={`accordion ${className ? className : ''}`}>
      <Button className="basic toggable" onClick={handleToggle}>
        <div className="title">
          { renderTitle() }
        </div>
        <AWIcon name={ isOpen ? 'chevron_up' : 'chevron_down' }/>
      </Button>
      <Collapse isOpen={isOpen}>
        {children}
      </Collapse>
    </div>
  );
};

Accordion.propTypes = propTypes;

export default Accordion;
