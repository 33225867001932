import Axios from 'axios'

import Logger from './logger';
import {
  ERROR_BAD_NETWORK,
  ERROR_LOGIN_WRONG_LOGIN,
  ERROR_LOGIN_WRONG_PASSWORD,
  ERROR_LOGIN_TOO_MANY_ATTEMPTS,
  ERROR_UNKNOWN,
  HTTP_CODE_FORBIDDEN,
  HTTP_CODE_LOCKED
} from '../constants'

export default class Api {
  instance;
  authorization;

  constructor(config) {
    this.instance = Axios.create(config);
  }

  fetchRequest = (url, requestParam, bodyParams, method = 'GET', headers = null) => {
    headers = {
      ...headers,
      'Content-Type': 'application/json'
    };

    return this.instance({
      method: method,
      url: url,
      data: bodyParams,
      params: requestParam,
      headers: headers
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error.response) {
        Logger.error('Error occurred : ', error.response);
        if(error.response.status === HTTP_CODE_FORBIDDEN && error.response.data && error.response.data.error){
          switch(error.response.data.error){
            case 'INVALID_LOGIN':
              throw ERROR_LOGIN_WRONG_LOGIN;
            case 'INVALID_PASSWORD':
              throw ERROR_LOGIN_WRONG_PASSWORD;
            default:
              break;
          }
        }
        if(error.response.status === HTTP_CODE_LOCKED && error.response.data && error.response.data.error){
          throw ERROR_LOGIN_TOO_MANY_ATTEMPTS;
        }
        throw ERROR_UNKNOWN;
      }
      throw ERROR_BAD_NETWORK;
    });
  };

  logIn = (username, password) => {
    const url = '/login';
    const bodyParams = { username, password };
    return this.fetchRequest(url, null, bodyParams, 'POST');
  };

}

