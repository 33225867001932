import PropTypes from "prop-types";
import React from "react";
import {Translate} from "react-localize-redux";
import {Button} from "reactstrap";
import AWModal from "@aviwest/ui-kit/dist/js/components/modal";
import AWLoader from "@aviwest/ui-kit/dist/js/components/loader";

const propTypes = {
  onRefresh: PropTypes.func.isRequired,
  reason: PropTypes.string
};

const renderReason = (reason) => {
  switch(reason){
      case 'noConnection': return <Translate id="genericLabel.NO_CONNECTION.text"/>;
      case 'restartStudio': return <Translate id="genericLabel.WAIT_UNTIL_STREAMHUB_APPLICATION_RESTARTS.text"/>;
      case 'rebootServer': return <Translate id="genericLabel.WAIT_UNTIL_SERVER_REBOOTS.text"/>;
      case 'haltServer': return <Translate id="genericLabel.SERVER_IS_SHUTTING_DOWN.text"/>;
      case 'restartNodejs': return <Translate id="genericLabel.WAIT_WHILE_STREAMHUB_SERVICE_NODEJS_RESTARTS.text"/>;
      case 'updateStudio': return <Translate id="genericLabel.WAIT_WHILE_STREAMHUB_SERVICE_IS_UPDATING.text"/>;
      case 'waitForServer': return <Translate id="genericLabel.WAIT_WHILE_STREAMHUB_SERVICE_IS_STARTING.text"/>;
      default: return
  }
}

const ServerWaitingModal = (props) => {
  const { onRefresh, reason } = props;
  return (
  <AWModal id="serverWaiting_modal"
           confirm={true}
           icon="info_circle"
           open={true}
           title={<Translate id="genericLabel.STREAMHUB.text"/>}>
      <div className="confirm-text">
        { renderReason (reason) }
        { reason !== 'haltServer' &&
          <div className="indicator"><Translate id="genericLabel.REFRESH_PAGE_HELP.text"/></div>
        }
      </div>
      { reason !== 'haltServer' &&
      <>
        <AWLoader active={true} position="inline"/>
        <div className="buttons">
          <Button color="primary"
                  size="lg"
                  onClick={onRefresh}>
            <Translate id="genericLabel.REFRESH_PAGE.text"/>
          </Button>
        </div>
      </>
      }
    </AWModal>
  );
};

ServerWaitingModal.propTypes = propTypes;

export default ServerWaitingModal;