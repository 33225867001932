import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { dismissBanner } from '../../misc/banner.actions';
import AWBanner from "@aviwest/ui-kit/dist/js/components/banner";
import {withLocalize} from "react-localize-redux";

const propTypes = {
  banner: PropTypes.object,
  callDismissBanner: PropTypes.func.isRequired
};

const BannerSection = (props) => {
  const { callDismissBanner, banner, translate } = props;
  const bannerProps = banner ? {
    ...banner,
    title: banner.title ? translate(banner.title) : undefined,
    message: banner.message ? translate(banner.message) : undefined,
    action: banner.action ? {
      ...banner.action,
      title: banner.action.title ? translate(banner.action.title) : undefined
    } : undefined
  } : {};
  return (
    <div id="banner-section">
      { banner && <AWBanner { ...bannerProps } onDismiss={ () => callDismissBanner(banner.id) }/>}
    </div>
  )
};

BannerSection.propTypes = propTypes;

const mapStateToProps = (state) => {
  const { banner } = state;
  return {
    banner: banner.presentedBanner
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    callDismissBanner: (id) => dispatch(dismissBanner(id))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(BannerSection));