import PropTypes from 'prop-types';
import React from 'react';
import {isString} from "../../../utils/string-utils";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

const propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  subText: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

const Empty = (props) => {

    const { icon, text, subText } = props;
    return (
      <div className="empty-container">
        <div className="empty">
          { isString(icon) && <AWIcon name={icon}/> }
          { !isString(icon) && icon }
          <div className="text">
            { text }
          </div>
          { subText && <div className="subtext">
            { subText }
          </div>
          }
        </div>
      </div>
    )
};

Empty.propTypes = propTypes;

export default Empty;