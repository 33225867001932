import {
  HELP_MODAL
} from '../constants';

const initialState = {
  opened: false,
  filename: null
};

const helpReducer = (state = initialState, action) => {

  if(action.type === HELP_MODAL){
      return {
        ...state,
        opened: action.open,
        filename: action.filename
      };
  }

  return state;
};

export default helpReducer;