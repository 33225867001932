import {Formik} from "formik";
import PropTypes from "prop-types";
import React, {useState} from "react";
import {Button, Form, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {Translate, withLocalize} from "react-localize-redux";

import {
  inputRtspURI
} from "../../../../misc/streamhub.adapters";
import {generateUniqueId, isEmptyString, isGroupValid} from "../../../../utils/string-utils";
import {DEJITTER_BUFFER_MAX, DEJITTER_BUFFER_MIN, DEJITTER_BUFFER_DEFAULT, RTSP} from "../../../../constants";
import PasswordRevealInput from "../../../common/password-reveal-input";
import AWConfirm from "@aviwest/ui-kit/dist/js/components/confirm";
import HelpLayout from "../../../common/help-layout";

const propTypes = {
  config: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    host: PropTypes.string.isRequired,
    port: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    login: PropTypes.string,
    password: PropTypes.string,
    uniqueId: PropTypes.string
  }),
  forbiddenNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  alert: PropTypes.node,
  protocol: PropTypes.node
};

const RTSPForm = (props) => {
  const { config, forbiddenNames, translate, alert, protocol } = props;
  const [passwordProtected, setPasswordProtected] = useState(config && config.password ? true : false)

  const uriList = (values) => {
    return [inputRtspURI(values)];
  };

  const handleFormSubmit = (values) => {

    props.onSubmit(values);
  };

  const handleValidation = (values) => {
    const errors = {};

    // Name
    if(isEmptyString(values.name)){
      errors.name = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if(values.name.length >= 32){
      errors.name = 'genericLabel.TOO_LONG.text';
    }
    else if(forbiddenNames.indexOf(values.name) !== -1){
      errors.name = 'genericLabel.DUPLICATED_VALUES.text';
    }
    else if(!isGroupValid(values.name)){
      errors.name = 'genericLabel.INVALID_FORMAT.text'
    }

    // Host
    if(isEmptyString(values.host)){
      errors.host = 'genericLabel.REQUIRED_FIELD.text';
    }

    // Port
    if(isEmptyString(values.host)){
      errors.port = 'genericLabel.REQUIRED_FIELD.text';
    }

    // Label
    if(isEmptyString(values.label.length)){
      errors.label = 'genericLabel.REQUIRED_FIELD.text';
    }

    if (isEmptyString(values.login) && !isEmptyString(values.password)) {
      errors.login = 'genericLabel.REQUIRED_FIELD.text';
    }

    if (!isEmptyString(values.login) && isEmptyString(values.password)) {
      errors.password = 'genericLabel.REQUIRED_FIELD.text';
    } else {
      delete errors.password;
    }

    // Password with length > 32 characters are not encrypted (Bugs #15905)
    if((!config || (config && config.password !== values.password)) && values.password.length > 32) {
      errors.password = 'genericLabel.TOO_LONG.text';
    }

    // DeJitterBuffer
    if(isEmptyString(values.dejitterBuffer)){
      errors.dejitterBuffer = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if(parseInt(values.dejitterBuffer) < DEJITTER_BUFFER_MIN || parseInt(values.dejitterBuffer) > DEJITTER_BUFFER_MAX){
      errors.dejitterBuffer = 'genericLabel.INVALID_FORMAT.text';
    }

    return errors;
  };

  return (
    <Formik initialValues={{
              id: config ? config.id : undefined,
              type: RTSP,
              name: config ? config.name : '',
              host: config ? config.host : '',
              port: config ? config.port : 554,
              label: config ? config.label : '',
              login: config && config.login ? config.login : '',
              password: config && config.password ? config.password : '',
              dejitterBuffer: config ? config.dejitterBuffer : DEJITTER_BUFFER_DEFAULT,
              uniqueId: config && config.uniqueId ? config.uniqueId : generateUniqueId(),
            }}
            validate={ handleValidation }
            validateOnBlur={false}
            validateOnChange={true}
            onSubmit={ handleFormSubmit }>
      {({
          values,
          errors,
          dirty,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue
          /* and other goodies */
        }) => (
        <Form onSubmit={ handleSubmit }>

          <HelpLayout
          filename={`c_sh_rtsp_input_profile.html`}
          form={<fieldset disabled={config && config.enable === true}>
            {alert}
            {protocol}

            <FormGroup>
              <Label for="name">
                <Translate id="genericLabel.NAME.text"/>
              </Label>
              <Input type="text"
                    name="name"
                    id="settings_rtsp_name"
                    invalid={errors.name !== undefined}
                    placeholder={ translate('genericLabel.NAME.text') }
                    value={values.name}
                    onBlur={handleBlur}
                    onChange={handleChange}/>
              <Input type="hidden"
                     name="uniqueId"
                     id="uniqueId"
                     readOnly={true}
                     value={values.uniqueId}/>      
              <FormFeedback>
                <Translate id={errors.name} />
              </FormFeedback>
            </FormGroup>

            <FormGroup>
              <Label for="host">
                <Translate id="genericLabel.HOST.text"/>
              </Label>
              <Input type="text"
                    name="host"
                    id="settings_rtsp_host"
                    invalid={errors.host !== undefined}
                    placeholder={ translate('genericLabel.HOST.text') }
                    value={values.host}
                    onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.host} />
              </FormFeedback>
            </FormGroup>

            <FormGroup>
              <Label for="port">
                <Translate id="genericLabel.PORT.text"/>
              </Label>
              <Input type="number"
                    name="port"
                    id="settings_rtsp_port"
                    invalid={errors.port !== undefined}
                    placeholder={ translate('genericLabel.PORT.text') }
                    value={values.port}
                    onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.port} />
              </FormFeedback>
            </FormGroup>

            <FormGroup>
              <Label for="label">
                <Translate id="genericLabel.LABEL.text"/>
              </Label>
              <Input type="text"
                    name="label"
                    id="settings_rtsp_label"
                    invalid={errors.label !== undefined}
                    placeholder={ translate('genericLabel.LABEL.text') }
                    value={values.label}
                    onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.label} />
              </FormFeedback>
            </FormGroup>

            <FormGroup check>
              <Label check>
                <Input type="checkbox"
                      name="passwordProtected"
                      id="settings_rtsp_passwordProtected"
                      onChange={() => {
                        setPasswordProtected(!passwordProtected);
                        setFieldValue('login', '');
                        setFieldValue('password', '');
                      }}
                      checked={passwordProtected}/>{' '}
                <Translate id="genericLabel.PASSWORD_PROTECTED.text"/>
              </Label>
            </FormGroup>
            { passwordProtected &&
            <>
              <FormGroup>
                <Label for="login">
                  <Translate id="genericLabel.LOGIN.text"/>
                </Label>
                <Input type="text"
                      name="login"
                      id="settings_rtsp_login"
                      invalid={errors.login !== undefined}
                      placeholder={ translate('genericLabel.LOGIN.text') }
                      value={values.login}
                      onChange={handleChange}/>
                <FormFeedback>
                  <Translate id={errors.login} />
                </FormFeedback>
              </FormGroup>

              <FormGroup>
                <Label for="password">
                  <Translate id="genericLabel.PASSWORD.text"/>
                </Label>
                <PasswordRevealInput name="password"
                                    id="settings_rtsp_password"
                                    setFieldValue={setFieldValue}
                                    invalid={errors.password !== undefined}
                                    placeholder={ translate('genericLabel.PASSWORD.text') }
                                    value={values.password}
                                    onChange={handleChange}
                                    error={errors.password}/>
              </FormGroup>
            </>
            }
            <FormGroup>
              <Label for="dejitterBuffer">
                <Translate id="genericLabel.DEJITTER_BUFFER.text"/>
              </Label>
              <Input type="number"
                    name="dejitterBuffer"
                    id="settings_rtsp_dejitterBuffer"
                    invalid={errors.dejitterBuffer !== undefined}
                    min={DEJITTER_BUFFER_MIN}
                    max={DEJITTER_BUFFER_MAX}
                    placeholder={ translate('genericLabel.DEJITTER_BUFFER.text') }
                    value={values.dejitterBuffer}
                    onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.dejitterBuffer} />
              </FormFeedback>
              <div className="indicator">
                <Translate id={`genericLabel.DEJITTER_BUFFER_HELP.text`}/>
              </div>
            </FormGroup>

            <div className="uri-preview">
              { uriList(values).map((uri, index) => (<div key={`${uri}-${index}`} id="settings_rtsp_uriPreview" className="uri">{uri}</div>)) }
            </div>
            </fieldset>}

            buttons={<FormGroup className="buttons">
              { props.onDelete && !props.backToDashboard &&
              <AWConfirm
                onConfirm={props.onDelete}
                confirmationText={ props.translate("genericLabel.SECOND_CLICK_CONFIRM.text") }
                render={(ref, onClick) => (
                  <Button id="settings_rtsp_deleteButton" 
                    innerRef={ref}
                    onClick={onClick}
                    disabled={config && config.enable === true}
                    className="mr-auto"
                    outline
                    color="primary">
                    <Translate id="genericLabel.DELETE.text"/>
                  </Button>
                )} />
              }
              { props.onCancel && !props.backToDashboard &&
              <Button id="settings_rtsp_cancelButton"
                onClick={props.onCancel}
                outline
                color="primary">
                <Translate id="genericLabel.CANCEL.text"/>
              </Button>
              }
              <Button id="settings_rtsp_saveButton"
                      color="primary"
                      disabled={!dirty}
                      type="submit">
                <Translate id="genericLabel.SAVE.text"/>
              </Button>
            </FormGroup>
          } />
        </Form>
      )}
    </Formik>
  );
};

RTSPForm.propTypes = propTypes;

export default withLocalize(RTSPForm);