import {
  IDENTITY_CHECKED,
  LOGGED_IN,
  LOGGED_OUT,
  RECEIVED_USER_ROLE,
  RECEIVED_NEW_TOKEN
} from '../constants';

// const initialState = {
//   username: null,
//   token: null,
//   loggedAt: null,
//   role: null
// };

const initialState = {
  checked: false,
  changePasswordRequired: false
};

const identityReducer = (state = initialState, action) => {
  switch(action.type){

    case IDENTITY_CHECKED:
      return {
        ...state,
        checked: true
      };

    case LOGGED_IN:
      return {
        ...state,
        username: action.username,
        token: action.token,
        loggedAt: new Date()
      };

    case RECEIVED_USER_ROLE:
      return {
        ...state,
        role: action.role,
        changePasswordRequired: action.changePasswordRequired
      };

    case RECEIVED_NEW_TOKEN:
      return {
        ...state,
        token: action.token
      };

    case LOGGED_OUT:
      return {
        checked: true
      };

    default:
      return state;
  }
};

export default identityReducer;