import { localizeReducer } from 'react-localize-redux';

import BannerReducer from './misc/banner.reducer';
import CapabilitiesReducer from "./misc/capabilities.reducer";
import ConfigReducer from './misc/config.reducer';
import DashboardReducer from './components/pages/dashboard/dashboard.reducer';
import DatastoreReducer from './misc/datastore.reducer';
import FilesReducer from './components/pages/files/files.reducer';
import IdentityReducer from './misc/identity.reducer';
import LoginReducer from './components/pages/login/login.reducer';
import SettingsReducer from './components/pages/settings/settings.reducer';
import StreamhubReducer from './misc/streamhub.reducer';
import ToolsReducer from './components/pages/tools/tools.reducer';
import LicenseReducer from "./misc/license.reducer";
import HelpReducer from "./misc/help.reducer";


export default {
  banner: BannerReducer,
  capabilities: CapabilitiesReducer,
  config: ConfigReducer,
  localize: localizeReducer,
  login: LoginReducer,
  dashboard: DashboardReducer,
  files: FilesReducer,
  identity: IdentityReducer,
  settings: SettingsReducer,
  streamhub: StreamhubReducer,
  tools: ToolsReducer,
  datastore: DatastoreReducer,
  license: LicenseReducer,
  help: HelpReducer
}