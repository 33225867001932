import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getAvailableIPOutputProtocols } from "../../../../misc/capabilities.selectors";
import { Button } from 'reactstrap';
import AWIcon from '@aviwest/ui-kit/dist/js/components/icon';
import { outputIcon } from '../../../../utils/global-utils';
import { OUTPUT_TYPE_IP } from '../../../../constants';

const propTypes = {
  onProfileCreation: PropTypes.func.isRequired,
  protocols: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const NewInputIPProfile = (props) => {
  const { protocols, onProfileCreation, license } = props;

  return (<div id="ipOutput_newProfile" className="new-profile">
    { license.details.SRTOutputsOnly &&
      <Button
        id={"ipOutput_SRT_button"}
        className="square protocol"
        key={"SRT"}
        onClick={() => onProfileCreation("SRT")}>
        <AWIcon name={outputIcon(OUTPUT_TYPE_IP, "SRT")} />
      </Button>
    }
    { !license.details.SRTOutputsOnly && protocols.map(protocol => (
      <Button
        id={"ipOutput_"+protocol+"_button"}
        className="square protocol"
        key={protocol}
        onClick={() => onProfileCreation(protocol)}>
        <AWIcon name={outputIcon(OUTPUT_TYPE_IP, protocol)} />
      </Button>
    ))}
  </div>);
}


const mapStateToProps = (state, ownProps) => {

  return {
    protocols: getAvailableIPOutputProtocols(state),
    license: state.license,
  }
};

NewInputIPProfile.propTypes = propTypes;

export default connect(mapStateToProps, {})(NewInputIPProfile);