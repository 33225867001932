import PropTypes from 'prop-types';
import React from 'react';

import {
  DEVICE_TYPE_AIR,
  DEVICE_TYPE_HE4000,
  DEVICE_TYPE_RACK,
  DEVICE_TYPE_MOJOPRO_APP,
  DEVICE_TYPE_MOJOPRO_LAPTOP,
  DEVICE_TYPE_PRO,
  DEVICE_TYPE_STUDIO
} from "../../../constants";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

const propTypes = {
  deviceType: PropTypes.oneOf([DEVICE_TYPE_AIR, DEVICE_TYPE_HE4000, DEVICE_TYPE_RACK, DEVICE_TYPE_MOJOPRO_APP, DEVICE_TYPE_MOJOPRO_LAPTOP, DEVICE_TYPE_PRO, DEVICE_TYPE_STUDIO]).isRequired
};

const DeviceIcon = (props) => {
  const { deviceType, ...otherProps } = props;
  switch(deviceType){
    case DEVICE_TYPE_AIR:
      return (
      <AWIcon name="fu_air" {...otherProps}/>
      )
    case DEVICE_TYPE_HE4000:
      return (
      <AWIcon name="fu_he4000" {...otherProps}/>
      )
    case DEVICE_TYPE_RACK:
      return (
      <AWIcon name="fu_rack_series" {...otherProps}/>
      )
    case DEVICE_TYPE_MOJOPRO_APP:
      return (
      <AWIcon name="fu_mojopro_smartphone" {...otherProps}/>
      )
    case DEVICE_TYPE_MOJOPRO_LAPTOP:
      return (
      <AWIcon name="fu_mojopro_laptop" {...otherProps}/>
      )
    case DEVICE_TYPE_PRO:
      return (
      <AWIcon name="fu_pro" {...otherProps}/>
      )
    case DEVICE_TYPE_STUDIO:
      return (
      <AWIcon name="fu_streamhub" {...otherProps}/>
      )
    default:
      return null
  }
};

DeviceIcon.propTypes = propTypes;

export default DeviceIcon;