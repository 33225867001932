import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {XAxis, YAxis, LineSeries, XYPlot, HorizontalGridLines, VerticalGridLines, DiscreteColorLegend} from 'react-vis';
import AutoSizer from "react-virtualized-auto-sizer";
import { connect } from 'react-redux';
import DayJS from 'dayjs';
import {withLocalize} from 'react-localize-redux';

import '../../../../../node_modules/react-vis/dist/style.css';
import {GRAPH_COLORS, GRAPH_GRID_COLOR, GRAPH_LEGEND_COLOR, GRAPH_MIN_WIDTH} from "../../../../constants";
import { AWIcon, AWModal } from '@aviwest/ui-kit';
import { Button } from 'reactstrap';

 const propTypes = {
  infos: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      timestamp: PropTypes.number.isRequired,
      cpuPercent: PropTypes.number.isRequired,
      memoryPercent: PropTypes.number.isRequired,
      memoryTotal: PropTypes.number.isRequired,
    })).isRequired,
  }),
   translate: PropTypes.func.isRequired,
   onCancel: PropTypes.bool.isRequired,
};

const margins= {
  left: 50,
  right: 50,
  bottom: 18
};

const tickTotalX = 4;
const tickTotalY = 4;
const tickWidth = 0;

class Graph extends Component {

  constructor(props){
    super(props);

    this.toggleLegend = this.toggleLegend.bind(this);

    this.state = {
      legend: false
    };
    this.stateGraph = [];

    this.props.services.map((service,index) => {
      this.stateGraph.push(service.data[service.data.length -1].svcName);
    });
  }

  toggleLegend() {
    this.setState({
      legend: !this.state.legend
    });
  }

  toggleGraph(service){
    if(this.stateGraph.includes(service)){
      this.stateGraph = this.stateGraph.filter(item => item !== service)
    }else{
      this.stateGraph.push(service)
    }
  }

  formatBitrate(value, index, scale, tickTotal){
    return `${scale.tickFormat(tickTotal, '~s')(value)}`;
  }

  graphsRender(servicesTarget, servicestargetMax, targetName, servicesTitles, typeTarget){
    return (
    <div>{targetName}
        <AutoSizer disableHeight>
            {({ width }) => {
              return width > GRAPH_MIN_WIDTH ?
                <div className="graph">
                  <div className="base">
                    <XYPlot
                      getX={d => d.timestamp }
                      getY={d => d[typeTarget] }
                      xType="time"
                      yDomain={[-0.1, Math.max(...servicestargetMax[typeTarget])]}
                      height={200}
                      width={width}
                      margin={margins}>
                      <HorizontalGridLines style={{ stroke: GRAPH_GRID_COLOR }} tickTotal={tickTotalY}/>
                      <VerticalGridLines style={{ stroke: GRAPH_GRID_COLOR }} tickTotal={tickTotalX}/>
                      <XAxis style={{ line: {stroke: GRAPH_GRID_COLOR}, text: {stroke: GRAPH_LEGEND_COLOR}}} tickTotal={tickTotalX} tickFormat={v => `${DayJS(v).format('HH:mm:ss')}`} tickSizeOuter={0} tickSizeInner={tickWidth}/>
                      <YAxis title={"PERCENT"} style={{ line: {stroke: GRAPH_GRID_COLOR}, text: {stroke: GRAPH_LEGEND_COLOR}}} tickTotal={tickTotalY} tickFormat={ this.formatBitrate } tickSizeOuter={0} tickSizeInner={tickWidth}/>
                      <LineSeries getNull={(d) => d.hidden !== true} data={servicesTarget[0].serv.data} color={GRAPH_COLORS[servicesTarget[0].i]}/>
                    </XYPlot>
                  </div>
                  {servicesTarget.map((service,index)=>{
                    if(index>=1){
                      return(
                      <div className="overlay" onMouseOver={ this.toggleLegend } onMouseOut={ this.toggleLegend }>
                    <XYPlot
                      getX={d => d.timestamp }
                      getY={d => d[typeTarget] }
                      xType="time"
                      yDomain={[-0.1, Math.max(...servicestargetMax[typeTarget])]}
                      height={200 }
                      width={width}
                      margin={margins}>
                      <LineSeries getNull={(d) => d.hidden !== true} data={service.serv.data} color={GRAPH_COLORS[service.i]}/>
                    </XYPlot>
                  </div>)}
                })}
                  { this.state.legend &&
                    <div className="overlay">
                      { 
                      <DiscreteColorLegend
                        width={width}
                        orientation="horizontal"
                        items={
                          servicesTitles
                        }
                      />}
                    </div>
                  } 
              </div> : null;
            }}
        </AutoSizer>
      </div>)
  }

  maxService(service, type){
    var max;
    for (let i = 0; i < service.data.length; i++) {
      const element = service.data[i][type];
      max = max>element ? max : element
    };
    return max;
  }

  render(){
    if(!this.props.info){
      
      return <div>NULL INFOS</div>;
    }
    if(!this.props.services){
      return <div>NULL SERVICES</div>;
    }

     
    var inputs = [], inputsTitles=[], inputsmax={cpu:[],mem:[]};
    var outputs=[],outputsTitles=[], outputsmax={cpu:[],mem:[]};
    var encoders=[],encodersTitles=[], encodersmax={cpu:[],mem:[]};
    this.props.services.map((service,index) => {
     // let maxH = Math.max(this.props.services[i].maxCpu ,this.props.services[i].maxMem );
      //maxH = maxH > 1 ? maxH*1.2 : 1;
      if(this.stateGraph.includes(service.data[service.data.length -1].svcName)){
      if(service.data[service.data.length -1].type==="Input" || service.data[service.data.length -1].type==="Mosaic" || service.data[service.data.length -1].type==="Recorder"){
          inputs.push({serv:service, i:index});
          inputsTitles.push({title: service.data[service.data.length -1].svcName, color: GRAPH_COLORS[index], strokeWidth:10});
          inputsmax.cpu.push(this.maxService(service, 'cpu')*1.4);
          inputsmax.mem.push(this.maxService(service, 'mem')*1.4);
        }else if(service.data[service.data.length -1].type==="NDI Output" || service.data[service.data.length -1].type==="IP Output"){
          outputs.push({serv:service, i:index});
          outputsmax.cpu.push(this.maxService(service, 'cpu')*1.4);
          outputsmax.mem.push(this.maxService(service, 'mem')*1.4);
          outputsTitles.push({title: service.data[service.data.length -1].svcName, color: GRAPH_COLORS[index], strokeWidth:10});
        }else if(service.data[service.data.length -1].type==="Encoder"){
          encoders.push({serv:service, i:index});
          encodersmax.cpu.push(this.maxService(service, 'cpu')*1.4);
          encodersmax.mem.push(this.maxService(service, 'mem'));
          encodersTitles.push({title: service.data[service.data.length -1].svcName, color: GRAPH_COLORS[index], strokeWidth:10});
        };}
    });
    

    return ( 
    <AWModal id="ServicesGraph_modal"
             confirm={true}
             title=""
             onClose={ this.props.onCancel }
             open={true}>
        {!inputs[0] && !outputs[0] && !encoders[0] &&
        <div>Launch a service to see the graphs</div>}
        <div>
        {this.props.services.map((service,index)=>{
            return (<Button
             className="basic"
             active={this.stateGraph.includes(service.data[service.data.length -1].svcName)}
             style={{background: GRAPH_COLORS[index]}}
             onClick={() => this.toggleGraph(service.data[service.data.length -1].svcName)}>
               <AWIcon name="chart_line" style={{marginRight: '0.2rem'}}/>
               {service.data[service.data.length -1].svcName}
           </Button>)
          })}</div>
      <div>
      { inputs[0] &&
        this.graphsRender(inputs,inputsmax,"Inputs CPU",inputsTitles,"cpu")}
      { inputs[0] &&
        this.graphsRender(inputs,inputsmax,"Memory",inputsTitles,"mem")} 

      { outputs[0] &&
        this.graphsRender(outputs,outputsmax,"Ouputs CPU",outputsTitles,"cpu")}
      { outputs[0] &&
        this.graphsRender(outputs,outputsmax,"Memory",outputsTitles,"mem")}
        
      { encoders[0] &&
        this.graphsRender(encoders,encodersmax,"Encoders CPU",encodersTitles,"cpu")}      
      { encoders[0] &&
        this.graphsRender(encoders,encodersmax,"Memory",encodersTitles,"mem")}
      </div>
      </AWModal>
    );
  }
}

Graph.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  return {
    infos: state.datastore.monitorStreamStats.cpuPercent,
    services: state.datastore.servicesMonitorStats
  };
};

export default connect(mapStateToProps)(withLocalize(Graph));