import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {Translate, withLocalize} from 'react-localize-redux';
import { connect } from 'react-redux';
import {Alert, Button, Form, FormFeedback, FormGroup, Input, Label} from 'reactstrap';
import { Formik} from "formik";


import { isEmptyString } from '../../../../../utils/string-utils';
import {STATUS_LIVE, STATUS_OFF, STATUS_ON} from "../../../../../constants";
import {lostStreamModePropTypes} from "../../../../../utils/models-prop-types";
import {getAvailableHardwareOutputStandards, getAvailableLostStreamModes, getAvailableVideoCards} from "../../../../../misc/capabilities.selectors";
import HelpLayout from '../../../../common/help-layout';
import CustomPatternForm from '../../../settings/custom-pattern/custom-pattern-form';
import {SETTINGS_OUTPUT_CUSTOM_PATTERN_PREFIX} from '../../../../../constants'
import {getBasebandPlayerOutputNames} from "../../../../../misc/config.selectors";
import { outputMatchingVideoCard } from '../../../../../utils/global-utils';

const MIN_REF_OFFSET = -511;
const MAX_REF_OFFSET = 511;
const CUSTOM_PATTERN_VALUE = 3;

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  forbiddenNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  outputId: PropTypes.string.isRequired,
  outputStatus: PropTypes.oneOf([STATUS_LIVE, STATUS_OFF, STATUS_ON]),
  config: PropTypes.shape({
    name: PropTypes.string.isRequired,
    outputStandard: PropTypes.string.isRequired,
    sameAsInput: PropTypes.bool.isRequired,
    lostStreamMode: PropTypes.number.isRequired,
    lostStreamPolicy: PropTypes.bool,
    lostPatternMode: PropTypes.number.isRequired,
    refTimingOffset: PropTypes.number.isRequired,
    enableDisplayName: PropTypes.bool,
    enableMulticast: PropTypes.bool,
    ttl: PropTypes.number,
    destinations: PropTypes.string,
    video: PropTypes.shape({
      redundancy: PropTypes.bool,
    })
  }),
  lostStreamModes: PropTypes.arrayOf(lostStreamModePropTypes).isRequired,
  outputStandards: PropTypes.arrayOf(PropTypes.string).isRequired,
  localIps: PropTypes.arrayOf(PropTypes.string),
  licenceStatus: PropTypes.bool,
};

class GeneralForm extends Component {

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCustomPattern = this.handleCustomPattern.bind(this);

    this.state = {
      customPatternFormVisible: false
    }
  }

  componentDidMount(){
    if(this.props.config.lostStreamMode === CUSTOM_PATTERN_VALUE || this.props.config.lostPatternMode === CUSTOM_PATTERN_VALUE){
      this.handleCustomPattern(true);
    }
  }

  handleCustomPattern(display){
    this.setState({
      customPatternFormVisible: display
    });
  }

  handleSubmit(values, {resetForm} ) {
    const data = {
      ...values,
      lostStreamMode: parseInt(values.lostStreamMode),
      lostPatternMode: parseInt(values.lostPatternMode)
    };
     this.props.onSubmit(data);
     resetForm(values);
     this.props.closeModal();
  }
  
  handleValidation = (values) => {
    const errors = {};
    //Name
    if (isEmptyString(values.name)) {
      errors.name = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if(this.props.forbiddenNames.indexOf(values.name) !== -1){
      errors.name = 'genericLabel.DUPLICATED_VALUES.text';
    }
    if (values.name.length > 24) {
      errors.name = 'genericLabel.TOO_LONG.text';
    }

    const offset = values.refTimingOffset;
    if (isNaN(offset)) {
      errors.refTimingOffset = 'genericLabel.REQUIRED_FIELD.text';
    }
    if (offset < MIN_REF_OFFSET || offset > MAX_REF_OFFSET) {
      errors.refTimingOffset = 'genericLabel.REF_TIMING_OFFSET_INTERVAL.text';
    }

    return errors;
  };

  render() {

    const props = this.props;
    const {translate, config, outputStatus, outputStandards, lostStreamModes, outputId, refInputStatus } = props;
    return (
      <Formik initialValues={{
                name: config.name,
                outputStandard: config.outputStandard,
                sameAsInput: config.sameAsInput,
                lostStreamMode: `${config.lostStreamMode}`, // We transform to string here so that 'dirty' prop works
                lostStreamPolicy: config.lostStreamPolicy,
                lostPatternMode: `${config.lostPatternMode}`,
                refTimingOffset: config.refTimingOffset,
                enableDisplayName: config.enableDisplayName,
                }}
              validate={this.handleValidation}
              validateOnBlur={false}
              validateOnChange={true}
              onSubmit={this.handleSubmit}>
        {({
            values,
            errors,
            dirty,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue
            /* and other goodies */
          }) => (
          <Form className="output-settings-form"
                onSubmit={handleSubmit}>

            <HelpLayout filename="c_sh_sdi_output_settings.html"
              form={<Fragment>

                {outputStatus !== STATUS_OFF &&
                <Alert color="warning">
                  <Translate id="genericLabel.WARNING_LOCAL_OUTPUT.text"/>
                </Alert>
                }

                <FormGroup>
                  <Label for="name">
                    <Translate id="genericLabel.NAME.text"/>
                  </Label>
                  <Input type="text"
                         required
                         name="name"
                         id="output_hardwareSetting_name"
                         invalid={errors.name !== undefined}
                         placeholder={translate("genericLabel.NAME.text")}
                         value={values.name}
                         onBlur={handleBlur}
                         onChange={handleChange}/>
                  <FormFeedback>
                    <Translate id={errors.name}/>
                  </FormFeedback>
                  <div className="indicator">
                    <Translate id={`genericLabel.NAME_DISPLAYED_ON_PREVIEW_AND_OUTPUT.text`}/>
                  </div>
                </FormGroup>

                <FormGroup>
                  <Label for="outputStandard">
                    <Translate id="genericLabel.OUTPUT_STANDARD.text"/>
                  </Label>
                  <Input type="select"
                         name="outputStandard"
                         id="output_hardwareSetting_outputStandard"
                         onBlur={handleBlur}
                         onChange={handleChange}
                         value={values.outputStandard}>
                    {outputStandards.map(standard => {
                      return <option key={standard} value={standard}>{standard}</option>
                    })}
                  </Input>
                </FormGroup>

                <FormGroup check>
                  <Label check>
                    <Input type="checkbox"
                           name="sameAsInput"
                           id="output_hardwareSetting_sameAsInput"
                           onBlur={handleBlur}
                           onChange={handleChange}
                           checked={values.sameAsInput}/>{' '}
                    <Translate id="genericLabel.SAME_AS_CAMERA_VIDEO_STANDARD.text"/>
                  </Label>
                </FormGroup>

                {values.lostStreamMode !== 0 &&
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox"
                           name="enableDisplayName"
                           id="output_hardwareSetting_enableDisplayName"
                           onChange={handleChange}
                           checked={values.enableDisplayName}/>{' '}
                    <Translate id="genericLabel.DISPLAY_OUTPUT_NAME.text"/>
                  </Label>
                </FormGroup>
                }
                
                <FormGroup>
                  <Label for="refTimingOffset">
                    <Translate id="genericLabel.REF_TIMING_OFFSET.text"/>
                  </Label>
                  <Input type="number"
                         required
                         name="refTimingOffset"
                         min={MIN_REF_OFFSET}
                         max={MAX_REF_OFFSET}
                         id="output_hardwareSetting_refTimingOffset"
                         invalid={errors.refTimingOffset !== undefined}
                         placeholder={translate("genericLabel.REF_TIMING_OFFSET.text")}
                         value={values.refTimingOffset}
                         onBlur={handleBlur}
                         onChange={handleChange}
                         disabled={refInputStatus==="OFF"}/>
                  <FormFeedback>
                    <Translate id={errors.refTimingOffset}/>
                  </FormFeedback>
                  <div className="indicator">
                    <Translate id="genericLabel.REF_TIMING_OFFSET_HELP.text"/>
                  </div>
                </FormGroup>

                <FormGroup>
                  <Label for="lostStreamMode">
                    <Translate id="genericLabel.DEFAULT_VIDEO_PATTERN.text"/>
                  </Label>
                  <Input type="select"
                         name="lostStreamMode"
                         id="output_hardwareSetting_lostStreamMode"
                         onBlur={handleBlur}
                         onChange={(e) => {
                           handleChange(e);
                           if (parseInt(e.target.value) !== CUSTOM_PATTERN_VALUE && parseInt(values.lostPatternMode) !== CUSTOM_PATTERN_VALUE) {
                            this.handleCustomPattern(false);
                           }
                           else {
                             this.handleCustomPattern(true);
                           }
                         }}
                         value={values.lostStreamMode}>
                    {lostStreamModes.map(dest => {
                      return <option key={dest.value}
                                     value={dest.value}
                                     id={translate(`lostStreamMode.${dest.name}.text`)}>{translate(`lostStreamMode.${dest.name}.text`)}</option>
                    })}
                  </Input>
                </FormGroup>

                <FormGroup>
                  <Label for="lostPatternMode">
                    <Translate id="genericLabel.POLICY_VIDEO_PATTERN.text"/>
                  </Label>
                  <Input type="select"
                         name="lostPatternMode"
                         id="output_hardwareSetting_lostPatternMode"
                         onBlur={handleBlur}
                         onChange={(e) => {
                           handleChange(e);
                           if (parseInt(e.target.value) !== CUSTOM_PATTERN_VALUE && parseInt(values.lostStreamMode) !== CUSTOM_PATTERN_VALUE) {
                             this.handleCustomPattern(false);
                           }
                           else {
                             this.handleCustomPattern(true);
                           }
                         }}
                         value={values.lostPatternMode}>
                    {lostStreamModes.map(dest => {
                      return <option key={dest.value}
                                     value={dest.value}
                                     id={translate(`lostStreamMode.${dest.name}.text`)}>{translate(`lostStreamMode.${dest.name}.text`)}</option>
                    })}
                  </Input>
                </FormGroup>

                { this.state.customPatternFormVisible &&
                  <CustomPatternForm prefix={SETTINGS_OUTPUT_CUSTOM_PATTERN_PREFIX} id={outputId} timestamp={new Date().getTime()}/>
                }
                </Fragment>}

                buttons={
                  <FormGroup className="buttons">
                    <Button id="output_hardwareSetting_saveButton"
                            color="primary"
                            disabled={!dirty}
                            type="submit">
                      <Translate id="genericLabel.SAVE.text"/>
                    </Button>
                  </FormGroup>
                } />
          </Form>
        )}
      </Formik>
    );
  }
}

GeneralForm.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  const existingBasebandPlayerOutputNames = getBasebandPlayerOutputNames(state);
  return {
    outputStatus: state.streamhub.hardwareOutputs.find(output => output.id === ownProps.outputId).status,
    refInputStatus: state.streamhub.hardwareOutputs.find(output => output.id === ownProps.outputId).refInputStatus,
    videoCard: outputMatchingVideoCard(getAvailableVideoCards(state), state.streamhub.hardwareOutputs.find(output => output.id === ownProps.outputId)),
    config: state.config.basebandPlayer[ownProps.outputId],
    lostStreamModes: getAvailableLostStreamModes(state),
    outputStandards: getAvailableHardwareOutputStandards(state, ownProps),
    forbiddenNames: existingBasebandPlayerOutputNames.filter(name => name !== state.config.basebandPlayer[ownProps.outputId].name),
    smpteIps: state.streamhub.smpteIps,
    localIps: state.streamhub.localIps,
    licenceStatus: state.streamhub.smpte2110[1] ? state.streamhub.smpte2110[0].allowed2110 : false,
  }
};

export default connect(mapStateToProps)(withLocalize(GeneralForm));