import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {Button} from "reactstrap";
import {Translate} from "react-localize-redux";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

import {outputIpProfilePropTypes, outputPropTypes} from "../../../../utils/models-prop-types";
import IPProfileMetadata from '../../../common/ip-profile-metadata';
import {isEmptyString} from "../../../../utils/string-utils";
import Empty from "../../../common/empty";
import {OUTPUT_TYPE_IP, STATUS_LOCKED, STATUS_OFF, WEBRTC} from "../../../../constants";
import StatusBadge from '../../../common/status-badge';
import { outputIcon } from '../../../../utils/global-utils';

const matchingSearch = (profile, search) => {
  if(search.length === 0){
    return true
  }
  const lowerCaseSearch = search.toLowerCase();
  return profile.name.toLowerCase().indexOf(lowerCaseSearch) !== -1 ||
      profile.protocol.toLowerCase().indexOf(lowerCaseSearch) !== -1
};

const propTypes = {
  search: PropTypes.string.isRequired,
  output: outputPropTypes,
  ipProfiles: PropTypes.arrayOf(outputIpProfilePropTypes),
  onProfileEdit: PropTypes.func,
  onProfileAttach: PropTypes.func,
  canSelect: PropTypes.bool.isRequired,
  ipOutputs: PropTypes.arrayOf(outputPropTypes)
};

const defaultProps = {
  output: null,
  onProfileEdit: null
};

class OutputIPProfileList extends Component {

  render() {
    const { ipProfiles, onProfileEdit, onProfileDelete, search, onProfileAttach, canSelect, ipOutputs } = this.props;
    const hasActions = onProfileEdit !== null || onProfileDelete !== null;
    const matchingProfiles = ipProfiles.filter(profile => matchingSearch(profile, search));
    if(matchingProfiles.length === 0){
      if(!isEmptyString(search)){
        return (
          <Empty icon="search"
                 text={<Translate id="genericLabel.OUTPUTS_IP_PROFILES_SEARCH_MATCH_EMPTY.text"/>}
                 subText={`"${search}"`}/>
        );
      }
      else {
        return (
          <Empty icon={ <AWIcon name="tv_screen"/> }
                 text={<Translate id="genericLabel.OUTPUTS_IP_PROFILES_EMPTY.text"/>}/>
        );
      }
    }
    return (
      <div className="profiles-settings">
          { matchingProfiles
              .map(profile => {
                const matchingOutput = ipOutputs.find(output => output.ipProfileId === profile.id);
                return <div className={`profile${(canSelect && !profile.available) ? " disabled" : "" }`}  key={ profile.name }>
                  { canSelect &&
                    <div className="selection">
                      <Button id={"outputsProfile_"+profile.name+"_selectButton"}
                        className="basic icon"
                        disabled={!profile.available}
                        onClick={() => onProfileAttach(profile)}>
                        <AWIcon name="circle"/>
                      </Button>
                    </div>
                  }
                  <div className="name text-clip" id={"outputsProfile_"+profile.id+"_name"}>
                    <span title={profile.name} className="text-clip">
                    { profile.name }
                    </span>
                  </div>
                  <div className="protocol" id={"outputsProfile_"+profile.name+"_protocol"}>
                    <AWIcon name={outputIcon(OUTPUT_TYPE_IP, profile.protocol)} className="mr-1" /> { profile.protocol }
                  </div>
                  <div className="status" id={"outputsProfile_"+profile.name+"_status"}><StatusBadge status={matchingOutput ? (matchingOutput.lockstate ? STATUS_LOCKED : matchingOutput.status) : STATUS_OFF} /></div>
                  <div className="metadata">
                    <IPProfileMetadata ipProfile={profile}/>
                  </div>
                  <div className="actions">
                    { hasActions &&
                    <Fragment>
                      { profile.enable === false && onProfileEdit !== null && profile.protocol !== WEBRTC &&
                      <Button id={"outputsProfile_"+profile.name+"_editButton"}
                              className="basic icon"
                              disabled={matchingOutput ? matchingOutput.lockstate : false}
                              onClick={() => onProfileEdit(profile)}>
                        <AWIcon name="edit"/>
                      </Button>
                      }
                    </Fragment>
                    }
                  </div>
                </div>;
              }) }
          </div>
    );
  }
}

OutputIPProfileList.propTypes = propTypes;
OutputIPProfileList.defaultProps = defaultProps;

export default OutputIPProfileList;