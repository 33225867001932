import {SETTINGS_INTERCOM_INPUTS_UPDATE, SETTINGS_INTERCOM_UPDATE, SETTINGS_INTERCOM_OUTPUTS_UPDATE, SETTINGS_GET_AES67_SOURCES_LIST} from "../../../../constants";

export const updateIntercomSettings = (data) => {
  return {
    type: SETTINGS_INTERCOM_UPDATE,
    data
  }
};

export const updateIntercomInputSettings = (data) => {
  return {
    type: SETTINGS_INTERCOM_INPUTS_UPDATE,
    data
  }
};

export const updateIntercomOutputSettings = (data) => {
  return {
    type: SETTINGS_INTERCOM_OUTPUTS_UPDATE,
    data
  }
}

export const getaes67SourcesList = () => {
  return {
    type: SETTINGS_GET_AES67_SOURCES_LIST,
  }
}