import PropTypes from "prop-types";
import React from 'react';
import {Translate} from "react-localize-redux";
import { Form, Formik } from "formik";
import { Label, Input, FormGroup, Button, FormFeedback } from "reactstrap";
import { audioDevicePropTypes } from "../../../../utils/models-prop-types";
import { AWIcon } from "@aviwest/ui-kit";


const propTypes = {
    //onSubmit: PropTypes.func.isRequired,
    intercomConfig: PropTypes.shape({
        autoStart: PropTypes.bool,
        sipRtpPortStart: PropTypes.number,
        sipRtpPortRange: PropTypes.number,
        inputDevice: PropTypes.string,
        inputMode: PropTypes.string,
        inputVolume: PropTypes.string,
        outputDevice: PropTypes.string,
        outputVolume: PropTypes.string,
        playbackFramesPerPacket: PropTypes.number
      }),
      outputAudioDevices: PropTypes.arrayOf(audioDevicePropTypes),
      outputAudioDeviceSelected: audioDevicePropTypes,
      inputs: PropTypes.object.isRequired,
      config: PropTypes.object

};

const OutputRoutingForm = (props) => {
    const {
        outputAudioDeviceSelected,
        inputs,
        config,
        intercomConfig
     } = props;

    const handleSubmit = (values, { resetForm }) => {
        const activeOutputsChannels=[];
        inputs.forEach((value, index) => {
            const activeOutputChannels = [];
            values.outputDeviceValues[index].forEach((value, index) => {
            if(value === true){
                activeOutputChannels.push(index);
                }
             });
             activeOutputsChannels.push(JSON.stringify(activeOutputChannels));
        });
        const data = {
            ...values,
            inputs,
            activeOutputsChannels
        }
        props.onSubmit(data);
        values.edited=false;
        resetForm(values);

    }

    const handleValidation = (values) => {
        const errors ={};
        let validMulticastAddress = true;
        //errors.SourceAddresses=[]
        let multicastRegex = /^2(?:2[4-9]|3\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d?|0)){3}$/;
        for (let index = 0; index < values.SourceAddresses.length; index++) {
          const element = values.SourceAddresses[index+1];
            //errors["SourceAddresses"+index]={};
          if(element && !multicastRegex.test(element)){
            validMulticastAddress=false;
            console.log(element)
            errors["SourceAddresses"+(index+1)] = 'genericLabel.INVALID_IP_ADDRESS.text'
            }
          }
        console.log(errors)
        return errors;
    }

    const mapValues =(maxEntries, audioMapString) => {
        const audioMap = JSON.parse(audioMapString);
        return [...Array(maxEntries)].map((_, i) => {
          return audioMap.indexOf(i) !== -1;
        });
      };

    const init = (outputAudioDeviceSelected, inputs, intercomChannelProfile ) =>{
        var outputDeviceValues=[];
        if(outputAudioDeviceSelected){
            inputs.forEach((element, index) => {
                let temp = mapValues(outputAudioDeviceSelected.max_entries, intercomChannelProfile[element.id].audioOutputMap);
                outputDeviceValues[index]=temp;
            });
        }
        
        return outputDeviceValues;
    }
    const initSinksEnable = (intercomChannelProfile, maxEntries) =>{
        var SourceAddressEnable=[];
        for (let index = 1; index <= maxEntries; index++) {
            const element = intercomChannelProfile[index];
            SourceAddressEnable[index] = element.aes67SourceEnabled;
        }

        return SourceAddressEnable;
    }
    const initSinks = (intercomChannelProfile, maxEntries) =>{
        var SourceAddresses=[];
        for (let index = 1; index <= maxEntries; index++) {
            const element = intercomChannelProfile[index];
            SourceAddresses[index] = element.aes67SourceAddress;
        }
        return SourceAddresses;
    }

    if(!outputAudioDeviceSelected){
        return null;
    }

    const addLine=(values)=>{
        values.edited=true
        values.nbOutputVisible=values.nbOutputVisible+1 > outputAudioDeviceSelected.max_entries ? outputAudioDeviceSelected.max_entries : values.nbOutputVisible+1;
    }
    const removeLine=(values)=>{
        values.edited=true;
        values.nbOutputVisible= values.nbOutputVisible-1 <= 0 ? 1 : values.nbOutputVisible-1;
    }

    return(
        
        <Formik initialValues={{
            edited:false,
            outputDeviceValues: init(outputAudioDeviceSelected,inputs, config.intercomChannelProfile),
            SourceAddresses: initSinks(config.intercomChannelProfile, outputAudioDeviceSelected.max_entries),
            SourceAddressEnable: initSinksEnable(config.intercomChannelProfile, outputAudioDeviceSelected.max_entries),
            nbOutputVisible: intercomConfig.nbOutputVisible < outputAudioDeviceSelected.max_entries ? intercomConfig.nbOutputVisible : outputAudioDeviceSelected.max_entries
          }}
          validateOnBlur={false}
          validateOnChange={true}
          validate={ handleValidation }
          onSubmit={ handleSubmit }>
        {({
          values,
          errors,
          dirty,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
            <Form onSubmit={handleSubmit}>
                <table className="routing">
                <tr className="label_routing">
                    { inputs.map((value, key) => {
                                return <td> <Label className="vertical_routing" check key={key}>
                                    {value.name}
                                </Label></td>
                            })}
                            <td className="background_routing"> 
                                <div >
                                    <span className="bottom_routing">Output Device</span>
                                    <span className="top_routing">Streamhub Inputs</span>
                                    <div className="line_routing"></div>
                                </div>
                            </td>
                            { outputAudioDeviceSelected.is_aes67 &&
                               <> <td className="aes67check"></td><td className="aes67 center">
                                    <span>AES-67 Sinks</span>
                                </td><td className="aes67label"></td>
                                </>
                            }
                            </tr>
                    { [...Array(values.nbOutputVisible)].map((value1, key1) => {
                        var index=key1+1;
                        return <tr key={key1}>
                            { inputs.map((value, key) => {
                                return <td key={key}><FormGroup check>
                                    <Input   
                                            type="checkbox"
                                            name={`outputDeviceValues[${key}][${key1}]`}
                                            onChange={handleChange}
                                            checked={values.outputDeviceValues[key][key1]}/>
                                </FormGroup></td>
                            }) }
                            <td className="label_right_routing">{`o.${index}`}</td>
                            { outputAudioDeviceSelected.is_aes67 &&
                            <><td className="aes67check">
                                <Input  id={"AES67Settings_sinksEnable_"+index}
                                        type="checkbox"
                                        name={`SourceAddressEnable[${index}]`}
                                        onChange={handleChange}
                                        checked={ values.SourceAddressEnable[index]}/></td>
                            <td className="aes67">
                              <FormGroup>
                              <Input type="text"
                                        name={`SourceAddresses[${index}]`}
                                        id={"AES67Settings_sinks_"+index}
                                        value={values.SourceAddresses[index]}
                                        invalid={errors["SourceAddresses"+index] }
                                        onChange={handleChange}/>
                                <FormFeedback>
                                  <Translate id={errors["SourceAddresses"+index] } />
                                </FormFeedback>
                                </FormGroup>
                                </td>
                            <td className="aes67label text-clip" title={`Source #${index} - ${config.device.Identifier}`}>
                                    Source #{index} - {config.device.Identifier}
                                </td>
                            </>}
                        </tr>
                    })}
                </table>
                <FormGroup className="flex-left mb-0 mt-3">
                    <Button color="primary"
                            disabled={values.nbOutputVisible>=outputAudioDeviceSelected.max_entries}
                            onClick={()=> addLine(values)}>
                        <AWIcon name="add" />
                    </Button>
                    <Button color="primary"
                            disabled={values.nbOutputVisible<=1}
                            onClick={()=> removeLine(values)}>
                        <AWIcon name="remove" />
                    </Button>
                </FormGroup>
                <FormGroup className="buttons">
                    <Button color="primary"
                            disabled={(isSubmitting || !dirty) && !values.edited}
                            type="submit">
                        <Translate id="genericLabel.SAVE.text"/>
                    </Button>
          </FormGroup>
            </Form>
        )}
        </Formik>
        
    );
};
OutputRoutingForm.prototype = propTypes;

export default OutputRoutingForm;