import React from 'react';
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

import DeviceIcon from '../../../../common/device-icon/index';
import { inputPropTypes, inputIpProfilePropTypes } from "../../../../../utils/models-prop-types";
import {INPUT_TYPE_DEVICE, INPUT_TYPE_IP, SST} from "../../../../../constants";
import { Translate } from "react-localize-redux";


const propTypes = {
  input: inputPropTypes.isRequired,
  ipProfile: inputIpProfilePropTypes
};

const Source = (props) => {
    const { input, ipProfile } = props;
    const { deviceType, deviceDisplayName, type, deviceVersion } = input;
    if(type === INPUT_TYPE_IP && ipProfile){
      return (
          <div className="source">
            <AWIcon name="tv_screen"/>
            <span className="type protocol"><Translate id={`channelType.${ipProfile.protocol}.text`} /></span>
          </div>
      );
    }
    else if(type === INPUT_TYPE_DEVICE){
      return (
          <div className="source">
            <DeviceIcon deviceType={ deviceType }/>
            <span className="type">
              { deviceDisplayName } - { deviceVersion }
            </span>
          </div>
      );
    }
    else {
      return (
          <div className="source">
            <AWIcon name="video_camera"/>
            <span className="type protocol">{ SST }</span>
          </div>
      );
    }
};

Source.propTypes = propTypes;

export default Source;