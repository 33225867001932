import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import React from 'react';
import {Translate} from "react-localize-redux";
import { Button, FormGroup, Input, Label } from "reactstrap";

const propTypes = {
    config: PropTypes.object
};

const AES67Form = (props) => {
    const{

    } = props;

    const handleSubmit = (values, {resetForm}) => {
        console.log(values)
        //props.onSubmit(values);
        resetForm(values);
    }

    const handleValidation = (values) => {
        const errors = {};

        return errors;
    }

    return(
        <Formik initialValues={{
                    jsp: "Option 3"
                }}
                validateOnBlur={false}
                validateOnChange={true}
                validate={ handleValidation }
                onSubmit={ handleSubmit }>
        {({
          values,
          errors,
          dirty,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
            <Form onSubmit={handleSubmit}>
                AES FORM FROM FORM FILE
                <FormGroup>
                <Label for="networkInterface">
                    Network Interface
                  </Label>
                  <Input type="select"
                        name="networkInterface"
                        id="AES67Settings_inputMode"
                        value={values.jsp}
                        onChange={handleChange}>
                        <option>Option 1 </option>
                        <option>Option 2 </option>
                        <option>Option 3 </option>
                        <option>Option 4 </option>
                  </Input>
                </FormGroup>
                <FormGroup className="buttons">
                    <Button id="intercomSettings_submit"
                            color="primary"
                            disabled={isSubmitting || !dirty}
                            type="submit">
                        <Translate id="genericLabel.SAVE.text"/>
                    </Button>
                </FormGroup>
            </Form>)}
        </Formik>

    )
};
AES67Form.prototype = propTypes;

export default AES67Form;