import {
  RECEIVED_LOGS,
  RECEIVED_NEW_LOG,
  TOOLS_LOGS_FILTERS_CHANGE,
  TOOLS_LOGS_PAGE_CHANGE
} from "../../../../constants";

const initialState = {
  list: null,
  warningCount: 0,
  errorCount: 0,
  filters: {
    info: true,
    warning: true,
    error: true
  },
  currentPage: 1,
  perPage: 25,
  pagesTotal: 1
};

const logsReducer = (state = initialState, action) => {
  if(action.type === RECEIVED_LOGS){
    return {
        ...state,
      list: action.logs,
      warningCount: 0,
      errorCount: 0,
      pagesTotal: action.pagesTotal
    };
  }

  if(action.type === RECEIVED_NEW_LOG){
    return {
      ...state,
      warningCount: action.counterWarning,
      errorCount: action.counterDanger
    };
  }

  if(action.type === TOOLS_LOGS_FILTERS_CHANGE){
    return {
      ...state,
      filters: action.filters,
      currentPage: 1
    }
  }

  if(action.type === TOOLS_LOGS_PAGE_CHANGE){
    return {
      ...state,
      currentPage: action.page,
      perPage: action.perPage
    }
  }

  return state;
};

export default logsReducer;