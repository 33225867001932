import PropTypes from "prop-types";
import React from 'react';
import {Translate} from "react-localize-redux";
import {FormFeedback, Label, Input, FormGroup, Button} from "reactstrap";
import {isEmptyString} from "../../../../utils/string-utils";
import {Form, Formik} from "formik";

const propTypes = {
  config: PropTypes.shape({
    enable: PropTypes.bool.isRequired,
    cleanPeriod: PropTypes.number
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const CLEAN_PERIOD_MIN = 1
const CLEAN_PERIOD_MAX = 365

const MediaSettingsFormCleaner = (props) => {
  const { config } = props;

  const handleSubmit = (values, { resetForm }) => {
    if(!values.enable && isEmptyString(values.cleanPeriod)){
      values.cleanPeriod = props.config.cleanPeriod;
    }
    props.onSubmit(values);
    resetForm(values);
  };

  const handleValidation = (values) => {
    const errors = {};

    if(values.enable){
      if(isEmptyString(values.cleanPeriod)) {
        errors.cleanPeriod = 'genericLabel.REQUIRED_FIELD.text';
      }

      if(values.cleanPeriod < CLEAN_PERIOD_MIN || values.cleanPeriod > CLEAN_PERIOD_MAX){
        errors.cleanPeriod = 'genericLabel.REQUIRED_FIELD.text';
      }
    }
    return errors;
  };


  return (
    <Formik initialValues={{
              enable: config.enable,
              cleanPeriod: config.cleanPeriod
            }}
            validateOnBlur={false}
            validateOnChange={true}
            validate={ handleValidation }
            onSubmit={ handleSubmit }>
      {({
          values,
          errors,
          dirty,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
        <Form onSubmit={handleSubmit}>
          <FormGroup check>
            <Label check>
              <Input id="media_cleaner_enableCleaner"
                     type="checkbox"
                     name="enable"
                     onChange={handleChange}
                     checked={values.enable}/>{' '}
              <Translate id="genericLabel.ENABLE_MEDIA_CLEANER.text"/>
            </Label>
          </FormGroup>
          <FormGroup>
            <Label for="cleanPeriod">
              <Translate id="genericLabel.STORAGE_PERIOD.text"/>
            </Label>
            <Input type="number"
                   min={CLEAN_PERIOD_MIN}
                   max={CLEAN_PERIOD_MAX}
                   name="cleanPeriod"
                   id="media_cleaner_cleanPeriod"
                   invalid={ errors.cleanPeriod !== undefined }
                   disabled={!values.enable}
                   value={values.cleanPeriod}
                   onBlur={handleBlur}
                   onChange={handleChange}/>
            <div className="indicator">
              <Translate id="genericLabel.DAYS.text"/>
            </div>
            <FormFeedback>
              <Translate id={errors.cleanPeriod}/>
            </FormFeedback>
          </FormGroup>
          <FormGroup className="buttons">
            <Button id="media_cleaner_saveButton"
                    color="primary"
                    disabled={isSubmitting || !dirty}
                    type="submit">
              <Translate id="genericLabel.SAVE.text"/>
            </Button>
          </FormGroup>
        </Form>
      )}
    </Formik>
  );
};

MediaSettingsFormCleaner.propTypes = propTypes;

export default MediaSettingsFormCleaner;