import PropTypes from 'prop-types';
import React from 'react';
import {Translate, withLocalize} from 'react-localize-redux';
import { connect } from 'react-redux';
import { Alert, Button, Form, FormFeedback, FormGroup, Input } from 'reactstrap';
import {Formik} from "formik";

import { logIn, loadIdentity } from './login.actions';
import { isEmptyString } from '../../../utils/string-utils';
import {
  ERROR_LOGIN_WRONG_LOGIN,
  ERROR_LOGIN_WRONG_PASSWORD,
  ERROR_LOGIN_TOO_MANY_ATTEMPTS
} from '../../../constants';
import AWAuthLayout from "@aviwest/ui-kit/dist/js/layouts/auth-layout";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

import authBackground from '../../../assets/img/auth-background.png';
import StreamHubLogo from '../../common/streamhub-logo';

const propTypes = {
  globalError: PropTypes.string,
  globalErrorColor: PropTypes.string,
  logIn: PropTypes.func.isRequired,
  loadIdentity: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

const LoginPage = (props) => {

  const handleFormSubmit = (values) => {
    props.logIn(values.login, values.password);
  };

  const handleValidation = (values) => {
    const errors = {};
    if(isEmptyString(values.login)){
      errors.login = 'genericLabel.INVALID_LOGIN.text';
    }
    if(isEmptyString(values.password)){
      errors.password = 'genericLabel.INVALID_PASSWORD.text'
    }

    return errors;
  };

  const { translate, globalError, globalErrorColor, loading } = props;
  return (
  <AWAuthLayout title={<StreamHubLogo width={350} />} point={false} backgroundImage={authBackground} subtitle={translate("genericLabel.TAGLINE.text")}>
    <div className="login">
      <div className="icon">
        <AWIcon name="account"/>
      </div>
      <Formik initialValues={{
                login: '',
                password: ''
              }}
              validate={ handleValidation }
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={ handleFormSubmit }>
        {({
            values,
            errors,
            dirty,
            touched,
            handleChange,
            handleBlur,
            handleSubmit
            /* and other goodies */
          }) => (
        <Form onSubmit={handleSubmit}>

          <Alert color={globalErrorColor}
                 isOpen={globalError !== null}>
            <Translate id={globalError} />
          </Alert>

          <FormGroup>
            <Input type="text"
                   required
                   name="login"
                   id="login"
                   invalid={errors.login !== undefined}
                   placeholder={translate("genericLabel.LOGIN.text")}
                   value={values.login}
                   onBlur={handleBlur}
                   onChange={handleChange}/>
            <FormFeedback>
              <Translate id={errors.login} />
            </FormFeedback>
          </FormGroup>

          <FormGroup>
            <Input type="password"
                   required
                   name="password"
                   id="password"
                   invalid={errors.password !== undefined}
                   placeholder={translate("genericLabel.PASSWORD.text")}
                   value={values.password}
                   onBlur={handleBlur}
                   onChange={handleChange}/>
            <FormFeedback>
              <Translate id={errors.password} />
            </FormFeedback>
          </FormGroup>

          <FormGroup>
            <Button id="login_logInButton"
                    color="primary"
                    type="submit"
                    block
                    disabled={loading}>
              <Translate id="genericLabel.LOG_IN.text" />
            </Button>
          </FormGroup>
        </Form>
        )}
      </Formik>
    </div>
  </AWAuthLayout>
  );
};

LoginPage.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  const { login } = state;
  const { error: loginError } = login;

  let globalError = null;
  let globalErrorColor = null;

  if (loginError) {
    globalErrorColor = 'danger';
    switch (loginError) {
      case ERROR_LOGIN_WRONG_LOGIN:
        globalError = 'genericLabel.INVALID_LOGIN.text';
        break;
      case ERROR_LOGIN_WRONG_PASSWORD:
        globalError = 'genericLabel.INVALID_PASSWORD.text';
        break;
      case ERROR_LOGIN_TOO_MANY_ATTEMPTS:
        globalError = 'genericLabel.USER_ACCOUNT_IS_LOCKED.text';
        break;
      default:
        globalError = 'genericLabel.ERROR.text';
        break;
    }
  }
  return {
    globalError,
    globalErrorColor
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    logIn: (login, password) => dispatch(logIn({ login, password })),
    loadIdentity: () => dispatch(loadIdentity())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(LoginPage));