import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Input, Label } from 'reactstrap';
import {Translate, withLocalize} from "react-localize-redux";
import AWPopup, {AWPopupCol, AWPopupRow} from "@aviwest/ui-kit/dist/js/components/popup";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

export const hasAtLeastOneFilter = (filters) => {
  return filters.devices === false ||
    filters.hardware === false ||
    filters.ndiOutputs === false ||
    filters.multiView === false ||
    filters.ipInputs === false ||
    filters.ipOutputs === false ||
    filters.statusOff === false ||
    filters.statusOn === false ||
    filters.statusLive === false;
};

const filtersShape = PropTypes.shape({
  devices: PropTypes.bool,
  hardware: PropTypes.bool,
  ndiOutputs: PropTypes.bool,
  multiView: PropTypes.bool,
  ipInputs: PropTypes.bool,
  ipOutputs: PropTypes.bool,
  statusOff: PropTypes.bool,
  statusOn: PropTypes.bool,
  statusLive: PropTypes.bool
});

const propTypes = {
  onChange: PropTypes.func.isRequired,
  filters: filtersShape.isRequired,
  id: PropTypes.string
};

class DashboardFilters extends Component {
  constructor(props){
    super(props);

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleClearFilters = this.handleClearFilters.bind(this);

    this.state = {
      filters: props.filters,
      hasAtLeastOneFilter: hasAtLeastOneFilter(props.filters)
    }
  }

  componentDidUpdate(newProps){
    const { filters } = this.state;
    if(newProps.filters.devices !== filters.devices ||
      newProps.filters.hardware !== filters.hardware ||
      newProps.filters.ndiOutputs !== filters.ndiOutputs ||
      newProps.filters.multiView !== filters.multiView ||
      newProps.filters.ipInputs !== filters.ipInputs ||
      newProps.filters.ipOutputs !== filters.ipOutputs ||
      newProps.filters.statusOff !== filters.statusOff ||
      newProps.filters.statusOn !== filters.statusOn ||
      newProps.filters.statusLive !== filters.statusLive){
      this.setState({
        filters: newProps.filters,
        hasAtLeastOneFilter: hasAtLeastOneFilter(newProps.filters)
      });
    }
  }

  handleCheckboxChange(name, checked) {
    const state = this.state;
    state.filters[name] = checked;
    state.hasAtLeastOneFilter = hasAtLeastOneFilter(state.filters);
    this.setState(state);
    this.props.onChange(state.filters);
  }

  handleClearFilters(){
    const state = this.state;
    const newState = {
      hasAtLeastOneFilter: false,
      filters: {
        devices: state.filters.devices !== undefined ? true : undefined,
        multiView: state.filters.multiView !== undefined ? true : undefined,
        hardware: state.filters.hardware !== undefined ? true : undefined,
        ndiOutputs: state.filters.ndiOutputs !== undefined ? true : undefined,
        ipInputs: state.filters.ipInputs  !== undefined ? true : undefined,
        ipOutputs: state.filters.ipOutputs  !== undefined ? true : undefined,
        statusOff: true,
        statusOn: true,
        statusLive: true
      }
    };
    this.setState(newState);
    this.props.onChange(newState.filters);
  }

  render(){
    const { filters, hasAtLeastOneFilter } = this.state;
    const {
      devices,
      multiView,
      hardware,
      ndiOutputs,
      ipInputs,
      ipOutputs,
      statusOff,
      statusOn,
      statusLive
    } = filters;
    const {translate, id } = this.props;
    return (
    <AWPopup direction="bottom"
             arrow={true}
             portal={true}
             shadow={true}
             renderTrigger={({ onClick, ref, className }) => (
               <Button id={"dashboard_"+id+"_filterButton"}
                       className={`basic icon ${className}`} title={translate('genericLabel.FILTERS.text')}
                       innerRef={ref}
                       size="sm"
                       onClick={onClick}>
                 <AWIcon name="filter"
                         className={hasAtLeastOneFilter ? 'primary' : undefined}/>
               </Button>
             )}>
        {() => (
          <div className="dashboard-filters">
            <div className="aw-popup-header">
              <div className="title">
                <Translate id="genericLabel.FILTERS.text"/>
              </div>
              <Button id={"dashboard_"+id+"_filterAllButton"}
                      className="basic action"
                      onClick={this.handleClearFilters}>
                <Translate id="genericLabel.FILTERS_ALL.text"/>
              </Button>
            </div>

            <AWPopupRow>
              <AWPopupCol>

                { hardware !== undefined &&
                <div>
                  <Label check>
                    <Input id={"dashboard_"+id+"_filterHardware"}
                           checked={hardware}
                           onChange={(e) => this.handleCheckboxChange('hardware', e.target.checked)}
                           type="checkbox"
                    />
                    {' '}
                    <Translate id="genericLabel.FILTER_HARDWARE.text"/>
                  </Label>
                </div>
                }
                { ndiOutputs !== undefined &&
                <div>
                  <Label check>
                    <Input id={"dashboard_"+id+"_filterNDI"}
                           checked={ndiOutputs}
                           onChange={(e) => this.handleCheckboxChange('ndiOutputs', e.target.checked)}
                           type="checkbox"
                    />
                    {' '}
                    <Translate id="genericLabel.FILTER_NDI_OUTPUTS.text"/>
                  </Label>
                </div>
                }
                { devices !== undefined &&
                <div>
                  <Label check>
                    <Input id={"dashboard_"+id+"_filterDevice"}
                           checked={devices}
                           onChange={(e) => this.handleCheckboxChange('devices', e.target.checked)}
                           type="checkbox"/>
                    {' '}
                    <Translate id="genericLabel.FILTER_DEVICES.text"/>
                  </Label>
                </div>
                }
                { ipInputs !== undefined &&
                <div>
                  <Label check>
                    <Input id={"dashboard_"+id+"_filterIpProfile"}
                           checked={ipInputs}
                           onChange={(e) => this.handleCheckboxChange('ipInputs', e.target.checked)}
                           type="checkbox"/>
                    {' '}
                    <Translate id="genericLabel.FILTER_IP_PROFILES.text"/>
                  </Label>
                </div>
                }
                { ipOutputs !== undefined &&
                <div>
                  <Label check>
                    <Input id={"dashboard_"+id+"_filterIpProfile"}
                           checked={ipOutputs}
                           onChange={(e) => this.handleCheckboxChange('ipOutputs', e.target.checked)}
                           type="checkbox"/>
                    {' '}
                    <Translate id="genericLabel.FILTER_IP_PROFILES.text"/>
                  </Label>
                </div>
                }
                { multiView !== undefined &&
                <div>
                  <Label check>
                    <Input id={"dashboard_"+id+"_filterMultiview"}
                           checked={multiView}
                           onChange={(e) => this.handleCheckboxChange('multiView', e.target.checked)}
                           type="checkbox"/>
                    {' '}
                    <Translate id="genericLabel.FILTER_MULTIVIEW.text"/>
                  </Label>
                </div>
                }
              </AWPopupCol>
              <AWPopupCol>
                <div>
                  <Label check>
                    <Input id={"dashboard_"+id+"_filterOff"}
                           checked={statusOff}
                           onChange={ (e) => this.handleCheckboxChange('statusOff', e.target.checked) }
                           type="checkbox"/>
                    {' '}
                    <Translate id="genericLabel.FILTER_OFF.text"/>
                  </Label>
                </div>
                <div>
                  <Label check>
                    <Input id={"dashboard_"+id+"_filterOn"}
                           checked={statusOn}
                           onChange={ (e) => this.handleCheckboxChange('statusOn', e.target.checked) }
                           type="checkbox"/>
                    {' '}
                    <Translate id="genericLabel.FILTER_ON.text"/>
                  </Label>
                </div>
                <div>
                  <Label check>
                    <Input id={"dashboard_"+id+"_filterLive"}
                           checked={statusLive}
                           onChange={ (e) => this.handleCheckboxChange('statusLive', e.target.checked) }
                           type="checkbox"/>
                    {' '}
                    <Translate id="genericLabel.FILTER_LIVE.text"/>
                  </Label>
                </div>
              </AWPopupCol>
            </AWPopupRow>
          </div>
        )}
      </AWPopup>
    );
  }
}

DashboardFilters.propTypes = propTypes;

export default withLocalize(DashboardFilters);

export const filtersPropTypes = filtersShape;