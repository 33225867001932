import {
  HELP_MODAL
} from "../constants";


export const openHelpModal = (filename, open) => {
  return {
    type: HELP_MODAL,
    open,
    filename
  }
};