import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {formatBitrate} from "../../../../../utils/global-utils";

const propTypes = {
  inputId: PropTypes.string.isRequired,
  totalRxBitrate: PropTypes.number,
  totalBitrate: PropTypes.number
};

class BitrateIndicator extends Component {
  render(){
    if(!this.props.totalRxBitrate && !this.props.totalBitrate){
      return null;
    }
    if(this.props.totalRxBitrate){
      return (
        <div className="bitrate-indicator text-clip">{ formatBitrate(this.props.totalRxBitrate) }</div>
      );
    }
    if(this.props.totalBitrate){
      return (
        <div className="bitrate-indicator text-clip">{ formatBitrate(this.props.totalBitrate) }</div>
      );
    }
  }
}

BitrateIndicator.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  const stats = state.datastore.inputStreamStats[ownProps.inputId];
  return {
    totalRxBitrate: stats ? stats.data[stats.data.length - 1].totalRxBitrate : null,
    totalBitrate: stats ? stats.data[stats.data.length - 1].totalBitrate : null
  }
};

export default connect(mapStateToProps)(BitrateIndicator);