import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Translate, withLocalize} from "react-localize-redux";
import {ClipLoader} from "react-spinners";
import {SPINNER_FTP_COLOR} from "../../../../constants";
import {getAvailableFTPTransferModes, getAvailableFTPTransferTypes} from "../../../../misc/capabilities.selectors";
import {
  Button, Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label, Row,
  Alert
} from "reactstrap";
import {Formik} from "formik";

import PasswordRevealInput from "../../../common/password-reveal-input";
import {
  ftpURI,
} from "../../../../misc/streamhub.adapters";
import {
  testFileTransfer
} from "./file-transfer.settings.actions";
import {
  FILE_TRANSFER_MODE_ACTIVE,
  FILE_TRANSFER_MODE_PASSIVE,
  FILE_TRANSFER_TYPE_FTP,
  FILE_TRANSFER_TYPE_sFTP
} from "../../../../constants";
import {generateUniqueId, isEmptyString, isFTPNameValid} from "../../../../utils/string-utils";

const propTypes = {
  editingFileTransferId: PropTypes.string,
  fileTransfer: PropTypes.shape({
    enable: PropTypes.bool.isRequired,
    deleteFileAfterTransfer: PropTypes.bool.isRequired,
    createDir: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    host: PropTypes.string.isRequired,
    username: PropTypes.string,
    password: PropTypes.string,
    path: PropTypes.string,
    port: PropTypes.number,
    rule: PropTypes.string,
    transferMode: PropTypes.oneOf([FILE_TRANSFER_MODE_ACTIVE, FILE_TRANSFER_MODE_PASSIVE]),
    transferType: PropTypes.oneOf([FILE_TRANSFER_TYPE_FTP, FILE_TRANSFER_TYPE_sFTP]),
  }),
  ftpTransferModes: PropTypes.arrayOf(PropTypes.string).isRequired,
  fileTransferTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  forbiddenNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  testResult: PropTypes.string,
  callTestFileTransfer: PropTypes.func.isRequired
};

const PORT_MIN = 21;
const PORT_MAX = 65535;

class FileTransferForm extends Component {

  constructor(props){
    super(props);
    this.state = {
      testing: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTest = this.handleTest.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
  }

  handleSubmit(values, { resetForm }){
    this.props.onSubmit(values);
  }

  handleTest(fileTransfer){
    this.setState({
      testing: true
    });
    this.props.callTestFileTransfer(fileTransfer)
  }

  handleValidation(values){
    const errors = {};

    const { forbiddenNames, fileTransfer } = this.props;

    // Name
    if(isEmptyString(values.name)){
      errors.name = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if(values.name.length >= 32){
      errors.name = 'genericLabel.TOO_LONG.text';
    }
    else if(!isFTPNameValid(values.name)){
      errors.name = 'genericLabel.INVALID_FORMAT.text';
    }
    else if(forbiddenNames.filter(name => fileTransfer ? name !== fileTransfer.name : true).indexOf(values.name) !== -1){
      errors.name = 'genericLabel.DUPLICATED_VALUES.text';
    }

    // Host
    if(isEmptyString(values.host)){
      errors.host = 'genericLabel.REQUIRED_FIELD.text';
    }

    // Port
    if(isEmptyString(values.port)){
      errors.port = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if(values.port < PORT_MIN || values.port > PORT_MAX){
      errors.port = 'genericLabel.INVALID_FORMAT.text';
    }

    // Rule
    if(isEmptyString(values.rule)){
      errors.rule = 'genericLabel.REQUIRED_FIELD.text';
    }
    // else if(!isFTPRuleValid(values.rule)){
    //   errors.rule = 'genericLabel.INVALID_FORMAT.text';
    // }

    if(isEmptyString(values.username)){
      errors.username = 'genericLabel.REQUIRED_FIELD.text';
    }

    if(isEmptyString(values.password)){
      errors.password = 'genericLabel.REQUIRED_FIELD.text';
    }

    if(isEmptyString(values.path)){
      errors.path = 'genericLabel.REQUIRED_FIELD.text';
    }

    return errors;
  }

  render() {
    const { fileTransfer, translate, ftpTransferModes, fileTransferTypes, testResult } = this.props;
    return (
      <Formik initialValues={{
                enable: true,
                deleteFileAfterTransfer: false,
                createDir: false,
                name: '',
                host: '',
                port: 21,
                username: '',
                password: '',
                transferType: FILE_TRANSFER_TYPE_FTP,
                transferMode: FILE_TRANSFER_MODE_PASSIVE,
                path: '/',
                rule: '.*',
                uniqueId: fileTransfer && fileTransfer.uniqueId ? fileTransfer.uniqueId : generateUniqueId(),
                ...fileTransfer // That way, we override all properties if profile exists
              }}
              validate={ this.handleValidation }
              validateOnBlur={false}
              validateOnChange={true}
              onSubmit={ this.handleSubmit }>
        {({
            values,
            errors,
            dirty,
            isValid,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue
            /* and other goodies */
          }) => (
      <Form onSubmit={ handleSubmit }>
        <FormGroup>
          <Label for="name">
            <Translate id="genericLabel.NAME.text"/>
          </Label>
          <Input type="text"
                 name="name"
                 id="settings_FileTransfer_form_name"
                 disabled={!values.enable}
                 invalid={errors.name !== undefined}
                 placeholder={ translate('genericLabel.NAME.text') }
                 value={values.name}
                 onBlur={handleBlur}
                 onChange={handleChange}/>
          <Input type="hidden"
                     name="uniqueId"
                     id="uniqueId"
                     readOnly={true}
                     value={values.uniqueId}/>       
          <FormFeedback>
            <Translate id={errors.name} />
          </FormFeedback>
        </FormGroup>

        <Row form>
          <Col>
            <FormGroup check>
              <Label check>
                <Input id="settings_FileTransfer_form_enableFTP"
                       type="checkbox"
                       name="enable"
                       onBlur={handleBlur}
                       onChange={handleChange}
                       checked={values.enable}/>{' '}
                <Translate id="genericLabel.ENABLE_FTP.text"/>
              </Label>
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col>
            <FormGroup check>
              <Label check>
                <Input id="settings_FileTransfer_form_enableAutoDelete"
                       type="checkbox"
                       name="deleteFileAfterTransfer"
                       onBlur={handleBlur}
                       onChange={handleChange}
                       checked={values.deleteFileAfterTransfer}/>{' '}
                <Translate id="genericLabel.AUTOMATICALLY_DELETE_AFTER_TRANSFER.text"/>
              </Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup check>
              <Label check>
                <Input id="settings_FileTransfer_form_enableCreatePath"
                       type="checkbox"
                       name="createDir"
                       disabled={!values.enable}
                       onBlur={handleBlur}
                       onChange={handleChange}
                       checked={values.createDir}/>{' '}
                <Translate id="genericLabel.CREATE_DIR.text"/>
              </Label>
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col>
            <FormGroup>
              <Label for="transferMode">
                <Translate id="genericLabel.MODE.text"/>
              </Label>
              <Input type="select"
                     name="transferMode"
                     id="settings_FileTransfer_form_transferMode"
                     disabled={!values.enable}
                     value={values.transferMode}
                     onChange={handleChange}>
                { ftpTransferModes.map(mode => (
                  <option key={mode} value={mode}>{ mode }</option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="transferType">
                <Translate id="genericLabel.TYPE.text"/>
              </Label>
              <Input type="select"
                     name="transferType"
                     id="settings_FileTransfer_form_transferType"
                     disabled={!values.enable}
                     value={values.transferType}
                     onChange={handleChange}>
                { fileTransferTypes.map(type => (
                  <option key={type} value={type}>{ type }</option>
                ))}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col>
            <FormGroup>
              <Label for="host">
                <Translate id="genericLabel.HOST.text"/>
              </Label>
              <Input type="text"
                     name="host"
                     id="settings_FileTransfer_form_host"
                     disabled={!values.enable}
                     invalid={errors.host !== undefined}
                     placeholder={ translate('genericLabel.HOST.text') }
                     value={values.host}
                     onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.host} />
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="port">
                <Translate id="genericLabel.PORT.text"/>
              </Label>
              <Input type="number"
                     name="port"
                     id="settings_FileTransfer_form_port"
                     min={PORT_MIN}
                     max={PORT_MAX}
                     disabled={!values.enable}
                     invalid={errors.port !== undefined}
                     placeholder={ translate('genericLabel.PORT.text') }
                     value={values.port}
                     onBlur={handleBlur}
                     onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.port} />
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col>
            <FormGroup>
              <Label for="username">
                <Translate id="genericLabel.USERNAME.text"/>
              </Label>
              <Input type="text"
                     name="username"
                     id="settings_FileTransfer_form_username"
                     disabled={!values.enable}
                     invalid={errors.username !== undefined}
                     placeholder={ translate('genericLabel.USERNAME.text') }
                     value={values.username}
                     onBlur={handleBlur}
                     onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.username} />
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="password">
                <Translate id="genericLabel.PASSWORD.text"/>
              </Label>
              <PasswordRevealInput name="password"
                                   id="settings_FileTransfer_form_password"
                                   setFieldValue={setFieldValue}
                                   disabled={!values.enable}
                                   invalid={errors.password !== undefined}
                                   placeholder={ translate('genericLabel.PASSWORD.text') }
                                   value={values.password}
                                   onBlur={handleBlur}
                                   onChange={handleChange}
                                   error={errors.password}/>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col>
            <FormGroup>
              <Label for="path">
                <Translate id="genericLabel.PATH.text"/>
              </Label>
              <Input type="text"
                     name="path"
                     id="settings_FileTransfer_form_path"
                     disabled={!values.enable}
                     invalid={errors.path !== undefined}
                     placeholder={ translate('genericLabel.PATH.text') }
                     value={values.path}
                     onBlur={handleBlur}
                     onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.path} />
              </FormFeedback>
              <div className="indicator">
                <Translate id="genericLabel.PATH_HELP.text"/>
              </div>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="rule">
                <Translate id="genericLabel.RULES.text"/>
              </Label>
              <Input type="text"
                     name="rule"
                     id="settings_FileTransfer_form_rule"
                     disabled={!values.enable}
                     invalid={errors.rule !== undefined}
                     placeholder={ translate('genericLabel.RULES.text') }
                     value={values.rule}
                     onBlur={handleBlur}
                     onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.rule} />
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <div className="uri-preview">
          { ftpURI(values) }
        </div>
        <FormGroup className="buttons">
          { this.state.testing && testResult === null &&
          <div className="loader-container">
            <div className="loader">
              <ClipLoader loading={true}
                          color={SPINNER_FTP_COLOR}
                          size={32}/>
            </div>
          </div>
          }
          <Button id="settings_FileTransfer_form_testButton"
                  color="secondary"
                  disabled={(dirty && !isValid) || !values.enable || !values.host || (this.state.testing  && testResult === null)}
                  onClick={() => this.handleTest(values)}>
            <Translate id="genericLabel.TEST_FTP.text"/>
          </Button>
          { this.props.onCancel &&
          <Button className="btn-cancel" onClick={this.props.onCancel}>
            <Translate id="genericLabel.CANCEL.text"/>
          </Button>
          }
          <Button id="settings_FileTransfer_form_saveButton"
                  color="primary"
                  disabled={ !isValid || isSubmitting || !dirty}
                  type="submit">
            <Translate id="genericLabel.SAVE.text"/>
          </Button>
        </FormGroup>
        { testResult !== null  &&
        <Alert color={testResult === '0' ? 'success' : 'warning'}>
          <Translate id={`testFTPTransfer.${testResult}.text`}/>
        </Alert>
        }
      </Form>
        )}
      </Formik>
    );
  }
}

FileTransferForm.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  const fileTransferConfig = state.config.fileTransfer;
  const fileTransfer = fileTransferConfig ? fileTransferConfig[ownProps.editingFileTransferId] : null;
  const existingFileTransferNames = fileTransferConfig ? Object.keys(fileTransferConfig).map(key => fileTransferConfig[key].name) : [];
  const { testResult } = state.settings.fileTransfer;
  if(fileTransfer){
    fileTransfer.id = ownProps.editingFileTransferId;
  }
  return {
    fileTransfer,
    ftpTransferModes: getAvailableFTPTransferModes(state),
    fileTransferTypes: getAvailableFTPTransferTypes(state),
    forbiddenNames: existingFileTransferNames.filter(name => !fileTransfer || name !== fileTransfer.name),
    testResult
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    callTestFileTransfer: (fileTransfer) => dispatch(testFileTransfer(fileTransfer))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(FileTransferForm));