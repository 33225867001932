import { withRouter } from "react-router-dom";
import {cacheIdentityFromSso} from "./login.actions";

const LoginSso = (props) => {
  const { ssoKey, inputId } = props.match.params;
  cacheIdentityFromSso('sso', ssoKey)
  if (inputId) {
    window.location.assign(`/dashboard/${inputId}`);
  } else {
    window.location.reload();
  }
  return null
}

export default withRouter(LoginSso);