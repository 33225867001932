import {
  BANNER_QUEUE_MESSAGE,
  BANNER_PRESENT_MESSAGE,
  BANNER_DISMISS_MESSAGE,
  BANNER_OVERRIDE_MESSAGE
} from '../constants';

const initialState = {
  presentedBanner: undefined,
  queue: []
};

const bannerReducer = (state = initialState, action) => {
  switch(action.type){

    case BANNER_QUEUE_MESSAGE:
      const { id, priority, level, message, icon, autoDismissAfter, dismissible, action: bannerAction, title } = action;
      if(state.queue.findIndex(banner => banner.id === id) === -1){ // We only append banner that not already present in queue
        const queue = state.queue.slice();
        queue.push({
          id,
          priority,
          level,
          message,
          icon,
          autoDismissAfter,
          dismissible,
          action: bannerAction,
          title
        });
        return {
          ...state,
          queue: queue
        };
      }
      break;

    case BANNER_PRESENT_MESSAGE:
      const banner = state.queue.find(banner => banner.id === action.id);
      return {
        ...state,
        presentedBanner: banner ? banner : null
      };

    case BANNER_DISMISS_MESSAGE:
      return {
        ...state,
        queue: state.queue.filter(bannerObj => bannerObj.id !== action.id),
        presentedBanner: (state.presentedBanner && state.presentedBanner.id === action.id) ? null : state.presentedBanner
      };

    case BANNER_OVERRIDE_MESSAGE:
      return {
        ...state,
        presentedBanner: state.queue.find(bannerObj => bannerObj.id === action.id)
      };

    default:
      return state;
  }
  return state;
};

export default bannerReducer;